import React, {useState, useEffect} from 'react';
import AidedCollegeComponent from './AidedCollegeComponent';
import AutonomousComponent from './AutonomousComponent';
import UniversityComponent from './UniversityComponent';
import { api } from '../../../../../helpers/api';
const InstitutionTypePhaseBeneficiaries = () => {
    const [generalDetails, setGeneralDetails] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.general_info);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const type = generalDetails[0]?.type_of_institution;

    let ComponentToRender;

    switch (type) {
        case 'University':
            ComponentToRender = UniversityComponent;
            break;
        case 'Autonomous College':
            ComponentToRender = AutonomousComponent;
            break;
        case 'Aided College':
            ComponentToRender = AidedCollegeComponent;
        case 'Government College': // Considered same for ComponentToRender
            ComponentToRender = AidedCollegeComponent;
            break;
        default:
            ComponentToRender = () => <div>No components found</div>; // Wrap in function for rendering JSX directly
            break;
    }

    return <ComponentToRender />;
}
export default InstitutionTypePhaseBeneficiaries;
