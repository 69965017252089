import React from 'react';
import { Button, Container, Table } from 'reactstrap';

function GeneralInformation(props) {
    return (
        <div className="view">
            <Container>
                <div className="edit">
                    <Button color="primary" className="btn-rounded px-3" onClick={() => props.setEdit()}>
                        Edit
                    </Button>
                </div>

                <Table striped >
                    <thead>
                        <tr>
                            

                            <th>Institution Name </th>
                            <th>PFMS Unique Code</th>
                            
                        </tr>
                    </thead>
                    {/* {props.faculityData2?.map((item, k) => (
                        <tbody>
                            <tr>
                                
                                <td>{item[MANAGE_LABELS.NAME] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.DESIGNATION] ?? '--'}</td>
                                
                            </tr>
                        </tbody>
                    ))} */}
                      {/* {props.faculityData2.length === 0 ? (
                        <tbody>
                        {props.faculityData2?.map((item, k) => (
                            <tr>
                                
                               
                                <td>{item[MANAGE_LABELS.NAME] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.DESIGNATION] ?? '--'}</td>
                            </tr>
                             ))}
                        </tbody>
                     ) : (
                        <>
                         <tr>
                        <td colSpan="5" className="text-center">
                            No data found
                        </td>
                    </tr>
                </>
                )} */}
                </Table>
            </Container>
        </div>
    );
}

export default GeneralInformation;
