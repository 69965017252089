import React, { useEffect, useState } from 'react';
import { Edit } from 'react-feather';
import { api } from '../../../../../helpers/api';
import FormArea from './form';

import { Button, Table } from 'reactstrap';
import Success from '../../../success';
export default function ViewComponent(props) {
    const handleFields = () => {
        return {};
    };
    const [formData, setFormData] = useState({ ...handleFields() });
    const [formDatas, setFormDatas] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [flag, setFlag] = useState('Y');
    const [success, setSuccess] = useState(false);
    
    const [openForm, setOpenForm] = useState(false);
    const [bestPractice, setbestPractice] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editIndex, setEditIndex] = useState(null);
    const [edit, setEdit] = useState(false);
    const handleEdit = (id) => {
        const index = bestPractice.findIndex((item) => item.id === id);
        setEditIndex(index);
        setFormData({ ...bestPractice[index] });
        setOpenForm(true);
        setEdit(true);
    };

    const handleCancelEdit = () => {
        setEditIndex(null);
        setFormData({ ...handleFields() });
        setOpenForm(false);
    };
    const handleSaveData = () => {
        setSuccess(true);
    };
    const handleSuccessAction = () => {
        setSuccess(false);
        fetchData();
        if (Object.keys(formData)?.length > 0) {
            const data = formDatas;
            data.push(formData);
            setFormDatas(data);
            setFormData({ ...handleFields() });
            setOpenForm(false);
            setActiveStep(1);
        } else {
            alert('Please fill the form');
        }
    };
    const handleNext = (edit) => {
        // console.log(edit);
        setActiveStep(0);
    };
    const handleCreate = () => {
        setEdit(false);
        setOpenForm(true);
    };

    const fetchData = async () => {
        try {
            const response = await api.get(`co-institution-info`);
            const jsonData = response?.data?.data?.data?.best_practice;
            setbestPractice(jsonData);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div>
            <>
                {!openForm && (
                    <div className="d-flex justify-content-end my-3">
                        {' '}
                        <Button color="primary" onClick={() => handleCreate()}>
                            Create
                        </Button>
                    </div>
                )}
                {success && (
                    <Success
                        title="Best Practices submitted for approval by the Institution head"
                        toggle={handleSuccessAction}
                        open={success}
                    />
                )}
                {openForm && (
                    <>
                        <FormArea
                            formData={formData}
                            handleNext={handleNext}
                            activeStep={activeStep}
                            setFormData={setFormData}
                            handleSaveData={handleSaveData}
                            handleCancelEdit={handleCancelEdit}
                            edit={edit}
                            {...bestPractice[editIndex]}
                            setActiveStep={setActiveStep}
                        />
                    </>
                )}
                {!openForm && (
                    <div className="table-section w-100 ">
                        <Table striped>
                            <thead className="table-head">
                                <tr className="text-left">
                                    <th style={{ minWidth: '463px' }}>Describe the Best Practices</th>
                                    <th style={{ minWidth: '398px' }}>Remarks</th>
                                    <th style={{ minWidth: '128px' }}>Status </th>
                                    <th style={{ minWidth: '62px' }}>Actions </th>
                                </tr>
                            </thead>
                            {bestPractice?.length > 0 ? (
                                <tbody>
                                    {bestPractice.map((item, index) => (
                                        <tr key={index} className="text-left">
                                            <td className="text-justify">{item.best_practices}</td>
                                            <td className="text-justify">{item.remarks}</td>
                                            <td>
                                                {item.is_approved === 0 ? (
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                ) : item.is_approved === 2 ? (
                                                    <Button disabled color="danger" size="sm">
                                                        Reverted
                                                    </Button>
                                                ) : item.is_approved === 1 ? (
                                                    <Button disabled color="success" size="sm">
                                                        Approved
                                                    </Button>
                                                ) : 
                                                    item.is_approved === 3 && (
                                                        <Button disabled color="info" size="sm">
                                                            Reverted By RUSA Admin
                                                        </Button>
                                                    )
                                                }
                                            </td>
                                            <td>
                                                {item.is_approved === 2 ?(
                                                    <Edit
                                                        className="me-3 cursor-pointer"
                                                        onClick={() => handleEdit(item.id)}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                                {item.is_approved === 3 ?(
                                                    <Edit
                                                        className="me-3 cursor-pointer"
                                                        onClick={() => handleEdit(item.id)}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            No data
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </Table>
                    </div>
                )}
            </>
        </div>
    );
}
