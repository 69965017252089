import React from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import { MANAGE_LABELS } from '../manage/datamanager';

function PreviewInformation(props) {
    // console.log(props.status);
    // console.log(props);
    return (
        <div>
            <div className="view">
                {/* <div className="banner">OTHER ACCREDITATIONS</div> */}
                {/* <Container className="container-width"> */}
                <div className="edit">
                    <Button color="primary" className=" px-3  mt-2" onClick={() => props.setEdit()}>
                        Edit
                    </Button>
                </div>

                {/* <div className="table-section">
                        <>
                            <Row></Row>
                        </>
                    </div> */}
                <div className="table-section w-100 ">
                    <>
                        <div className="preview-page ml-0">
                            <Row>
                                <Col sm={12} md={12} className="my-3 ">
                                    <Row className="text-dark">
                                        <Col md={4} sm={4}>
                                            Total Project Cost <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                        </Col>
                                        <Col md={2} sm={2}>
                                            :
                                        </Col>
                                        <Col md={6} sm={6}>
                                            {props.data?.PROJECT_COST ?? '--'}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12} md={12} className="my-3">
                                    <Row className="text-dark">
                                        <Col md={4} sm={4}>
                                            Agreed probable amount of Contract{' '}
                                            <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                        </Col>
                                        <Col md={2} sm={2}>
                                            :
                                        </Col>
                                        <Col md={6} sm={6}>
                                            {props.data?.AGREED_CONTRACT_AMOUNT ?? '--'}
                                        </Col>
                                    </Row>
                                </Col>
                                {/* <Col sm={12} md={12} className="my-3">
                                        <Row className="text-dark">
                                            <Col md={4} sm={4}>
                                            Agency Name <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                            </Col>
                                            <Col md={2} sm={2}>
                                                :
                                            </Col>
                                            <Col md={6} sm={6}>
                                                {props.data[MANAGE_LABELS.AGENCY_NAME] ?? '--'}
                                            </Col>
                                        </Row>
                                    </Col> */}

                                <Table striped>
                                    <thead className="table-head">
                                        <tr>
                                            <th>
                                                Institution Name <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                            </th>
                                            <th>
                                                Project Cost <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                            </th>
                                            <th>
                                                Agreed Contract Amount{' '}
                                                <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                            </th>
                                            <th>
                                                Agency Name <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                            </th>
                                            <th>
                                                Approved Cost <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                            </th>
                                            <th>
                                                Amount Utilised <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                            </th>
                                            <th>
                                                % of Utilisation <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                            </th>
                                            {/* <th>
                                                                        <PlusCircle onClick={addRow} />
                                                                    </th> */}
                                        </tr>
                                    </thead>
                                    {props.data.fundingDetails?.map((item, k) => (
                                        <>
                                            <tbody>
                                                <tr className="text-left">
                                                    <td>{item.INSTITUTION_NAME ?? '--'}</td>
                                                    <td>{item.PROJECT_COST ?? '--'}</td>
                                                    <td>{item.AGREED_CONTRACT_AMOUNT ?? '--'}</td>
                                                    <td>{item.AGENCY_NAME ?? '--'}</td>
                                                    <td>{item.APPROVED_COST ?? '--'}</td>
                                                    <td>{item.AMOUNT_UTILISED ?? '--'}</td>
                                                    <td>
                                                        {((item.AMOUNT_UTILISED / item?.APPROVED_COST) * 100).toFixed(
                                                            2
                                                        ) ?? '--'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                    ))}
                                </Table>
                            </Row>
                            {/* <Row>
                                    <Col
                                        className='d-flex justify-content-between mt-4'>
                                        <Button type="submit" color="primary" outline onClick={props.handleSubmit()}>
                                            Submit for Approval
                                        </Button>
                                    </Col>
                                </Row> */}
                        </div>
                    </>
                </div>
                {/* </Container> */}
            </div>
        </div>
    );
}

export default PreviewInformation;
