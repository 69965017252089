import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';

function Nirf(props) {
    const [generalDetails, setGeneralDetails] = useState([]); // Changed setgeneralDetails to setGeneralDetails for consistency
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [showForm, setShowForm] = useState(true);
    const [revert, setRevert] = useState(false);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };
    
        const fetchData = async () => {
            try {
                const response = await api.get(`institution/${id}`);
                const jsonData = response?.data?.data?.data?.nirf_accreditation;
                if (jsonData) {
                    const updatedUsers = jsonData.map(user => ({
                        ...user,
                        nirf_rank_status: user.nirf_rank_status === '1' ? 'Yes' : 'No' // Updated nirf_rank_status to Yes/No
                    }));

                    const accreditation = jsonData;

                    if (accreditation.some((item) => item.nirf_rank_status === '0' && item.is_approved === 1)) {
                        setHasDataNo(true);
                    }
        
                    if (accreditation.some((item) => item.nirf_rank_status === '1' && item.is_approved === 1)) {
                        setHasDataYes(true)
                    }

                    setGeneralDetails(updatedUsers);
                }
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        useEffect(() => {
        fetchData();
    }, [id]);

      // revert
      const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`/nirf-acreditation/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setGeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    //

    return (
        <div>
              {revert && (
                <Failure
                    title="NIRF details has been Reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="d-flex justify-content-left mx-3 my-2">
                <Row className="label">
                    <label>Does the institution possess a valid NIRF ranking ?</label>
                </Row>
                <Row className="ml-5">:</Row>
                <Row className="ml-5">
                    <FormGroup check>
                            <Input
                                type="radio"
                                name="showForm"
                                value="yes"
                                checked={showForm === true}
                                onChange={() => setShowForm(true)}
                            />{' '}
                        <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                            Yes
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                            <Input
                                type="radio"
                                name="showForm"
                                value="no"
                                checked={showForm === false}
                                onChange={() => setShowForm(false)}
                            />{' '}
                        <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                            No
                        </Label>
                    </FormGroup>
                </Row>
            </div>
            {showForm === true ? (
                <div className="table-section w-100 my-3 overflow-auto">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ maxWidth: '150px' }}>Academic Year</th>
                                <th style={{ maxWidth: '150px' }}>NIRF Rank</th>
                                <th style={{ maxWidth: '200px' }}>Remarks</th>
                                <th style={{ maxWidth: '50px' }}>Status</th>
                                <th style={{ maxWidth: '50px' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generalDetails.map((item, index) =>
                                item.nirf_rank_status === 'Yes' ? (
                                    <tr key={index} className="text-left">
                                        <td style={{ maxWidth: '150px' }}>{item.academic_year}</td>
                                        <td style={{ maxWidth: '150px' }}>{item.nirf_rank}</td>
                                        <td className="text-justify" style={{ maxWidth: '200px' }}>
                                            {item.remarks}
                                        </td>
                                        <td>
                                            {item.is_approved === 0 ? (
                                                <td className="d-flex">
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <td>
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted by head
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 1 ? (
                                                <td>
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved by head
                                                    </Button>
                                                </td>
                                            ) : (
                                                item.is_approved === 3 && (
                                                    <td>
                                                        <Button type="button" color="info" size="sm" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {item.is_approved === 1 && (
                                                <td>
                                                    <Button
                                                        type="button"
                                                        size="sm"
                                                        outline
                                                        color="primary"
                                                        onClick={() => handleRevert(item.id)}>
                                                        Revert
                                                    </Button>
                                                </td>
                                            )}
                                        </td>
                                    </tr>
                                ) : null
                            )}
                        </tbody>
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ minWidth: '400px' }}>Remarks</th>
                                <th style={{ minWidth: '150px' }}>Status</th>
                                <th style={{ minWidth: '150px' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generalDetails.map((item, index) =>
                                item.nirf_rank_status === 'No' ? (
                                    <tr key={index} className="text-left">
                                        <td style={{ minWidth: '400px' }}>{item.remarks}</td>
                                        <td>
                                            {item.is_approved === 0 ? (
                                                <td className="d-flex">
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <td>
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted by head
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 1 ? (
                                                <td>
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved by head
                                                    </Button>
                                                </td>
                                            ) : (
                                                item.is_approved === 3 && (
                                                    <td>
                                                        <Button type="button" color="info" size="sm" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {item.is_approved === 1 && (
                                                <td>
                                                    <Button
                                                        type="button"
                                                        size="sm"
                                                        outline
                                                        color="primary"
                                                        onClick={() => handleRevert(item.id)}>
                                                        Revert
                                                    </Button>
                                                </td>
                                            )}
                                        </td>
                                    </tr>
                                ) : null
                            )}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
}

export default Nirf;
