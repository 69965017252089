import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';

function HeadInternational(props) {
    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);

    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };

    const handleSaveData = (id) => {
        formData.is_approved = 1;
        // console.log(formData);
        api.post(`library/approve/${id}`, formData)
            .then((response) => {
                // console.log('Approved');
                setSuccess(true);
            })
            .catch((error) => {
                // console.error('Failed');
                // toggleModal();
            });
    };

    const handleRevertData = (id) => {
        api.post(`library/revert/${id}`)
            .then((response) => {
                // console.log('Reverted');
                setRevert(true);
            })
            .catch((error) => {
                // Handle errors if the POST request fails
                // console.error('Failed');
                // toggleModal();
            });
    };



    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data?.sports_common;

            const sports = jsonData.filter((item) => item.type === 'international');

            if (sports.some((item) => item.status === 0 && item.is_approved === 0 )) {
                setHasDataNo(true);
            }

            if (sports.some((item) => item.status === 1 && item.is_approved === 0 )) {
                setHasDataYes(true)
            }
            setGeneralDetails(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (

        <div>
            <>
                {' '}
                <div className="d-flex justify-content-left mx-3">
                    <Row className="label">
                        <label>Does any of your students participated in International level competition ?</label></Row>
                    <Row className="ml-5">:</Row>
                    <Row className="ml-5">
                        {' '}
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="yes"
                                    checked={showForm === true}
                                    onChange={() => setShowForm(true)}
                                />{' '}
                            <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                                Yes
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="no"
                                    checked={showForm === false}
                                    onChange={() => setShowForm(false)}
                                />{' '}
                            <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                                No
                            </Label>
                        </FormGroup>
                    </Row>
                </div>
            </>
            {showForm === true ? (
                <div className="table-section w-100 my-3 overflow-auto">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '150px' }}>Academic Year</th>
                                <th style={{ minWidth: '280px' }}>Sports Event</th>
                                <th style={{ minWidth: '340px' }}>No. of Students selected at International level</th>
                                <th style={{ minWidth: '280px' }}>Position Secured</th>
                                <th style={{ minWidth: '400px' }}>Remarks</th>
                                <th style={{ minWidth: '100px' }}>Status</th>
                            </tr>
                        </thead>
                        {generalDetails?.filter((item) => item.status === 1 && item.type === 'international').length >
                            0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.status === 1 && item.type === 'international' ? (
                                        <tr key={index} className="text-left">
                                            <td>{item.year}</td>
                                            <td className='text-justify'>{item.item}</td>
                                            <td>{item.total}</td>
                                            <td className='text-justify'>{item.position}</td>
                                            <td className='text-justify'>{item.remarks ?? '--'}</td>

                                            {item.is_approved === 0 ? (
                                                <td>
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <td>
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted by head
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 1 ? (
                                                <td>
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved by head
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 3 ? (
                                                <td>
                                                    <Button type="button" color="info" size="sm" disabled>
                                                        Reverted by RUSA Admin
                                                    </Button>
                                                </td>
                                            ) : (
                                                ''
                                            )}
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '150px' }}>Remarks</th>

                                <th style={{ minWidth: '100px' }}>Status</th>
                            </tr>
                        </thead>
                        {generalDetails?.filter((item) => item.status === 0 && item.type === 'international').length >
                            0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.status === 0 && item.type === 'international' ? (
                                        <tr key={index} className="text-left">
                                            <>
                                                <td className='text-justify'>{item.remarks}</td>
                                                {item.is_approved === 0 ? (
                                                    <td>
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 2 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted by head
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 1 ? (
                                                    <td>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved by head
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 3 ? (
                                                    <td>
                                                        <Button type="button" color="info" size="sm" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            )}
        </div>
    );
}
export default HeadInternational;
