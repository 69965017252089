import React, { useEffect, useState } from 'react';
import { Edit, Trash2 } from 'react-feather';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';
import ManangeGeneralaInformation from './manage';
import { MANAGE_LABELS } from './manage/datamanager';
import {
    create_fundReceived,
    delete_fundReceived,
    list_fundRecieved,
    update_fundReceived,
} from '../../../../../helpers/actions/api.functions';
import toast from 'react-hot-toast';
import { getErrorMessage } from '../../../../../components/genError';
import Success from '../success';

const fields = {
    [MANAGE_LABELS.START_YEAR]: null,
    [MANAGE_LABELS.END_YEAR]: null,
    [MANAGE_LABELS.RUSA_PHASE]: null,
    [MANAGE_LABELS.COMPONENT]: null,
    [MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED]: null,
    [MANAGE_LABELS.TOTAL_STATE_RECEIVED]: null,
    [MANAGE_LABELS.TOTAL_FUND_RECEIVED]: null,
    [MANAGE_LABELS.REMARKS]: null,
};
const initial_values = [fields];

function DataOperatorFundRecievedMain() {
    const [generalData, setGeneralData] = useState(initial_values);
    const [formData, setformData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [newReg, setNewReg] = useState(true);
    const [flag, setFlag] = useState('Y');
    const [status, setStatus] = useState('');
    const [primaryStep, setPrimaryStep] = useState(1);
    const [otherDetails, setotherDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(1);
    const [listData, setListData] = useState([]);
    const [indEdit, setIndEdit] = useState(false);
    const [success, setSuccess] = useState(false);

    const [editIndex, setEditIndex] = useState(null);

    const handleEdit = (id) => {
        const data = [];
        otherDetails.forEach((item) => {
            if (item.id === id) {
                const { is_approved, grade, present_accreditation_cycle, ...rest } = item;
                // console.log(rest);
                data.push(rest);
            }
        });
        // console.log(data);
        // setEditIndex(index);
        setGeneralData(data);
        setPrimaryStep(2);
        setEdit(true);
    };
    const setEditData = (item, id) => {
        const dt = String(item?.year);
        // console.log(dt);
        const newYear = dt.split('-');
        // console.log(newYear);
        const Faculityfields3 = {
            [MANAGE_LABELS.START_YEAR]: newYear[0] ?? '--',
            [MANAGE_LABELS.END_YEAR]: newYear[1] ?? '--',
            [MANAGE_LABELS.RUSA_PHASE]: item?.phase,
            [MANAGE_LABELS.COMPONENT]: item?.component,
            [MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED]: item?.total_central,
            [MANAGE_LABELS.TOTAL_STATE_RECEIVED]: item?.total_state,
            [MANAGE_LABELS.TOTAL_FUND_RECEIVED]: item?.total_fund,
            [MANAGE_LABELS.REMARKS]: item?.remarks,
            id: item?.id,
        };
        // console.log(Faculityfields3);
        setGeneralData([Faculityfields3]);
        setEdit(true);
        setPrimaryStep(2);
    };
    // const handleEditNo = (item,id) => {
    //     // const data = [];
    //     // otherDetails.forEach((item) => {
    //     //     if (item.id === id) {
    //     //         const { is_approved, grade, present_accreditation_cycle, ...rest } = item;
    //     //         console.log(rest);
    //     //         data.push(rest);
    //     //     }
    //     // });
    //     // console.log(data);
    //     // // setEditIndex(index);
    //     // setGeneralData(data);
    //     // setActiveStep(2);
    //     // setEdit(true);

    // };
    const handleCancelEdit = () => {
        setEditIndex(null);
        setGeneralData({ ...fields() });
        setPrimaryStep(1);
    };

    // console.log('1', primaryStep);
    const manageEdit = () => {
        setEdit(true);
    };
    const manageForm = () => {
        setActiveStep(2);
        const createFields = [
            {
                [MANAGE_LABELS.OTHER_REMARKS]: null,
            },
        ];
        setGeneralData(createFields);
    };
    const onSubmit = (data) => {
        // setGeneralData(data.data);
        setFlag(data.flag);
        setStatus(data.status);
        setEdit(false);
        setNewReg(false);
    };

    const handleCallBack = () => {
        setSuccess(false);
        setPrimaryStep(1);
        setActiveStep(1);
    };
    const handleCreate = () => {
        setPrimaryStep(2);

        const createFields = [
            {
                [MANAGE_LABELS.START_YEAR]: null,
                [MANAGE_LABELS.END_YEAR]: null,
                [MANAGE_LABELS.RUSA_PHASE]: null,
                [MANAGE_LABELS.COMPONENT]: null,
                [MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED]: null,
                [MANAGE_LABELS.TOTAL_STATE_RECEIVED]: null,
                [MANAGE_LABELS.TOTAL_FUND_RECEIVED]: null,
                [MANAGE_LABELS.REMARKS]: null,
            },
        ];
        setGeneralData(createFields);
        // console.log(generalData);
    };
    // ------
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };
    const fetchData = () => {
        list_fundRecieved()
            .then((res) => {
                setListData(res?.data?.data);
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };

    useEffect(() => {
        fetchData();
    }, []);
    const generateObject = (data, i) => {
        const objData = {
            year: `${data?.START_YEAR}-${data?.END_YEAR}`,
            remarks: data?.REMARKS,
            phase: data?.RUSA_PHASE,
            component: data?.COMPONENT,
            total_central: data?.TOTAL_CENTRAL_RECEIVED,
            total_state: data?.TOTAL_STATE_RECEIVED,
            total_fund: `${Number(data?.TOTAL_CENTRAL_RECEIVED) + Number(data?.TOTAL_STATE_RECEIVED)}`,
        };

        return objData;
    };
    const handleDelete = (id) => {
        delete_fundReceived(id)
            .then((res) => {
                toast.success('Budget Deleted successfully');
                fetchData();
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };
    const createFund = (data) => {
        // console.log(data, 'create budget');
        const payload = [];
        data.map((item, i) => {
            payload.push(generateObject(item, i));
        });
        // console.log(payload, indEdit);
        if (!indEdit) {
            create_fundReceived(payload)
                .then((res) => {
                    toast.success('Budget Added Successfully');
                    fetchData();
                    setEdit(false);
                    setSuccess(true);
                    // setPrimaryStep(2)
                })
                .catch((err) => {
                    toast.error(`${getErrorMessage(err.response.data.error)}`, {
                        duration: 4000,
                        position: 'top-right',
                    });
                });
        } else {
            // console.log("hey hey hey")
            update_fundReceived(payload[0], data[0]?.id)
                .then((res) => {
                    toast.success('Budget Updated Successfully');
                    fetchData();
                    setEdit(false);
                    setIndEdit(false);
                    // setPrimaryStep(1);
                    setSuccess(true)
                })
                .catch((err) => {
                    toast.error(`${getErrorMessage(err.response.data.error)}`, {
                        duration: 4000,
                        position: 'top-right',
                    });
                });
        }
    };
    // -----
    return (
        <div className="generalinfo">
            {success && (
                <Success
                    title="Fund received details are submitted for approval by the RUSA Admin."
                    toggle={handleCallBack}
                    open={success}
                />
            )}
            {/* <div className="d-flex justify-content-left ml-3 mt-2">
                <Row>Does your institution posses any Other accreditation?</Row>
                <Row className="ml-5">:</Row>
                <Row className="ml-5">
                    {' '}
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="flag" checked={flag === 'Y'} onChange={() => setFlag('Y')} /> Yes
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="flag" checked={flag === 'N'} onChange={() => setFlag('N')} /> No
                        </Label>
                    </FormGroup>
                </Row>
            </div> */}
            {flag === 'Y' && (
                <>
                    {primaryStep === 1 ? (
                        <>
                            <div className="d-flex justify-content-end mb-3">
                                {' '}
                                <Button onClick={() => handleCreate()} color="primary" className="mt-2 mb-3 px-3">
                                    Create
                                </Button>
                            </div>
                            <div className="table-section w-100  overflow-auto">
                                <Table striped>
                                    <thead className="table-head overflow-auto">
                                        <tr className="text-left">
                                            <th style={{ minWidth: '120px' }}>Financial Year</th>
                                            <th style={{ minWidth: '120px' }}>RUSA Phase</th>
                                            <th style={{ minWidth: '150px' }}>Component Name</th>
                                            <th style={{ minWidth: '110px' }}>Total Central Share Received</th>
                                            <th style={{ minWidth: '100px' }}>Total State Share Received </th>
                                            <th style={{ minWidth: '100px' }}>Total Fund </th>
                                            <th style={{ minWidth: '100px' }}>Remarks</th>
                                            <th style={{ minWidth: '100px' }}>Status</th>
                                            <th style={{ minWidth: '100px' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listData.length > 0 ? (
                                            listData.map((item, i) => (
                                                <tr key={i} className="text-left">
                                                    {/* <td>{i + 1}</td> */}
                                                    <td>{item.year}</td>
                                                    <td>{item.phase}</td>
                                                    <td>{item.component}</td>
                                                    <td>{item.total_central}</td>
                                                    <td>{item.total_state}</td>
                                                    <td>{item.total_fund}</td>
                                                    <td>{item.remarks ?? '--'}</td>

                                                    <td>
                                                        {item.is_approved === 0 ? (
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        ) : item.is_approved === 2 ? (
                                                            <Button disabled color="danger" size="sm">
                                                                Reverted By Head
                                                            </Button>
                                                        ) : item.is_approved === 1 ? (
                                                            <Button disabled color="success" size="sm">
                                                                Approved By Head
                                                            </Button>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Edit
                                                            className="me-3 cursor-pointer"
                                                            onClick={() => {
                                                                setEditData(item, item.id);
                                                                setIndEdit(true);
                                                            }}
                                                        />
                                                        {/* <Trash2
                                                            className="ml-3 cursor-pointer"
                                                            onClick={() => handleDelete(item.id)}
                                                        /> */}
                                                        <Trash2
                                                            className="ml-3 text-danger"
                                                            onClick={() => handleDelete(item.id)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={10} className="text-center">
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    ) : (
                        <>
                            {' '}
                            {newReg || edit ? (
                                <ManangeGeneralaInformation
                                    // data={generalData}
                                    data={generalData}
                                    setGeneralData={setGeneralData}
                                    edit={edit}
                                    setFlag={setFlag}
                                    flag={flag}
                                    status={status}
                                    handleCallBack={handleCallBack}
                                    setStatus={setStatus}
                                    fields={fields}
                                    onSubmit={onSubmit}
                                    setEdit={manageEdit}
                                    primaryStep={primaryStep}
                                    setPrimaryStep={setPrimaryStep}
                                    handleCancelEdit={handleCancelEdit}
                                    {...otherDetails[editIndex]}
                                    createFund={createFund}
                                />
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </>
            )}
            {flag === 'N' && (
                <>
                    {activeStep === 1 ? (
                        <>
                            <div className="d-flex justify-content-end my-3">
                                {' '}
                                <Button onClick={manageForm} color="primary">
                                    Create
                                </Button>
                            </div>
                            <div className="table-section w-100 ">
                                <Table striped>
                                    <thead className="table-head">
                                        <tr className="text-left">
                                            <th>Remarks</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {otherDetails.filter((item) => item.other_status === 0).length > 0 ? (
                                            otherDetails.map((item, i) =>
                                                item.other_status === 0 ? (
                                                    <tr key={i} className="text-left">
                                                        {/* <td>{i + 1}</td> */}

                                                        <td style={{ textAlign: 'justify' }} className="w-75">
                                                            {item.remarks}
                                                        </td>
                                                        <td>
                                                            {item.is_approved === 0 ? (
                                                                <Button
                                                                    disabled
                                                                    color="warning"
                                                                    class="btn btn-secondary"
                                                                    style={{
                                                                        color: '#ED7D31',
                                                                        fontWeight: '400',
                                                                        backgroundColor: '#F5F7F8',
                                                                    }}
                                                                    size="sm">
                                                                    Pending
                                                                </Button>
                                                            ) : item.is_approved === 2 ? (
                                                                <Button disabled color="danger" size="sm">
                                                                    Reverted
                                                                </Button>
                                                            ) : item.is_approved === 1 ? (
                                                                <Button disabled color="success" size="sm">
                                                                    Approved
                                                                </Button>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.is_approved === 2 ? (
                                                                <Edit
                                                                    className="me-3 cursor-pointer"
                                                                    onClick={() => setEditData(item, item.id)}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan={3} className="text-center">
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
}

export default DataOperatorFundRecievedMain;
