import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Container } from 'reactstrap';
import { api } from '../../../../../../../helpers/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Failure from '../../../../../institutions/failure';
const NetJrf = (props) => {
    // console.log(props);
    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [revert, setRevert] = useState(false);

    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get(`/institution/${id}`); // Assuming api.get works as expected
            // // console.log(response);
            const jsonData = response?.data?.data?.data;

            // console.log(jsonData);
            setGeneralDetails(jsonData.rank);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);

    const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`/rank/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setGeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    // console.log(props);
    return (
        <div>
            {revert && (
                <Failure
                    title="NET/JRF details has been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="view">
                <Container className="container-width">
                    <div className="mt-5">
                        <h5 className="header-title mb-5 mt-5">Qualified Students in NET/JRF</h5>

                        {/* <div className="table-section">Qualified Students of NET/JRF</div> */}

                        <table class="table">
                            <thead className="table-head ml-5 mt-0 ">
                                <tr>
                                    <th style={{ minWidth: '120px' }}>Academic Year</th>
                                    <th style={{ minWidth: '300px' }}>Number of Students Qualified in NET/JRF</th>
                                    <th>Subject</th>
                                    <th>Remarks</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            {generalDetails?.length > 0 ? (
                                <tbody>
                                    {generalDetails.map((item, index) =>
                                        item.type === 'net' ? (
                                            <tr key={index} className="text-left">
                                                <td>{item.year}</td>
                                                <td>{item.students}</td>
                                                <td className="text-justify">{item.subject}</td>

                                                <td className="text-justify">{item.remarks}</td>
                                                <td>
                                                    {item.is_approved === 0 ? (
                                                        <td className="d-flex">
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 2 ? (
                                                        <td>
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted by Head
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 1 ? (
                                                        <td>
                                                            <Button type="button" color="success" size="sm" disabled>
                                                                Approved by Head
                                                            </Button>
                                                        </td>
                                                    ) : (
                                                        item.is_approved === 3 && (
                                                            <td>
                                                                <Button type="button" size="sm" color="info" disabled>
                                                                    Reverted by RUSA Admin
                                                                </Button>
                                                            </td>
                                                        )
                                                    )}
                                                </td>
                                                <td>
                                                    {item.is_approved === 1 && (
                                                        <td>
                                                            <Button
                                                                type="button"
                                                                size="sm"
                                                                outline
                                                                color="primary"
                                                                onClick={() => handleRevert(item.id)}>
                                                                Revert
                                                            </Button>
                                                        </td>
                                                    )}
                                                </td>
                                            </tr>
                                        ) : (
                                            ''
                                        )
                                    )}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            No data Found
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        <div className="p-2 m-1">
                            <Row>
                                <Col className="d-flex justify-content-start mt-4 p-0">
                                    {/* <Button type="submit" color="primary">
                                    Approve
                                </Button> */}

                                    {/* <Button
                                           
                                            color="primary"
                                            outline
                                            className="ml-3">
                                            Resubmit
                                        </Button> */}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default NetJrf;
