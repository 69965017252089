import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import Success from '../../../success';
import Failure from '../../../institutions/failure';
import { api } from '../../../../../helpers/api';

function DetailsOfBogHead(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);
    const [formData, setFormData] = useState([]);

   
   
    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.details_bog);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    

    return (
        <div>
            
            <div className="view">
                <Container className="container-width">
                    <div className="table-section w-100 overflow-auto">
                        <Table striped>
                            <thead className="table-head ml-5 mt-0 ">
                                <tr>
                                    <th>Name</th>
                                    <th>Designation</th>
                                    <th>Remarks</th>
                                    <th style={{ minLength: '150px' }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
        {generalDetails.filter((item) => item.type === 'pmu').length > 0 ? (
            generalDetails.map((item, k) => {
                if (item.type === 'pmu') {
                    return (
                        <tr key={k}>
                            <td>{item.name}</td>
                            <td>{item.designation}</td>
                            <td>{item.remarks}</td>
                            <td style={{ maxWidth: '5n0px' }}>
                                                    {item.is_approved === 0 && (
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 1 && (
                                                        <Button disabled color="success" size="sm">
                                                            Approved by Head
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 2 && (
                                                        <Button disabled color="danger" size="sm">
                                                            Reverted by Head
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 3 && (
                                                        <Button disabled color="info" size="sm">
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    )}
                                                </td>
                                               
                        </tr>
                    );
                } else {
                    return null; // Returning null for non-'bog' items
                }
            })
        ) : (
            <tr>
                <td colSpan="4" className="text-center">
                    No data found
                </td>
            </tr>
        )}
    </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default DetailsOfBogHead;
