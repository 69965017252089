export const general_institutions_type = [
    { label: 'Government College' },
    { label: 'Aided College' },
    { label: 'Self Financing College' },
    { label: 'Autonomous College' },
    { label: 'University' },
    { label: 'Others' },
];

export const general_afliated_university = [
    { label: 'Mahatma Gandhi University' },
    { label: 'University of Kerala' },
    { label: 'Kannur University' },
    { label: 'Cochin University of Science and Technology' },
    { label: 'APJ Abdul Kalam Technological University' },
    { label: 'Sree Sankaracharya University of Sanskrit' },
];

export const nature_of_college_management = [
    { label: 'Corporate' },
    { label: 'Society' },
    { label: 'Minority' },
    { label: 'Trust' },
    { label: 'Others' },
];

// export const districts = [
//     { label: 'Thiruvananthapuram' },
//     { label: 'Kollam' },
//     { label: 'Pathanamthitta' },
//     { label: 'Alappuzha' },
//     { label: 'Kottayam' },
//     { label: 'Idukki' },
//     { label: 'Ernakulam' },
//     { label: 'Thrissur' },
//     { label: 'Palakkad' },
//     { label: 'Malappuram' },
//     { label: 'Kozhikode' },
//     { label: 'Wayanad' },
//     { label: 'Kannur' },
//     { label: 'Kasaragod' },
// ];

export const districts = [
    {
        label: 'Thiruvananthapuram',
        parliamentaryConstituencies: [{ label: '- select -' }, { label: 'Thiruvananthapuram' }, { label: 'Attingal' }],
        constituencies: [
            { label: '- select -' },
            { label: 'Neyyattinkara' },
            { label: 'Kovalam' },
            { label: 'Kattakkada' },
            { label: 'Parassala' },
            { label: 'Aruvikkara' },
            { label: 'Nemom' },
            { label: 'Thiruvananthapuram' },
            { label: 'Vattiyoorkavu' },
            { label: 'Kazhakkoottam' },
            { label: 'Vamanapuram' },
            { label: 'Nedumangad' },
            { label: 'Chirayinkeezhu ' },
            { label: 'Attingal ' },
            { label: 'Varkala' },
        ],
    },
    {
        label: 'Kollam',
        parliamentaryConstituencies: [{ label: 'Mavelikkara' }, { label: 'Kollam' }, { label: 'Alappuzha' }],
        constituencies: [
            { label: 'Kollam' },
            { label: 'Karunagapally' },
            { label: 'Chavara' },
            { label: 'Kunnathur' },
            { label: 'Kottarakkara' },
            { label: 'Pathanapuram' },
            { label: 'Punalur' },
            { label: 'Chadayamangalam' },
            { label: 'Kundara' },
            { label: 'Eravipuram' },
            { label: 'Chathannoor' },
        ],
    },
    {
        label: 'Pathanamthitta',
        parliamentaryConstituencies: [{ label: '- select -' }, { label: 'Pathanamthitta' }],
        constituencies: [
            { label: '- select -' },
            { label: 'Thiruvalla' },
            { label: 'Ranni' },
            { label: 'Aranmula' },
            { label: 'Konni' },
            { label: 'Adoor' },
        ],
    },
    {
        label: 'Alappuzha',
        parliamentaryConstituencies: [{ label: '- select -' }, { label: 'Alappuzha' }, { label: 'Mavelikkara' }],
        constituencies: [
            { label: '- select -' },
            { label: 'Aroor' },
            { label: 'Cherthala' },
            { label: 'Alappuzha' },
            { label: 'Ambalappuzha' },
            { label: 'Kuttanad' },
            { label: 'Haripad' },
            { label: 'Kayamkulam' },
            { label: 'Mavelikara' },
            { label: 'Chengannur' },
        ],
    },
    {
        label: 'Kottayam',
        parliamentaryConstituencies: [
            { label: '- select -' },
            { label: 'Kottayam' },
            { label: 'Mavelikkara' },
            { label: 'Pathanamthitta' },
        ],
        constituencies: [
            { label: '- select -' },
            { label: 'Pala' },
            { label: 'Kaduthuruthy' },
            { label: 'Vaikom' },
            { label: 'Ettumanoor' },
            { label: 'Kottayam' },
            { label: 'Puthuppally' },
            { label: 'Changanassery' },
            { label: 'Kanjirappally' },
            { label: 'Poonjar' },
        ],
    },
    {
        label: 'Idukki',
        parliamentaryConstituencies: [{ label: '- select -' }, { label: 'Idukki' }],
        constituencies: [
            { label: '- select -' },
            { label: 'Peerumade' },
            { label: 'Devikulam' },
            { label: 'Udumbanchola' },
            { label: 'Thodupuzha' },
            { label: 'Idukki' },
        ],
    },
    {
        label: 'Ernakulam',
        parliamentaryConstituencies: [
            { label: '- select -' },
            { label: 'Chalakudy' },
            { label: 'Ernakulam' },
            { label: 'Kottayam' },
            { label: 'Idukki' },
        ],
        constituencies: [
            { label: '- select -' },
            { label: 'Perumbavoor' },
            { label: 'Angamaly' },
            { label: 'Aluva' },
            { label: 'Kalamassery' },
            { label: 'Paravur' },
            { label: 'Vypin' },
            { label: 'Kochi' },
            { label: 'Thrippunithura' },
            { label: 'Ernakulam' },
            { label: 'Thrikkakara' },
            { label: 'Kunnathunad' },
            { label: 'Piravom' },
            { label: 'Muvattupuzha' },
            { label: 'Kothamangalam' },
        ],
    },
    {
        label: 'Thrissur',
        parliamentaryConstituencies: [
            { label: '- select -' },
            { label: 'Alathur' },
            { label: 'Thrissur' },
            { label: 'Chalakudy' },
        ],
        constituencies: [
            { label: '- select -' },
            { label: 'Chelakkara' },
            { label: 'Kunnamkulam' },
            { label: 'Guruvayur' },
            { label: 'Manalur' },
            { label: 'Wadakkanchery' },
            { label: 'Ollur' },
            { label: 'Thrissur' },
            { label: 'Nattika' },
            { label: 'Kaipamangalam' },
            { label: 'Irinjalakuda' },
            { label: 'Puthukkad' },
            { label: 'Chalakudy' },
            { label: 'Kodungallur' },
        ],
    },
    {
        label: 'Palakkad',
        parliamentaryConstituencies: [
            { label: '- select -' },
            { label: 'Ponnani' },
            { label: 'Palakkad' },
            { label: 'Alathur' },
        ],
        constituencies: [
            { label: '- select -' },
            { label: 'Thrithala' },
            { label: 'Pattambi' },
            { label: 'Shornur' },
            { label: 'Ottapalam' },
            { label: 'Kongad' },
            { label: 'Mannarkkad' },
            { label: 'Malampuzha' },
            { label: 'Palakkad' },
            { label: 'Tarur' },
            { label: 'Chittur' },
            { label: 'Nenmara' },
            { label: 'Alathur' },
        ],
    },
    {
        label: 'Malappuram',
        parliamentaryConstituencies: [
            { label: '- select -' },
            { label: 'Malappuram' },
            { label: 'Wayanad' },
            { label: 'Ponnani' },
        ],
        constituencies: [
            { label: '- select -' },
            { label: 'Kondotty' },
            { label: 'Eranad' },
            { label: 'Nilambur' },
            { label: 'Wandoor' },
            { label: 'Manjeri' },
            { label: 'Perinthalmanna' },
            { label: 'Mankada' },
            { label: 'Malappuram' },
            { label: 'Vengara' },
            { label: 'Vallikkunnu' },
            { label: 'Tirurangadi' },
            { label: 'Tanur' },
            { label: 'Tirur' },
            { label: 'Kottakkal' },
            { label: 'Thavanur' },
            { label: 'Ponnani' },
        ],
    },
    {
        label: 'Kozhikode',
        parliamentaryConstituencies: [
            { label: '- select -' },
            { label: 'Vadakara' },
            { label: 'Kozhikode' },
            { label: 'Wayanad' },
        ],
        constituencies: [
            { label: '- select -' },
            { label: 'Vatakara' },
            { label: 'Kuttiady' },
            { label: 'Nadapuram' },
            { label: 'Koyilandy' },
            { label: 'Perambra' },
            { label: 'Balussery' },
            { label: 'Elathur' },
            { label: 'Kozhikode North' },
            { label: 'Kozhikode South' },
            { label: 'Beypore' },
            { label: 'Kunnamangalam' },
            { label: 'Koduvally' },
            { label: 'Thiruvambady' },
        ],
    },
    {
        label: 'Wayanad',
        parliamentaryConstituencies: [{ label: '- select -' }, { label: 'Wayanad' }],
        constituencies: [
            { label: '- select -' },
            { label: 'Mananthavady' },
            { label: 'Sulthan Bathery' },
            { label: 'Kalpetta' },
        ],
    },
    {
        label: 'Kannur',
        parliamentaryConstituencies: [
            { label: '- select -' },
            { label: 'Kasargod' },
            { label: 'Vadakara' },
            { label: 'Kannur' },
        ],
        constituencies: [
            { label: '- select -' },
            { label: 'Payyanur' },
            { label: 'Kalliasseri' },
            { label: 'Taliparamba' },
            { label: 'Irikkur' },
            { label: 'Azhikode' },
            { label: 'Kannur' },
            { label: 'Dharmadom' },
            { label: 'Thalassery' },
            { label: 'Kuthuparamba' },
            { label: 'Mattanur' },
            { label: 'Peravoor' },
        ],
    },
    {
        label: 'Kasaragod',
        parliamentaryConstituencies: [{ label: '- select -' }, { label: 'Kasargod' }],
        constituencies: [
            { label: '- select -' },
            { label: 'Manjeshwaram' },
            { label: 'Kasargod' },
            { label: 'Udma' },
            { label: 'Kanhangad' },
            { label: 'Thrikaripur' },
        ],
    },
];

export const MANAGE_LABELS = {
    name_of_institution: 'name_of_institution',
    address: 'address',
    type_of_institution: 'type_of_institution',
    name_of_college_management: 'name_of_college_management',
    MANAGEMENT_NATURE_DESC: 'name_of_college_management',
    name_address_of_institution_management: 'name_address_of_institution_management',
    affiliated_university: 'affiliated_university',
    year_of_establishment: 'year_of_establishment',
    district: 'district',
    parliamentary_constituency: 'parliamentary_constituency',
    assembly_constituency: 'assembly_constituency',
    revenue_district_office: 'revenue_district_office',
    taluk: 'taluk',
    state: 'state',
    official_emailid: 'official_emailid',
    official_contact_no: 'official_contact_no',
    principal: 'principal',
    official_email_of_principal: 'official_email_of_principal',
    official_contact_no_of_principal: 'official_contact_no_of_principal',
    remarks: 'remarks',
};

export const FIELD_LABELS = {
    INSTITUTION_NAME: 'Name of the Institution',
    INSTITUTION_ADDRESS: 'Address with Pincode',
    INSTITUTION_TYPE: 'Type of the Institution',
    MANAGEMENT_NATURE: 'Nature of College Management',
    MANAGEMENT_NATURE_DESC: 'Specify the Nature of College Management',
    MANAGEMENT_ADDRESS: 'Name and Address of the Institution Management',
    AFLIATED_UNIVERSITY: 'Affiliated University',
    ESTABLISHMENT_YEAR: 'Year of Establishment',
    DISTRICT: 'District',
    PARLIMENT: 'Parliamentary Constituency',
    ASSEMBLY: 'Assembly Constituency',
    REVENUE_DISTRICT: 'Revenue District Office',
    TALUK: 'Taluk',
    STATE: 'State',
    OFFICIAL_MAIL: 'Official Email ID',
    OFFICIAL_LANDLINE: ' Official Contact / Landline Number',
    PRINCIPAL_NAME: 'Name of the Principal',
    PRINCIPAL_MAIL: 'Official Email ID of the Principal',
    PRINCIPAL_PHONE: 'Official Contact No. of the Principal',
    REMARKS_DETAILS: 'Remarks',
};

export const location_Data = [
    {
        name: 'Thiruvananthapuram',
        assembly_const: [
            { name: 'Varkala' },
            { name: 'Attingal' },
            { name: 'Chirayinkeezhu' },
            { name: 'Nedumangadu' },
            { name: 'Vamanapuram' },
            { name: 'Kazhakootam' },
            { name: 'Vattiyoorkavu' },
            { name: 'Thiruvananthapuram' },
            { name: 'Nemom' },
            { name: 'Aruvikkara' },
            { name: 'Parassala' },
            { name: 'Kattakada' },
            { name: 'Kovalam' },
            { name: 'Neyyatinkara' },
        ],
        parle_const: [{ name: 'Attingal' }, { name: 'Thiruvananthapuram' }],
    },
    {
        name: 'Kollam',
        assembly_const: [
            { name: 'Adoor' },
            { name: 'Karunagapally' },
            { name: 'Chavara' },
            { name: 'Kunnathur' },
            { name: 'Kottarakkara' },
            { name: 'Pathanapuram' },
            { name: 'Punalur' },
            { name: 'Chadayamangalam' },
            { name: 'Kundara' },
            { name: 'Kollam' },
            { name: 'Eravipuram' },
            { name: 'Chathannoor' },
        ],
        parle_const: [{ name: 'Alappuzha' }, { name: 'Kollam' }, { name: 'Mavelikkara' }],
    },
    {
        name: 'Pathanamthitta',
        assembly_const: [
            { name: 'Chengannur' },
            { name: 'Thiruvalla' },
            { name: 'Ranni' },
            { name: 'Aranmula' },
            { name: 'Konni' },
            { name: 'Adoor' },
        ],
        parle_const: [{ name: 'Pathanamthitta' }],
    },

    {
        name: 'Alappuzha',
        assembly_const: [
            { name: 'Aroor' },
            { name: 'Cherthala' },
            { name: 'Alappuzha' },
            { name: 'Ambalappuzha' },
            { name: 'Kuttanad' },
            { name: 'Harippad' },
            { name: 'Kayamkulam' },
            { name: 'Mavelikkara' },
            { name: 'Chengannur' },
        ],
        parle_const: [{ name: 'Alappuzha' }, { name: 'Mavelikkara' }],
    },
    {
        name: 'Kottayam',
        assembly_const: [
            { name: 'Peerumade' },
            { name: 'Pala' },
            { name: 'Kaduthuruthy' },
            { name: 'Vaikom' },
            { name: 'Ettumanoor' },
            { name: 'Kottayam' },
            { name: 'Puthuppally' },
            { name: 'Changanassery' },
            { name: 'Kanjirappally' },
            { name: 'Poonjar' },
        ],
        parle_const: [{ name: 'Kottayam' }, { name: 'Mavelikkara' }, { name: 'Pathanamthitta' }],
    },
    {
        name: 'Idukki',
        assembly_const: [
            { name: 'Devikulam' },
            { name: 'Udumbanchola' },
            { name: 'Thodupuzha' },
            { name: 'Idukki' },
            { name: 'Peerumade' },
        ],

        parle_const: [{ name: 'Idukki' }],
    },
    {
        name: 'Ernakulam',
        assembly_const: [
            { name: 'Kodungallur' },
            { name: 'Perumbavoor' },
            { name: 'Angamaly' },
            { name: 'Aluva' },
            { name: 'Kalamassery' },
            { name: 'Paravur' },
            { name: 'Vypin' },
            { name: 'Kochi' },
            { name: 'Thrippunithura' },
            { name: 'Ernakulam' },
            { name: 'Thrikkakara' },
            { name: 'Kunnathunad' },
            { name: 'Piravom' },
            { name: 'Muvattupuzha' },
            { name: 'Kothamangalam' },
        ],
        parle_const: [{ name: 'Chalakkudy' }, { name: 'Ernakulam' }, { name: 'Kottayam' }, { name: 'Idukki' }],
    },
    {
        name: 'Thrissur',
        assembly_const: [
            { name: 'Alathur' },
            { name: 'Chelakkara' },
            { name: 'Kunnamkulam' },
            { name: 'Guruvayur' },
            { name: 'Manalur' },
            { name: 'Wadakkanchery' },
            { name: 'Ollur' },
            { name: 'Thrissur' },
            { name: 'Nattika' },
            { name: 'Kaipamangalam' },
            { name: 'Irinjalakuda' },
            { name: 'Puthukkad' },
            { name: 'Chalakudy' },
            { name: 'Kodungallur' },
        ],
        parle_const: [{ name: 'Alathur' }, { name: 'Thrissur' }, { name: 'Chalakkudy' }],
    },
    {
        name: 'Palakkad',
        assembly_const: [
            { name: 'Ponnani' },
            { name: 'Thrithala' },
            { name: 'Pattambi' },
            { name: 'Shornur' },
            { name: 'Ottapalam' },
            { name: 'Kongad' },
            { name: 'Mannarkkad' },
            { name: 'Malampuzha' },
            { name: 'Palakkad' },
            { name: 'Tarur' },
            { name: 'Chittur' },
            { name: 'Nenmara' },
            { name: 'Alathur' },
        ],
        parle_const: [{ name: 'Ponnani' }, { name: 'Palakkad' }, { name: 'Alathur' }],
    },
    {
        name: 'Malappuram',
        assembly_const: [
            { name: 'Thiruvambady' },
            { name: 'Kondotty' },
            { name: 'Eranad' },
            { name: 'Nilambur' },
            { name: 'Wandoor' },
            { name: 'Manjeri' },
            { name: 'Perinthalmanna' },
            { name: 'Mankada' },
            { name: 'Malappuram' },
            { name: 'Vengara' },
            { name: 'Vallikkunnu' },
            { name: 'Tirurangadi' },
            { name: 'Tanur' },
            { name: 'Tirur' },
            { name: 'Kottakkal' },
            { name: 'Thavanur' },
            { name: 'Ponnani' },
        ],
        parle_const: [{ name: 'Malappuram' }, { name: 'Wayanad' }, { name: 'Ponnani' }],
    },
    {
        name: 'Kozhikode',
        assembly_const: [
            { name: 'Kalpetta' },
            { name: 'Vatakara' },
            { name: 'Kuttiady' },
            { name: 'Nadapuram' },
            { name: 'Koyilandy' },
            { name: 'Perambra' },
            { name: 'Balussery' },
            { name: 'Elathur' },
            { name: 'Kozhikode North' },
            { name: 'Kozhikode South' },
            { name: 'Beypore' },
            { name: 'Kunnamangalam' },
            { name: 'Koduvally' },
            { name: 'Thiruvambady' },
        ],
        parle_const: [{ name: 'Vadakara' }, { name: 'Kozhikode' }, { name: 'Wayanad' }],
    },
    {
        name: 'Wayanad',
        assembly_const: [{ name: 'Mananthavady' }, { name: 'Sulthan Bathery' }, { name: 'Kalpetta' }],
        parle_const: [{ name: 'Wayanad' }],
    },
    {
        name: 'Kannur',
        assembly_const: [
            { name: 'Thrikaripur' },
            { name: 'Payyanur' },
            { name: 'Kalliasseri' },
            { name: 'Taliparamba' },
            { name: 'Irikkur' },
            { name: 'Azhikode' },
            { name: 'Kannur' },
            { name: 'Dharmadom' },
            { name: 'Thalassery' },
            { name: 'Kuthuparamba' },
            { name: 'Mattanur' },
            { name: 'Peravoor' },
        ],
        parle_const: [{ name: 'Kasargod' }, { name: 'Vadakara' }, { name: 'Kannur' }],
    },
    {
        name: 'Kasaragod',
        assembly_const: [
            { name: 'Manjeshwaram' },
            { name: 'Kasaragod' },
            { name: 'Udma' },
            { name: 'Kanhangad' },
            { name: 'Thrikaripur' },
        ],
        parle_const: [{ name: 'Kasaragod' }],
    },
];
