import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';
function Naac() {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [showForm, setShowForm] = useState(true);
    const [revert, setRevert] = useState(false);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };

    const fetchData = async () => {
        try {
            const response = await api.get(`institution/${id}`);
            const jsonData = response?.data?.data?.data?.naac_accreditations;
            

            const accreditation = jsonData;

            if (accreditation.some((item) => item.accrediation_status === 0 && item.is_approved === 1)) {
                setHasDataNo(true);
            }

            if (accreditation.some((item) => item.accrediation_status === 1 && item.is_approved === 1)) {
                setHasDataYes(true)
            }

            const updatedUsers = jsonData?.map((user) => {
                user.accrediation_status = user.accrediation_status === 1 ? 'Yes' : 'No';
                return user;
            });
            setGeneralDetails(updatedUsers);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }

        
    };
    
    useEffect(() => {
        fetchData();
    }, [id]);

    // revert
    const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`/naac-acreditation/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setGeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    //
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    return (
        <div>
            {revert && (
                <Failure
                    title="NAAC details has been Reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="d-flex justify-content-left mx-3">
                <Row className="label">
                    <label>Does your institution possess NAAC accreditation during previous 5 years ?</label>
                </Row>
                <Row className="ml-5">:</Row>
                <Row className="ml-5">
                    <FormGroup check>
                        <Input
                            type="radio"
                            name="showForm"
                            value="yes"
                            checked={showForm === true}
                            onChange={() => setShowForm(true)}
                        />{' '}
                        <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                            Yes
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input
                            type="radio"
                            name="showForm"
                            value="no"
                            checked={showForm === false}
                            onChange={() => setShowForm(false)}
                        />{' '}
                        <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                            No
                        </Label>
                    </FormGroup>
                </Row>
            </div>

            {showForm === true ? (
                <div className="table-section w-100 my-3 overflow-auto">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th>Date of accreditation</th>
                                <th>Valid Upto</th>
                                <th>NAAC Grade</th>
                                <th>NAAC Score</th>
                                <th>Present NAAC Accreditation Cycle</th>
                                <th>Remarks</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generalDetails
                                .filter((data) => data.accrediation_status === 'Yes')
                                .map((item, index) => (
                                    <tr key={index} className="text-left">
                                        <td>{formatDate(item.accreditation_date)}</td>
                                        <td>{formatDate(item.valid_until)}</td>
                                        <td>{item.grade}</td>
                                        <td>{item.score}</td>
                                        <td>{item.present_accreditation_cycle}</td>
                                        <td>{item.remarks}</td>
                                        <td>
                                            {item.is_approved === 0 ? (
                                                <td className="d-flex">
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <td>
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted by head
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 1 ? (
                                                <td>
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved by head
                                                    </Button>
                                                </td>
                                            ) : (
                                                item.is_approved === 3 && (
                                                    <td>
                                                        <Button type="button" color="info" size="sm" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {item.is_approved === 1 && (
                                                <td>
                                                    <Button
                                                        type="button"
                                                        size="sm"
                                                        outline
                                                        color="primary"
                                                        onClick={() => handleRevert(item.id)}>
                                                        Revert
                                                    </Button>
                                                </td>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th>Current Application Status</th>
                                <th>Remarks</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generalDetails
                                .filter((data) => data.accrediation_status === 'No')
                                .map((item, index) => (
                                    <tr key={index} className="text-left">
                                        <td>{item.current_application_status}</td>
                                        <td>{item.remarks}</td>
                                        <td>
                                            {item.is_approved === 0 ? (
                                                <td className="d-flex">
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <td>
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted by head
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 1 ? (
                                                <td>
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved by head
                                                    </Button>
                                                </td>
                                            ) : (
                                                item.is_approved === 3 && (
                                                    <td>
                                                        <Button type="button" color="info" size="sm" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {item.is_approved === 1 && (
                                                <td>
                                                    <Button
                                                        type="button"
                                                        size="sm"
                                                        outline
                                                        color="primary"
                                                        onClick={() => handleRevert(item.id)}>
                                                        Revert
                                                    </Button>
                                                </td>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
}

export default Naac;
