import React, { useState } from 'react';
import { api } from '../../../../../../../helpers/api';
import { Container, Row, Col, Button } from 'reactstrap';
import FailureError from '../error';
import { generateError } from '../../../../../../../utilities';

const PreviewInformation = (props) => {
    // console.log(props);
    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };
    const handleSaveData = () => {
        if(!props.edit){
            const payload = { ...props.formData };
        if (payload.role === 'DATA OPERATOR') {
            payload.role = 'data-operator';
        } else if (payload.role === 'FINANCE OFFICER') {
            payload.role = 'finance-officer';
        } else if (payload.role === 'RESEARCH OFFICER') {
            payload.role = 'research-officer';
        } else if (payload.role === 'NODAL OFFICER') {
            payload.role = 'nodal-officer';
        } else if (payload.role === 'SECTION OFFICER') {
            payload.role = 'section-officer';
        } else if (payload.role === 'STATE PROJECT COORDINATOR') {
            payload.role = 'state-project-coordinator';
        } else if (payload.role === 'ASSISTANT') {
            payload.role = 'assistant';
        } else if (payload.role === 'SYSTEM ADMIN') {
            payload.role = 'system-admin';
        }
        api.post('adminusers', payload)
            .then((response) => {
                // Handle the response from the API if needed
                // console.log('RUSA SPD User creation success:', response.data);
                props.handleSaveData();
            })
            .catch((error) => {
                // console.error('RUSA SPD User creation fails:', generateError(error?.response?.data?.error));
                toggleModal();
            });
        }else{
            const payload = { ...props.formData };
        if (payload.role === 'DATA OPERATOR') {
            payload.role = 'data-operator';
        } else if (payload.role === 'FINANCE OFFICER') {
            payload.role = 'finance-officer';
        } else if (payload.role === 'RESEARCH OFFICER') {
            payload.role = 'research-officer';
        } else if (payload.role === 'NODAL OFFICER') {
            payload.role = 'nodal-officer';
        } else if (payload.role === 'SECTION OFFICER') {
            payload.role = 'section-officer';
        } else if (payload.role === 'STATE PROJECT COORDINATOR') {
            payload.role = 'state-project-coordinator';
        } else if (payload.role === 'ASSISTANT') {
            payload.role = 'assistant';
        } else if (payload.role === 'SYSTEM ADMIN') {
            payload.role = 'system-admin';
        }
        api.put(`adminusers/${props.formData.id}`, payload)
            .then((response) => {
                // Handle the response from the API if needed
                // console.log('RUSA SPD User creation success:', response.data);
                props.handleSaveData();
            })
            .catch((error) => {
                // console.error('RUSA SPD User creation fails:', generateError(error?.response?.data?.error));
                toggleModal();
            });
        }
    };
    return (
        <div>
            <div className="d-flex justify-content-end my-3">
                <Button onClick={() => props.setActiveStep(1)} color="primary">
                    Edit
                </Button>
            </div>
            <div className="preview-page">
                <Row>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Name of User
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={5} sm={5}>
                                {props.formData?.name}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Email ID
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={5} sm={5}>
                                {props.formData?.email}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Phone Number
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={5} sm={5}>
                                {props.formData?.phone}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Designation
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={5} sm={5} className='text-justify'>
                                {props.formData?.designation}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                User Role
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={5} sm={5}>
                                {props.formData?.role}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="my-3">
                    <Button color="primary" onClick={handleSaveData}>
                        Create
                    </Button>
                </div>
            </div>
            <FailureError isOpen={isModalOpen} toggle={toggleModal} />
        </div>
    );
};

export default PreviewInformation;
