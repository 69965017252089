import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { api } from '../../../../../../helpers/api';
import FailureError from '../../../best practises new/best practise/error';
import { MANAGE_LABELS } from '../manage/datamanager';

const PreviewInformation = (props) => {
    // console.log(props.edit);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    const handleSaveData = () => {
        if (isSubmitting) {
            // If already submitting, do nothing
            return;
        }

        setIsSubmitting(true);
        if (!props.edit) {
            let payload = props.data;
            payload.forEach((a) => {
                a['other_status'] = 1;
            });

            api.post('institution/others', payload)
                .then((response) => {
                    // Handle the response from the API if needed
                    // console.log('success:', response.data);
                    props.setSuccess(true);
                    // props.handleCallBack();
                })
                .catch((error) => {
                    // Handle errors if the POST request fails
                    // console.error('fails:', error);
                    // toggleModal();
                });
        } else {
            // If in edit mode, make a PUT request
            let payload = props.data[0];
            payload['other_status'] = 1;

            // props.setPrimaryStep(1);
            // api.put(`/institution/others/${props.formData.id}`, payload)
            api.put(`institution/others/${props.data[0].id}`, payload)
                .then((response) => {
                    // props.handleCallBack();
                    props.setSuccess(true);
                })
                .catch((error) => {
                    // Handle error, maybe show an error message
                    // console.error('Error updating data:', error);
                    toggleModal();
                });
        }
    };
    // ------
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };
    // -----
    return (
        <div>
            <div className="view">
                {/* <div className="banner">OTHER ACCREDITATIONS</div> */}
                <Container className="container-width">
                    <div className="edit">
                        <Button color="primary" onClick={() => props.setEdit()}>
                            Edit
                        </Button>
                    </div>

                    <div className="table-section w-100 ">
                        {props.flag === 'Y' ? (
                            <>
                                {props.data?.map((item, k) => (
                                    <div className="preview-page">
                                        <Row>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={3} sm={3}>
                                                        Name of accrediting agency
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={8} sm={8}>
                                                        <div className="text-justify">
                                                            {item[MANAGE_LABELS.NAME_OF_ACCREDITING_AGENCY] ?? '--'}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={3} sm={3}>
                                                        Agency type
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={8} sm={8}>
                                                        <div className="text-justify">
                                                            {' '}
                                                            {item[MANAGE_LABELS.AGENCY_TYPE] ?? '--'}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={3} sm={3}>
                                                        Date of Accreditation
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={8} sm={8}>
                                                        <div className="text-justify">
                                                            {formatDate(item[MANAGE_LABELS.DATE_OF_ACCREDITATION])}{' '}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={3} sm={3}>
                                                        Valid Upto
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={8} sm={8}>
                                                        <div className="text-justify">
                                                            {formatDate(item[MANAGE_LABELS.VALID_UPTO])}{' '}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={3} sm={3}>
                                                        Accreditation Score/ Grade
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={8} sm={8}>
                                                        <div className="text-justify">
                                                            {' '}
                                                            {item[MANAGE_LABELS.GRADE_SCORE] ?? '--'}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={3} sm={3}>
                                                        Remarks
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={8} sm={8}>
                                                        <div className="text-justify">
                                                            {' '}
                                                            {item[MANAGE_LABELS.OTHER_REMARKS] ?? '--'}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Button
                                    color="primary"
                                    className="my-4"
                                    onClick={(e) => handleSaveData(e)}
                                    disabled={isSubmitting}>
                                    Submit for Approval
                                </Button>
                            </>
                        ) : (
                            <Row>
                                <Col sm={12} md={12} className="my-3">
                                    <Row className="text-dark">
                                        <Col md={6} sm={6}>
                                            Does your institution posses a NIRF valid NIRF Ranking?
                                        </Col>
                                        <Col md={1} sm={1}>
                                            :
                                        </Col>
                                        <Col md={5} sm={5}>
                                            No
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12} md={12} className="my-3">
                                    <Row className="text-dark">
                                        <Col md={4} sm={4}>
                                            Remarks
                                        </Col>
                                        <Col md={1} sm={1}>
                                            :
                                        </Col>
                                        <Col md={7} sm={7}>
                                            {props.status}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                </Container>
            </div>
            <FailureError isOpen={isModalOpen} toggle={toggleModal} />
        </div>
    );
};

export default PreviewInformation;
