import React, { useEffect, useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { LiaEditSolid } from 'react-icons/lia';
import { useHistory } from 'react-router-dom';
import { api } from '../../../../../helpers/api';
import { COLLEGE_LISTS } from './datamanager';
import FormArea from './form';
// import LiaEditSolid from 'react-icons/lia';
import { Button, Table } from 'reactstrap';
import Success from '../../../success';
export default function ViewComponent(props) {
    const handleFields = () => {
        return {
            // ug,pg,mphil
            INSTITUTIONS_lIST: '',
            EMAIL_ID: '',
        };
    };
    const [existingcollege, setExistingCollege] = useState([]);
    const [loading, setLoading] = useState(true);

    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get('institution-dataoperator');
            const jsonData = response?.data?.data;

            // console.log(jsonData);
            setExistingCollege(jsonData?.institutions);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const history = useHistory();

    const redirectToPage = (item) => {
        history.push({ pathname: `/dataoperator-InstitutionList/${item.id}`, state: { ...item } });
    };
    const [formData, setFormData] = useState({ ...handleFields() });
    const [formDatas, setFormDatas] = useState({
        // colleges_existing: [],
        colleges: COLLEGE_LISTS,
    });
    const [activeStep, setActiveStep] = useState(1);
    const [success, setSuccess] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [edit, setEdit] = useState(false);
    const [institution, setinstitution] = useState(false);

    const handleSaveData = () => {
        setSuccess(true);
    };
    const handleEdit = (id) => {
        const index = existingcollege.findIndex((item) => item.id === id);
        setEditIndex(index);
        setFormData({ ...existingcollege[index] });
        setOpenForm(true);
        setEdit(true);
    };

    const handleSuccessAction = () => {
        setSuccess(false);
        fetchData();
        window.location.reload();
        if (Object.keys(formData)?.length > 0) {
            const data = formDatas.colleges_existing;
            data.push(formData);
            setFormDatas((f) => ({ ...f, colleges_existing: data }));
            setFormData({ ...handleFields() });
            setOpenForm(false);
            setActiveStep(1);
        } else {
            alert('Please fill the form');
        }
    };
    const handleNext = () => {
        setActiveStep(0);
    };
    // console.log(formDatas);
    return (
        <div>
            {!openForm && (
                <div className="d-flex justify-content-between mt-3">
                    <h5 className="header-title mb-1 mt-0">Institutions List</h5>{' '}
                </div>
            )}
            {success && (
                <Success title="Institution Admin created successfully" toggle={handleSuccessAction} open={success} />
            )}
            {openForm && (
                <>
                    <FormArea
                        formData={formData}
                        handleNext={handleNext}
                        activeStep={activeStep}
                        setFormData={setFormData}
                        handleSaveData={handleSaveData}
                        setActiveStep={setActiveStep}
                        edit={edit}
                        setSuccess={setSuccess}
                        {...existingcollege[editIndex]}
                    />
                </>
            )}
            {!openForm && (
                <div className="table-section w-100 ">
                    <div className="d-flex justify-content-end mb-3 ">
                        {/* <Button onClick={() => setOpenForm(true)} color="primary">
                            Create
                        </Button>
                        <FaFileExcel className="excel-icon my-2 ml-2" style={{ color: 'green' }} /> */}
                    </div>

                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ maxWidth: '500px' }}>Name of the Institution</th>
                                <th style={{ maxWidth: '500px' }}>Email ID</th>
                                {/* <th style={{ maxWidth: '100px' }}>Actions</th> */}
                            </tr>
                        </thead>

                        <tbody>
                            {existingcollege.map((institution) => (
                                <tr className="text-left" key={institution.id}>
                                    <td
                                        style={{ maxWidth: '500px' }}
                                        className="cursor-pointer hyper_link capitalize text-justify flex justify-center pt-3"
                                        onClick={() => redirectToPage(institution)}>
                                        {institution?.institution_name}
                                    </td>
                                    <td style={{ maxWidth: '500px' }} className="pt-3">
                                        {institution?.email}
                                    </td>
                                    {/* <td
                                        style={{ fontSize: '17px', maxWidth: '100px' }}
                                        className="w-25"
                                        onClick={() => handleEdit(institution.id)}>
                                        <LiaEditSolid />
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
}
