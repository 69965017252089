import React, { useState } from 'react';
import { Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import PreviewInformation from '../preview/index.jsx';

const FormArea = (props) => {
    const { formData, setFormData, handleSaveData, activeStep, handleNext, setActiveStep } = props;
    const [fileError, setFileError] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.UPLOAD_FILE || formData.document) {
            // Check if a file is selected or already uploaded
            handleNext();
        } else {
            setFileError('Please upload a PDF file.');
        }
    };
    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileType = file.type;
            const fileSize = file.size;

            if (fileType === 'application/pdf' && fileSize <= 1048576) {
                setFormData({ ...formData, UPLOAD_FILE: file });
                setFileError('');
            } else {
                setFormData({ ...formData, UPLOAD_FILE: null }); // Clear file data
                setFileError('Please upload a valid PDF file less than 1 MB.');
            }
        } else {
            setFormData({ ...formData, UPLOAD_FILE: null }); // Clear file data
            setFileError('Please upload a PDF file.');
        }
    };

    return (
        <div>
            <div className="manage">
                <div className="form-section">
                    <div className="info">
                        <div className="mandatory">{/* <sup>*</sup>All fields are mandatory. */}</div>
                    </div>
                    <Container className="form-container permanent-area">
                        <div>
                            {activeStep === 1 ? (
                                <>
                                    <Form onSubmit={handleSubmit}>
                                        <>
                                            <>
                                                {' '}
                                                <Row className="my-3">
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-2" style={{ fontSize: '16px' }}>
                                                            Date{' '}
                                                        </Label>
                                                        <sup style={{ color: 'red', fontSize: '14px' }}> *</sup>{' '}
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="date"
                                                            value={formData.DATE}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    DATE: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Col>

                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-2 " style={{ fontSize: '16px' }}>
                                                            Govt Orders/ Proceeding details
                                                        </Label>
                                                        <sup style={{ color: 'red', fontSize: '14px' }}> *</sup>{' '}
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="text"
                                                            value={formData.GOVT_ORDER_PROCEEDINGS_DETAILS}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    GOVT_ORDER_PROCEEDINGS_DETAILS: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-2" style={{ fontSize: '16px' }}>
                                                            Upload File{' '}
                                                        </Label>
                                                        <sup style={{ color: 'red', fontSize: '14px' }}> *</sup>{' '}
                                                        <br></br>
                                                        <Input
                                                            autoComplete="off"
                                                            type="file"
                                                            accept=".pdf"
                                                            onChange={(e) => handleFileUpload(e)}
                                                            style={{ display: 'none' }}
                                                            id="fileInput"
                                                            name="UPLOAD_FILE"
                                                        />
                                                        <Label htmlFor="fileInput">
                                                            <Button
                                                                onClick={() =>
                                                                    document.getElementById('fileInput').click()
                                                                }>
                                                                Choose File
                                                            </Button>{' '}
                                                            {formData.UPLOAD_FILE?.name}
                                                        </Label>
                                                        <br></br>
                                                        {fileError && (
                                                            <label style={{ color: 'red', fontSize: '12px' }}>
                                                                {fileError}
                                                            </label>
                                                        )}
                                                    </Col>
                                                </Row>{' '}
                                            </>
                                            {/* -----------end---------- */}
                                        </>
                                        <Row>
                                            <Col md={12} sm={12} my-2>
                                                <Label className="my-2" style={{ fontSize: '16px' }}>
                                                    Remarks
                                                </Label>
                                                <Input
                                                    autoComplete="off"
                                                    type="text"
                                                    value={formData.REMARKS}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, REMARKS: e.target.value })
                                                    }
                                                />
                                            </Col>
                                        </Row>{' '}
                                        <div className="my-4">
                                            <Button type="submit">
                                                {/* // onClick={() => (activeStep === 1 ?  : handleSaveData())}> */}
                                                {activeStep === 0 ? 'Submit for Approval' : 'Preview'}
                                            </Button>
                                        </div>
                                    </Form>
                                </>
                            ) : (
                                <>
                                    <PreviewInformation
                                        setEdit={() => setActiveStep(1)}
                                        formData={formData}
                                        activeStep={activeStep}
                                        handleSaveData={handleSaveData}
                                        setActiveStep={props.setActiveStep}
                                    />
                                </>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default FormArea;
