export const MANAGE_LABELS = {
    TOTAL_COST: 'TOTAL_COST',
    PROJECT_COST: 'PROJECT_COST',
    AGREED_CONTRACT_AMOUNT: 'AGREED_CONTRACT_AMOUNT',
    
    AGENCY_NAME: 'AGENCY_NAME',
    INSTITUTION_NAME: 'INSTITUTION_NAME',
    APPROVED_COST: 'APPROVED_COST',
    AMOUNT_UTILISED: 'AMOUNT_UTILISED',
    PERCENTAGE_UTILISED: 'PERCENTAGE_UTILISED',
    // PROSSESSED_BY_THE_INSTITUTION: 'PROSSESSED_BY_THE_INSTITUTION',
    // DISABLED_FRIENDLY: 'DISABLED_FRIENDLY',
    // GIVE_DETAILS: 'GIVE_DETAILS',
    // CONSTRUCTED_BUIDINGS: 'CONSTRUCTED_BUIDINGS',
    // BUILDING_DETAILS: 'BUILDING_DETAILS',
    // FUNDIG_AGENCY: 'FUNDIG_AGENCY',
    // REMARKS: 'REMARKS',
};
