import React, { useState } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { api } from '../../../../../../helpers/api';
import Success from '../../../../success';
import { MANAGE_LABELS } from '.././manage/datamanager';

function StatusNoForm(props) {
    const [keyField, setKeyField] = useState(0);
    const [formData, setFormData] = useState(props.data);
    // const [edit,setEdit] = useState(false);
    const [formDatas, setFormDatas] = useState([]);
    const [flag, setFlag] = useState(props.flag);
    const [success, setSuccess] = useState(false);
    const [status, setStatus] = useState(false);
    const [preview, setPreview] = useState(false);
    // console.log('2', props.primaryStep);
    const fields = {
        [MANAGE_LABELS.OTHER_REMARKS]: null,
    };
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = () => {
        // console.log('Form submitted');
        //   props.setActiveStep(1);
        setStatus(true);
        setPreview(true);
    };
    const handleBack = () => {
        //   props.setActiveStep(1);
        setStatus(false);
        setPreview(false);
    };
    const handleSubmitForm = () => {
        if (isSubmitting) {
            // If already submitting, do nothing
            return;
        }

        setIsSubmitting(true);
        if (!props.edit) {
            let payload = formData;
            payload.forEach((a) => {
                a['other_status'] = 0;
            });

            api.post('institution/others', payload)
                .then((response) => {
                    // Handle the response from the API if needed
                    // console.log('success:', response.data);
                    setSuccess(true);

                    // props.handleCallBack();
                })
                .catch((error) => {
                    // Handle errors if the POST request fails
                    // console.error('fails:', error);
                    // toggleModal();
                });
        } else {
            // If in edit mode, make a PUT request

            let payload = formData;
            payload.forEach((a) => {
                a['other_status'] = 0;
            });
            // api.put(`/institution/others/${props.formData.id}`, payload)
            api.put(`institution/others/${props.data[0].id}`, payload)
                .then((response) => {
                    props.setEdit(false);
                    setSuccess(true);
                })
                .catch((error) => {
                    // Handle error, maybe show an error message
                    // console.error('Error updating data:', error);
                    // toggleModal();
                });
        }
    };

    const handleSuccessAction = (e) => {
        props.setActiveStep(1);
        setPreview(false);
        props.fetchData();
    };
    const handleChange = (name, value, ke) => {
        // console.log(name);
        let d = formData;
        d.forEach((item, ind) => {
            if (ind === ke) {
                item[name] = value;
            }
        });

        setFormData(d);
        setKeyField((f) => f + 1);
    };

    const validate = () => {
        let error = {};
        if (flag === 'Y')
            formData.forEach((item, k) => {
                Object.keys(item).map((key) => {
                    // console.log(item, key);
                    if (!item[key] || item[key] === '') {
                        error[`${k}_${key}`] = 'please fill this field';
                    }
                });
            });

        return error;
    };

    return (
        <>
            {success && (
                <Success
                    title="Other accreditation details submitted for approval by the Institution head"
                    toggle={handleSuccessAction}
                    open={success}
                />
            )}
            <div className="manage">
                <div className="form-section">
                    <Container className="form-container ms-2 mt-3">
                        {status === false ? (
                            <>
                                <>
                                    <Form className="form-style" onSubmit={handleSubmit}>
                                        {formData?.map((item, ke) => (
                                            <Row className="repeat-box" key={ke}>
                                                <Col sm={12} md={12} className='mt-3'>
                                                    <FormGroup>
                                                        <Label style={{ fontSize: '14px' }}>Remarks <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}</Label>
                                                        <Input
                                                            id={MANAGE_LABELS.OTHER_REMARKS}
                                                            name={MANAGE_LABELS.OTHER_REMARKS}
                                                            value={item[MANAGE_LABELS.OTHER_REMARKS] ?? ''}
                                                            required
                                                            maxLength={1000}
                                                            onChange={(e) =>
                                                                handleChange(e.target.name, e.target.value, ke)
                                                            }
                                                            type="text"></Input>
                                                        <div style={{ color: '#2971b6' }}>
                                                            Limit your description to 1000 characters or less
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} sm={12}>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        outline
                                                        className='mb-4'
                                                        // onClick={handleSubmit}>
                                                    >
                                                        Preview
                                                    </Button>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Form>
                                </>
                            </>
                        ) : preview === true ? (
                            <>
                                <div className="d-flex justify-content-end mb-3">
                                    {' '}
                                    <Button color="primary" onClick={() => handleBack()}>
                                        Edit
                                    </Button>
                                </div>
                                <div className="table-section w-100 ">
                                    <>
                                        <div className="preview-page">
                                            {formData?.map((item, k) => (
                                                <Row key="k">
                                                    <Col sm={12} md={12} className="my-3">
                                                        <Row className="text-dark">
                                                            <Col md={3} sm={3}>
                                                                Remarks
                                                            </Col>
                                                            <Col md={1} sm={1}>
                                                                :
                                                            </Col>
                                                            <Col md={8} sm={8}>
                                                                <div className="text-justify">
                                                                    {' '}
                                                                    {item[MANAGE_LABELS.OTHER_REMARKS] ?? '--'}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col sm={12} md={12}>
                                                        <Button
                                                        className='my-3'
                                                            color="primary"
                                                            onClick={handleSubmitForm}
                                                            disabled={isSubmitting}>
                                                            Submit for Approval
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </div>
                                    </>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </Container>
                </div>
            </div>
        </>
    );
}

export default StatusNoForm;
