import React from 'react';
import { Row, Container, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useState } from 'react';
// import DataOperatorStaffPatternMain from './staff_details';
import StageTwoMain from './Stage 2';
// import DataOperatorFundDetailsMain from './Details of Fund Received/rankholders';
import StageOneMain from './Stage 1';
import PreparatoryGrantUtilisation from './preparatory_grant_details';
function DataOperatorRusaFundUtilisationDetails() {
    const tabContents = [
        { id: '1', title: 'Equity Initiative Utilisation Stage 1' },
        { id: '2', title: 'Equity Initiative Utilisation Stage 2' },
        { id: '3', title: 'Preparatory Grant Utilisation' },
    ];
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card>
                    <CardBody>
                        <>
                            <Nav className="nav nav-pills navtab-bg nav-justified">
                                {tabContents.map((tab, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === tab.id })}
                                                onClick={() => {
                                                    toggle(tab.id);
                                                }}>
                                                <i className={classnames(tab.icon, 'd-sm-none', 'd-block', 'mr-1')}></i>
                                                <span className="d-none d-sm-block">{tab.title}</span>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>

                            <TabContent>
                                {activeTab === '1' ? (
                                    <>
                                        <StageOneMain />
                                    </>
                                ) : activeTab === '2' ? (
                                    <>
                                        <StageTwoMain />
                                    </>
                                ) : (
                                    <>
                                        <PreparatoryGrantUtilisation />
                                    </>
                                )}
                            </TabContent>
                        </>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default DataOperatorRusaFundUtilisationDetails;
