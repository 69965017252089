import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';

function HeadNAAC(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(true);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };

    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get(`admin-institution-info`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.naac_accreditations;
            const updatedUsers = jsonData?.map((user) => ({
                ...user,
                accrediation_status: user.accrediation_status === 1 ? 'Yes' : 'No'
            }));
            const accreditation = jsonData;

            if (accreditation.some((item) => item.accrediation_status === 0 && item.is_approved === 1)) {
                setHasDataNo(true);
            }

            if (accreditation.some((item) => item.accrediation_status === 1 && item.is_approved === 1)) {
                setHasDataYes(true)
            }
            setGeneralDetails(updatedUsers);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
        return formattedDate;
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-left mx-3">
                <Row className="label">
                    <label>Does your institution possess NAAC accreditation during previous 5 years ?</label>
                </Row>
                <Row className="ml-5">:</Row>
                <Row className="ml-5">
                    <FormGroup check>
                            <Input
                                type="radio"
                                name="showForm"
                                value="yes"
                                checked={showForm}
                                onChange={() => setShowForm(true)}
                            />{' '}
                        <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                            Yes
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                            <Input
                                type="radio"
                                name="showForm"
                                value="no"
                                checked={!showForm}
                                onChange={() => setShowForm(false)}
                            />{' '}
                        <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                            No
                        </Label>
                    </FormGroup>
                </Row>
            </div>
            {showForm ? (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ minWidth: '150px' }}>Date of accreditation</th>
                                <th>Valid Upto</th>
                                <th>NAAC Grade</th>
                                <th>NAAC Score</th>
                                <th>Present NAAC Accreditation Cycle</th>
                                <th>Remarks</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generalDetails.filter((data) => data.accrediation_status === 'Yes').map((item, index) => (
                                <tr key={index} className="text-left">
                                    <td>{formatDate(item.accreditation_date)}</td>
                                    <td>{formatDate(item.valid_until)}</td>
                                    <td>{item.grade}</td>
                                    <td>{item.score}</td>
                                    <td>{item.present_accreditation_cycle}</td>
                                    <td className="text-justify">{item.remarks}</td>
                                    <td>
                                        {item.is_approved === 0 ? (
                                            <Button
                                            disabled
                                            color="warning"
                                            className="btn btn-secondary"
                                            style={{
                                                color: '#ED7D31',
                                                fontWeight: '400',
                                                backgroundColor: '#F5F7F8',
                                            }}
                                            size="sm">
                                            Pending
                                        </Button>
                                        ) : item.is_approved === 2 ? (
                                            <Button disabled color="danger" size="sm">
                                                Reverted by head
                                            </Button>
                                        ) : item.is_approved === 1 ? (
                                            <Button disabled color="success" size="sm">
                                                Approved by head
                                            </Button>
                                        ) :item.is_approved === 3 ? (
                                            <Button disabled color="info" size="sm">
                                                Reverted by RUSA Admin
                                            </Button>
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ minWidth: '150px' }}>Current Application Status</th>
                                <th style={{ minWidth: '150px' }}>Remarks</th>
                                <th style={{ minWidth: '100px' }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generalDetails.filter((data) => data.accrediation_status === 'No').map((item, index) => (
                                <tr key={index} className="text-left">
                                    <td>{item.current_application_status}</td>
                                    <td>{item.remarks}</td>
                                    <td>
                                        {item.is_approved === 0 ? (
                                            <Button
                                            disabled
                                            color="warning"
                                            className="btn btn-secondary"
                                            style={{
                                                color: '#ED7D31',
                                                fontWeight: '400',
                                                backgroundColor: '#F5F7F8',
                                            }}
                                            size="sm">
                                            Pending
                                        </Button>
                                        ) : item.is_approved === 2 ? (
                                            <Button disabled color="danger" size="sm">
                                                Reverted by head
                                            </Button>
                                        ) : item.is_approved === 1 ? (
                                            <Button disabled color="success" size="sm">
                                                Approved by head
                                            </Button>
                                        ): item.is_approved === 3 ? (
                                            <Button disabled color="info" size="sm">
                                                Reverted by RUSA Admin
                                            </Button>
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
}

export default HeadNAAC;
