import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Container, Row, Col, Card, CardBody,Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Failure from '../../../../../../institutions/failure';


function HeadClassRoomDetails(props) {
   
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);
    const { id } = useParams();
    
        const fetchData = async () => {
            try {
                const response = await api.get(`institution/${id}`); // Assuming api.get works as expected

                const jsonData = response?.data?.data?.data;

                // console.log(jsonData);
                setGeneralDetails(jsonData.classrooms);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        useEffect(() => {
        fetchData();
    }, [id]);
    
    const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`/classroom/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setGeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    // console.log(props.status);
    // console.log(props.remark);
    return (
        <div>
            <div className="view">
            {revert && (
                <Failure
                    title="Infrastructure classroom details has been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <Container className="p-0 my-4">
                <Card>
                    <CardBody className="p-0">
                        {/* <h5 className="header-title mb-3 mt-0">Institution General Information</h5> */}
                        {/* <div className="edit">
                        <Button color="primary" className="px-3" onClick={() => props.setNewReg(true)}>
                            Create
                        </Button> 
                    </div> */}
                        <div className="table-section" style={{ textAlign: 'justify' }}>
                            {generalDetails.length > 0 ? (
                                generalDetails.map((data, i) => (
                                    <Table style={{ width: '100%', tableLayout: 'fixed' }} striped key={i}>
                                        <tbody>
                                        <tr style={{ width: '100%' }}>
                                                <div className='w-50 d-flex justify-content-start '>
                                                <td>
                                                {data.is_approved === 0 ? (
                                                    <td className="w-50">
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                ) : data.is_approved === 2 ? (
                                                    <td className="w-50">
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted by Head
                                                        </Button>
                                                    </td>
                                                ) : data.is_approved === 1 ? (
                                                    <td className="w-50">
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved by Head
                                                        </Button>
                                                    </td>
                                                ) :  data.is_approved === 3 && (
                                                    <td className="w-50">
                                                        <Button type="button" color="info" size="sm" disabled>
                                                            Reverted by head
                                                        </Button>
                                                    </td>
                                                )}
                                                </td>
                                                <div>
                                                <td>
                                                            {data.is_approved === 1 && (
                                                                <td>
                                                                    <Button
                                                                        type="button"
                                                                        size="sm"
                                                                        outline
                                                                        color="primary"
                                                                        onClick={() => handleRevert(data.id)}
                                                                    >  
                                                                        Revert
                                                                    </Button>
                                                                </td>
                                                            )}
                                                        </td>
                                                        </div>
                                                        </div>
                                                        <td></td>
                                            </tr>
                                            <tr style={{ width: '40%' }}>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Total number of Classrooms</td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.classroom ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 25</td>
                                                <td>{data.upto25 ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 50</td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.upto50 ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 100</td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.upto100 ?? '--'}</td>
                                            </tr>{' '}
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity more than 100</td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.above100 ?? '--'}</td>
                                            </tr>{' '}
                                           
                                            <tr style={{ width: '50%' }}>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}> Remarks</td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}><div className="text-justify">{data.remarks ?? '--'}</div></td>
                                            </tr>{' '}
                                        </tbody>
                                    </Table>
                                ))
                            ) : (
                                <Table striped>
                                    <tbody>
                                        <tr style={{ width: '50%' }}>
                                            <td> Total number of Classroom</td>
                                            <td>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 25</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 50</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 100</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity more than 100</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                       
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}> Remarks</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                    </tbody>
                                </Table>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
        </div>
    );
}


export default HeadClassRoomDetails;
