import React from 'react';
import { Row, Col, Card, CardBody, TabContent, Nav, NavItem, NavLink, Container } from 'reactstrap';
import classnames from 'classnames';
import { useState } from 'react';

import Budget_details from './Budget_details';
import Details_of_fund_received from './Details_of_fund_r';
import Fund_recieved_and_utilization_details from './Fund_received_and_utilization_details';

function FundingDetailsSPD() {
    const [activeTab, setActiveTab] = useState('1');

    const tabContents = [
        { id: '1', title: 'Budget Details' },
        { id: '2', title: 'Details of fund received' },
        { id: '3', title: 'Fund received and utilization details' },
    ];

    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card>
                    <CardBody>
                        <Nav className="nav nav-pills navtab-bg nav-justified">
                            {tabContents.map((tab, index) => {
                                return (
                                    <NavItem key={index}>
                                        <NavLink
                                            href="#"
                                            className={classnames({ active: activeTab === tab.id })}
                                            onClick={() => {
                                                toggle(tab.id);
                                            }}>
                                            <i className={classnames(tab.icon, 'd-sm-none', 'd-block', 'mr-1')}></i>
                                            <span className="d-none d-sm-block">{tab.title}</span>
                                        </NavLink>
                                    </NavItem>
                                );
                            })}
                        </Nav>

                        <TabContent>
                            {activeTab === '1' ? (
                                <>
                                    <Budget_details />
                                </>
                            ) : activeTab === '2' ? (
                                <>
                                    <Details_of_fund_received />
                                </>
                            ) : activeTab === '3' ? (
                                <>
                                    <Fund_recieved_and_utilization_details />
                                </>
                            ) : (
                                <>{}</>
                            )}
                        </TabContent>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default FundingDetailsSPD;
