import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import { FaFileExcel } from "react-icons/fa";
import { assistant_budget, finance_budget, nodal_budget, research_budget, section_budget, state_budget, system_budget } from '../../../../../helpers/actions/api.functions';
import { getLoggedInUser } from '../../../../../helpers/authUtils';
import toast from 'react-hot-toast';




function BudgetDetails() {
    const [data, setData] = useState([]);
    const userData = getLoggedInUser();
    const userRole = userData?.role_id;
    // console.log(userRole);

    const getEndpoint = (id) => {
        switch (id) {
            case '9':
                return section_budget();
            case '10':
                return state_budget();
            case '7':
                return research_budget();
            case '8':
                return nodal_budget();
            case '11':
                return assistant_budget();
            case '12':
                return system_budget();
            case '6':
                return finance_budget();
            default:
                return '';
        }
    };
    const getStaffListing = (userRole) => {
        getEndpoint(userRole)
            .then((res) => {
                setData(res?.data?.data);
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
            });
    };

    useEffect(() => {
        if (userRole) {
            getStaffListing(userRole);
        }
    }, [userRole]);
   
    return (
        <div>
            <div className="view ">
                
                
            </div>
            <Container className="form-container" style={{ padding: '10px' }}>
            

            <div className="table-section w-100 my-5 ">
                       
                        <Table striped>
                            <thead className="table-head">
                                <tr className="text-left">
                                    
                                    <th style={{ minWidth: '100px' }}>Financial Year</th>
                                    <th style={{ minWidth: '150px' }}>Details of Budget</th>
                                    <th style={{ minWidth: '80px' }}>Head of Account</th>
                                    <th style={{ minWidth: '80px' }}>Total Outlay</th>
                                    <th style={{ minWidth: '80px' }}>Remarks</th>
                                    <th style={{ minWidth: '80px' }}>Status</th>
                                    
                                </tr>
                            </thead>
                            {/* {College_names.map((item, id) => {
                                
                                return (
                                    <tbody key={id}>
                                        <tr className="text-left">
                                            <td>{id+1}</td>
                                            <td>{item.label}</td>
                                            <td><FiEdit /></td>
                                            
                                        </tr>
                                    </tbody>
                                );
                            })} */}
                             {data && data?.length > 0 ? (
                                    <tbody>
                                        {data?.map((item) => (
                                            <tr className="text-left">
                                                <td>{item?.year}</td>
                                    <td>{item?.details}</td>
                                    <td>{item?.account}</td>
                                    <td>{item?.total}</td>
                                    <td>{item?.remarks}</td>
                                                <td style={{ maxWidth: '5n0px' }}>
                                                    {item.is_approved === 0 && (
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 1 && (
                                                        <Button disabled color="success" size="sm">
                                                            Approved
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 2 && (
                                                        <Button disabled color="danger" size="sm">
                                                            Reverted 
                                                        </Button>
                                                    )}
                                                    </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr className="text-center">
                                            <th colSpan={6}>No Data Found</th>
                                        </tr>
                                    </tbody>
                                )}
                               
                         
                        </Table>
                    </div>
            </Container>        
        </div>
    );
}

export default  BudgetDetails;
