import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
const PreviewInformation = (props) => {
    const{setActiveStep}=props
    return (
        <div>
            
            <div className="preview-page">
            <Button type="button" color="primary" className='edit-button mx-2 my-2' onClick={() => setActiveStep(1)} >
            Edit
            </Button>
                <Row>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                            Date
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.DATE}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                            Govt Orders/ Proceedings details
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.GOVT_ORDER_PROCEEDINGS_DETAILS}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                            Uploaded File
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.UPLOAD_FILE?.name}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                              Remarks
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.REMARKS}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                    <Button color="primary" onClick={() => props.handleSaveData()}>
                        Submit for Approval
                    </Button>
                    </Col>

                    

                    
                </Row>
            </div>
        </div>
    );
};

export default PreviewInformation;
