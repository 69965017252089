import React, { useState } from 'react';
import { Button, Col, Container, Row, Table } from 'reactstrap';

function GeneralInformation(props) {
    // console.log(props.newReg, props.listData);
    const [formData, setFormData] = useState({});
    return (
        <div>
            <div className="view">
                {/* <div className="banner">OTHER ACCREDITATIONS</div> */}

                <Container className="container-width">
                {!props.listData || props.listData?.length === 0 ? (
                        <div className="edit">
                            <Button color="primary" className="px-3 mt-2 " onClick={() => props.handleCreate()}>
                                Create
                            </Button>
                        </div>
                    ) : (
                        <div className="edit">
                            <Button color="primary" className="px-3 mt-2 " onClick={() => props.handleEdit()}>
                                Edit
                            </Button>
                        </div>
                    )}
                    

                    <div className="preview-page">
                    
                            
                       
                        <Row>
                            <Col className='d-flex justify-content-end'>
                            {props.listData?.is_approved === 0 ? (
                                        <td className="text-primary">Pending</td>
                                    ) : props.listData?.is_approved === 1 ? (
                                        <td className="text-success">Approved</td>
                                    ) : (
                                        <td className="text-warning">Reverted</td>
                                    )}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} className="my-3">
                                <Row className="text-dark">
                                    <Col md={4} sm={4}>
                                        Total Project Cost
                                    </Col>
                                    <Col md={2} sm={2}>
                                        :
                                    </Col>
                                    <Col md={6} sm={6}>
                                        {props.listData?.cost}
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm={12} md={12} className="my-3">
                                <Row className="text-dark">
                                    <Col md={4} sm={4}>
                                        Agreed Probable Amount of Contract
                                    </Col>
                                    <Col md={2} sm={2}>
                                        :
                                    </Col>
                                    <Col md={6} sm={6}>
                                        {props.listData?.amount}
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm={12} md={12} className="my-3">
                                <Row className="text-dark">
                                    <Col md={4} sm={4}>
                                        Agency Name
                                    </Col>
                                    <Col md={2} sm={2}>
                                        :
                                    </Col>
                                    <Col md={6} sm={6}>
                                        {props.listData?.agency}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    {/* <div className="mandatory my-3 text-dark">Details of Rank holders</div> */}
                    <Table striped className="mt-5">
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '150px' }}>Institution Name</th>
                                <th style={{ minWidth: '150px' }}>Approved Cost</th>
                                <th style={{ minWidth: '100px' }}>Amount Utilised</th>
                                <th style={{ minWidth: '100px' }}>% of Utilisation</th>
                                {/* <th style={{ minWidth: '100px' }}>Actions</th> */}
                            </tr>
                        </thead>

                        {props.listData?.institutions?.length > 0 ? (
                            props.listData?.institutions.map((item, k) => (
                                <>
                                    <tbody>
                                        <tr className="text-left">
                                            <td>{item.name ?? '--'}</td>
                                            <td>{item.approved ?? '--'}</td>
                                            <td>{item.utilised ?? '--'}</td>
                                            <td>{item.percentage ?? '--'}</td>
                                            
                                        </tr>
                                    </tbody>
                                </>
                            ))
                        ) : (
                            <>
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        No data found
                                    </td>
                                </tr>
                            </>
                        )}
                    </Table>

                    {/* <Col className="d-flex justify-content-start mt-4 ml-2 p-0">
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                           >
                                                            Approve
                                                        </Button>

                                                        <Button
                                                            color="primary"
                                                            outline
                                                            className="ml-3"
                                                            >
                                                            Resubmit
                                                        </Button>
                                                    </Col> */}
                </Container>
            </div>
        </div>
    );
}

export default GeneralInformation;
