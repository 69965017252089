import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

import { MinusCircle, PlusCircle } from 'react-feather';
import { Button, Container, Form, Input, Row, Table } from 'reactstrap';
import Success from '../../../../../../pages/apps/success';
import PreviewInformation from '../preview';
import { MANAGE_LABELS } from './datamanager';

function ManageGeneralInformation(props) {
    const { faculityData2 } = props; // Destructure props

    const [faculityData, setFaculityData2] = useState(faculityData2); // Define setFaculityData2
    const [keyField2, setKeyField2] = useState(0);
    const [success, setSuccess] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [validEmail, setValidEmail] = useState(false); // Changed variable name to validEmail

    const Faculityfields2 = {
        [MANAGE_LABELS.name]: null,
        [MANAGE_LABELS.designation]: null,
        [MANAGE_LABELS.number]: null,
        [MANAGE_LABELS.email]: null,
    };

    const removeFaculityField2 = (j) => {
        const updatedFaculityData2 = faculityData.filter((_, index) => j !== index);
        setFaculityData2(updatedFaculityData2);
        setKeyField2(keyField2 + 1);
    };

    const onAddFaculityFields2 = () => {
        const updatedFaculityData2 = [...faculityData, Faculityfields2];
        setFaculityData2(updatedFaculityData2);
        setKeyField2(keyField2 + 1);
    };

    // Rest of your code...

    const handleSubmit = (e) => {
        e.preventDefault();
        const error = validate();
    
        if (Object.keys(error).length === 0) {
            const isEmailValid = faculityData.every(item => isValidEmail(item[MANAGE_LABELS.email]));
    
            if (isEmailValid) {
                setActiveStep(2);
            } else {
                setValidEmail(true);
            }
        }
    };
    
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    
    const validate = () => {
        let error = {};
    
        faculityData.forEach((item, k) => {
            Object.keys(item).forEach((key) => {
                if (!item[key] || item[key] === '') {
                    error[`${k}_${key}`] = 'Please fill this field';
                }
            });
        });
    
        return error;
    };
    
    const handleSuccessAction = () => {
        props.onSubmit({ faculityData2 });
    };
    
    const faculityTableChange2 = (name, value, index) => {
        const updatedFaculityData2 = [...faculityData];
        updatedFaculityData2[index][name] = value;
        setFaculityData2(updatedFaculityData2);
        setKeyField2(keyField2 + 1);
    };
    
    return (
        <div>
            {success && (
                <Success
                    title="Staff Pattern in RUSA submitted for approval by the RUSA Admin"
                    toggle={handleSuccessAction}
                    open={success}
                />
            )}
            <Container className="form-container p-0 mt-5 ml-2">
                {activeStep === 1 ? (
                    <Form className="form-style" onSubmit={handleSubmit}>
                    <Table striped>
                        <thead>
                            <tr></tr>
                        </thead>
                        <thead style={{ fontSize: '13px' }} className="table-head">
                            <tr>
                                <th>Name *</th>
                                <th>Designation *</th>
                                <th>Contact Number *</th>
                                <th>Email *</th>
                                {!props?.newEdit && <th></th>}
                            </tr>
                        </thead>
                        <tbody className=" text-center" style={{ fontSize: '13px' }}>
                            {faculityData.map((item, j) => (
                                <tr key={j}>
                                    <td>
                                        <Input
                                            type="text"
                                            value={item[MANAGE_LABELS.name] ?? ''}
                                            name={MANAGE_LABELS.name}
                                            required
                                            onChange={(e) =>
                                                faculityTableChange2(e.target.name, e.target.value, j)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            type="text"
                                            value={item[MANAGE_LABELS.designation] ?? ''}
                                            name={MANAGE_LABELS.designation}
                                            required
                                            onChange={(e) =>
                                                faculityTableChange2(e.target.name, e.target.value, j)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            type="text"
                                            minLength={10}
                                            maxLength={12}
                                            value={item[MANAGE_LABELS.number] ?? ''}
                                            name={MANAGE_LABELS.number}
                                            required
                                            onChange={(e) =>
                                                faculityTableChange2(
                                                    e.target.name,
                                                    e.target.value.replace(/[^0-9+]/g, ''),
                                                    j
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            type="email"
                                            value={item[MANAGE_LABELS.email] ?? ''}
                                            name={MANAGE_LABELS.email}
                                            required
                                            onChange={(e) =>
                                                faculityTableChange2(e.target.name, e.target.value, j)
                                            }
                                        />
                                        {validEmail && <div className='text-danger text-left'>Invalid email</div>}
                                    </td>
                                    {!props?.newEdit && (
                                        <td>
                                            <div className="d-flex">
                                                {j === faculityData.length - 1 && (
                                                    <div className="pr-2">
                                                        <PlusCircle onClick={(e) => onAddFaculityFields2()} />
                                                    </div>
                                                )}
                                                {j !== 0 && (
                                                    <div className="pr-2">
                                                        <MinusCircle onClick={(e) => removeFaculityField2(j)} />
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="d-flex justify-content-start mt-4 mx-2">
                        <Row>
                            <Button type="submit" color="primary" outline>
                                Preview
                            </Button>
                        </Row>
                    </div>
                </Form>
                
                ) : (
                    <PreviewInformation
                        faculityData2={faculityData2}
                        setSuccess={setSuccess}
                        setActiveStep={setActiveStep}
                        edit={props.newEdit ? true : false}
                    />
                )}
            </Container>
        </div>
    );
    

}
export default ManageGeneralInformation;