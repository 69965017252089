import React, { useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { api } from '../../../../../../../helpers/api';
import Failure from '../../../../../institutions/failure';
import Success from '../../../../../success';
export default function FundDetails(props) {
    const [success, setSuccess] = useState(false);
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);



   

    const fetchData = async () => {
        try {
            const response = await api.get(`/admin-institution-info`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.rusa_phase_two;
            setgeneralDetails(jsonData);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

  

    // let component = props.checkboxA ? 'Research' : 'Innovation and quality improvement'
    return (
        <div>
      

            <div className="table-section w-100 overflow-auto">
                <Table striped>
                    <thead className="table-head">
                        <tr className="text-left">
                            <th style={{ minWidth: '150px' }}>Date as on :</th>

                            <th style={{ minWidth: '200px' }}>Construction Details</th>

                            <th style={{ minWidth: '200px' }}>Renovation Details</th>

                            <th style={{ minWidth: '200px' }}>Purchase Details</th>

                            <th style={{ minWidth: '200px' }}>Other Details</th>
                            <th style={{ minWidth: '200px' }}>Enhancing quality and excellences details</th>
                            <th style={{ minWidth: '200px' }}> Major Research Projects details</th>

                            <th style={{ minWidth: '250px' }}>
                                Details of Fund Utilized from the total allocated amount
                            </th>

                            <th style={{ minWidth: '250px' }}>Details of Physical Progress</th>

                            
                        </tr>
                    </thead>
                    {generalDetails.filter((item) => item.type === 'fund utilized' && (
                                item.component_name === 'Research'
                                ? 'Research'
                                : 'Innovation and quality improvement'
                            )).length > 0 ? (
                            <tbody>
                                {generalDetails?.map((item, index) =>
                                    item.type === 'fund utilized' && (
                                        item.component_name === 'Research'
                                        ? 'Research'
                                        : 'Innovation and quality improvement'
                                    ) ? (
                                    <tr key={index} className="text-left">
                                        <td>{item.date}</td>

                                        <td>{item.construction_details}</td>

                                        <td>{item.renovation_details}</td>

                                        <td>{item.purchase_details}</td>

                                        <td>{item.other_details}</td>

                                        <td>{item.enhancing_quality_details}</td>
                                        
                                        <td>{item.major_research_details}</td>

                                        <td>{item.fund_utilized_details}</td>

                                        <td>{item.physical_progress_details}</td>

                                       
                                    </tr>
                                ) : (
                                    ''
                                )
                            )}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="15" className="text-center">
                                    No data found
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>
        </div>
    );
}
