import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';
function HeadAuditoriumDetails(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);
    const { id } = useParams();
    
        const fetchData = async () => {
            try {
                const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected

                const jsonData = response?.data?.data?.data;

                // console.log(jsonData);
                setGeneralDetails(jsonData.buildings);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        useEffect(() => {
        fetchData();
    }, [id]);

    

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    
    return (
        <div>
            {revert && (
                <Failure
                    title="Infrastructure building details has been Reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            {generalDetails.length > 0 ? (
               
                    <div className="view">
                        <Container className="container-width ml-1">
                            <div className="table-section w-100">
                                {generalDetails?.map((data, i) => (
                                    <>
                                        <div className="preview-page mx-0">
                                            <Row>
                                                <Col className='my-4'>
                                                    <>
                                                        {data.is_approved === 0 && (
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 1 && (
                                                            <Button disabled color="success" size="sm">
                                                                Approved
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 2 && (
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 3 && (
                                                        <td>
                                                            <Button type="button" size="sm" color="info" disabled>
                                                                Reverted by Head
                                                            </Button>
                                                        </td>
                                                        )}
                                                    </>
                                                    </Col>
                                                    <Col >
                                                  
                                                    
                                                </Col>
                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={6} sm={6}>
                                                            Total Area of land possessed by the institution (in acres)
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={5} sm={5}>
                                                            {data.area ?? '--'}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={6} sm={6}>
                                                        Whether all the buildings are disabled friendly
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={5} sm={5}>
                                                            {data.disabled === 1 ? 'Yes' : 'No'}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={6} sm={6}>
                                                            if Yes /No, Give details :
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={5} sm={5}>
                                                        <div className="text-justify">{data.details ?? '--'}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={6} sm={6}>
                                                            Total Number of newly constructed buildings in the last 5
                                                            years
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={5} sm={5}>
                                                            {data.building_count ?? '--'}
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col sm={12} md={12} className="mt-3 mb-1">
                                                    {' '}
                                                    <div className="d-flex justify-content-center w-100">
                                                        <Table striped className="">
                                                            <thead className="table-head">
                                                                <tr className="text-left">
                                                                    <th style={{ maxWidth: '525px' }}>
                                                                        Building Details
                                                                    </th>
                                                                    <th style={{ maxWidth: '525px' }}>
                                                                        Funding Agency
                                                                    </th>
                                                                    {/* <th style={{ minWidth: '150px' }}>Actions</th> */}
                                                                </tr>
                                                            </thead>
                                                            {data.funding_agencies ? (
                                                                <tbody>
                                                                    {data.funding_agencies.map((item, k) => (
                                                                        <tr className="text-left" key={k}>
                                                                            <td style={{maxWidth:"525px"}}>{item.agency_name ?? '--'}</td>
                                                                            <td style={{maxWidth:"525px"}}>{item.building_name ?? '--'}</td>
                                                                            {/* <td>
                                                                <FaRegEdit className="edit-icon" />
                                                            </td> */}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            ) : (
                                                                <tbody>
                                                                    <tr className="text-center">
                                                                        <td colSpan="3">No data found</td>
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </Col>

                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={6} sm={6}>
                                                            Remarks
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={5} sm={5}>
                                                        <div className="text-justify">{data.remarks ?? '--'}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </Container>
                    </div>
                
            ) : (
                <>
                    <div className="view">
                        <Container className="container-width ml-1">
                            <div className="table-section w-100 ">
                                <>
                                    <div className="preview-page">
                                        <Row>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={6} sm={6}>
                                                        Total Area of land possessed by the institution (in acres)
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={5} sm={5}>
                                                        --
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={6} sm={6}>
                                                    Whether all the buildings are disabled friendly
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={5} sm={5}>
                                                        --
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={6} sm={6}>
                                                        if Yes /No, Give details :
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={5} sm={5}>
                                                        --
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={6} sm={6}>
                                                        Total Number of newly constructed buildings in the last 5 years
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={5} sm={5}>
                                                        --
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <div className="d-flex justify-content-center w-100">
                                                    <Table striped className="">
                                                        <thead className="table-head">
                                                            <tr className="text-left">
                                                                <th style={{ minWidth: '50px' }}>Building Details</th>
                                                                <th style={{ minWidth: '150px' }}>Funding Agency</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr className="text-center">
                                                                <td colSpan="3">No data found</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={6} sm={6}>
                                                        Remarks
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={5} sm={5}>
                                                        --
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            </div>
                        </Container>
                    </div>
                </>
            )}
        </div>
    );
}

export default HeadAuditoriumDetails;
