import React from 'react';
import { Edit } from 'react-feather';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { MANAGE_LABELS } from '../manage/datamanager';
import { create_staffPattern, update_staffPattern } from '../../../../../../helpers/actions/api.functions';
import { getErrorMessage } from '../../../../../../components/genError';
import toast from 'react-hot-toast';
const PreviewInformation = (props) => {
    const { setActiveStep } = props;
    // console.log(props);
    const handleSubmit = () => {
        // console.log(props?.faculityData2)
        const data = props?.faculityData2;
        // console.log(data);
        if (props?.edit === true) {
            const id = data[0].id;
            update_staffPattern(data[0], id)
                .then((res) => {
                    // console.log(res);
                    props.setSuccess(true);
                   
                })
                .catch((err) => {
                    toast.error(`Something Went Wrong`);
                });
        } else {
            create_staffPattern(data)
                .then((res) => {
                    // console.log(res);
                    props.setSuccess(true);
                    props.setEdit(false);
                    props.setNewEdit(false);
                })
                .catch((err) => {
                    toast.error(`${getErrorMessage(err.response.data.error)}`, {
                        duration: 4000,
                        position: 'top-right',
                    });
                });
        }
    };
    return (
        <div className="view ">
            <div className="d-flex justify-content-end my-3">
                <Button color="primary" className=" px-3 mt-0" onClick={() => setActiveStep(1)}>
                    Edit
                </Button>
            </div>
            <Table striped>
                <thead className="table-head">
                    <tr>
                        <th>Name *</th>
                        <th>Designation *</th>
                        <th>Contact Number*</th>
                        <th>Email ID*</th>
                        {/* <th>Action</th> */}
                    </tr>
                </thead>
                {props.faculityData2?.map((item, k) => (
                    <tbody className="border-bottom">
                        <tr>
                            <td>{item[MANAGE_LABELS.name] ?? '--'}</td>
                            <td>{item[MANAGE_LABELS.designation] ?? '--'}</td>
                            <td>{item[MANAGE_LABELS.number] ?? '--'}</td>
                            <td>{item[MANAGE_LABELS.email] ?? '--'}</td>
                            {/* <td>
                                    {""}
                                  <Button>Edit</Button> <Button>Remove</Button> 
                                    </td> */}
                        </tr>
                    </tbody>
                ))}
            </Table>
            <div className="">
                <Button color="primary" className=" px-3" onClick={() => handleSubmit()}>
                    Submit for approval
                </Button>
            </div>
        </div>
    );
};

export default PreviewInformation;
