import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'reactstrap';
// import Success from '../../success';
import { api } from '../../../../../helpers/api';

function HeadUg(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);
    const [formData, setFormData] = useState([]);

    const handleSaveData = (id) => {
        formData.is_approved = 1;
        // console.log(formData);
        api.post(`academic/approve/${id}`, formData)
            .then((response) => {
                // console.log('Approved');
                setSuccess(true);
            })
            .catch((error) => {
                // console.error('Failed');
                // toggleModal();
            });
    };

    const handleRevertData = (id) => {
        api.post(`academic/revert/${id}`)
            .then((response) => {
                // console.log('Reverted');
                setRevert(true);
            })
            .catch((error) => {
                // Handle errors if the POST request fails
                // console.error('Failed');
                // toggleModal();
            });
    };

    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.academic_details);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSuccessAction = () => {
        fetchData();
        setSuccess(false);
    };
    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };

    return (
        <div>
            <div className="view">
                <Container className="container-width">
                    <div className="table-section w-100 overflow-auto">
                        <Table striped>
                            <thead className="table-head ml-5 mt-0 ">
                                <tr>
                                <th style={{ minWidth: '129px' }}>Academic Year (YYYY-YYYY)</th>
                                <th style={{ minWidth: '142px' }}>Course Type</th>
                                <th style={{ minWidth: '205px' }}>Course Discipline</th>
                                <th style={{ minWidth: '205px' }}>Course Offered</th>
                                <th style={{ minWidth: '138px' }}>No.of Seats </th>
                                <th style={{ minWidth: '168px' }}>Total no.of Students</th>
                                <th style={{ minWidth: '164px' }}>No.of Male Students</th>
                                <th style={{ minWidth: '176px' }}>No.of Female Students</th>
                                <th style={{ minWidth: '147px' }}>No.of SC Students</th>
                                <th style={{ minWidth: '212px' }}>No.of SC Female Students</th>
                                <th style={{ minWidth: '147px' }}>No.of ST Students</th>
                                <th style={{ minWidth: '200px' }}>No.of ST Female Students</th>
                                <th style={{ minWidth: '500px' }}>Remarks</th>
                                    <th className="text-justify" style={{ minLength: '150px' }}>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            {generalDetails.filter((item) => item.course_type === 'UG').length > 0 ? (
                                <tbody>
                                    {generalDetails.map((item, index) =>
                                        item.course_type === 'UG' ? (
                                            <tr key={index} className="text-left">
                                                <>
                                                    <td>{item.year}</td>
                                                    <td>{item.type}</td>
                                                    <td className="text-justify">{item.discipline}</td>
                                                    <td className="text-justify">{item.course}</td>
                                                    <td>{item.seats}</td>
                                                    <td>{item.students}</td>
                                                    <td>{item.male}</td>
                                                    <td>{item.female}</td>
                                                    <td>{item.sc}</td>
                                                    <td>{item.sc_female}</td>
                                                    <td>{item.st}</td>
                                                    <td>{item.st_female}</td>
                                                    <td className="text-justify">{item.remarks}</td>
                                                    {item.is_approved === 0 ? (
                                                        <td className="d-flex">
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 2 ? (
                                                        <td>
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 1 ? (
                                                        <td>
                                                            <Button type="button" color="success" size="sm" disabled>
                                                                Approved
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 3 && (
                                                        <td>
                                                            <Button type="button" color="info" size="sm" disabled>
                                                                Reverted by RUSA Admin
                                                            </Button>
                                                        </td>
                                                    )}
                                                </>
                                            </tr>
                                        ) : (
                                            ''
                                        )
                                    )}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="14" className="text-center">
                                            No data Found
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </Table>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default HeadUg;
