import React, { useState } from 'react';
import { Delete } from 'react-feather';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

function FailureError({ isOpen, toggle }) {
    const handleCloseModal = () => {
        window.location.reload();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} onClosed={handleCloseModal}>
            <ModalHeader className="all-to-right">
                <button className="close" onClick={toggle}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </ModalHeader>
            <ModalBody>
                <div className="all-to-center">
                    <Delete className="successCheck" />
                </div>
                <div className="all-to-center text-center py-3">Email Id is already taken</div>
            </ModalBody>
        </Modal>
    );
}

export default FailureError;
