import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';

function HeadOTHER(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(true);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };
    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get(`admin-institution-info`);
            const jsonData = response?.data?.data?.data?.other_accreditation;
            const updatedUsers = jsonData?.map(user => ({
                ...user,
                other_status: user.other_status === 1 ? 'Yes' : 'No'
            }));
            const accreditation = jsonData;

            if (accreditation.some((item) => item.other_status === 0 && item.is_approved === 1)) {
                setHasDataNo(true);
            }

            if (accreditation.some((item) => item.other_status === 1 && item.is_approved === 1)) {
                setHasDataYes(true)
            }

            setGeneralDetails(updatedUsers);
            setLoading(false);
        } catch (error) {
            // console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formatDate = dateString => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div>
            <div className="d-flex justify-content-left mx-3">
                <Row className="label">
                    <label>Does the institution possess Other Accreditations?</label>
                </Row>
                <Row className="ml-5">:</Row>
                <Row className="ml-5">
                    <FormGroup check>
                            <Input
                                type="radio"
                                name="showForm"
                                value="yes"
                                checked={showForm === true}
                                onChange={() => setShowForm(true)}
                            />{' '}
                       <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>Yes
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                            <Input
                                type="radio"
                                name="showForm"
                                value="no"
                                checked={showForm === false}
                                onChange={() => setShowForm(false)}
                            />{' '}
                        <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                            No
                        </Label>
                    </FormGroup>
                </Row>
            </div>
            {showForm === true ? (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th>Name of accrediting agency</th>
                                <th>Agency type</th>
                                <th>Date of Accreditation</th>
                                <th>Valid Upto</th>
                                <th>Accreditation Score/ Grade</th>
                                <th>Remarks</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generalDetails.length > 0 ? (
                                generalDetails.map((item, index) =>
                                    item.other_status === 'Yes' ? (
                                        <tr key={index} className="text-left">
                                            <td>{item.accrediation_agency_name}</td>
                                            <td>{item.agency_type}</td>
                                            <td>{formatDate(item.accreditation_date)}</td>
                                            <td>{formatDate(item.valid_until)}</td>
                                            <td>{item.score}</td>
                                            <td className='text-justify'>{item.remarks}</td>
                                            <td>
                                            {item.is_approved === 0 ? (
                                                     <Button
                                                     disabled
                                                     color="warning"
                                                     className="btn btn-secondary"
                                                     style={{
                                                         color: '#ED7D31',
                                                         fontWeight: '400',
                                                         backgroundColor: '#F5F7F8',
                                                     }}
                                                     size="sm">
                                                     Pending
                                                 </Button>
                                                ) : item.is_approved === 2 ? (
                                                    <Button disabled color="danger" size="sm">
                                                        Reverted by head
                                                    </Button>
                                                ) : item.is_approved === 1 ? (
                                                    <Button disabled color="success" size="sm">
                                                        Approved by head
                                                    </Button>
                                                ) : item.is_approved === 3 ? (
                                                    <Button disabled color="info" size="sm">
                                                        Reverted by RUSA Admin
                                                    </Button>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ) : null
                                )
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th>Remarks</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generalDetails.length > 0 ? (
                                generalDetails.map((item, index) =>
                                    item.other_status === 'No' ? (
                                        <tr key={index} className="text-left">
                                            <td>{item.remarks}</td>
                                            <td>
                                                {item.is_approved === 0 ? (
                                                     <Button
                                                     disabled
                                                     color="warning"
                                                     className="btn btn-secondary"
                                                     style={{
                                                         color: '#ED7D31',
                                                         fontWeight: '400',
                                                         backgroundColor: '#F5F7F8',
                                                     }}
                                                     size="sm">
                                                     Pending
                                                 </Button>
                                                ) : item.is_approved === 2 ? (
                                                    <Button disabled color="danger" size="sm">
                                                        Reverted by head
                                                    </Button>
                                                ) : item.is_approved === 1 ? (
                                                    <Button disabled color="success" size="sm">
                                                        Approved by head
                                                    </Button>
                                                ) : item.is_approved === 3 ? (
                                                    <Button disabled color="info" size="sm">
                                                        Reverted by RUSA Admin
                                                    </Button>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ) : null
                                )
                            ) : (
                                <tr>
                                    <td colSpan="2" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
}

export default HeadOTHER;
