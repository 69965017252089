import React, { useState } from 'react';
import { Card, CardBody, Container, TabContent } from 'reactstrap';
import DataOperatorStaffPatternMain from './staff_details';
function InstitutionPFMSdetails() {
    const tabContents = [{ id: '1', title: 'STAFF PATTERN IN RUSA' }];
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card>
                    <CardBody>
                        <>
                            

                            <TabContent>
                                {activeTab === '1' ? (
                                    <>
                                    <h5 className="header-title mb-4 mt-0">Institution PFMS Details</h5>
                                        <DataOperatorStaffPatternMain />
                                    </>
                                ) : (
                                    <>{/* <StaffDetails /> */}</>
                                )}
                            </TabContent>
                        </>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default InstitutionPFMSdetails;
