import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import { MANAGE_LABELS } from '../manage/datamanager';
import { FaFileExcel } from 'react-icons/fa';

function GeneralInformation(props) {
    // console.log(props.newReg, props);
    const [formData, setFormData] = useState({});
    return (
        <div>
            <div className="view">
                {/* <div className="banner">OTHER ACCREDITATIONS</div> */}
                <Container className="container-width">
                    {!props.listData || props.listData?.length === 0 ? (
                        <div className="edit">
                            <Button color="primary" className="px-3 mt-2 " onClick={() => props.handleCreate()}>
                                Create
                            </Button>
                        </div>
                    ) : (
                        <div className="edit">
                            <Button color="primary" className="px-3 mt-2 " onClick={() => props.handleEdit()}>
                                Edit
                            </Button>
                        </div>
                    )}

                    <div className="table-section w-100 ">
                        <>
                            <div className="preview-page">
                                <Row>
                                    <Col className="d-flex justify-content-end">
                                        {props.listData?.is_approved === 0 ? (
                                            <td className="text-primary">Pending</td>
                                        ) : props.listData?.is_approved === 1 ? (
                                            <td className="text-success">Approved</td>
                                        ) : (
                                            <td className="text-warning">Reverted</td>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} className="my-3">
                                        <Row className="text-dark">
                                            <Col md={4} sm={4}>
                                                Total Project Cost{' '}
                                            </Col>
                                            <Col md={2} sm={2}>
                                                :
                                            </Col>
                                            <Col md={6} sm={6}>
                                            {props.listData?.total_cost}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} md={12} className="my-3">
                                        <Row className="text-dark">
                                            <Col md={4} sm={4}>
                                                Agreed probable amount of Contract
                                            </Col>
                                            <Col md={2} sm={2}>
                                                :
                                            </Col>
                                            <Col md={6} sm={6}>
                                                {props.listData?.amount ?? '--'}
                                            </Col>
                                        </Row>
                                    </Col>

                                    {/* <Table striped>
                                    <thead>
                                        <tr className="text-left">
                                            <th style={{ minWidth: '50px' }}>Institution Name</th>
                                            <th style={{ minWidth: '150px' }}>Project Cost</th>
                                            <th style={{ minWidth: '150px' }}>Agreed Contract Amount</th>
                                            <th style={{ minWidth: '150px' }}>Agency Name</th>
                                            <th style={{ minWidth: '150px' }}>Approved Cost</th>
                                            <th style={{ minWidth: '150px' }}>Amount Utilised</th>
                                            <th style={{ minWidth: '150px' }}>% of Utilisation</th>
                                        </tr>
                                    </thead>
                                    {props.data.fundingDetails?.map((item, k) => (
                                        <>
                                            <tbody>
                                                <tr className="text-left">
                                                    <td>{item.INSTITUTION_NAME ?? '--'}</td>
                                                    <td>{item.PROJECT_COST ?? '--'}</td>
                                                    <td>{item.AGREED_CONTRACT_AMOUNT ?? '--'}</td>
                                                    <td>{item.AGENCY_NAME ?? '--'}</td>
                                                    <td>{item.APPROVED_COST ?? '--'}</td>
                                                    <td>{item.AMOUNT_UTILISED ?? '--'}</td>
                                                    <td>{item.PERCENTAGE_UTILISED?? '--'}</td>
                                                </tr>
                                            </tbody>
                                        </>
                                    ))}
                                </Table> */}
                                </Row>
                            </div>
                        </>
                        {/* <div>
        <Table striped>
                                    <thead className= "table-head">
                                        <tr className="text-left">
                                            <th style={{ minWidth: '50px' }}>Institution Name</th>
                                            <th style={{ minWidth: '150px' }}>Project Cost</th>
                                            <th style={{ minWidth: '150px' }}>Agreed Contract Amount</th>
                                            <th style={{ minWidth: '150px' }}>Agency Name</th>
                                            <th style={{ minWidth: '150px' }}>Approved Cost</th>
                                            <th style={{ minWidth: '150px' }}>Amount Utilised</th>
                                            <th style={{ minWidth: '150px' }}>% of Utilisation</th>
                                        </tr>
                                    </thead>
                                    {props.data.fundingDetails?.map((item, k) => (
                                        <>
                                            <tbody>
                                                <tr className="text-left">
                                                    <td>{item.INSTITUTION_NAME ?? '--'}</td>
                                                    <td>{item.PROJECT_COST ?? '--'}</td>
                                                    <td>{item.AGREED_CONTRACT_AMOUNT ?? '--'}</td>
                                                    <td>{item.AGENCY_NAME ?? '--'}</td>
                                                    <td>{item.APPROVED_COST ?? '--'}</td>
                                                    <td>{item.AMOUNT_UTILISED ?? '--'}</td>
                                                    <td>{item.PERCENTAGE_UTILISED?? '--'}</td>
                                                </tr>
                                            </tbody>
                                        </>
                                    ))}
                                </Table>

        </div> */}
                    </div>
                </Container>
                {/* <div>
                    <FaFileExcel className="excel-icon my-3" />
                </div> */}

                <div className="overflow-auto">
                    <Table striped className="mt-5">
                        <thead className="table-head ">
                            <tr className="text-left">
                                <th style={{ minWidth: '50px' }}>Institution Name</th>
                                <th style={{ minWidth: '150px' }}>Project Cost</th>
                                <th style={{ minWidth: '150px' }}>Agreed Contract Amount</th>
                                <th style={{ minWidth: '150px' }}>Agency Name</th>
                                <th style={{ minWidth: '150px' }}>Approved Cost</th>
                                <th style={{ minWidth: '150px' }}>Amount Utilised</th>
                                <th style={{ minWidth: '150px' }}>% of Utilisation</th>
                            </tr>
                        </thead>
                        {props.listData?.institutions?.length > 0 ? (
                            props.listData?.institutions?.map((item, k) => (
                                <>
                                    <tbody>
                                        <tr className="text-left">
                                            <td>{item.name ?? '--'}</td>
                                            <td>{item.cost ?? '--'}</td>
                                            <td>{item.contract ?? '--'}</td>
                                            <td>{item.agency ?? '--'}</td>
                                            <td>{item.approved ?? '--'}</td>
                                            <td>{item.utilised ?? '--'}</td>
                                            <td>{item.percentage ?? '--'}</td>
                                        </tr>
                                    </tbody>
                                </>
                            ))
                        ) : (
                            <>
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        No data found
                                    </td>
                                </tr>
                            </>
                        )}
                    </Table>
                </div>
            </div>
        </div>
    );
}

export default GeneralInformation;
