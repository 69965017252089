export const finanacial_years = [
    { label: '2014 - 2015' },
    { label: '2015 - 2016' },
    { label: '2016 - 2017' },
    { label: '2017 - 2018' },
    { label: '2018 - 2019' },
    { label: '2019 - 2020' },
    { label: '2020 - 2021' },
    { label: '2022 - 2023' },
    { label: '2023 - 2024' },
    { label: '2024 - 2025' },
    { label: '2025 - 2026' },
    { label: '2026 - 2027' },
];