import React, { useState } from 'react';
import { Button, Container, Input, Row, Table } from 'reactstrap';

import Success from '../../../../success';
import { MANAGE_LABELS } from './datamanager';

import { COLLEGES } from './datamanager';

function Index(props) {
    const collegeNames = COLLEGES.map((college) => ({
        [MANAGE_LABELS.NAME]: college.label,
        [MANAGE_LABELS.DESIGNATION]: null,
    }));

    const [faculityData2, SetFaculityData2] = useState(props.faculityData2);
    const [formData, setFormData] = useState([]);
    const [formDatas, setformDatas] = useState([]);

    const [success, setSuccess] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [isSuccessOpen, setSuccessOpen] = useState(false);

    // console.log(faculityData2);

    // ----------------------------------------------------------
    const faculityTableChange2 = (name, value) => {
        let updatedFaculityData = [...faculityData2];
        updatedFaculityData[name] = value;
        SetFaculityData2(updatedFaculityData);
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const error = validate();

    //     console.log(error);
    //     if (Object.keys(error).length === 0) {
    //         setSuccess();
    //     }
    // };
    const handleChange = (k, value, id) => {
        const newUnique = [...props.newPfms];
        newUnique[k].unique_code = value;
        props.setNewData(newUnique);
    };
    const handleSubmit = () => {
        // const data = formDatas;
        // data.push(formDatas);
        //  setformDatas(data);
        setSuccess(true);
    };
    // console.log(formDatas);
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const error = validate();

    //     console.log(error);
    //     if (Object.keys(error).length === 0) {
    //         const data = formDatas;
    //         data.push(formDatas);
    //         // setformDatas(data);
    //         setSuccess();

    //     }
    // };
    // const validate = () => {
    //     let error = {};

    //     // faculityData.forEach((item, k) => {
    //     //     Object.keys(item).map((key) => {
    //     //         if (!item[key] || item[key] === '') {
    //     //             error[`${k}_${key}`] = 'please fill this field';
    //     //         }
    //     //     });
    //     // });
    //     faculityData2.forEach((item, k) => {
    //         Object.keys(item).map((key) => {
    //             if (!item[key] || item[key] === '') {
    //                 error[`${k}_${key}`] = 'please fill this field';
    //             }
    //         });
    //     });
    //     // Object.keys(formData).map((key) => {
    //     //     if (!formData[key] || formData[key] === '') {
    //     //         error[key] = 'please fill this field';
    //     //     }
    //     // });
    //     return error;
    // };
    const handleSuccessAction = () => {
        props.onSubmit({ faculityData2: faculityData2 });
    };
    const handleClose = () => {
        setSuccess(false);
    };
    const handleFields = () => {
        return {};
    };
    // console.log(formData);
    return (
        <div>
            {success && (
                <Success
                    title="PFMS unique code submitted for approval by the RUSA Admin"
                    toggle={handleClose}
                    open={success}
                />
            )}
            <Container className="form-container p-0">
                {activeStep === 1 ? (
                    <>
                        {/* <Form className="form-style"> */}
                        <Table striped>
                            <thead>
                                <tr></tr>
                            </thead>
                            <thead style={{ fontSize: '13px' }} className="table-head">
                                <tr>
                                    <th>Institution Name</th>
                                    <th>PFMS Unique Code</th>
                                </tr>
                            </thead>
                            <tbody className="text-center" style={{ fontSize: '13px' }}>
                                {props.newPfms.length === 0 ? (
                                    <tr>
                                        <td colSpan={2} className="text-center">
                                            No data
                                        </td>
                                    </tr>
                                ) : (
                                    props.newPfms.map((item, k) => (
                                        <tr key={k}>
                                            <td>
                                                <Input
                                                    type="text"
                                                    value={item.institution}
                                                    disabled
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            [formData.NAME]: e.target.value,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="text"
                                                    value={item.unique_code}
                                                    onChange={(e) => handleChange(k, e.target.value, item.id)}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>

                        <div className="d-flex justify-content-start mt-4 mx-2">
                            <Row>
                                <Button
                                    type="submit"
                                    color="primary"
                                    // onClick={() => {setActiveStep(1);setSuccess(true)}}
                                    onClick={() => props?.onSubmit(props?.newPfms)}
                                    outline>
                                    {/* <Button color="primary" className="btn-rounded px-3" onClick={() => props.setSuccess(true)}> */}
                                    Submit For Approval
                                </Button>
                            </Row>
                        </div>
                        {/* </Form> */}
                        <div className="table-section w-100 pt-4">
                            <Table striped>
                                <thead className="table-head">
                                    <tr className="text-left">
                                        <th style={{ minWidth: '150px' }}>Institution Name</th>
                                        <th style={{ minWidth: '150px' }}>PFMS Unique Code</th>
                                        <th style={{ minWidth: '100px' }}>Status </th>
                                    </tr>
                                </thead>

                                {props.completed && props.completed.length > 0 ? (
                                    <tbody>
                                        {props.completed?.map((item, k) => (
                                            <tr key={k}>
                                                <td>{item?.institution ?? '--'}</td>
                                                <td>{item?.unique_code ?? '--'}</td>
                                                {item?.is_approved === 0 ? (
                                                    <td className="text-primary">Pending</td>
                                                ) : item?.is_approved === 1 ? (
                                                    <td className="text-success">Approved</td>
                                                ) : (
                                                    <td className="text-warning">Reverted</td>
                                                )}
                                                {/* <td>
                                                    <Edit
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            // console.log(item);
                                                            // props.setIndData(item);
                                                            // props.setNewEdit(true);
                                                        }}></Edit>
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <>
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                No data found
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </Table>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </Container>
        </div>
    );
}

export default Index;
