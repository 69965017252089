import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';

function HeadNIRF(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(true);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };

    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get(`admin-institution-info`);
            const jsonData = response?.data?.data?.data?.nirf_accreditation;
            const updatedUsers = jsonData?.map((user) => {
                user.nirf_rank_status = user.nirf_rank_status === '1' ? 'Yes' : 'No';
                return user;
            });
            const accreditation = jsonData;

            if (accreditation.some((item) => item.nirf_rank_status === '0' && item.is_approved === 1)) {
                setHasDataNo(true);
            }

            if (accreditation.some((item) => item.nirf_rank_status === '1' && item.is_approved === 1)) {
                setHasDataYes(true)
            }
            setGeneralDetails(updatedUsers);
        } catch (error) {
            error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-left mx-3 my-2">
                <Row className="label">
                    <label>Does the institution possess a valid NIRF ranking ?</label>
                </Row>
                <Row className="ml-5">:</Row>
                <Row className="ml-5">
                    <FormGroup check>
                        <Input
                            type="radio"
                            name="showForm"
                            value="yes"
                            checked={showForm === true}
                            onChange={() => setShowForm(true)}
                        />{' '}
                        <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                            Yes
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input
                            type="radio"
                            name="showForm"
                            value="no"
                            checked={showForm === false}
                            onChange={() => setShowForm(false)}
                        />{' '}
                        <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                            No
                        </Label>
                    </FormGroup>
                </Row>
            </div>

            {showForm === true ? (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ minWidth: '150px' }}>Academic Year</th>
                                <th style={{ minWidth: '150px' }}>NIRF Rank</th>
                                <th style={{ minWidth: '330px' }}>Remarks</th>
                                <th style={{ minWidth: '100px' }}>Status</th>
                            </tr>
                        </thead>

                        {generalDetails.filter((data) => data.nirf_rank_status === 'Yes').length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.nirf_rank_status === 'Yes' ? (
                                        <tr key={index} className="text-left">
                                            <td>{item.academic_year}</td>
                                            <td>{item.nirf_rank}</td>
                                            <td className="text-justify" style={{ width: '180px' }}>
                                                {item.remarks}
                                            </td>
                                            <td>
                                                {item.is_approved === 0 ? (
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        className="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                ) : item.is_approved === 2 ? (
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted by head
                                                    </Button>
                                                ) : item.is_approved === 1 ? (
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved by head
                                                    </Button>
                                                ) : item.is_approved === 3 ? (
                                                    <Button type="button" color="info" size="sm" disabled>
                                                        Reverted by RUSA Admin
                                                    </Button>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ) : null
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ minWidth: '150px' }}>Remarks</th>
                                <th style={{ minWidth: '100px' }}>Status</th>
                            </tr>
                        </thead>

                        {generalDetails.filter((data) => data.nirf_rank_status === 'No').length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.nirf_rank_status === 'No' ? (
                                        <tr key={index} className="text-left">
                                            <td>{item.remarks}</td>
                                            <td>
                                                {item.is_approved === 0 ? (
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        className="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                ) : item.is_approved === 2 ? (
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted by head
                                                    </Button>
                                                ) : item.is_approved === 1 ? (
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved by head
                                                    </Button>
                                                ) : item.is_approved === 3 ? (
                                                    <Button type="button" color="info" size="sm" disabled>
                                                        Reverted by RUSA Admin
                                                    </Button>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ) : null
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="2" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            )}
        </div>
    );
}

export default HeadNIRF;
