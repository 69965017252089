import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Failure from '../../../../../../institutions/failure';

function HeadNationalLevel(props) {
    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);
    const { id } = useParams();
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };
   
        // Function to fetch data
        const fetchData = async () => {
            try {
                const response = await api.get(`institution/${id}`); // Assuming api.get works as expected
                // // console.log(response);
                const jsonData = response?.data?.data?.data?.sports_common;

                const sports = jsonData.filter((item) => item.type === 'national');
    
                if (sports.some((item) => item.status === 0 && item.is_approved === 1)) {
                    setHasDataNo(true);
                }
    
                if (sports.some((item) => item.status === 1 && item.is_approved === 1)) {
                    setHasDataYes(true)
                }
                setgeneralDetails(jsonData);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        useEffect(() => {
        fetchData();
    }, [id]);

      // revert
      const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`sportscommon/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setgeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    // end revert
    return (
        
        <div>
             {revert && (
                <Failure
                    title="National competitions details has been Reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <>
                {' '}
                <div className="d-flex justify-content-left mx-3 mt-2">
                    <Row className="label">
                    <label>Does any of your students participated in National level competition ?</label>
                    </Row>
                    <Row className="ml-5">:</Row>
                    <Row className="ml-5">
                        {' '}
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="yes"
                                    checked={showForm === true}
                                    onChange={() => setShowForm(true)}
                                />{' '}
                            <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                                Yes
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="no"
                                    checked={showForm === false}
                                    onChange={() => setShowForm(false)}
                                />{' '}
                            <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                                No
                            </Label>
                        </FormGroup>
                    </Row>
                </div>
            </>
            {showForm === true ? (
                <div className="table-section w-100 my-3 overflow-auto">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '150px' }}>Academic Year</th>
                                    <th style={{ minWidth: '280px' }}>Sports Event</th>
                                    <th style={{ minWidth: '314px' }}>No. of Students selected at National level</th>
                                    <th style={{ minWidth: '280px' }}>Position Secured</th>
                                    <th style={{ minWidth: '400px' }}> Remarks</th>
                                    <th style={{ minWidth: '100px' }}>Status</th>
                                    <th style={{ minWidth: '100px' }}>Actions</th>
                            </tr>
                        </thead>
                        {generalDetails.filter((item) => item.status === 1 && item.type === 'national').length > 0 ? (
                            <tbody>
                                {generalDetails.map(
                                    (item, index) =>
                                        item.status === 1 &&  item.type === 'national' ? (
                                            <tr key={index} className="text-left">
                                                 <td>{item.year}</td>
                                            <td className='text-justify'>{item.item}</td>
                                            <td>{item.total}</td>
                                            <td className='text-justify'>{item.position }</td>
                                            <td className='text-justify'>{ item.remarks ?? '--'}</td>
                                            <td>
                                               {item.is_approved === 0 ? (
                                                    <td className="d-flex">
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                        <br></br>
                                                    </td>
                                                ) : item.is_approved === 2 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted by head
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 1 ? (
                                                    <td>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved by head
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 3 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="info" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                ) :(
                                                    ''
                                                )}
                                               </td>
                                               <td>
                                                    {item.is_approved === 1 && (
                                                        <td>
                                                            <Button
                                                                type="button"
                                                                size="sm"
                                                                outline
                                                                color="primary"
                                                                onClick={() => handleRevert(item.id)}

                                                            >
                                                                Revert
                                                            </Button>
                                                        </td>

                                                    )}



                                                </td>
                                            </tr>
                                        ) : ('')
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '150px' }}>Remarks</th>
                                <th style={{ minWidth: '150px' }}>Status</th>
                                <th style={{ minWidth: '150px' }}>Action</th>

                            </tr>
                        </thead>
                        {generalDetails.filter((item) => item.status === 0 && item.type === 'national').length > 0 ? (
                            <tbody>
                                {generalDetails.map(
                                    (item, index) =>
                                        item.status === 0 && item.type === 'national' ? (
                                            <tr key={index} className="text-left">
                                                <>
                                                    <td className='text-justify'>{item.remarks}</td>
                                                    <td>
                                               {item.is_approved === 0 ? (
                                                    <td className="d-flex">
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                        <br></br>
                                                    </td>
                                                ) : item.is_approved === 2 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted by head
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 1 ? (
                                                    <td>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved by head
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 3 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="info" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                ) :(
                                                    ''
                                                )}
                                               </td>
                                               <td>
                                                    {item.is_approved === 1 && (
                                                        <td>
                                                            <Button
                                                                type="button"
                                                                size="sm"
                                                                outline
                                                                color="primary"
                                                                onClick={() => handleRevert(item.id)}

                                                            >
                                                                Revert
                                                            </Button>
                                                        </td>

                                                    )}



                                                </td>
                                                </>
                                            </tr>
                                        ) : ('')
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            )}
        </div>
    );
}


export default HeadNationalLevel;
