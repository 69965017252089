import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';

function Nirf(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [showForm, setShowForm] = useState(true);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };

    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
            try {
                const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected
                const jsonData = response?.data?.data?.data?.nirf_accreditation;
                const updatedUsers = jsonData;

                const accreditation = jsonData;

                if (accreditation.some((item) => item.nirf_rank_status === '0' && item.is_approved === 1)) {
                    setHasDataNo(true);
                }
    
                if (accreditation.some((item) => item.nirf_rank_status === '1' && item.is_approved === 1)) {
                    setHasDataYes(true)
                }

                setgeneralDetails(updatedUsers);
                // console.log(jsonData);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);
    return (
        <div>
            <>
                {' '}
                <div className="d-flex justify-content-left mx-3 my-2">
                    <Row className="label">
                        <label>Does the institution possess a valid NIRF ranking ?</label>
                    </Row>
                    <Row className="ml-5">:</Row>
                    <Row className="ml-5">
                        {' '}
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="yes"
                                    checked={showForm === true}
                                    onChange={() => setShowForm(true)}
                                />{' '}
                        <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                            Yes
                        </Label>
                        </FormGroup>
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="no"
                                    checked={showForm === false}
                                    onChange={() => setShowForm(false)}
                                />{' '}
                        <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                            No
                        </Label>
                        </FormGroup>
                    </Row>
                </div>
            </>
            {showForm === true ? (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ maxWidth: '150px' }}>Academic Year</th>
                                <th style={{ maxWidth: '150px' }}>NIRF Rank</th>
                                <th style={{ maxWidth: '800px' }}>Remarks</th>
                                <th style={{ maxWidth: '50px' }}>Status</th>
                            </tr>
                        </thead>
                        {generalDetails.filter((data) => data.nirf_rank_status == '1').length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.nirf_rank_status == '1' ? (
                                        <tr key={index} className="text-left">
                                            <td style={{ maxWidth: '150px' }}>{item.academic_year}</td>
                                            <td style={{ maxWidth: '150px' }}>{item.nirf_rank}</td>
                                            <td className="text-justify" style={{ maxWidth: '800px' }}>
                                                {item.remarks}
                                            </td>
                                            {item.is_approved === 0 ? (
                                                <td className="d-flex" style={{ maxWidth: '50px' }}>
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                    <br></br>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <td style={{ maxWidth: '50px' }}>
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 1 ? (
                                                <td style={{ maxWidth: '50px' }}>
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 3 ? (
                                                <td style={{ maxWidth: '50px' }}>
                                                    <Button type="button" color="info" size="sm" disabled>
                                                        Reverted by RUSA Admin
                                                    </Button>
                                                </td>
                                            ) : (
                                                ''
                                            )}
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '150px' }}>Remarks</th>
                                <th style={{ minWidth: '150px' }}>Status</th>

                                {/* <th style={{ minWidth: '100px' }}>Actions</th> */}
                            </tr>
                        </thead>
                        {generalDetails.filter((data) => data.nirf_rank_status == '0').length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.nirf_rank_status == '0' ? (
                                        <tr key={index} className="text-left">
                                            <>
                                                <td>{item.remarks}</td>
                                                {item.is_approved === 0 ? (
                                                    <td className="d-flex">
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                        <br></br>
                                                    </td>
                                                ) : item.is_approved === 2 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 1 ? (
                                                    <td>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 3 ? (
                                                    <td>
                                                        <Button type="button" color="info" size="sm" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            )}
        </div>
    );
}

export default Nirf;
