import React from 'react';
import { Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import PreviewInformation from '../preview/index.jsx';

const FormArea = (props) => {
    const { formData, setFormData, handleSaveData, activeStep, handleNext, setActiveStep } = props;
    const handleSubmit = (e) => {
        e.preventDefault();
        handleNext();
    };
    return (
        <div>
            <div className="manage">
                <h5 className="header-title mb-1 mt-0">Create New Institution</h5>{' '}
                <div className="form-section">
                    <div className="info">
                        <div className="mandatory">{/* <sup>*</sup>All fields are mandatory. */}</div>
                    </div>
                    <Container className="form-container permanent-area">
                        <div>
                            {activeStep === 1 ? (
                                <>
                                    <Form onSubmit={handleSubmit}>
                                        <>
                                            <>
                                                {' '}
                                                <Row className="my-3">
                                                    <Col md={6} sm={12} my-2>
                                                        <Label className="my-2">Institution Name</Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="text"
                                                            value={formData.institution_name}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    institution_name: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={6} sm={12} my-2>
                                                        <Label className="my-2">Email ID</Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="email"
                                                            value={formData.email}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    email: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                </Row>{' '}
                                            </>
                                            {/* -----------end---------- */}
                                        </>

                                        <div className="my-3">
                                            <Button type="submit">
                                                {/* // onClick={() => (activeStep === 1 ?  : handleSaveData())}> */}
                                                {activeStep == 0 ? 'Submit for Approval' : 'Preview'}
                                            </Button>
                                        </div>
                                    </Form>
                                </>
                            ) : (
                                <>
                                    <PreviewInformation
                                        formData={formData}
                                        activeStep={activeStep}
                                        handleSaveData={handleSaveData}
                                        setActiveStep={setActiveStep}
                                    />
                                </>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default FormArea;
