export const MANAGE_LABELS = {
    INSTITUTIONS_lIST: 'institutions_list',
    EMAIL_ID: 'email_id',
};
export const COLLEGE_LISTS = [
    {
        id: 1,
        name: 'Bishop Moore College,Mavelikara, Kattanam Road, Kallumala Near, Junction, Kallumala, Kerala 690110',
        email: 'bpmoorecollege@gmail.com @gmail.com',
    },
    {
        id: 2,
        name: 'NSS College Cherthala, KR Puram, Cherthala, Kerala 688556',
        email: 'cherthalansscollege@gmail.com @gmail.com',
    },
    {
        id: 3,
        name: 'Peet Memorial Training College, Old Mavelikkara, Mavelikara, Kerala 690101',
        email: 'peetmemorialcollege@gmail.com ',
    },
    {
        id: 4,
        name: 'Sree Narayana College , S.N Puram P.O,Cherthala, Alappuzha, Kerala-688582',
        email: 'snccherthala@gmail.com ',
    },
    { id: 5, name: 'St Michaels College, Cherthala, Kerala 688539', email: 'michaelscherthala@gmail.com' },
    { id: 6, name: 'St. Aloysius College, Edathua-689573', email: 'sacedathua@gmail.com' },
    {
        id: 7,
        name: 'T . K. Madhava Memorial College,Nangiarkulangara, Kerala 690513',
        email: 'tkmmcollege.principal2021@gmail.com',
    },
    { id: 8, name: 'Al Ameen College, Edathala North P O, Aluva-683564', email: 'alameencollege@gmail.com ' },
    { id: 9, name: 'Bharata Mata College, Thrikkakara-682021', email: 'bharatamata@yahoo.co.in ' },
    { id: 10, name: 'Bharata Mata College, Thrikkakara-682021', email: 'bharatamata@yahoo.co.in ' },
    {
        id: 11,
        name: 'CUSAT,  University Road, South Kalamassery, Kalamassery, Kochi, Kerala- 682022',
        email: 'registrar@cusat.ac.in ',
    },
    { id: 12, name: 'Government College, Mekkara,Thripunithura-682301', email: 'govcoltpr@yahoo.in ' },
    { id: 13, name: 'Government Sanskrit College, Tripunithura-682301', email: 'govsktclgtpra@gmail.com ' },
    {
        id: 14,
        name: 'Maharajas College, Park Avenue Rd, Opp. Subhash Bose Park, Marine Drive, Ernakulam, Kerala- 682011',
        email: 'principal@maharajas.ac.in ',
    },
    {
        id: 15,
        name: 'Mar Athanasius College,Kothamangalam College P.O,Kothamangalam, Kerala- 686 666',
        email: 'mac@macollege.in ',
    },
    { id: 16, name: 'Mar Thoma College For Women, Perumbavoor-683542', email: 'mtcwpbr@yahoo.in' },
    {
        id: 17,
        name: 'Morning Star Home Science College,Anghamaly  south po 683573',
        email: 'morningstarcsn@gmail.com ',
    },
    { id: 18, name: 'Nirmala College, Muvattupuzha-686661', email: 'nirmalacollege@gmail.com ' },
    {
        id: 19,
        name: 'Rajagiri College of Social Sciences (Autonomous),Rajagiri P.O, Kalamassery, Cochin - 683 104,Kerala, India.',
        email: 'principal@rajagiri.edu ',
    },
    { id: 20, name: 'S N M College Maliankara,Moothakunnam -683516', email: 'principalsnmc@gmail.com ' },
    { id: 21, name: 'Sacred Heart College,Thevara-682013', email: 'iqac@shcollege.ac.in' },
    { id: 22, name: 'SNM Training College, Moothamkulam, Ernakulam-683516', email: 'snmtrainingcollegemkm@gmail.com' },
    {
        id: 23,
        name: 'Sree Sankara University of Sanskrit, Thalayattumpilli, Kalady, Kerala -683574',
        email: 'ssusreg@gmail.com',
    },
    {
        id: 24,
        name: 'Sree Sankara Vidyapeetom College, Sree sankarapuram, Valayanchirangara- 683556',
        email: 'ssvidyapeetom@gmail.com',
    },
    { id: 25, name: 'St. Alberts College, Banerji Road, Ernakulam-682018', email: 'principal@alberts.edu.in' },
    { id: 26, name: 'St. Paul’s College,HMT Colony ,Kalamassery, Ernakulam-683503', email: 'principal@stpauls.ac.in ' },
    { id: 27, name: 'St. Peters College, Kolenchery-682311', email: 'principal@stpeterscollege.ac.in' },
    {
        id: 28,
        name: 'St. Teresas College,Park Ave, Marine Drive, Ernakulam, Kerala ',
        email: 'principal@teresas.ac.in ',
    },
    { id: 29, name: 'St.Xaviers College For Women, Aluva 683101', email: 'college@stxaviersaluva.ac.in ' },
    {
        id: 30,
        name: 'T.M. Jacob Memorial Govt. College, Road, Manimalakunnu, Koothattukulam, Kerala- 686662',
        email: 'gcmanimalakunnu@yahoo.co.in ',
    },
    { id: 31, name: 'Union Christian College, Aluva- 683102', email: 'rusa@uccollege.edu.in' },
    {
        id: 32,
        name: 'Government College Kattappana,Government College Rd, Kattappana, Kerala- 685515',
        email: 'gckattappana@gmail.com',
    },
    {
        id: 33,
        name: 'Govt College Munnar,Kochi-Dhanushkodi Road, Kannan Devan Hills, Idukki, Munnar, Kerala -685612',
        email: 'gcmunnar@gmail.com ',
    },
    {
        id: 34,
        name: 'Marian College, Kuttikkanam P.O, Peermade, Idukki, Kerala, India',
        email: 'mariancollege@mariancollege.org ',
    },
    { id: 35, name: 'N S S College, Rajakumari,Kulapparachal P.O, Idukki -685619', email: 'nssrajakumari@yahoo.com ' },
    { id: 36, name: 'Newman College, East P.O Thodupuzha -685586', email: 'principal@newmancollege.ac.in ' },
    { id: 37, name: 'Pavanatma College,Murrickacherry -685604', email: 'pavanatmacollegem@gmail.com ' },
    {
        id: 38,
        name: 'Govt. Brennen College, Dharmadam - Melur Road, Dharmadam, Thalassery, Kerala- 670106',
        email: 'brennencollege@gmail.com ',
    },
    {
        id: 39,
        name: 'Kannur University , Thavakkara,Civil station (P.O), Kannur, Kerala- 670002',
        email: 'registrar@kannuruniv.ac.in',
    },
    { id: 40, name: 'KMM Womens College, NH 66, Pallikkunnu, Kannur, Kerala -670004', email: 'kmmgwc@rediffmail.com ' },
    { id: 41, name: 'MG College,Iritty Keezhur Kunnu, Punnad, Kerala- 670703', email: 'mgcollege.ac.in@gmail.com ' },
    {
        id: 42,
        name: 'Nirmalagiri College, Kuthuparamba, Kannur, Nirmalagiri, Kerala 670701',
        email: 'nirmalagiricollege@gmail.com ',
    },
    {
        id: 43,
        name: 'Pazhassi Raja N.S.S.College, Mattanur, Kannur, Kerala, India - 670 702',
        email: 'prnsscollege@yahoo.com ',
    },
    {
        id: 44,
        name: 'PKM College of Education, Kaithapram P.O, MadampamKannur, Kerala, India, PIN – 670631',
        email: 'pkmcedn@yahoo.co.in ',
    },
    { id: 45, name: 'S.E.S. College, Kannur, Sreekandapuram, Kerala- 670631', email: 'sescollege.skprm@gmail.com' },
    { id: 46, name: 'Sir Syed College, Karimbam, Taliparamba, Kerala -670142', email: 'mail@sirsyedcollege.ac.in ' },
    { id: 47, name: 'Govt. College Kasaragod, Vidya Nagar, Kerala- 671123', email: 'principalgcksd@gmail.com' },
    {
        id: 48,
        name: '"EKNM Government College, Elerithattu, Elerithattu P.O. Nileshwar Via. Kasaragod,Kerala - 671314',
        email: 'eknmgovtcollege@yahoo.com ',
    },
    {
        id: 49,
        name: 'G.P.M. Govt. College, Manjeswaram, Hosabettu, Manjeshwar, Kasaragod, Kerala - 671323',
        email: 'gpmgcm2@gmail.com',
    },

    {
        id: 50,
        name: 'Nehru Arts And Science College, Kanjangad, Padnekkat P.O. Kasaragod, Kerala - 671314',
        email: 'nascollegekanhangad@gmail.com',
    },

    { id: 51, name: 'St. Pius X College, Mundottu, Rajapuram, Kasaragod, Kerala - 671532', email: 'stpius@gmail.com' },

    {
        id: 53,
        name: 'B J M Government College, Chavara, Sankaramangalam, Chavara P O, Salem - Kochi - Kanyakumari Highway, Sankaramangalam, Chavara, Kerala - 691583',
        email: 'bjmgovtcollege@gmail.com',
    },

    {
        id: 54,
        name: 'K.S. M. D. B. College, Sasthamcotta, Kollam, Kerala - 690521',
        email: 'dbcsasthamcotta@gmail.com',
    },

    { id: 55, name: 'NSS College Nilamel, PO, Kollam, Kerala, India - 691535', email: 'nsscnilamel2018@gmail.com' },

    {
        id: 56,
        name: 'Sree Narayana College For Women, College Junction, Kollam, Kerala, India. Pin Code: 691001',
        email: 'sncollegeforwomen@gmail.com',
    },

    {
        id: 57,
        name: 'Sree Narayana College, Kollam-TVM Highway, Kollam, Kerala – 691001',
        email: 'snckollam@gmail.com',
    },

    { id: 58, name: 'Sree Narayana College, Kollam, Punalur, Kerala - 691305', email: 'sncpunalur@gmail.com' },

    { id: 59, name: 'St Johns College, Anchal, College Jn, Anchal, Kerala - 691306', email: '' },

    {
        id: 60,
        name: 'St. Stephens College, Kollam P.O. Pathanapuram, Kollam - 689 695 KERALA, INDIA',
        email: 'ststephenspathanapuram@gmail.com',
    },

    { id: 61, name: 'St. Gregorios College, Pulamon, Kottarakkara, Kerala - 691531', email: '' },

    {
        id: 62,
        name: 'TKM College Of Arts And Science, Karicode, Peroor, Kollam, Kerala - 691005',
        email: 'tkmarts@gmail.com',
    },

    {
        id: 63,
        name: 'TKM College of Engineering, Karicode, Kollam - 691005, Kerala, India',
        email: 'principal@tkmce.ac.in',
    },

    { id: 64, name: 'Alphonsa College, Arunapuram, Pala - 686574', email: 'alphonsaoffice@gmail.com' },

    { id: 64, name: 'Assumption College, Changhanassery, 686101', email: 'ac@assumptioncollege.in' },

    {
        id: 65,
        name: 'Baselius College, Manorama Junction, Near Malayala Manorama, Kottayam - Kumily Rd, Kottayam, Kerala - 686001',
        email: 'principal@baselius.ac.in',
    },

    { id: 66, name: 'Bishop Chulaparambil Memorial College, KK Road, Kottayam, 686001', email: 'bcmktm@yahoo.com' },

    {
        id: 67,
        name: 'Bishop Kurialacherry College For Women, Amalagiri P.O, Kottayam - 686561',
        email: 'bkcamala@yahoo.com',
    },

    { id: 68, name: 'CMS College, Kottayam, Kerala - 686001', email: 'kottayamcmscollege@gmail.com' },

    { id: 69, name: 'Deva Matha College, Kuravilangad P.O, Kottayam - 686633', email: 'principaldmck@gmail.com' },

    { id: 70, name: 'Devaswom Board College, Thalayolaparambu - 686605', email: 'dbprincipal@gmail.com' },

    { id: 71, name: 'Govt College, Nattakom, Kottayam Kerala - 686013', email: 'gckottayam@gmail.com' },

    { id: 72, name: 'Kuriakose Gregorios College, Pampady, Kottayam - 686502', email: 'mail@kgcollege.ac.in' },

    {
        id: 73,
        name: 'MG University, Priyadarsini Hills, Kottayam, Kerala, India - 686560',
        email: 'registrar@mgu.ac.in',
    },

    { id: 74, name: 'MG University, Priyadarsini Hills, Kottayam, Kerala, India - 686560', email: 'mguif@mgu.ac.in' },

    { od: 75, name: 'NSS Hindu College, Changanacherry - 686102', email: 'nsshcchy@gmail.com' },

    { id: 76, name: 'NSS Training College, Perunna, Changanacherry - 686102', email: 'principalnsstrcy@gmail.com' },

    {
        id: 77,
        name: 'S V R N S S College Theerthapadapuram, Vazhoor, Kerala - 686505',
        email: 'info@svrnsscollege.com',
    },

    { id: 78, name: 'St. Berchmans College, Changhanassery - 686101', email: 'sbc@sbcollege.ac.in' },

    { id: 79, name: 'St. Dominics College, Prathode P.O, Kanjirapally - 686512', email: 'principalsdc@gmail.com' },

    { id: 80, name: 'St. Georges College, Aruvithura - 686122', email: 'iqacsgcaruvithura@gmail.com' },

    { id: 81, name: 'St. Josephs Training College, Mannanam - 686561', email: 'stjosephstrainingcollege@gmail.com' },

    {
        id: 82,
        name: 'St. Marys College, Manarcaud, Maalam P.O, Kottayam - 686 019, Kerala, India',
        email: 'smc.manarcaud@gmail.com',
    },

    { id: 83, name: 'St. Stephens College, Uzhavoor, Kottayam - 686634', email: 'info@ststephens.net.in' },
    { id: 84, name: 'St. Thomas College, Arunapuram, Pala-686574', email: 'principal.stc@gmail.com' },

    {
        id: 85,
        name: 'St.Thomas College of Teacher Education, Pala, Kottayam, Kerala – 686 575',
        email: 'stcepala@gmail.com',
    },

    {
        id: 86,
        name: 'C K G Memorial Government College, Perambra, Kozhikode Pin:673525',
        email: 'ckgmgovcollege@yahoo.com',
    },

    {
        id: 87,
        name: 'Farook College (Autonomous), Farook College P.O., Kozhikode, PIN 673632',
        email: 'mail@farookcollege.ac.in',
    },

    {
        id: 88,
        name: 'Government College Mokeri, Mokeri PO, Kozhikkode, Kerala- 673507',
        email: 'mokericollege@yahoo.co.in',
    },
    {
        id: 89,
        name: 'Government College of Teacher Education, Mananchira, Kozhikode - 673001',
        email: 'gctekozhikode@yahoo.com',
    },

    {
        id: 90,
        name: 'Govt. College Madappally, Madappally P.O, College Road, Vadakara, Kozhikode, Madappallly, Kerala 673102',
        email: 'gcmadappally@gmail.com',
    },

    { id: 91, name: 'Malabar Christian College, Calicut- 673001, Kerala', email: 'admin@mccclt.ac.in' },

    {
        id: 92,
        name: 'Muhammed Abdurahiman Memorial Orphanage College, Manassery P.O, Kozhikode, Mukkam, Kerala 673602',
        email: 'mamocollege@gmail.com',
    },

    {
        id: 93,
        name: 'S.A.R.B.T.M. Govt. College, Koyilandy, Koyilandy P.O, Muchukunnu, Via Koyilandy, Kerala State, Pin -673307.',
        email: 'gckoyilandy@gmail.com',
    },

    {
        id: 94,
        name: 'Sree Narayana Guru College, Chelannur, Kozhikode, Kozhikode Balussery Road, Kerala 673616',
        email: 'sngcollege2007@yahoo.com',
    },
    {
        id: 95,
        name: 'St Joseph s College Autonomous Devagiri, Medical College P O Kozhikode  673008, Kerala',
        email: 'principal.devagiri@gmail.com',
    },

    {
        id: 96,
        name: 'Govt Arts & Science College, Meenchanda - Beypore Rd, Opposite NSS Higher Secondary School, Meenchanda, Kozhikode, Kerala- 673018',
        email: 'gasckkdprincipal@gmail.com',
    },

    { id: 97, name: 'Govt. College, Kodencherry, Kerala- 673580', email: 'gck.calicut@yahoo.co.in' },

    { id: 98, name: 'Calicut University, Tirur - Calicut Rd, Thenhipalam, Kerala- 673635', email: 'reg@uoc.ac.in' },

    {
        id: 99,
        name: 'EMEA College of Arts and Science, Kumminiparamba PO, Kondotty, Malappuram, Kerala - India PIN :673638',
        email: 'mail@emeacollege.ac.in',
    },

    {
        id: 100,
        name: 'KAHM Unity Womens College, P.O. Narukara, Manjeri, Malappuram, Kerala State, India, 676 122',
        email: 'info@unitywomenscollege.in',
    },

    {
        id: 101,
        name: 'Marthoma College, Chungathara, Chungathara P.O, Malappuram, Kerala, Pin : 679 334',
        email: 'mtcchungathara@gmail.com',
    },
    {
        id: 102,
        name: 'MES Keveeyam College, Valanchery, Valanchery P.O, Malappuram, Kerala, Pincode:676552',
        email: 'principal@meskeveeyamcollege.ac.in',
    },

    {
        id: 103,
        name: 'MES Mampad College, Mampad College P.O, Malappuram, Kerala 676542',
        email: 'info@mesmampad.org',
    },

    {
        id: 104,
        name: 'N.S.S. College, College Rd, Collegekunnu, Manjeri, Kerala- 676122',
        email: 'nsscollegemji@gmail.com',
    },

    {
        id: 105,
        name: 'PSMO College, Tirurangadi, Malappuram, Kerala, 676 306',
        email: 'mail@psmocollege.ac.in',
    },

    {
        id: 106,
        name: 'Sullamussalam Science College, Areekode, Ugrapuram (P.O), Malappuram',
        email: 'principal@sscollege.ac.in',
    },

    {
        id: 107,
        name: 'Tunchan Memorial Govt. College, BP Angadi Mangattiri, Kuttai Rd, Kerala',
        email: 'tmgctirur@gmail.com',
    },

    {
        id: 108,
        name: 'Government College, Munduparamba, P.O, Manjeri Road, Malappuram, Kerala, India-676509',
        email: 'malappuramgc@gmail.com',
    },

    {
        id: 109,
        name: 'Government College Chittur, Chittur College P.O, Palakkad, Chittur, Kerala -678104',
        email: 'gccprincipal@gmail.com',
    },

    {
        id: 110,
        name: 'Govt. Victoria College, College Road, Palakkad, Kerala- 678001',
        email: 'victoriapkd@gmail.com',
    },

    {
        id: 111,
        name: 'M P Moothedath Memorial Sree Narayana Trusts College, Shoranur, Palakkad, Kerala - 679122',
        email: 'mpmmsncshr@gmail.com',
    },

    {
        id: 112,
        name: 'Mercy College, Mercy College Jn, Pallippuram, Palakkad, Kerala- 678006',
        email: 'mercycollegepkd@yahoo.com',
    },

    {
        id: 113,
        name: 'N S S College Ottapalam, Palakkad - Ponnani Rd, Palappuram, Kerala- 679103',
        email: 'nsstcottapalam@gmail.com',
    },

    {
        id: 114,
        name: 'N.S.S Training College, Ottapalam, P.B.No.3, Ottapalam, Palakkad, Kerala, India - 679 101',
        email: 'nssottapalam@gmail.com',
    },

    {
        id: 115,
        name: 'NSS College, State Highway 58, Nemmara, Palakkad, Kerala-678508',
        email: 'nsscollegenemmara@gmail.com',
    },
    {
        id: 116,
        name: 'SNGS College, Pattambi, Cherpulassery Rd, Mele Pattambi, Palakkad, Kerala- 679306',
        email: 'sngscollege@gmail.com',
    },

    {
        id: 117,
        name: 'Bishop Abraham Memorial College, Thuruthicad P O, Mallappally, Tiruvalla-689597',
        email: 'office@bamcollege.ac.in',
    },

    {
        id: 118,
        name: 'Mar Thoma College, Kuttapuzha P.O, Thiruvalla -689103',
        email: 'mtcofficetvla@gmail.com',
    },

    {
        id: 119,
        name: 'N.S.S. Training College, Pandalam, Pathanamthitta-689501',
        email: 'principal_tcpdlm@yahoo.com',
    },

    {
        id: 120,
        name: 'NSS College, Pandalam, Kerala -689501',
        email: 'nsscollegepandalam@gmail.com',
    },

    {
        id: 121,
        name: 'Sahodaran Ayyappan Smaraka S.N.D.P Yogam College, Sahodaragiri, Cherimukku, Konni P.O, Pathanamthitta – 689691',
        email: 'sassndpyogamcollegekonni@gmail.com',
    },

    {
        id: 122,
        name: 'St. Thomas College, Kozhencherry, Pathanamthitta -689641',
        email: 'stthomaskozhy@gmail.com',
    },

    {
        id: 123,
        name: 'St. Thomas College, Ranni, Pazhavangado P. O, Pathanamthitta, Kerala Pin: 689673',
        email: 'stcranni@gmail.com',
    },

    {
        id: 124,
        name: 'Titus lI Teachers College, SCS Campus, Thiruvalla, Kerala- 689101',
        email: 'tituscollege@gmail.com',
    },

    {
        id: 125,
        name: 'All Saints College, Veli - Perumathura Rd, Karikkakam, Thiruvananthapuram, Kerala 695007',
        email: 'allsaintscolegeasc@gmail.com',
    },

    {
        id: 126,
        name: 'College of Engineering, Sreekaryam - Kulathoor Rd, P.O, Sreekariyam, Thiruvananthapuram, Kerala 695016',
        email: 'principal@cet.ac.in',
    },

    {
        id: 127,
        name: 'Government Arts College, Gandhi Bhavan Rd, Housing Board, Thycaud, Thiruvananthapuram, Kerala -695014',
        email: 'artscollegeofficetvpm@gmail.com',
    },

    {
        id: 128,
        name: 'Govt. College Attingal, Attingal, Kerala 695101',
        email: 'govtcollegeattingal@gmail.com',
    },

    {
        id: 129,
        name: 'Govt. College Nedumangadu, Aakkottupara Nedumangad, Thiruvananthapuram, Kerala- 695541',
        email: 'govt.collegenedumangad@yahoo.com',
    },

    {
        id: 130,
        name: 'Kerala University, University of Kerala Senate House Campus, Palayam, Thiruvananthapuram, Kerala- 695034',
        email: 'dpd@keralauniversity.ac.in',
    },

    {
        id: 131,
        name: 'Mahatma Gandhi College, Pattom P.O, Sree Nagar, Kesavadasapuram, Thiruvananthapuram, Kerala- 695004',
        email: 'nssmgcollege@gmail.com',
    },

    {
        id: 132,
        name: 'Mar Ivanios College Autonomous, Bethany Hills, Mar Ivanios College Rd, Thiruvananthapuram, Kerala -695015',
        email: 'principal@mic.ac.in',
    },

    {
        id: 133,
        name: 'Mar Theophilus Training College, Mar Ivanios Vidya Nagar, Nalanchira, Paruthippara, Thiruvananthapuram, Kerala- 695015',
        email: 'mttctvm15@gmail.com',
    },

    {
        id: 134,
        name: 'NSS College for Women, Neeramankara, TVPM-695040',
        email: 'nsscollegeforwomen@hotmail.com',
    },

    {
        id: 135,
        name: 'S N College, Sivagiri, Varkala Sreenivasapuram, Varkala, Kerala- 695145',
        email: 'sncvpr@gmail.com',
    },

    {
        id: 136,
        name: 'Sree Narayana College, College Rd, Chempazhanthy, Thiruvananthapuram, Kerala- 695587',
        email: 'snc.org@gmail.com',
    },

    {
        id: 137,
        name: 'UGC-HRDC, University College of Engineering Karyavattam Campus, Pavithram, Thiruvananthapuram, Kerala 695581',
        email: 'hrdcunike@yahoo.com',
    },

    {
        id: 138,
        name: 'University College, Mahathma Gandhi Rd, University of Kerala Senate House Campus, Palayam, Thiruvananthapuram, Kerala 695034',
        email: 'principal.uc@gmail.com',
    },

    {
        id: 139,
        name: 'Womens College, CV Raman Pillai Road, Thycaud P.O, Vazhuthacaud, Thiruvananthapuram, Kerala 695014',
        email: 'gcwtvpm@gmail.com',
    },

    {
        id: 140,
        name: 'Carmel College, Mala (P.O), Thrissur - 680732, Kerala, India',
        email: 'carmelcollege@rediffmail.com',
    },

    {
        id: 141,
        name: 'Christ College, Irinjalakuda, P.O, Irinjalakuda North, Thrissur- 680125',
        email: 'office@christcollegeijk.edu.in',
    },

    {
        id: 142,
        name: 'KKTM Government College, Pullut (P.O), Kodungallur, Thrissur, Kerala – 680 663',
        email: 'kktmcollege@gmail.com',
    },

    {
        id: 143,
        name: 'Prajyoti Niketan College, Pudukad, Thrissur, Kerala-680301',
        email: 'prajyotiniketan@yahoo.co.in',
    },

    {
        id: 144,
        name: 'Sree Narayana College, Nattika, Thrissur, Kerala, India-680 566.',
        email: 'snnattika@gmail.com',
    },

    {
        id: 145,
        name: 'Sree Vivekananda College, P.O. Kunnakulam, Akkikkavu, Kizhoor, Kerala- 680523',
        email: 'sreevivekanandakkm@gmail.com',
    },

    {
        id: 146,
        name: "St. Joseph's College Irinjalakuda, Irinjalakuda - 680 121, Thrissur, Kerala",
        email: 'principal@stjosephs.edu.in',
    },

    {
        id: 147,
        name: "St. Mary's College Thrissur, St Marys College Rd, Chembukkav, Thrissur, Kerala 680020",
        email: 'smctsr@gmail.com',
    },

    {
        id: 148,
        name: 'St. Thomas College, Thrissur-680 001',
        email: 'principal@stthomas.ac.in',
    },
    {
        id: 149,
        name: 'Vimala College, Ramavarmapuram Road, Thrissur, Kerala- 680009',
        email: 'mail@vimalacollege.edu.in',
    },

    {
        id: 150,
        name: 'P M College, Potta PO, Chalakudy, Thrissur, Kerala 680722',
        email: 'pmgcprincipal@gmail.com',
    },

    {
        id: 151,
        name: 'Sree C. Achuthamenon College, Hill Gardens Housing Colony, Kuttanellur, Thrissur, Kerala- 680014',
        email: 'govtcollegetcr@yahoo.in',
    },

    {
        id: 152,
        name: 'Mary Matha Arts And Science College, Mananthavady, Vemom, Mananthavady, Kerala- 670645',
        email: 'mmcmntdy@gmail.com',
    },

    {
        id: 153,
        name: 'Pazhassi Raja College, Pulpally, Wayanad, Kerala-673 579',
        email: 'pazhassirajacollege@gmail.com',
    },

    {
        id: 154,
        name: "St. Mary's College, Sulthan Bathery, Wayanad, Kerala, India-673592",
        email: 'stmaryssby@gmail.com',
    },

    {
        id: 155,
        name: 'Govt. College, Mananthavady, Kerala- 670645',
        email: 'gcmdy11@yahoo.co.in',
    },

    {
        id: 156,
        name: 'NMSM Govt. College, Puzhamudy, Vellaram Kunnu Rd, Silver Hills, Kalpetta, Kerala -673122',
        email: 'nmsmgck@gmail.com',
    },

    {
        id: 157,
        name: 'CUSAT, University Road, South Kalamassery, Kalamassery, Kochi, Kerala- 682022',
        email: 'cusatechfoundation@gmail.com',
    },
];
