import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { api } from '../../../../helpers/api';

function CollegePractiseMain(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setgeneralDetails(jsonData.best_practice);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div className="view">
                <Container className="p-0">
                    <Card>
                        <CardBody className="p-5">
                            <h5 className="header-title mb-5 mt-0">Best Practices</h5>
                            <div className="table-section w-100 ">
                                <Table striped>
                                    <thead className="table-head">
                                        <tr className="text-left">
                                            <th className="w-25">Describe the Best Practices</th>
                                            <th className="w-50">Remarks</th>
                                            <th className="w-25">Status</th>
                                        </tr>
                                    </thead>
                                    {generalDetails?.length > 0 ? (
                                        <tbody>
                                            {generalDetails.map((item, index) => (
                                                <tr key={index} className="text-left">
                                                    <>
                                                        <td className='text-justify'>{item.best_practices}</td>
                                                        <td className="text-justify">{item.remarks}</td>
                                                        {item.is_approved === 0 ? (
                                                            <td>
                                                                <Button
                                                                    disabled
                                                                    color="warning"
                                                                    class="btn btn-secondary"
                                                                    style={{
                                                                        color: '#ED7D31',
                                                                        fontWeight: '400',
                                                                        backgroundColor: '#F5F7F8',
                                                                    }}
                                                                    size="sm">
                                                                    Pending
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 2 ? (
                                                            <td>
                                                                <Button disabled color="danger" size="sm">
                                                                    Reverted 
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 1 ? (
                                                            <td>
                                                                <Button disabled color="success" size="sm">
                                                                    Approved
                                                                </Button>
                                                            </td>
                                                        ) : (
                                                            item.is_approved === 3 && (
                                                                <td>
                                                                <Button disabled color="info" size="sm">
                                                                    Reverted By RUSA Admin
                                                                </Button>
                                                                </td>
                                                            )
                                                        )}
                                                    </>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan="3" className="text-center">
                                                    No data Found
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </div>
    );
}

export default CollegePractiseMain;
