import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';

function HeadSportFacilities(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);

    

   

    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.sports);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSuccessAction = () => {
        fetchData();
        setSuccess(false);
    };

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };

    return (
        <div className="view">
          
            <Container className="p-0 my-4">
                <Card>
                    <CardBody className="p-0">
                        <div className="table-section">
                            {generalDetails.length > 0 ? (
                                generalDetails.map((data, i) => (
                                    <Table style={{ width: '100%', tableLayout: 'fixed' }} striped key={i}>
                                        <tbody>
                                            <tr>
                                              
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {data.is_approved === 0 ? (
                                                        <td>
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                    ) : data.is_approved === 2 ? (
                                                        <Button disabled color="danger" size="sm">
                                                            Reverted by head
                                                        </Button>
                                                    ) : data.is_approved === 1 ? (
                                                        <Button disabled color="success" size="sm">
                                                            Approved by head
                                                        </Button>
                                                    ) : (
                                                        data.is_approved === 3 && (
                                                            <Button disabled color="info" size="sm">
                                                                Reverted by RUSA Admin
                                                            </Button>
                                                        )
                                                    )}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Total number of sanctioned posts in Physical Education Department</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.faculities ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of permanent faculties in the Department</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.permanent ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of Guest faculties in the Department</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.guest ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Gymnasium</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.gym ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Swimming pool</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.pool ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Indoor Stadium</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.stadium ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Playground</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.playground ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Applicable games for which the playground is suitable</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.games ? JSON.parse(data.games).join(', ') : '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Remarks</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.remarks ?? '--'}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                ))
                            ) : (
                                <Table striped>
                                    <tbody>
                                
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Total number of sanctioned posts in Physical Education Department</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{ '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of permanent faculties in the Department</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{ '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of Guest faculties in the Department</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{ '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Gymnasium</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{ '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Swimming pool</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{ '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Indoor Stadium</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{ '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Playground</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{ '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Applicable games for which the playground is suitable</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{ '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Remarks</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{ '--'}</td>
                                            </tr>
                                    </tbody>
                                </Table>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default HeadSportFacilities;
