
// @flow
import Cookies from 'js-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    // console.log(user);
    if (!user) {
        return false;
    }

    if (!user.token) {
        // console.warn('access token expired');
        return false;
    } else {
        return true;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = Cookies.get('user');
    console.log(user);

    return user ? (typeof user === 'object' ? user : JSON.parse(user)) : null;
};

const getLoggedInInstitution = () => {
    const institution = Cookies.get('institution');
    console.log(institution);

    return institution ? (typeof institution === 'object' ? institution : JSON.parse(institution)) : null;
};

const getActiveUser = () => {
    const user = Cookies.get('user');

    return user ? (typeof user === 'object' ? user : JSON.parse(user)) : null;
};

export { isUserAuthenticated, getLoggedInUser, getActiveUser, getLoggedInInstitution };





























// // @flow

// /**
//  * Checks if user is authenticated
//  */
// const isUserAuthenticated = () => {
//     const user = getLoggedInUser();
//     console.log(user);
//     if (!user) {
//         return false;
//     }
//     // const decoded = jwtDecode(user.token);

//     if (!user.token) {
//         console.warn('access token expired');
//         return false;
//     } else {
//         return true;
//     }
// };

// /**
//  * Returns the logged in user
//  */
// const getLoggedInUser = () => {
//     const user = localStorage.getItem('user');
//     console.log(user);

//     return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
// };

// const getLoggedInInstitution = () => {
//     const user = localStorage.getItem('institution');
//     console.log(user);

//     return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
// };

// const getActiveUser = () => {
//     const user = localStorage.getItem('user');

//     return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
// };

// export { isUserAuthenticated, getLoggedInUser, getActiveUser, getLoggedInInstitution };
