import React from 'react';
import { Row, Col, Card, CardBody, TabContent, Nav, NavItem, NavLink, Container } from 'reactstrap';
import classnames from 'classnames';
import { useState } from 'react';

import Equity_initiative_utilisation from './Equity_initiative_utilisation';
import Equity_initiative_utilisation_stage_2 from './Equity_initiative_Utilisation_stage_2';
import Preparatory_grant_utilisation from './Preparatory_grant_utilisation';

function FundingUtilisationSPD() {
    const [activeTab, setActiveTab] = useState('1');

    const tabContents = [
        { id: '1', title: 'Equity Initiative Utilisation Stage 1' },
        { id: '2', title: 'Equity Initiative Utilisation Stage 2' },
        { id: '3', title: 'Preparatory Grant Utilisation' },
    ];

    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card>
                    <CardBody>
                        <Nav className="nav nav-pills navtab-bg nav-justified">
                            {tabContents.map((tab, index) => {
                                return (
                                    <NavItem key={index}>
                                        <NavLink
                                            href="#"
                                            className={classnames({ active: activeTab === tab.id })}
                                            onClick={() => {
                                                toggle(tab.id);
                                            }}>
                                            <i className={classnames(tab.icon, 'd-sm-none', 'd-block', 'mr-1')}></i>
                                            <span className="d-none d-sm-block">{tab.title}</span>
                                        </NavLink>
                                    </NavItem>
                                );
                            })}
                        </Nav>

                        <TabContent>
                            {activeTab === '1' ? (
                                <>
                                    <Equity_initiative_utilisation />
                                </>
                            ) : activeTab === '2' ? (
                                <>
                                    <Equity_initiative_utilisation_stage_2 />
                                </>
                            ) : (
                                <>
                                    <Preparatory_grant_utilisation />
                                </>
                            )}
                        </TabContent>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default FundingUtilisationSPD;
