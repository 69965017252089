import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { api } from '../../../../../../helpers/api';
// import FailureError from '../../../best practises new/best practise/error';
import { MANAGE_LABELS } from '../manage/datamanager';

const PreviewInformation = (props) => {
    // console.log(props.edit);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    // ------

    // -----
    return (
        <div>
            <div className="view">
                {/* <div className="banner">OTHER ACCREDITATIONS</div> */}
                <Container className="container-width">
                    <div className="edit">
                        <Button color="primary" onClick={() => props.setEdit()}>
                            Edit
                        </Button>
                    </div>

                    <div className="table-section w-100 ">
                        {props.flag === 'Y' ? (
                            <>
                                {props.data?.map((item, k) => (
                                    <div className="preview-page">
                                        <Row>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={4} sm={4}>
                                                        Financial Year
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={7} sm={7}>
                                                        {item[MANAGE_LABELS.START_YEAR] ?? '--'}-
                                                        {item[MANAGE_LABELS.END_YEAR] ?? '--'}
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={4} sm={4}>
                                                        RUSA Phase
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={7} sm={7}>
                                                        {item[MANAGE_LABELS.RUSA_PHASE] ?? '--'}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={4} sm={4}>
                                                        Total Central Share Received
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={7} sm={7}>
                                                        {item[MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED]}{' '}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={4} sm={4}>
                                                        Total State Share Received
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={7} sm={7}>
                                                        {item[MANAGE_LABELS.TOTAL_STATE_RECEIVED]}{' '}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={4} sm={4}>
                                                        Total Fund Received
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={7} sm={7}>
                                                        {Number(item[MANAGE_LABELS.TOTAL_STATE_RECEIVED]) +
                                                            Number(item[MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED])}{' '}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={4} sm={4}>
                                                        Component Name
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={7} sm={7}>
                                                        {item[MANAGE_LABELS.COMPONENT] ?? '--'}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={4} sm={4}>
                                                        Remarks
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={7} sm={7}>
                                                        {item[MANAGE_LABELS.REMARKS] ?? '--'}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Button
                                    color="primary"
                                    className="my-4"
                                    onClick={() => props?.createFund(props?.data)}
                                    disabled={isSubmitting}>
                                    Submit for Approval
                                </Button>
                            </>
                        ) : (
                            <Row></Row>
                        )}
                    </div>
                </Container>
            </div>
            {/* <FailureError isOpen={isModalOpen} toggle={toggleModal} /> */}
        </div>
    );
};

export default PreviewInformation;
