import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import Success from '../../success';
import Failure from '../../../institutions/failure';
import { api } from '../../../../../helpers/api';

function DetailsOfBogHead(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);
    const [formData, setFormData] = useState([]);

    const handleSaveData = (id) => {
        formData.is_approved = 1;
        // console.log(formData);
        api.post(`/bog/approve/${id}`, formData)
            .then((response) => {
                // console.log('Approved');
                setSuccess(true);
            })
            .catch((error) => {
                // console.error('Failed');
                // toggleModal();
            });
    };

    const handleRevertData = (id) => {
        api.post(`/bog/revert/${id}`)
            .then((response) => {
                // console.log('Reverted');
                setRevert(true);
            })
            .catch((error) => {
                // Handle errors if the POST request fails
                // console.error('Failed');
                // toggleModal();
            });
    };

    const fetchData = async () => {
        try {
            const response = await api.get(`/head-institution-info`);
            const jsonData = response?.data?.data?.data?.details_bog;
            
            // Check if data is received and not empty
            if (jsonData && jsonData.length > 0) {
               
                setgeneralDetails(jsonData);
            } else {
                // Handle case where data is empty or not received
                // console.log('No data received');
            }
        } catch (error) {
            // Handle error
            // console.error('Error fetching data:', error);
            // Optionally, set an error state or display an error message to the user
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        fetchData();
    }, []);

    const handleSuccessAction = () => {
        fetchData();
        setSuccess(false);
    };
    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };

    return (
        <div>
            {success && <Success title="BoG details has been approved" toggle={handleSuccessAction} open={success} />}
            {revert && <Failure title="BoG details has been reverted" toggle={handleRevertAction} open={revert} />}
            <div className="view">
                <Container className="container-width">
                    <div className="table-section w-100 overflow-auto">
                        <Table striped>
                            <thead className="table-head ml-5 mt-0 ">
                                <tr>
                                    <th style={{minWidthwidth:'100px'}}>Name</th>
                                    <th style={{minWidthwidth:'200px'}}>Designation</th>
                                    <th style={{minWidthwidth:'300px'}}>Remarks</th>
                                    <th style={{ minLength: '60px' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
        {generalDetails.filter((item) => item.type === 'bog').length > 0 ? (
            generalDetails.map((item, k) => {
                if (item.type === 'bog') {
                    return (
                        <tr key={k}>
                            <td>{item.name}</td>
                            <td>{item.designation}</td>
                            <td>{item.remarks}</td>
                            {item.is_approved === 0 ? (
                                                <td className="d-flex">
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        size="sm"
                                                        onClick={() => handleSaveData(item.id)}>
                                                        Approve
                                                    </Button>
                                                    <br></br>
                                                    <Button
                                                        color="primary"
                                                        outline
                                                        className="ml-3"
                                                        size="sm"
                                                        onClick={() => handleRevertData(item.id)}>
                                                        Resubmit
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <td>
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted by Head
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 1 ? (
                                                <td>
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved by Head
                                                    </Button>
                                                </td>
                                            
                                            ) : item.is_approved === 3 ? (
                                                <td>
                                                    <Button type="button" color="info" size="sm" disabled>
                                                        Approved by RUSA Admin
                                                    </Button>
                                                </td>
                                            ) : (
                                                ''
                                            )}
                        </tr>
                    );
                } else {
                    return null; // Returning null for non-'bog' items
                }
            })
        ) : (
            <tr>
                <td colSpan="4" className="text-center">
                    No data found
                </td>
            </tr>
        )}
    </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default DetailsOfBogHead;
