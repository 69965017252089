import React, { useEffect, useState } from 'react';
import { api } from '../../../../../helpers/api';
import { COLLEGE_LISTS } from './datamanager';
import FormArea from './form';

import { useHistory } from 'react-router-dom';
// import LiaEditSolid from 'react-icons/lia';
// import LiaEditSolid from 'react-icons/lia';
import { Table } from 'reactstrap';
import { getLoggedInUser } from '../../../../../helpers/authUtils';
import Success from '../../../success';
export default function ViewComponent() {
    const handleFields = () => {
        return {
            // ug,pg,mphil
            INSTITUTIONS_lIST: '',
            EMAIL_ID: '',
        };
    };
    const [existingcollege, setExistingCollege] = useState([]);
    const [loading, setLoading] = useState(true);
    const userData = getLoggedInUser();
    const user = userData?.role_id;
    let fetchData;
    if (user === '9') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-section');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setExistingCollege(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '10') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-state');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setExistingCollege(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '7') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-research');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setExistingCollege(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '8') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-nodal');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setExistingCollege(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '11') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-assistant');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setExistingCollege(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '12') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-system');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setExistingCollege(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '6') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-finance');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setExistingCollege(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else {
    }

    useEffect(() => {
        fetchData();
    }, []);

    // // Use a useEffect to log the updated value of existingcollege
    // useEffect(() => {
    //     // console.log(existingcollege);
    // }, [existingcollege]);

    const history = useHistory();
    const currenTPath = history.location.pathname;

    const redirectToPage = (item) => {
        history.push({ pathname: `${currenTPath}/${item.id}`, state: { ...item } });
        // history.push({ pathname: `/InstitutionLists/${item.id}`, state: { ...item } });
    };
    const [formData, setFormData] = useState({ ...handleFields() });
    const [formDatas, setFormDatas] = useState({
        // colleges_existing: [],
        colleges: COLLEGE_LISTS,
    });
    const [activeStep, setActiveStep] = useState(1);
    const [success, setSuccess] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const handleSaveData = () => {
        setSuccess(true);
    };
    const handleSuccessAction = () => {
        setSuccess(false);
        window.location.reload();
        if (Object.keys(formData)?.length > 0) {
            const data = formDatas.colleges_existing;
            data.push(formData);
            setFormDatas((f) => ({ ...f, colleges_existing: data }));
            setFormData({ ...handleFields() });
            setOpenForm(false);
            setActiveStep(0);
        } else {
            alert('Please fill the form');
        }
    };
    const handleNext = () => {
        setActiveStep(0);
    };
    // console.log(formDatas);
    return (
        <div>
            {!openForm && (
                <div className="d-flex justify-content-between my-3">
                    <h5 className="header-title mb-1 mt-0">Institutions List </h5>{' '}
                </div>
            )}
            {success && (
                <Success title="Institution Admin created successfully." toggle={handleSuccessAction} open={success} />
            )}
            {openForm && (
                <>
                    <FormArea
                        formData={formData}
                        handleNext={handleNext}
                        activeStep={activeStep}
                        setFormData={setFormData}
                        handleSaveData={handleSaveData}
                        setActiveStep={setActiveStep}
                    />
                </>
            )}
            {!openForm && (
                <div className="table-section w-100 ">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ minWidth: '150px' }}>Name of the Institution</th>
                                <th style={{ minWidth: '150px' }}>Email ID</th>
                            </tr>
                        </thead>

                        <tbody>
                            {existingcollege.length > 0 ? (
                                existingcollege.map((institution) => (
                                    <tr className="text-left" key={institution.id}>
                                        <td
                                            className="cursor-pointer hyper_link"
                                            onClick={() => redirectToPage(institution)}>
                                            {institution?.institution_name}
                                        </td>
                                        <td>{institution?.email}</td>
                                        {/* <td><LiaEditSolid /></td> */}
                                    </tr>
                                ))
                            ) : (
                                <>
                                    <tr className="text-center">
                                        <td colSpan={2}> No Data Found</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
}
