import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';
function HeadAuditoriumDetails(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);

    const { id } = useParams();
    
        const fetchData = async () => {
            try {
                const response = await api.get(`institution/${id}`); // Assuming api.get works as expected

                const jsonData = response?.data?.data?.data;

                // console.log(jsonData);
                setGeneralDetails(jsonData.auditoriums);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        useEffect(() => {
        fetchData();
    }, [id]);

    const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`/auditorium/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setGeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    return (
        <div>
            {revert && (
                <Failure
                    title="Infrastructure auditorium details has been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            {generalDetails.length > 0 ? (
                
                    <div className="view">
                        <Container className="container-width ml-1">
                            <div className="table-section w-100">
                                {generalDetails?.map((data, i) => (
                                    <>
                                        <div className="preview-page mx-0">
                                            <Row>
                                            <Col  sm={12} md={6} className='my-4'>
                                                    <>
                                                    <div className='w-50 d-flex justify-content-start '>
                                                        {data.is_approved === 0 && (
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 1 && (
                                                            <Button disabled color="success" size="sm">
                                                                Approved by Head
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 2 && (
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted by Head
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 3 && (
                                                        <td>
                                                            <Button type="button" size="sm" color="info" disabled>
                                                                Reverted by RUSA Admin
                                                            </Button>
                                                        </td>
                                                        )}
                                                        
                                                    <div className='ml-3'>
                                                    {data.is_approved === 1 && (
                                                                
                                                                    <Button
                                                                        type="button"
                                                                        size="sm"
                                                                        outline
                                                                        color="primary"
                                                                        onClick={() => handleRevert(data.id)}
                                                                    >  
                                                                        Revert
                                                                    </Button>
                                                                
                                                            )}
                                                            </div>
                                                            </div>
                                                    </>
                                                </Col>
                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={4} sm={4}>
                                                            Total number of Auditoriums
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={7} sm={7}>
                                                            {data.auditorium_count ?? '--'}
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col sm={12} md={12} className="mt-3 mb-1">
                                                    {' '}
                                                    <div className="d-flex justify-content-center w-100">
                                                        <Table striped className="">
                                                            <thead className="table-head">
                                                                <tr className="text-left">
                                                                    <th style={{maxWidth: '400px'}}>
                                                                        Auditorium details
                                                                    </th>
                                                                    <th style={{maxWidth: '400px'}}>
                                                                        Seating capacity
                                                                    </th>
                                                                    <th style={{maxWidth: '400px'}}>Remarks</th>
                                                                    {/* <th style={{ minWidth: '150px' }}>Actions</th> */}
                                                                </tr>
                                                            </thead>
                                                            {data.audioriumd_details ? (
                                                                <tbody>
                                                                    {data.audioriumd_details.map((item, k) => (
                                                                        <tr className="text-left" key={k}>
                                                                            <td style={{maxWidth: '400px'}}>
                                                                                {item.detail ?? '--'}
                                                                            </td>
                                                                            <td  style={{maxWidth: '400px'}}>
                                                                                {item.capacity ?? '--'}
                                                                            </td>
                                                                            <td className=" text-justify" style={{maxWidth: '400px'}}>
                                                                                {item.remarks ?? '--'}
                                                                            </td>
                                                                            {/* <td>
                                                                <FaRegEdit className="edit-icon" />
                                                            </td> */}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            ) : (
                                                                <tbody>
                                                                    <tr className="text-center">
                                                                        <td colSpan="3">No data found</td>
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </Container>
                    </div>
                
            ) : (
                <>
                    <div className="view">
                        <Container className="container-width ml-1">
                            <div className="table-section w-100 ">
                                <>
                                    <div className="preview-page">
                                        <Row>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={4} sm={4}>
                                                        Total number of auditoriums
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={7} sm={7}>
                                                        --
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col sm={12} md={12} className="my-3">
                                                <div className="d-flex justify-content-center w-100">
                                                    <Table striped className="">
                                                        <thead className="table-head">
                                                            <tr className="text-left">
                                                                <th style={{ minWidth: '50px' }}>Auditorium details</th>
                                                                <th style={{ minWidth: '150px' }}>Seating capacity</th>
                                                                <th style={{ minWidth: '150px', textAlign:'justify' }}>Remarks</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr className="text-center">
                                                                <td colSpan="3">No data found</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            </div>
                        </Container>
                    </div>
                </>
            )}
        </div>
    );
}

export default HeadAuditoriumDetails;
