import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import { api } from '../../../../../helpers/api';



function HeadItAssetDetails(props) {
    // console.log(props.status);
    // console.log(props.remark);
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [success, setSuccess] = useState(false);
    // const [revert, setRevert] = useState(false);


    const fetchData = async  () => {
        try{  
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setgeneralDetails(jsonData.info_tech_assets);

        }
        catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div>
            <div className="view">
                {/* <div className="banner"></div> */}
                {/* <Container className="container-width"> */}
                    {/* <div ></div> */}
                    <div className="table-section w-100 ">
                        <>
                            <div className="">
                                {/* <Row>
                                    <Col sm={12} md={12} className="my-3">
                                        <Row className="text-dark">
                                            <Col md={3} sm={3}>
                                                Name of Asset
                                            </Col>
                                            <Col md={1} sm={1}>
                                                :
                                            </Col>
                                            <Col md={8} sm={8}>
                                                name
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm={12} md={12} className="my-3">
                                        <Row className="text-dark">
                                            <Col md={3} sm={3}>
                                                Quantity (as per Stock Register)
                                            </Col>
                                            <Col md={1} sm={1}>
                                                :
                                            </Col>
                                            <Col md={8} sm={8}>
                                                quantity
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} md={12} className="my-3">
                                        <Row className="text-dark">
                                            <Col md={3} sm={3}>
                                                Quantity (in working condition)
                                            </Col>
                                            <Col md={1} sm={1}>
                                                :
                                            </Col>
                                            <Col md={8} sm={8}>
                                                15
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} md={12} className="my-3">
                                        <Row className="text-dark">
                                            <Col md={3} sm={3}>
                                                Remarks
                                            </Col>
                                            <Col md={1} sm={1}>
                                                :
                                            </Col>
                                            <Col md={8} sm={8}>
                                              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti delectus provident est tenetur facilis sequi veniam illo voluptates non beatae, odit fugiat commodi nulla qui sit possimus quas. Tempora, veniam.
                                            </Col>
                                        </Row>
                                    </Col>
                                   
                                </Row> */}
                                <div className="table-section w-100  mt-4 overflow-auto">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left align-top">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '150px' }}>Name of Assets</th>
                                <th style={{ minWidth: '300px' }}>Quantity(as per Stock Register)</th>
                                <th style={{ minWidth: '300px' }}>Quantity (in working condition)</th>
                                <th style={{ minWidth: '150px' }}>Remarks</th>
                                <th style={{ minWidth: '150px' }}>Status</th>
                            </tr>
                        </thead>
                        {generalDetails.length > 0 ? (
                                        generalDetails.map((data, i) => (
                                            <tr key={i} className="text-left">
                                                <td>{data.assets}</td>
                                                <td>{data.quantity_stock_register}</td>
                                                <td>{data.quantity_working_condition}</td>
                                                <td style={{ textAlign: 'justify' }}>{data.remarks}</td>
                                                {data.is_approved === 0 ? (
                                                   <td>
                                                   <Button
                                                       disabled
                                                       color="warning"
                                                       class="btn btn-secondary"
                                                       style={{
                                                           color: '#ED7D31',
                                                           fontWeight: '400',
                                                           backgroundColor: '#F5F7F8',
                                                       }}
                                                       size="sm">
                                                       Pending
                                                   </Button>
                                               </td>
                                           ) : data.is_approved === 2 ? (
                                               <td>
                                                   <Button type="button" size="sm" color="warning" disabled>
                                                       Reverted by Head
                                                   </Button>
                                               </td>
                                           ) : data.is_approved === 1 ? (
                                               <td>
                                                   <Button type="button" color="success" size="sm" disabled>
                                                       Approved by Head
                                                   </Button>
                                               </td>
                                           ) : data.is_approved === 3 ? (
                                               <td>
                                                   <Button type="button" color="info" size="sm" disabled>
                                                       Reverted by RUSA Admin
                                                   </Button>
                                               </td>
                                                ) : (
                                                    ''
                                                )}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr className="text-center">
                                            <td colSpan="5">No data found</td>
                                            {/* <td></td> */}
                                        </tr>
                                    )}
                    </Table>
                </div>
                            </div>
                        </>
                    </div>
                    {/* <div className="p-2 m-1">
                        <Row>
                            <Col className="d-flex justify-content-start mt-4 p-0">
                                <Button type="submit" color="primary">
                                    Approve
                                </Button>

                                
                            </Col>
                        </Row>
                    </div> */}
                {/* </Container> */}
            </div>
        </div>
    );
}

export default HeadItAssetDetails;