import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
const PreviewInformation = (props) => {
    // console.log(props);
    return (


<>
        
<div className='d-flex justify-content-end'>
            <Button type="button" color="primary" className="px-3 mt-3" onClick={() => props.setEdit()} >
            
            Edit
            </Button>
            </div>
        <div>
 
            <div className="preview-page">
                <Row>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                            Financial Year (yyyy-yyyy)
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {Number(props.formData?.START_YEAR)}-{Number(props.formData?.START_YEAR)+1}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                            RUSA Phase
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.RUSA_PHASE}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                            Total Central Share Received
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.TOTAL_CENTRAL_SHARE}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                            Total State Share Received
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.TOTAL_STATE_SHARE}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                            Total Fund Received 
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.TOTAL_FUND_RECEIVED}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                            Total fund utilised by beneficiaries
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.TOTAL_FUND_UTILISED}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                            % of Utilisation
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.PERCENTAGE_UTILISED}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                              Remarks
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.REMARKS}
                            </Col>
                        </Row>
                    </Col>

                    

                    <Button color="primary" className="ml-2" onClick={() => props.createFund(props?.formData)}>
                        Submit for Approval
                    </Button>
                </Row>
            </div>
        </div></>
    );
};

export default PreviewInformation;
