import React, { useState } from 'react';
import { MinusCircle, PlusCircle } from 'react-feather';
import { Button, Container, Form, Input, Row, Table } from 'reactstrap';
import Success from '../../../../../success/index.jsx';
import { MANAGE_LABELS } from '../datamanager';
import PreviewInformation from '../preview/index.jsx';

function FormArea(props) {
    const Faculityfields2 = {
        [MANAGE_LABELS.YEAR]: null,
        [MANAGE_LABELS.DETAILS]: null,
        [MANAGE_LABELS.HEAD]: null,
        [MANAGE_LABELS.OUTLAY]: null,
        [MANAGE_LABELS.REMARKS]: null,
    };
    // console.log(props);

    const [faculityData2, SetFaculityData2] = useState(props.faculityData2);

    const [keyField2, SetKeyField2] = useState(0);
    const [success, setSuccess] = useState(false);
    const [activeStep, setActiveStep] = useState(1);

    // console.log(faculityData2);
    const handleNext = () => {
        setActiveStep(0);
    };
    // ----------------------------------------------------------
    const faculityTableChange2 = (name, value, index) => {
        let Field2 = faculityData2;
        Field2[index][name] = value;
        SetFaculityData2(Field2);
        SetKeyField2(keyField2 + 1);
        if (name === 'START_YEAR') {
            Field2[index]['END_YEAR'] = String(Number(value) + 1);
            SetFaculityData2(Field2);
        }
    };

    // const budgetEnd = (name, value, index) => {
    //     let Field2 = faculityData2;
    //     Field2[index][name] = value;
    //     SetFaculityData2(Field2);
    //     SetKeyField2(keyField2 + 1);
    // };
    const removeFaculityField2 = (j) => {
        let Field2 = faculityData2.filter((item, N) => j !== N);
        // console.log(Field2);
        SetFaculityData2(Field2);
        SetKeyField2(keyField2 + 1);
    };
    const onAddFaculityFields2 = () => {
        let Field2 = faculityData2;
        Field2.push(Faculityfields2);
        SetFaculityData2(Field2);
        SetKeyField2(keyField2 + 1);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const error = validate();
        handleNext();
        // console.log(error);
        if (Object.keys(error).length === 0) {
            setSuccess(true);
        }
    };
    const validate = () => {
        let error = {};

        // faculityData.forEach((item, k) => {
        //     Object.keys(item).map((key) => {
        //         if (!item[key] || item[key] === '') {
        //             error[`${k}_${key}`] = 'please fill this field';
        //         }
        //     });
        // });

        faculityData2.forEach((item, k) => {
            Object.keys(item).map((key) => {
                if (!item[key] || item[key] === '') {
                    error[`${k}_${key}`] = 'please fill this field';
                }
            });
        });
        // Object.keys(formData).map((key) => {
        //     if (!formData[key] || formData[key] === '') {
        //         error[key] = 'please fill this field';
        //     }
        // });
        return error;
    };
    const handleSuccessAction = () => {
        props.onSubmit({ faculityData2: faculityData2 });
    };
    const manageForm = () => {
        setActiveStep(1);
        const createFields = {
            [MANAGE_LABELS.START_YEAR]: null,
            [MANAGE_LABELS.END_YEAR]: null,
            [MANAGE_LABELS.DETAILS]: null,
            [MANAGE_LABELS.HEAD]: null,
            [MANAGE_LABELS.OUTLAY]: null,
            [MANAGE_LABELS.REMARKS]: null,
        };
        props.setFaculityData2(createFields);
    };

    return (
        <div>
            {success && (
                <Success
                    title="Budget Details are submitted for approval by the RUSA Admin."
                    toggle={handleSuccessAction}
                    open={success}
                />
            )}
            <Container className="form-container p-0 mt-0">
                {activeStep === 1 ? (
                    <>
                        <Form className="form-style" onSubmit={() => setActiveStep(2)}>
                            <Table>
                                <thead>
                                    <tr></tr>
                                </thead>
                                <thead
                                    style={{
                                        fontSize: '13px',
                                        backgroundColor: '#3b6eb9',
                                        color: '#fff',
                                        padding: '7px',
                                        marginTop: '15px',
                                        borderRadius: '5px',
                                    }}>
                                    <tr className="text-left">
                                        <th>
                                            Financial Year <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                        </th>
                                        <th>
                                            Details of Budget <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                        </th>
                                        <th>
                                            Head of Account <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                        </th>
                                        <th>
                                            Total Outlay <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                        </th>

                                        <th>Remarks</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className=" text-center" style={{ fontSize: '12px' }}>
                                    <>
                                        {faculityData2.map((item, j) => {
                                            // console.log(item);
                                            return (
                                                <>
                                                    <tr>
                                                        <td className="d-flex justify-content-between">
                                                            <Input
                                                                autoComplete="off"
                                                                name={MANAGE_LABELS.START_YEAR}
                                                                value={item[MANAGE_LABELS.START_YEAR] || ''}
                                                                required
                                                                onChange={(e) =>
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value.replace(/[^0-9+]/g, ''),
                                                                        j
                                                                    )
                                                                }
                                                                type="text"
                                                                maxLength={4}
                                                                placeholder="Start Year(YYYY)"></Input>
                                                            <span className="bold m-2">-</span>
                                                            <Input
                                                                autoComplete="off"
                                                                required
                                                                disabled
                                                                name={MANAGE_LABELS.END_YEAR}
                                                                value={
                                                                    item[MANAGE_LABELS.START_YEAR]
                                                                        ? Number(item[MANAGE_LABELS.START_YEAR]) + 1
                                                                        : ''
                                                                }
                                                                placeholder="End Year(YYYY)"
                                                                type="text"
                                                                maxLength={4}
                                                                onChange={
                                                                    (e) =>
                                                                        faculityTableChange2(
                                                                            e.target.name,
                                                                            e.target.value.replace(/[^0-9+]/g, ''),
                                                                            j
                                                                        )
                                                                    // budgetEnd(
                                                                    //     e.target.name,
                                                                    //     e.target.value.replace(/[^0-9+]/g, ''),
                                                                    //     j
                                                                    // )
                                                                }></Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                name={MANAGE_LABELS.DETAILS}
                                                                value={item[MANAGE_LABELS.DETAILS] || ''}
                                                                required
                                                                onChange={(e) =>
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value,
                                                                        j
                                                                    )
                                                                }></Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                name={MANAGE_LABELS.HEAD}
                                                                value={item[MANAGE_LABELS.HEAD] || ''}
                                                                required
                                                                onChange={(e) =>
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value,
                                                                        j
                                                                    )
                                                                }></Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                name={MANAGE_LABELS.OUTLAY}
                                                                value={item[MANAGE_LABELS.OUTLAY] || ''}
                                                                required
                                                                onChange={(e) =>
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value.replace(/[^0-9+]/g, ''),
                                                                        j
                                                                    )
                                                                }></Input>
                                                        </td>

                                                        <td>
                                                            <Input
                                                                type="text"
                                                                value={item[MANAGE_LABELS.REMARKS] || ''}
                                                                name={MANAGE_LABELS.REMARKS}
                                                                onChange={(e) =>
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value,
                                                                        j
                                                                    )
                                                                }></Input>
                                                        </td>
                                                        {props?.indEdit === false && (
                                                            <td className="align-middle">
                                                                <div className="d-flex">
                                                                    {j === faculityData2.length - 1 && (
                                                                        <div className="">
                                                                            <PlusCircle
                                                                                onClick={(e) => onAddFaculityFields2()}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {j !== 0 && (
                                                                        <div className="pl-2">
                                                                            <MinusCircle
                                                                                onClick={(e) => removeFaculityField2(j)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        )}
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    </>
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-start mt-4 mx-2">
                                <Row>
                                    <Button type="submit" color="primary" outline>
                                        Preview
                                    </Button>
                                </Row>
                            </div>
                        </Form>
                    </>
                ) : (
                    <>
                        <PreviewInformation
                            faculityData2={faculityData2}
                            setSuccess={setSuccess}
                            setActiveStep={setActiveStep}
                            manageForm={manageForm}
                            createBudget={props?.createBudget}
                        />
                    </>
                )}
            </Container>
        </div>
    );
}

export default FormArea;
