export const MANAGE_LABELS = {
    // -------------other---------------
    START_YEAR:'START_YEAR',
    END_YEAR:'END_YEAR',
    DETAILS: 'DETAILS',
    HEAD: 'HEAD',
    OUTLAY: 'OUTLAY',
    TOTAL_FUND_UTILISED: 'TOTAL_FUND_UTILISED',
    PERCENTAGE_UTILISED: 'PERCENTAGE_UTILISED',
    REMARKS: 'REMARKS',
};
