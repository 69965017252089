import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Button, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';

function HeadPhd(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [revert, setRevert] = useState(false);

    const fetchData = async () => {
        try {
            const response = await api.get(`institution-dataoperator/${id}`);
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.academic_details);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };

    return (
        <div>
            {revert && (
                <Failure
                    title="Academic phd details has been Reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="view">
                {/* <Container className="container-width"> */}
                <div className="table-section w-100 overflow-auto">
                    <Table striped>
                        <thead className="table-head ml-5 mt-0 ">
                            <tr>
                                <th style={{ minWidth: '129px' }}>Academic Year (YYYY-YYYY)</th>
                                <th style={{ minWidth: '129px' }}>Course Type</th>
                                <th style={{ minWidth: '170px' }}>No of Research Center</th>
                                <th style={{ minWidth: '190px' }}>No of Research Guides</th>
                                <th style={{ minWidth: '190px' }}>No of Research Scholars</th>
                                <th style={{ minWidth: '400px' }}>Remarks</th>
                                <th style={{ minWidth: '60px' }}>Status</th>

                                {/* <th>Actions</th> */}
                            </tr>
                        </thead>
                        {generalDetails.filter((item) => item.course_type === 'PHD').length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.course_type === 'PHD' ? (
                                        <tr key={index} className="text-left">
                                            <>
                                                <td className="text-justify">{item.year}</td>
                                                <td className="text-justify">{item.type}</td>
                                                <td className="text-justify">{item.centers}</td>
                                                <td className="text-justify">{item.guides}</td>
                                                <td className="text-justify">{item.scholars}</td>
                                                <td className="text-justify">{item.remarks}</td>
                                                <td>
                                                    {item.is_approved === 0 ? (
                                                        <td className="d-flex">
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 2 ? (
                                                        <td>
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 1 ? (
                                                        <td>
                                                            <Button type="button" color="success" size="sm" disabled>
                                                                Approved
                                                            </Button>
                                                        </td>
                                                    ) : (
                                                        item.is_approved === 3 && (
                                                            <td>
                                                                <Button type="button" color="info" size="sm" disabled>
                                                                    Reverted by RUSA Admin
                                                                </Button>
                                                            </td>
                                                        )
                                                    )}
                                                </td>
                                            </>
                                            {/* <td></td> */}
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        No Data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
                {/* </Container> */}
            </div>
        </div>
    );
}

export default HeadPhd;
