export const general_institutions_type = [
    { label: 'Government' },
    { label: 'Aided' },
    { label: 'Self financing College' },
    { label: 'Autonomous' },
    { label: 'University' },
    { label: 'Others' },
];

export const general_afliated_university = [{ label: 'Kerala' }, { label: 'Annamalai' }];

export const nature_of_college_management = [
    { label: 'Corporate' },
    { label: 'Society' },
    { label: 'Minority' },
    { label: 'Trust' },
    { label: 'Others' },
];

export const districts = [
    { label: 'Tiruvananthapuram' },
    { label: 'Kollam' },
    { label: 'Pathanamthaitta' },
    { label: 'Alappuzha' },
    { label: 'Kottayam' },
    { label: 'Idukki' },
    { label: 'Ernakulam' },
    { label: 'Trissur' },
    { label: 'Palakkad' },
    { label: 'Malappuram' },
    { label: 'Kozhikode' },
    { label: 'Wayanad' },
    { label: 'Kannur' },
    { label: 'Kasargod' },
];

export const MANAGE_LABELS = {
    year: 'year',
    agency: 'agency',
    details: 'details',
    remarks: 'remarks',
};
