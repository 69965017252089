import React from 'react';
import { StopCircle } from 'react-feather';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
function Failure(props) {
    return (
        <>
            <Modal isOpen={props.open} centered toggle={props.toggle} backdrop={false}>
                <ModalHeader toggle={props.toggle} className="all-to-center"></ModalHeader>
                <ModalBody>
                    <div className="all-to-center">
                        <StopCircle className="successCheck" color="red" />
                    </div>
                    <div className="all-to-center text-center py-3">{props.title}</div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default Failure;
