

import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
 import FundUtilizations from './FundUtilisation';
 import FundDetails from './fund-details';
import { api } from '../../../../../../helpers/api';

const MyForm = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await api.get(`/head-institution-info`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.rusa_phase_two;
            setGeneralDetails(jsonData || []);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            {/* <div className="mt-5 mb-3">RUSA Phase : RUSA 1</div> */}
            <div className="d-flex mb-4">
                Component Name <span className="px-2">:</span>
                <div className="d-flex flex-column">
                    {generalDetails.length > 0 && (
                        <div className="mx-2"> 
                             {generalDetails[0].component_name === 'Research' 
                                ? 'Research' 
                                : generalDetails[0].component_name === 'Innovation and quality improvement'
                                ? 'Innovation and quality improvement'
                                : ''}
                        </div>
                    )}
                </div>
            </div>

            <Nav className="nav nav-pills navtab-bg nav-justified">
                <NavItem>
                    <NavLink
                        href="#"
                        className={activeTab === '1' ? 'active' : ''}
                        onClick={() => setActiveTab('1')}
                    >
                        Fund Details
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        href="#"
                        className={activeTab === '2' ? 'active' : ''}
                        onClick={() => setActiveTab('2')}
                    >
                        Fund Utilisation
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <FundDetails generalDetails={generalDetails} />
                </TabPane>
                <TabPane tabId="2">
                    <FundUtilizations generalDetails={generalDetails} />
                </TabPane>
            </TabContent>
        </div>
    );
};

export default MyForm;

















// import React, { useState, useEffect } from 'react';
// import { api } from '../../../../../../helpers/api';
// import { Button, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
// import FundUtilizations from './FundUtilization';
// import FundDetails from './fund-details';

// const MyForm = () => {
//     const [checkboxA, setCheckboxA] = useState(false);
//     const [checkboxB, setCheckboxB] = useState(false);
//     const [showContinueButton, setShowContinueButton] = useState(false);
//     const [showTabs, setShowTabs] = useState(false);
//     const [activeTab, setActiveTab] = useState('1');
//     const [checkBoxManager, setCheckBoxManager] = useState('1');
//     const [generalDetails,setgeneralDetails]= useState([])
//     const [loading, setLoading] = useState(true);

//     const handleCheckboxChange = (checkbox) => {
//         if (checkbox === 'A') {
//             setCheckboxA((prev) => !prev);
//             if (!checkboxA && checkboxB) {
//                 setCheckboxB(false);
//             }
//         } else {
//             setCheckboxB((prev) => !prev);
//             if (!checkboxB && checkboxA) {
//                 setCheckboxA(false);
//             }
//         }
//     };

//     const handleContinueClick = () => {
//         setShowTabs(true);
//         setShowContinueButton(false);
//         if (checkboxA && !checkboxB) {
//             setCheckBoxManager('2');
//         } else if (!checkboxA && checkboxB) {
//             setCheckBoxManager('3');
//         } else if (checkboxA && checkboxB) {
//             setCheckBoxManager('1');
//         }
//     };


//     const fetchData = async () => {
//         try {
//             const response = await api.get(`/head-institution-info`); // Assuming api.get works as expected
//             const jsonData = response?.data?.data?.data?.rusa_phase_two;
            
//             setgeneralDetails(jsonData);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchData();
//     }, []);

//     useEffect(() => {
//         setShowContinueButton(checkboxA || checkboxB);
//     }, [checkboxA, checkboxB]);

//     useEffect(() => {
//         // Check the checkbox based on the data fetched
//         if (generalDetails) {
//             // Assuming you have a condition to check based on the data received
//             // For example, if the 'upgradation' field is true, check checkbox A
//             // If the 'infrastructure' field is true, check checkbox B
//             setCheckboxA(generalDetails.upgradation);
//             setCheckboxB(generalDetails.infrastructure);
//         }
//     }, [generalDetails]);

//     return (
//         <div>
//             <div className="check-box-area">
//                 <div className="mt-5 mb-3">RUSA Phase : RUSA 2</div>
//                 <div className="d-flex">
//                     Component Name <span className="px-2">:</span>
//                     <div className="d-flex flex-column">
//                         {(checkBoxManager === '2' || checkBoxManager === '1') && (
//                             <label>
//                                 <input
//                                     type="checkbox"
//                                     checked={checkboxA}
//                                     disabled={checkboxA}
//                                     className="mx-2"
//                                     onChange={() => handleCheckboxChange('A')}
//                                 />
//                                 New model colleges
//                             </label>
//                         )}
//                         {(checkBoxManager === '3' || checkBoxManager === '1') && (
//                             <label>
//                                 <input
//                                     type="checkbox"
//                                     checked={checkboxB}
//                                     disabled={checkboxB}
//                                     className="mx-2"
//                                     onChange={() => handleCheckboxChange('B')}
//                                 />
//                                 Infrastructure grants to colleges
//                             </label>
//                         )}
//                     </div>
//                 </div>
//             </div>

//             {showContinueButton && <Button onClick={handleContinueClick}>Continue</Button>}

//             {showTabs && (
//                 <>
//                     <Nav className="nav nav-pills navtab-bg nav-justified">
//                         <NavItem>
//                             <NavLink
//                                 href="#"
//                                 className={activeTab === '1' ? 'active' : ''}
//                                 onClick={() => setActiveTab('1')}>
//                                 Fund Details
//                             </NavLink>
//                         </NavItem>
//                         <NavItem>
//                             <NavLink
//                                 href="#"
//                                 className={activeTab === '2' ? 'active' : ''}
//                                 onClick={() => setActiveTab('2')}>
//                                 Fund Utilisation
//                             </NavLink>
//                         </NavItem>
//                     </Nav>

//                     <TabContent>
//                         {activeTab === '1' ? (
//                             <FundDetails
//                                 checkboxA={checkboxA}
//                                 checkboxB={checkboxB}
//                                 checkBoxManager={checkBoxManager}
//                             />
//                         ) : (
//                             <FundUtilizations
//                             checkboxA={checkboxA}
//                             checkboxB={checkboxB}
//                             checkBoxManager={checkBoxManager}
//                              />
//                         )}
//                     </TabContent>
//                 </>
//             )}
//         </div>
//     );
// };

// export default MyForm;
















