import React, { useState, useEffect } from 'react';
import { Button, Table, Container } from 'reactstrap';
import Success from '../../success';
import Failure from '../../../institutions/failure';
import { api } from '../../../../../helpers/api';

function GalleryHead(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);
    const [formData, setFormData] = useState([]);

    const handleSaveData = (id) => {
        formData.is_approved = 1;
        // console.log(formData);
        api.post(`gallery/approve/${id}`, formData)
            .then((response) => {
                // console.log('Approved');
                setSuccess(true);
            })
            .catch((error) => {
                // console.error('Failed');
                // toggleModal();
            });
    };

    const handleRevertData = (id) => {
        api.post(`gallery/revert/${id}`)
            .then((response) => {
                // console.log('Reverted');
                setRevert(true);
            })
            .catch((error) => {
                // Handle errors if the POST request fails
                // console.error('Failed');
                // toggleModal();
            });
    };

    const fetchData = async () => {
        try {
            const response = await api.get('head-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.gallery);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSuccessAction = () => {
        fetchData();
        setSuccess(false);
    };
    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };

    return (
        <div>
            {success && (
                <Success title="Gallery details has been approved" toggle={handleSuccessAction} open={success} />
            )}
            {revert && <Failure title="Gallery details has been reverted" toggle={handleRevertAction} open={revert} />}
            <div className="view">
                <Container className="container-width">
                    <div className="table-section w-100 overflow-auto">
                        <Table striped>
                            <thead className="table-head ml-5 mt-0 ">
                                <tr>
                                    <th style={{ minWidth: '150px' }}>Image Description</th>
                                    <th style={{ minWidth: '150px' }}>Image Link</th>
                                    <th style={{ minWidth: '150px' }}>Remarks </th>
                                    <th style={{ minLength: '150px' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {generalDetails?.length > 0 ? (
                                    generalDetails.map((item, index) => (
                                        <tr key={index} className="text-left">
                                            <>
                                                <td>{item.desciption}</td>
                                                <td>{item.link}</td>
                                                <td>{item.remarks}</td>
                                                {item.is_approved === 0 ? (
                                                    <td className="d-flex">
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            size="sm"
                                                            onClick={() => handleSaveData(item.id)}>
                                                            Approve
                                                        </Button>
                                                        <br></br>
                                                        <Button
                                                            color="primary"
                                                            outline
                                                            className="ml-3"
                                                            size="sm"
                                                            onClick={() => handleRevertData(item.id)}>
                                                            Resubmit
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 2 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted by Head
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 1 ? (
                                                    <td>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved by Head
                                                        </Button>
                                                    </td>
                                               
                                                ) : item.is_approved === 3 ? (
                                                    <td>
                                                        <Button type="button" color="info" size="sm" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            No data Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default GalleryHead;
