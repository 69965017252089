import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { list_pfms_completed, list_pfms_new, update_pfms } from '../../../../../helpers/actions/api.functions';
import ManangeGeneralaInformation from './manage';
import { MANAGE_LABELS } from './manage/datamanager';
import GeneralInformation from './view';

const Faculityfields2 = {
    [MANAGE_LABELS.NAME]: null,
    [MANAGE_LABELS.DESIGNATION]: null,
};
const initial_values2 = [Faculityfields2];
function DataOperatorStaffPatternMain() {
    const [faculityData2, setFaculityData2] = useState(initial_values2);
    const [edit, setEdit] = useState(false);
    const [newReg, setNewReg] = useState(true);
    const [completed, setCompleted] = useState([]);
    const [newPfms, setNewPfms] = useState([]);
    const [newData, setNewData] = useState([]);
    // console.log(newPfms, 'new Data');
    const manageEdit = () => {
        setEdit(true);
    };
    const generateObjData = (data) => {
        if (data?.unique_code && data?.unique_code !== '') {
            let sendData = {
                id: data?.id,
                unique_code: data?.unique_code,
            };
            return sendData;
        } else {
            return null;
        }
    };
    const onSubmit = (data) => {
        // console.log(data);
        const payload = [];
        data.map((item, i) => {
            if (item?.unique_code && item?.unique_code !== '') {
                payload.push(generateObjData(item));
            }
        });
        // console.log(payload);
        setFaculityData2(data.faculityData2);
        update_pfms(payload)
            .then((res) => {
                toast.success('Updated Successfully');
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`);
            });
        setCompleted([]);
        setNewPfms([]);
        fetchCompletedData();
        fetchNewData();
        // setEdit(false);
        // setNewReg(false);
    };

    const fetchCompletedData = () => {
        list_pfms_completed()
            .then((res) => {
                setCompleted(res?.data?.data);
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };
    const fetchNewData = () => {
        list_pfms_new()
            .then((res) => {
                setNewPfms(res?.data?.data);
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };

    useEffect(() => {
        fetchCompletedData();
        fetchNewData();
    }, []);

    return (
        <div className="generalinfo">
            {newReg || edit ? (
                <ManangeGeneralaInformation
                    faculityData2={faculityData2}
                    setFaculityData2={setFaculityData2}
                    edit={edit}
                    onSubmit={onSubmit}
                    newPfms={newPfms}
                    setNewPfms={setNewPfms}
                    completed={completed}
                    setNewData={setNewData}
                    newData={newData}
                />
            ) : (
                <GeneralInformation
                    setEdit={manageEdit}
                    setFaculityData2={setFaculityData2}
                    faculityData2={faculityData2}
                />
            )}
        </div>
    );
}

export default DataOperatorStaffPatternMain;
