export const MANAGE_LABELS = {
    // -------------other---------------
    FINANCIAL_YEAR: 'FINANCIAL_YEAR',
    FINANCIAL_YEAR: 'RUSA_PHASE',
    TOTAL_CENTRAL_SHARE: 'TOTAL_CENTRAL_SHARE',
    TOTAL_STATE_SHARE: 'TOTAL_STATE_SHARE',
    TOTAL_FUND_RECEIVED: 'TOTAL_FUND_RECEIVED',
    TOTAL_FUND_UTILISED: 'TOTAL_FUND_UTILISED',
    PERCENTAGE_UTILISED: 'PERCENTAGE_UTILISED',
    REMARKS: 'REMARKS',
};
