import React, {useState, useEffect} from 'react';
import { Button, Col, Container, Row, Card, CardBody, Table } from 'reactstrap';
import { api } from '../../../../helpers/api';

function AdminNetJrf() {
    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);
    const handleSaveData = (id) => {
        formData.is_approved = 1;
        // console.log(formData);
        api.post(`rank/approve/${id}`, formData)
            .then((response) => {
                // console.log('Approved');
                setSuccess(true);
            })
            .catch((error) => {
                // console.error('Failed');
                // toggleModal();
            });
    };

    const handleRevertData = (id) => {
        api.post(`rank/approve/${id}`)
            .then((response) => {
                // console.log('Reverted');
                setRevert(true);
            })
            .catch((error) => {
                // Handle errors if the POST request fails
                // console.error('Failed');
                // toggleModal();
            });
    };

    

    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.rank);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSuccessAction = () => {
        fetchData();
        setSuccess(false);
    };
    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    return (
        <div>
            <div className="view">
                <Container className="p-0">
                    <Card>
                        <CardBody className="p-5">
                            <h5 className="header-title mb-5 mt-0">Qualified Students of NET/JRF</h5>
            <div className="table-section w-100 my-3">
                <Table striped>
                    <thead className="table-head">
                        <tr className="text-left">
                            {/* <th style={{ minWidth: '50px' }}>#</th> */}
                            <th style={{ minWidth: '150px' }}>Academic Year</th>
                                    <th style={{ minWidth: '150px' }}>Number of NET/JRF students</th>
                                    <th style={{ minWidth: '100px' }}>Subject</th>
                                    <th style={{ minWidth: '100px' }}>Remarks </th>
                                    <th style={{ minWidth: '100px' }}>Status</th>

                        </tr>
                    </thead>
                   
                    {generalDetails?.filter((item) => item.type === 'net' ).length >
                    0 ? (
                    <tbody>
                            {generalDetails.map((item, index) =>
                                item.type === 'net' ? (
                                    <tr key={index} className="text-left">
                                        <td>{item.year}</td>
                                            <td>{item.students}</td>
                                            <td>{item.subject}</td>
                                            
                                            <td className='text-justify'>{item.remarks}</td>
                                            
                                        {item.is_approved === 0 ? (
                                            <td>
                                                 <Button
                                                        disabled
                                                        color="warning"
                                                        className="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                            </td>
                                        ) : item.is_approved === 2 ? (
                                            <td>
                                                <Button type="button" size="sm" color="danger" disabled>
                                                    Reverted
                                                </Button>
                                            </td>
                                        ) : item.is_approved === 1 ? (
                                            <td>
                                                <Button type="button" color="success" size="sm" disabled>
                                                    Approved
                                                </Button>
                                            </td>
                                        ) : item.is_approved === 3 && (
                                            <td>
                                                <Button type="button" color="info" size="sm" disabled>
                                                Reverted by RUSA Admin
                                                </Button>
                                            </td>
                                        )}
                                    </tr>
                                ) : (
                                    ''
                                )
                            )}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="9" className="text-center">
                                    No data Found
                                </td>
                            </tr>
                        </tbody>
                            )}
                        
                </Table>
            </div>
            </CardBody>
            </Card>
            </Container>
            </div>
        </div>
    );
}

export default AdminNetJrf;
