import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';

function HeadNAAC(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(true);
    const [revert, setRevert] = useState(false);

    const { id } = useParams();
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };
    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get(`institution-dataoperator/${id}`);
            const jsonData = response?.data?.data?.data?.internet;
            const updatedUsers = jsonData?.map((user) => {
                return { ...user, status: user.status === 1 ? true : false };
            });

            const internet = jsonData;

            if (internet.some((item) => item.status === 0 && item.is_approved === 1)) {
                setHasDataNo(true);
            }

            if (internet.some((item) => item.status === 1 && item.is_approved === 1)) {
                setHasDataYes(true)
            } 

            setGeneralDetails(updatedUsers);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    // end revert
    return (
        <div>
            {revert && (
                <Failure
                    title="Internet connection details has been Reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <>
                <div className="d-flex justify-content-left mx-3">
                    <Row className="label">
                        <label> Does your institution have a high-speed internet connection?</label>
                    </Row>
                    <Row className="ml-5">:</Row>
                    <Row className="ml-5">
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="yes"
                                    checked={showForm}
                                    onChange={() => setShowForm(true)}
                                />{' '}
                            <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                                Yes
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="no"
                                    checked={!showForm}
                                    onChange={() => setShowForm(false)}
                                />{' '}
                            <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                                No
                            </Label>
                        </FormGroup>
                    </Row>
                </div>
            </>
            {showForm ? (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ minWidth: '200px' }}> Name of Internet Service Provider (ISP)</th>
                                <th style={{ maxWidth: '80px' }}> Upload speed </th>
                                <th style={{ maxWidth: '80px' }}>Download speed </th>
                                <th
                                    style={{
                                        textAlign: 'justify',
                                        maxWidth: '250px',
                                    }}>
                                    Remarks
                                </th>
                                <th style={{ maxWidth: '60px' }}>Status</th>
                            </tr>
                        </thead>
                        {generalDetails.filter((data) => data.status).length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.status ? (
                                        <tr key={index} className="text-left">
                                            <td style={{ minWidth: '200px' }}>{item.isp}</td>
                                            <td style={{ maxWidth: '80px' }}>{item.upload}</td>
                                            <td style={{ maxWidth: '80px' }}>{item.download}</td>
                                            <td style={{ textAlign: 'justify', maxWidth: '250px' }}>{item.remarks} </td>

                                            <td>
                                                {item.is_approved === 0 && (
                                                    <td>
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                )}
                                                {item.is_approved === 1 && (
                                                    <td className="w-50">
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved by head
                                                        </Button>
                                                    </td>
                                                )}
                                                {item.is_approved === 2 && (
                                                    <td className="w-50">
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted by head
                                                        </Button>
                                                    </td>
                                                )}
                                                {item.is_approved === 3 && (
                                                    <td>
                                                        <Button type="button" size="sm" color="info" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                )}
                                            </td>
                                        </tr>
                                    ) : null
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ maxWidth: '250px' }}>Remarks</th>
                                <th style={{ minWidth: '100px' }}>Status</th>
                            </tr>
                        </thead>
                        {generalDetails.filter((data) => !data.status).length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    !item.status ? (
                                        <tr key={index} className="text-left">
                                            <td style={{ maxWidth: '250px' }}>{item.remarks}</td>
                                            <td>
                                                {item.is_approved === 0 && (
                                                    <td>
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                )}
                                                {item.is_approved === 1 && (
                                                    <td className="w-50">
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved by head
                                                        </Button>
                                                    </td>
                                                )}
                                                {item.is_approved === 2 && (
                                                    <td className="w-50">
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted by head
                                                        </Button>
                                                    </td>
                                                )}

                                                {item.is_approved === 3 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="info" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ) : null
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            )}
        </div>
    );
}

export default HeadNAAC;
