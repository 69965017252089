import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { api } from '../../../../../helpers/api';

function HeadSeminarHall(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await api.get(`admin-institution-info`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.seminar_halls;
            setgeneralDetails(jsonData);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    // console.log(props.status);
    // console.log(props.remark);
    return (
        <div>
            <div className="view">
            <Container className="container-width">
                    {}
                    <div className="table-section w-100">
                        {generalDetails?.length > 0 ? (
                            <>
                                {generalDetails?.map((data, i) => (
                                    <>
                                        <div className="preview-page mx-0">
                                            <Row>
                                                <Col className='my-3'>
                                                    <>  
                                                        {data.is_approved ===0 && (
                                                           
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                     
                                                        )}
                                                        {data.is_approved === 2 && (
                                                            <Button disabled color="danger" size="sm">
                                                                Reverted by Head
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 1 && (
                                                            <Button disabled color="success" size="sm">
                                                                Approved by Head
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 3 && (
                                                            <Button disabled color="info" size="sm">
                                                                Reverted by RUSA Admin
                                                            </Button>
                                                        )}
                                                    </>
                                                </Col>
                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={4} sm={4}>
                                                        Total Number of seminar halls
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={7} sm={7}>
                                                            {data.seminar_count ?? '--'}
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col sm={12} md={12} className="mt-3 mb-1">
                                                    {' '}
                                                    <div className="d-flex justify-content-center w-100">
                                                        <Table striped className="">
                                                            <thead className="table-head">
                                                                <tr className="text-left">
                                                                    <th style={{maxWidth: '400px'}}>
                                                                    Seminar Hall Details
                                                                    </th>
                                                                    <th style={{maxWidth: '400px'}}>
                                                                        Seating capacity
                                                                    </th>
                                                                    <th style={{maxWidth: '400px'}}>Remarks</th>
                                                                </tr>
                                                            </thead>
                                                            {data.details ? (
                                                                <tbody>
                                                                    {data.details.map((item, k) => (
                                                                        <tr className="text-left" key={k}>
                                                                            <td className='text-justify' style={{maxWidth: '400px'}}>{item.detail ?? '--'}</td>
                                                                            <td style={{maxWidth: '400px'}}>{item.capacity ?? '--' }</td>
                                                                            <td className='text-justify' style={{maxWidth: '400px'}}>{item.remarks ?? '--'}</td>
                                                                            {/* <td>
                                                                <FaRegEdit className="edit-icon" />
                                                            </td> */}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            ) : (
                                                                <tbody>
                                                                    <tr className="text-center">
                                                                        <td colSpan="3">No data found</td>
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className="preview-page mx-0">
                                    <Row>
                                        <Col sm={12} md={12} className="my-3">
                                            <Row className="text-dark">
                                                <Col md={4} sm={4}>
                                                Total Number of seminar halls
                                                </Col>
                                                <Col md={1} sm={1}>
                                                    :
                                                </Col>
                                                <Col md={7} sm={7}>
                                                    --
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col sm={12} md={12} className="mt-3 mb-1">
                                            {' '}
                                            <div className="d-flex justify-content-center w-100">
                                                <Table striped className="">
                                                    <thead className="table-head">
                                                        <tr className="text-left">
                                                            <th style={{ minWidth: '50px' }}>Seminar Hall Details</th>
                                                            <th style={{ minWidth: '150px' }}>Seating capacity</th>
                                                            <th style={{ minWidth: '150px' }}>Remarks</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr className="text-center">
                                                            <td colSpan={3}>No data found</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        )}
                    </div>
                </Container>
                {/* <Container className="p-0">
                    <Card>
                        <CardBody className="p-0">
                            <div className="table-section w-100 ">
                                <>
                                    <div className="preview-page">
                                        <Row>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={4} sm={4}>
                                                        Total Number of seminar halls
                                                    </Col>
                                                    <Col md={2} sm={2}>
                                                        :
                                                    </Col>
                                                    <Col md={6} sm={6}></Col>
                                                </Row>
                                            </Col>

                                            <div className="d-flex justify-content-center w-100">
                                                <Table striped className="">
                                                    <thead className="table-head">
                                                        <tr className="text-left">
                                                            <th style={{ minWidth: '50px' }}>Seminar Hall Details</th>
                                                            <th style={{ minWidth: '150px' }}> Seating Capacity</th>
                                                            <th style={{ minWidth: '150px' }}> Remarks</th>
                                                            <th style={{ minWidth: '150px' }}> Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                        <tr className="text-center">
                                            <td colSpan="4">No data found</td>
                                        </tr>
                                    </tbody>
                                                </Table>
                                            </div>
                                        </Row>
                                    </div>
                                </>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="p-2 m-1">
                       
                    </div>
                </Container> */}
            </div>
        </div>
    );
}

export default HeadSeminarHall;
