import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { MANAGE_LABELS } from '../datamanager';
import { Edit, Trash2 } from 'react-feather';

function GeneralInformation(props) {
    // console.log(props?.list);
    return (
        <div className="view">
            <div className="edit">
                <Button color="primary" className=" px-3 mt-2" onClick={() => props.setEdit()}>
                    Create
                </Button>
            </div>

            {/* <Table striped className='table-bg mt-0  '>
                    <thead >
                        <tr >
                            

                            <th>Year</th>
                            <th>Accredited Agency Name </th>
                            <th>Details</th>
                            <th>Remarks</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {props.faculityData2.length === 0 ? (
                        <tbody>
                        {props.faculityData2?.map((item, k) => (
                            <tr>
                                
                                <td>{item[MANAGE_LABELS.YEAR] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.ACCREDITED_AGENCY_NAME] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.DETAILS] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.REMARKS] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.ACTION]??'--'}</td>
                            </tr>
                             ))}
                        </tbody>
                     ) : (
                        <>
                         <tr>
                        <td colSpan="5" className="text-center">
                            No data found
                        </td>
                    </tr>
                </>
                )}
                </Table> */}
            <Table striped>
                <thead className="table-head ml-5 mt-0 ">
                    <tr>
                        <th>Financial Year</th>
                        <th>Details of Budget</th>
                        <th>Head of Account</th>
                        <th>Total Outlay</th>

                        <th>Remarks</th>
                        <th>Status</th>
                        <th>Action</th>
                        
                    </tr>
                </thead>
                {props.list.length > 0 ? (
                    <tbody>
                        {props.list?.map((item, k) => (
                            <tr key={k}>
                                <td>{item?.year ?? '--'}</td>
                                <td>{item?.details ?? '--'}</td>
                                <td>{item?.account ?? '--'}</td>
                                <td>{item?.total ?? '--'}</td>

                                <td>{item?.remarks ?? '--'}</td>
                                {item?.is_approved === 0 ? (
                                        <td className="text-primary">Pending</td>
                                    ) : item?.is_approved === 1 ? (
                                        <td className="text-success">Approved</td>
                                    ) : (
                                        <td className="text-warning">Reverted</td>
                                    )}
                                    {/* <td></td> */}
                                    
                                        <td>
                                            <Edit
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    // console.log(item);
                                                    props.setIndData(item);
                                                    props.setIndEdit(true);
                                                }}></Edit>
                                            <Trash2
                                                className="ml-3 text-danger"
                                                onClick={() => props?.deleteOrder(item?.id)}
                                            />
                                        </td>
                                    
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <>
                        <tr>
                            <td colSpan="7" className="text-center">
                                No data found
                            </td>
                        </tr>
                    </>
                )}
            </Table>
        </div>
    );
}

export default GeneralInformation;
