


import axios from 'axios';
import Cookies from 'js-cookie';

// Function to set or clear the Authorization header
export const refreshheaderToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        Cookies.set('token', token);
    } else {
        axios.defaults.headers.common['Authorization'] = null;
        Cookies.remove('token');
    }
};

// Base URL for the API
const servInstance = process.env.REACT_APP_BASE_URL;

// Create an Axios instance with the base URL and Authorization header from the cookie
const apiInstabce = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
    },
});

// API wrapper object
const api = {
    get: (URL, queryParams, type = {}) => apiInstabce.get(URL, queryParams, type).then((res) => res),
    post: (URL, reqData = {}) => apiInstabce.post(URL, reqData).then((res) => res),
    put: (URL, reqData = {}) => apiInstabce.put(URL, reqData).then((res) => res),
    delete: (URL, reqData = {}) => apiInstabce.delete(URL, reqData).then((res) => res),
};

// Fetch function with error handling
const fetchJSON = (url, options = {}) => {
    return fetch(servInstance + url, {
        ...options,
        headers: {
            ...options.headers,
            'Authorization': `Bearer ${Cookies.get('token')}`,
        },
    })
        .then((response) => {
            if (response.status !== 200) {
                throw response.json();
            }
            return response.json();
        })
        .catch((error) => {
            // console.log(error);
            throw error;
        });
};

export { fetchJSON, api };



























// import axios from 'axios';
// export const refreshheaderToken = (token) => {
//     if (token) {
//         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//     } else {
//         axios.defaults.headers.common['Authorization'] = null;
//     }
// };
// const servInstance = process.env.REACT_APP_BASE_URL;

// const apiInstabce = axios.create({
//     baseURL: process.env.REACT_APP_BASE_URL,
//     headers: {
//         Authorization: `Bearer ${localStorage.getItem('token')}`,
//     },
// });

// const api = {
//     get: (URL, queryParams, type = {}) => apiInstabce.get(URL, queryParams, type).then((res) => res),
//     post: (URL, reqData = {}) => apiInstabce.post(URL, reqData).then((res) => res),

//     put: (URL, reqData = {}) => apiInstabce.put(URL, reqData).then((res) => res),

//     delete: (URL, reqData = {}) => apiInstabce.delete(URL, reqData).then((res) => res),
// };

// const fetchJSON = (url, options = {}) => {
//     return fetch(servInstance + url, options)
//         .then((response) => {
//             console.log(response);
//             if (!response.status === 200) {
//                 console.log('failed');
//                 throw response.json();
//             }
//             console.log('failed');
//             return response.json();
//         })
//         .then((json) => {
//             console.log('failed');
//             return json;
//         })
//         .catch((error) => {
//             console.log(error);
//             throw error;
//         });
// };

// export { fetchJSON, api };
