import React, { useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { api } from '../../../../../../../helpers/api';
import Failure from '../../../../../institutions/failure';
import Success from '../../../../../success';
export default function FundDetails(props) {
  

    const [success, setSuccess] = useState(false);
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);
 


    const handleSaveData = (id) => {
        api.post(`phaseone/approve/${id}`)
            .then((response) => {
                // console.log('Approved');
                setSuccess(true);
            })
            .catch((error) => {
                // console.error('Failed');
                // toggleModal();
            });
    };

    const handleRevertData = (id) => {
        api.post(`phaseone/revert/${id}`)
            .then((response) => {
                // console.log('Reverted');
                setRevert(true);
            })
            .catch((error) => {
                // Handle errors if the POST request fails
                // console.error('Failed');
                // toggleModal();
            });
    };
 
    const fetchData = async () => {
        try {
            const response = await api.get(`/head-institution-info`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.rusa_phase_one;
            setgeneralDetails(jsonData);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handleSuccessAction = () => {
        fetchData();
        setSuccess(false);
    };
    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };

    return (
        <div>
            {success && <Success title="RUSA1 Pase Beneficiaries details has been Approved" toggle={handleSuccessAction} open={success} />}
            {revert && (
                <Failure title="RUSA1 Pase Beneficiaries details has been Reverted" toggle={handleRevertAction} open={revert} />
            )}
          
                <div className="table-section w-100 overflow-auto">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ minWidth: '150px' }}>Date as on :</th>
                                <th style={{ minWidth: '200px' }}>Details of Construction </th>
                                <th style={{ minWidth: '200px' }}>Details of Renovation </th>
                                <th style={{ minWidth: '200px' }}>Details of Purchase </th>
                                <th style={{ minWidth: '200px' }}> Other Details</th>
                                <th style={{ minWidth: '250px' }}>
                                    Fund Utilized from the total allocated amount (Rs)
                                </th>
                                <th style={{ minWidth: '250px' }}>Details of Physical Progress</th>
                                <th style={{ minWidth: '100px' }}>Status</th>
                                <th style={{ minWidth: '100px' }}>Actions</th>
                            </tr>
                        </thead>
                        {generalDetails.filter((item) => item.type === 'fund utilized' && (
                                item.component_name === 'Upgradation of existing degree colleges to model colleges'
                                ? 'Upgradation of existing degree colleges to model colleges'
                                : 'Infrastructure grants to colleges'
                            )).length > 0 ? (
                            <tbody>
                                {generalDetails?.map((item, index) =>
                                    item.type === 'fund utilized' && (
                                        item.component_name === 'Upgradation of existing degree colleges to model colleges'
                                        ? 'Upgradation of existing degree colleges to model colleges'
                                        : 'Infrastructure grants to colleges'
                                    ) ? (
                                        <tr key={index} className="text-left">
                                            <td>{item.date}</td>

                                            <td>{item.construction_details}</td>

                                            <td>{item.renovation_details}</td>

                                            <td>{item.purchase_details}</td>

                                            <td>{item.other_details}</td>

                                            <td>{item.fund_utilized_details}</td>

                                            <td>{item.physical_progress_details}</td>

                                            <td>
                                            {item.is_approved === 0 ? (
                                                <td className="d-flex px-0">
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        size='sm'
                                                        onClick={() => handleSaveData(item.id)}>
                                                        Approve
                                                    </Button>

                                                    <Button
                                                        color="primary"
                                                        outline
                                                        size='sm'
                                                        className="ml-3"
                                                        onClick={() => handleRevertData(item.id)}>
                                                        Resubmit
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                            <Button disabled color="danger" size="sm">
                                                                Reverted by head
                                                            </Button>
                                                        ) : item.is_approved === 1 ? (
                                                            <Button disabled color="success" size="sm">
                                                                Approved by head
                                                            </Button>
                                                        ) : item.is_approved === 3 ? (
                                                            <Button disabled color="info" size="sm">
                                                                Reverted by RUSA Admin
                                                            </Button>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                    <td></td>
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="15" className="text-center">
                                        No data found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
          
        </div>
    );
}
