import React, { useState } from 'react';
import { Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import { user_role } from '../datamanager';
import PreviewInformation from '../preview/index.jsx';
const FormArea = (props) => {
    const { formData, setFormData, handleSaveData, activeStep, handleNext,edit } = props;
    const [emailError, setEmailError] = useState('');

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;

        // Update the email field in formData
        setFormData({
            ...formData,
            email: emailValue,
        });

        // Perform email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(emailValue)) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if there is an email error before proceeding
        if (emailError) {
            // Display an error message or handle the error in your UI
            alert(emailError);
            return; // Prevent further execution of the handleSubmit function
        }

        // If email is valid, proceed to the next step
        handleNext();
    };
    return (
        <div>
            <div className="manage">
                <div className="form-section">
                    <div className="info">
                        <h5 className="header-title mb-3 mt-0">RUSA SPD User Creation </h5>
                    </div>
                    <Container className="form-container permanent-area">
                        <div>
                            {activeStep === 1 ? (
                                <>
                                    <Form onSubmit={handleSubmit}>
                                        <>
                                            <>
                                                {' '}
                                                <Row className="my-0">
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-2">Name of User</Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            maxLength={100}
                                                            type="text"
                                                            value={formData.name}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    name: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Col>

                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-2">Email ID</Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            maxLength={200}
                                                            type="email"
                                                            value={formData.email}
                                                            onChange={handleEmailChange}
                                                            // Apply a red border if there's an email error
                                                            style={{ borderColor: emailError ? 'red' : '' }}
                                                        />
                                                        {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                                                    </Col>
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-2">Phone Number</Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="text"
                                                            minLength={10}
                                                            maxLength={12}
                                                            value={formData.phone}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    phone: e.target.value.replace(/[^0-9+]/g, ''),
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-2">Designation</Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            maxLength={100}
                                                            type="text"
                                                            value={formData.designation}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    designation: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-2">User Role</Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="select"
                                                            value={formData.role}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    role: e.target.value,
                                                                })
                                                            }>
                                                            <option value="">Select a role</option>
                                                            {user_role.map((role, index) => (
                                                                <option key={index} value={role.label}>
                                                                    {role.label}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>{' '}
                                            </>
                                            {/* -----------end---------- */}
                                        </>
                                        <div className="my-3">
                                            <Button type="submit">
                                                {/* // onClick={() => (activeStep === 1 ?  : handleSaveData())}> */}
                                                {activeStep == 0 ? 'Submit for Approval' : 'Preview'}
                                            </Button>
                                        </div>
                                    </Form>
                                </>
                            ) : (
                                <>
                                    <PreviewInformation
                                        formData={formData}
                                        activeStep={activeStep}
                                        handleSaveData={handleSaveData}
                                        setActiveStep={props.setActiveStep}
                                        edit={edit}
                                    />
                                </>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default FormArea;
