import React from 'react';
import { Container, Row, Col, Table, Input, Button, FormGroup, Label } from 'reactstrap';
import { FaFileExcel } from 'react-icons/fa';
const PreparatoryGrantView = (props) => {
    const { viewData } = props;
    const handleNav = () => {
        props.setShowPreparatoryGrantView(false);
    };
    return (
        <div>
            {/* <Container> */}
            <div className="mb-0 mt-2 d-flex justify-content-end">
                <Button type="button" color="primary" onClick={() => handleNav()}>
                    Go Back
                </Button>

                {/* <div><FaFileExcel className='excel-icon mt-2' style={{color:"green"}}/></div> */}
            </div>
            <div className="preview-page">
                <Row>
                    <Col sm={6} md={6}>
                        <FormGroup>
                            <Label style={{ color: 'black' }}>Financial Year </Label>
                            <Input
                                // onChange={(e) => handleChange(e.target.name, e.target.value)}
                                disabled
                                // name={MANAGE_LABELS.TOTAL_COST}
                                value={viewData?.year}
                                placeholder=""
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={6}>
                        <FormGroup>
                            <Label style={{ color: 'black' }}>Total Amount</Label>
                            <Input
                                // onChange={(e) => handleChange(e.target.name, e.target.value)}
                                disabled
                                placeholder=""
                                value={viewData?.amount}
                                required
                                type="number"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Table striped>
                    <thead className="table-head">
                        <tr className="text-left">
                            <th>
                                Date <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                            </th>
                            <th>
                                Amount Utilised <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                            </th>
                            <th>
                                RUSA Activities <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {viewData?.details?.length > 0 ? (
                        viewData?.details?.map((item, k) => (
                            <tbody key={k}>
                                <tr className="text-left">
                                    <td>{item.date}</td>
                                    <td>{item.utilised}</td>
                                    <td>{item.activities}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" className="text-center">
                                No Data Found
                            </td>
                        </tr>
                    )}
                </Table>
            </div>
            {/* </Container> */}
        </div>
    );
};

export default PreparatoryGrantView;
