import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { api } from '../../../../helpers/api';
import Cookies from 'js-cookie';

const CollegeGeneralMain = () => {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const userData = Cookies.get('institution') ? JSON.parse(Cookies.get('institution')) : null;

    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;

            const generalDetails = jsonData.general_info || [];
            setGeneralDetails(generalDetails);
            // console.log(generalDetails);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Container className="p-3">
                <h5 className="header-title mb-5 mt-3">Institution General Information</h5>
                <div className="table-section w-100">
                    {generalDetails.length > 0 ? (
                        generalDetails.map((data, i) => (
                            <Table striped>
                                <tbody>
                                    <tr style={{ width: '50%' }}>
                                        {data.is_approved === 0 ? (
                                            <td>
                                                <Button
                                                    disabled
                                                    color="warning"
                                                    class="btn btn-secondary"
                                                    style={{
                                                        color: '#ED7D31',
                                                        fontWeight: '400',
                                                        backgroundColor: '#F5F7F8',
                                                    }}
                                                    size="sm">
                                                    Pending
                                                </Button>
                                            </td>
                                        ) : data.is_approved === 2 ? (
                                            <td>
                                                <Button disabled color="danger" size="sm">
                                                    Reverted by Head
                                                </Button>
                                            </td>
                                        ) : data.is_approved === 1 ? (
                                            <td>
                                                <Button disabled color="success" size="sm">
                                                    Approved by Head
                                                </Button>
                                            </td>
                                        ) : data.is_approved === 3 && (
                                            <td>
                                                <Button disabled color="info" size="sm">
                                                    Reverted by RUSA Admin
                                                </Button>
                                            </td>
                                        )}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className='w-50'>
                                            <b>INSTITUTION NAME</b>
                                        </td>
                                        <td className='w-50 text-justify'>{userData?.institution_name}</td>
                                    </tr>
                                    <tr>
                                    <td className='w-50'>
                                            <b>INSTITUTION ADDRESS</b>
                                        </td>
                                        <td className='w-50 text-justify'>{data.address}</td>
                                    </tr>
                                    <tr>
                                    <td className='w-50'>
                                            <b>INSTITUTION TYPE</b>
                                        </td>
                                        <td className='w-50 text-justify'>{data.type_of_institution}</td>
                                    </tr>
                                    <tr>
                                    <td className='w-50'>
                                            <b>MANAGEMENT NATURE</b>
                                        </td>
                                        <td className='w-50 text-justify'>
                                            {data.name_of_college_management} <br></br>
                                            {data.other_nature}
                                        </td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50'>
                                            <b>MANAGEMENT ADDRESS</b>
                                        </td>
                                        <td className='w-50 text-justify'>{data.name_address_of_institution_management}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50'>
                                            <b>AFFILIATED UNIVERSITY</b>
                                        </td>
                                        <td className='w-50 text-justify'>{data.affiliated_university}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50'>
                                            <b>ESTABLISHMENT YEAR</b>
                                        </td>
                                        <td className='w-50'>{data.year_of_establishment}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50'>
                                            <b>DISTRICT</b>
                                        </td>
                                        <td className='w-50'>{data.district}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50'>
                                            <b>PARLIAMENT CONSTITUENCY</b>
                                        </td>
                                        <td className='w-50'>{data.parliamentary_constituency}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50'>
                                            <b>ASSEMBLY CONSTITUENCY</b>
                                        </td>
                                        <td className='w-50'>{data.assembly_constituency}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50 text-justify'>
                                            <b>REVENUE DISTRICT OFFICE</b>
                                        </td>
                                        <td className='w-50'>{data.revenue_district_office}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50 text-justify'>
                                            <b>TALUK</b>
                                        </td>
                                        <td className='w-50'>{data.taluk}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50 text-justify'>
                                            <b>STATE</b>
                                        </td>
                                        <td className='w-50'>{data.state|| 'Kerala'}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50'>
                                            <b>OFFICIAL EMAIL</b>
                                        </td>
                                        <td className='w-50'>{userData?.email}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50'>
                                            <b>OFFICIAL LANDLINE NUMBER</b>
                                        </td>
                                        <td className='w-50'>{data.official_contact_no}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50'>
                                            <b>NAME OF PRINCIPAL</b>
                                        </td>
                                        <td className='w-50'>{data.principal}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50 '>
                                            <b>PRINCIPAL EMAIL</b>
                                        </td>
                                        <td className='w-50'>{data.official_email_of_principal}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50'>
                                            <b>CONTACT NUMBER OF PRINCIPAL</b>
                                        </td>
                                        <td className='w-50'>{data.official_contact_no_of_principal}</td>
                                    </tr>{' '}
                                    <tr>
                                    <td className='w-50 '>
                                            <b>REMARKS</b>
                                        </td>
                                        <td className='w-50 text-justify'>{data.remarks}</td>
                                    </tr>{' '}
                                </tbody>
                            </Table>
                        ))
                    ) : (
                        <Table striped>
                            <tbody>
                                <React.Fragment>
                                    <tr style={{ width: '50%' }}>
                                        <td>
                                            <b>INSTITUTION NAME</b>
                                        </td>
                                        <td>{userData?.institution_name}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>INSTITUTION ADDRESS</b>
                                        </td>
                                        <td>---</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>INSTITUTION TYPE</b>
                                        </td>
                                        <td>---</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>MANAGEMENT NATURE</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>MANAGEMENT ADDRESS</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>AFFILIATED UNIVERSITY</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>ESTABLISHMENT YEAR</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>DISTRICT</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>PARLIAMENTARY CONSTITUENCY</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>ASSEMBLY CONSTITUENCY</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>REVENUE DISTRICT</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>TALUK</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>STATE</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>OFFICIAL MAIL</b>
                                        </td>
                                        <td>{userData?.email}</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>OFFICIAL LANDLINE</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>PRINCIPAL NAME</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>PRINCIPAL MAIL</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>PRINCIPAL PHONE</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>REMARKS </b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                </React.Fragment>
                            </tbody>
                        </Table>
                    )}
                </div>

                {/* //// */}
            </Container>
        </div>
    );
};
export default CollegeGeneralMain;
