import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';
import Failure from '../../../institutions/failure';
import Success from '../../success';

function DPRHead(props) {
    const [showForm, setShowForm] = useState(true);

    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);
    const [formData, setFormData] = useState([]);

    const handleSaveData = (id) => {
        formData.is_approved = 1;
        // console.log(formData);
        api.post(`dpr/approve/${id}`, formData)
            .then((response) => {
                // console.log('Approved');
                setSuccess(true);
            })
            .catch((error) => {
                // console.error('Failed');
                // toggleModal();
            });
    };

    const handleRevertData = (id) => {
        api.post(`dpr/revert/${id}`)
            .then((response) => {
                // console.log('Reverted');
                setRevert(true);
            })
            .catch((error) => {
                // Handle errors if the POST request fails
                // console.error('Failed');
                // toggleModal();
            });
    };

    const fetchData = async () => {
        try {
            const response = await api.get('head-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.dpr);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSuccessAction = () => {
        fetchData();
        setSuccess(false);
    };
    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    // console.log(props.status);
    // console.log(props.remark);
    return (
        <div>
            {success && (
                <Success
                    title="DPR Details has been approved"
                    toggle={handleSuccessAction}
                    open={success}
                />
            )}
            {revert && (
                <Failure
                    title="DPR Details has been reverted"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="view">
                <Container className="container-width">
                    <div className="mt-0 overflow-auto">
                       

                        <Table striped>
                            <thead className="table-head ml-5 mt-0">
                                <tr>
                                    <th style={{minWidth:"150px"}}>DPR</th>
                                    <th style={{minWidth:"250px"}}>Approved plan for Construction</th>
                                    <th style={{minWidth:"250px"}}>Approved plan for Renovation</th>
                                    <th style={{minWidth:"300px"}}>Remarks</th>
                                    <th style={{minWidth:"150px"}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {generalDetails && generalDetails.length > 0 ? (
                                    generalDetails.map((item, index) => (
                                        <tr key={index} className="text-left">
                                            <td style={{maxWidth:"150px"}}>{item.link}</td>

                                            <td>
                                                <a href={item.construction_plan} target="_blank">
                                                    View
                                                </a>
                                            </td>
                                            <td>
                                                <a href={item.renovation_plan} target="_blank">
                                                    View
                                                </a>
                                            </td>
                                            <td className='text-justify'>{item.remarks}</td>
                                            <td>
                                                {item.is_approved === 0 ? (
                                                    <div className="d-flex">
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            size="sm"
                                                            onClick={() => handleSaveData(item.id)}>
                                                            Approve
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            outline
                                                            className="ml-3"
                                                            size="sm"
                                                            onClick={() => handleRevertData(item.id)}>
                                                            Resubmit
                                                        </Button>
                                                    </div>
                                                ) : item.is_approved === 2 ? (
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted by Head
                                                    </Button>
                                                ) : item.is_approved === 1 ? (
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved by Head
                                                    </Button>
                                                
                                                ) : item.is_approved === 3 ? (
                                                    <Button type="button" color="info" size="sm" disabled>
                                                        Reverted by RUSA Admin
                                                    </Button>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            No data Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default DPRHead;
