import React from 'react';
import { Button, Container, Table } from 'reactstrap';
import { MANAGE_LABELS } from '../manage/datamanager';
import { Edit, Trash2 } from 'react-feather';
import { staff_delete } from '../../../../../../helpers/actions/api.functions';
import toast from 'react-hot-toast';
import { getErrorMessage } from '../../../../../../components/genError';

function GeneralInformation(props) {
    
    
    return (
        <div className="view">
            <Container>
                <div className="edit">
                    <Button color="primary" className=" px-3" onClick={() => props.setEdit()}>
                        Create
                    </Button>
                </div>

                <Table striped>
                    <thead className="table-head">
                        <tr>
                            <th>Name *</th>
                            <th>Designation *</th>
                            <th>Contact Number*</th>
                            <th>Email ID*</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {props.list.length > 0 ? (
                        <tbody>
                            {props.list?.map((item, k) => (
                                <tr key={k}>
                                    <td>{item[MANAGE_LABELS.name] ? item[MANAGE_LABELS.name][0].toUpperCase() + item[MANAGE_LABELS.name].slice(1) : '--'}</td>

                                    <td>{item[MANAGE_LABELS.designation] ?? '--'}</td>
                                    <td>{item[MANAGE_LABELS.number] ?? '--'}</td>
                                    <td>{item[MANAGE_LABELS.email] ?? '--'}</td>
                                    {item?.is_approved === 0 ? (
                                        <td className="text-primary">Pending</td>
                                    ) : item?.is_approved === 1 ? (
                                        <td className="text-success">Approved</td>
                                    ) : (
                                        <td className="text-warning">Reverted</td>
                                    )}
                                    {/* <td></td> */}
                                    {item?.is_approved === 2 ? (
                                        <td>
                                            <Edit
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    // console.log(item);
                                                    props.setIndData(item);
                                                    props.setNewEdit(true);
                                                }}></Edit>
                                            <Trash2
                                                className="ml-3 text-danger"
                                                onClick={() => props?.deleteOrder(item?.id)}
                                            />
                                        </td>
                                    ) : (
                                        <>
                                            <td></td>
                                        </>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <>
                            <tr>
                                <td colSpan="5" className="text-center">
                                    No data found
                                </td>
                            </tr>
                        </>
                    )}
                </Table>
            </Container>
        </div>
    );
}

export default GeneralInformation;
