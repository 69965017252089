import React, { useState, useEffect } from 'react';
import { Button, Table, Row, Col } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Edit } from 'react-feather';
import Failure from '../../../../../../institutions/failure';

function HeadItAssetDetails(props) {
    // console.log(props.status);
    // console.log(props.remark);
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);
    const { id } = useParams();

    

    const fetchData = async () => {
        try {
            const response = await api.get(`institution-dataoperator/${id}`);
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.info_tech_assets);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

  


    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };

    return (
        <div>
           
            <div className="view">
                {/* <Container className="container-width">  */}

                <div className="table-section w-100 ">
                    <>
                        <div className="table-section w-100 ">
                           
                            <Table striped className="my-0">
                                {/* Your table header */}
                                <thead className="table-head">
                                    <tr className="text-left">
                                        {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                        <th style={{ minWidth: '150px' }}>Name of Assets</th>
                                        <th style={{ minWidth: '150px' }}>Quantity(as per Stock Register)</th>
                                        <th style={{ minWidth: '100px' }}>Quantity (in working condition)</th>
                                        <th style={{ minWidth: '100px' }}>Remarks</th>
                                        <th style={{ minWidth: '150px' }}>Status</th>
                                        {/* <th style={{ minWidth: '150px' }}>Actions</th> */}
                                        {/* <th style={{ minWidth: '100px' }}>Actio</th>
                                <th></th>
                                <th></th>
                                <th></th> 
                                <th></th> */}
                                        {/* <td></td> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {generalDetails.length > 0 ? (
                                        generalDetails.map((data, i) => (
                                            <tr key={i} className="text-left">
                                                <td>{data.assets}</td>
                                                <td>{data.quantity_stock_register}</td>
                                                <td>{data.quantity_working_condition}</td>
                                                <td style={{ textAlign: 'justify' }}>{data.remarks}</td>
                                                <td>
                                                    {data.is_approved === 0 ? (
                                                        <td>
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        </td>
                                                    ) : data.is_approved === 2 ? (
                                                        <td>
                                                            <Button disabled color="danger" size="sm">
                                                                Reverted by Head
                                                            </Button>
                                                        </td>
                                                    ) : data.is_approved === 1 ? (
                                                        <td>
                                                            <Button disabled color="success" size="sm">
                                                                Approved by Head
                                                            </Button>
                                                        </td>
                                                    ) : (
                                                        data.is_approved === 3 && (
                                                            <td>
                                                                <Button disabled color="info" size="sm">
                                                                    Reverted by RUSA Admin
                                                                </Button>
                                                            </td>
                                                        )
                                                    )}
                                                </td>

                                                {/* <Edit
                                                            className="me-3 cursor-pointer"
                                                            color="black"
                                                            onClick={() => handleEdit(data.id)}
                                                        /> */}
                                                {/* <td></td>
                                                    <td></td>
                                                    <td></td> */}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr className="text-center">
                                            <td colSpan="6">No data found</td>
                                            {/* <td></td> */}
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        {/* Your other code */}
                    </>
                </div>
                <div className="p-2 m-1">
                    <Row>
                        <Col className="d-flex justify-content-start mt-4 p-0">{/* Your button code */}</Col>
                    </Row>
                </div>
                {/* </Container>  */}
            </div>
        </div>
    );
}

export default HeadItAssetDetails;
