import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
function HeadOTHER(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [showForm, setShowForm] = useState(true);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };

    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.other_accreditation;
            const updatedUsers = jsonData;

            const accreditation = jsonData;

            if (accreditation.some((item) => item.other_status === 0 && item.is_approved === 1)) {
                setHasDataNo(true);
            }

            if (accreditation.some((item) => item.other_status === 1 && item.is_approved === 1)) {
                setHasDataYes(true)
            }

            setgeneralDetails(updatedUsers);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    // ------
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };
    // -----
    return (
        <div>
            <>
                {' '}
                <div className="d-flex justify-content-left mx-3">
                    <Row className="label">
                        <label>Does the institution possess a Other Accreditations ?</label>
                    </Row>
                    <Row className="ml-5">:</Row>
                    <Row className="ml-5">
                        {' '}
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="yes"
                                    checked={showForm === true}
                                    onChange={() => setShowForm(true)}
                                />{' '}
                       <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>Yes
                        </Label>
                        </FormGroup>
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="no"
                                    checked={showForm === false}
                                    onChange={() => setShowForm(false)}
                                />{' '}
                        <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                            No
                        </Label>
                        </FormGroup>
                    </Row>
                </div>
            </>
            {showForm === true ? (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th>Name of accrediting agency</th>
                                <th>Agency type</th>
                                <th> Date of Accreditation</th>
                                <th> Valid Upto</th>
                                <th>Accreditation Score/ Grade</th>
                                <th>Remarks</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        {generalDetails.filter((data) => data.other_status === 1).length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.other_status === 1 ? (
                                        <tr key={index} className="text-left">
                                            <td>{item.accrediation_agency_name}</td>
                                            <td>{item.agency_type}</td>
                                            <td>{formatDate(item.accreditation_date)}</td>
                                            <td> {formatDate(item.valid_until)}</td>
                                            <td>{item.score}</td>
                                            <td>{item.remarks}</td>
                                            {item.is_approved === 0 ? (
                                                <td className="d-flex">
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                    <br></br>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <td>
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 1 ? (
                                                <td>
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 3 ? (
                                                <td>
                                                    <Button type="button" color="info" size="sm" disabled>
                                                        Reverted by RUSA Admin
                                                    </Button>
                                                </td>
                                            ) : (
                                                ''
                                            )}
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ minWidth: '150px' }}>Remarks</th>

                                <th style={{ minWidth: '100px' }}>Status</th>
                                {/* <th style={{ minWidth: '100px' }}>Actions</th> */}
                            </tr>
                        </thead>
                        {generalDetails.filter((data) => data.other_status === 0).length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.other_status === 0 ? (
                                        <tr key={index} className="text-left">
                                            <>
                                                <td>{item.remarks}</td>
                                                {item.is_approved === 0 ? (
                                                    <td className="d-flex">
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 2 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 1 ? (
                                                    <td>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 3 ? (
                                                    <td>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            )}
        </div>
    );
}

export default HeadOTHER;
