import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { MANAGE_LABELS } from '../manage/datamanager';
import { Edit, Trash2 } from 'react-feather';
import { getErrorMessage } from '../../../../../../components/genError';
import toast from 'react-hot-toast';
import { agency_delete } from '../../../../../../helpers/actions/api.functions';

function GeneralInformation(props) {
    // const listing = props?.getListing()
    const deleteOrder = (id) => {
        agency_delete(id)
            .then((res) => {
                toast.success('Agency Deleted Successfully');
                // listing()
            })
            .catch((err) => {
                toast.error(`${getErrorMessage(err.response.data.error)}`, { duration: 4000, position: 'top-right' });
            });
    };
    return (
        <div className="view">
            <div className="d-flex justify-content-end my-3">
                <Button color="primary" className=" px-3" onClick={() => props.setEdit()}>
                    Create
                </Button>
            </div>

            {/* <Table striped className='table-bg mt-0  '>
                    <thead >
                        <tr >
                            

                            <th>Year</th>
                            <th>Accredited Agency Name </th>
                            <th>Details</th>
                            <th>Remarks</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {props.faculityData2.length === 0 ? (
                        <tbody>
                        {props.faculityData2?.map((item, k) => (
                            <tr>
                                
                                <td>{item[MANAGE_LABELS.YEAR] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.ACCREDITED_AGENCY_NAME] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.DETAILS] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.REMARKS] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.ACTION]??'--'}</td>
                            </tr>
                             ))}
                        </tbody>
                     ) : (
                        <>
                         <tr>
                        <td colSpan="5" className="text-center">
                            No data found
                        </td>
                    </tr>
                </>
                )}
                </Table> */}
            <Table striped>
                <thead className="table-head ml-5 mt-0 ">
                    <tr>
                        <th>Year*</th>
                        <th style={{ minWidth: '20px' }}>Accredited Agency Name*</th>
                        <th>Details*</th>
                        <th>Remarks*</th>
                        <th>Status*</th>
                        <th>Action*</th>
                    </tr>
                </thead>
                {props.list.length > 0 ? (
                    <tbody>
                        {props.list?.map((item, k) => (
                            <tr key={k}>
                                <td>{item[MANAGE_LABELS.year] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.agency] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.details] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.remarks] ?? '--'}</td>
                                {item?.is_approved === 0 ? (
                                        <td className="text-primary">Pending</td>
                                    ) : item?.is_approved === 1 ? (
                                        <td className="text-success">Approved</td>
                                    ) : (
                                        <td className="text-warning">Reverted</td>
                                    )}
                                    {/* <td></td> */}
                                    {item?.is_approved === 2 ? (
                                        <td>
                                            <Edit
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    // console.log(item);
                                                    props.setIndData(item);
                                                    props.setNewEdit(true);
                                                }}></Edit>
                                            <Trash2
                                                className="ml-3 text-danger"
                                                onClick={() => deleteOrder(item?.id)}
                                            />
                                        </td>
                                    ) : (
                                        <>
                                            <td></td>
                                            {/* <Edit
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    // console.log(item);
                                                    props.setIndData(item);
                                                    props.setNewEdit(true);
                                                }}></Edit> */}
                                        </>
                                    )}
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <>
                        <tr>
                            <td colSpan="5" className="text-center">
                                No data found
                            </td>
                        </tr>
                    </>
                )}
            </Table>
        </div>
    );
}

export default GeneralInformation;
