import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { api } from '../../../../../helpers/api';

function HeadNationalMain(props) {
    const [showForm, setShowForm] = useState(true);

    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);
    const [formData, setFormData] = useState([]);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };

    const handleSaveData = (id) => {
        formData.is_approved = 1;
        // console.log(formData);
        api.post(`grant/approve/${id}`, formData)
            .then((response) => {
                // console.log('Approved');
                setSuccess(true);
            })
            .catch((error) => {
                // console.error('Failed');
                // toggleModal();
            });
    };

    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data?.grants;
            const nationalGrants = jsonData.filter((item) => item.grant_type === 'National');

            if (nationalGrants.some((item) => item.status === 0 && item.is_approved === 1)) {
                setHasDataNo(true);
            }

            if (nationalGrants.some((item) => item.status === 1 && item.is_approved === 1)) {
                setHasDataYes(true)
            } setGeneralDetails(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSuccessAction = () => {
        fetchData();
        setSuccess(false);
    };
    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    return (
        <div>
            <>
                {' '}
                <div className="d-flex justify-content-left mx-3 mt-2">
                    <Row className="label">
                        <label>Have you received any research grants from National Agencies ?</label>
                    </Row>
                    <Row className="ml-5">:</Row>
                    <Row className="ml-5">
                        {' '}
                        <FormGroup check>
                            <Input
                                type="radio"
                                name="showForm"
                                value="yes"
                                checked={showForm === true}
                                onChange={() => setShowForm(true)}
                            />{' '}
                            <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                                Yes
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                type="radio"
                                name="showForm"
                                value="no"
                                checked={showForm === false}
                                onChange={() => setShowForm(false)}
                            />{' '}
                            <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                                No
                            </Label>
                        </FormGroup>
                    </Row>
                </div>
            </>
            {showForm === true ? (
                <div className="table-section w-100 my-3 overflow-auto">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '170px' }}>Agency Name</th>
                                <th style={{ minWidth: '290px' }}>Details of Grant Received</th>
                                <th style={{ minWidth: '191px' }}>Grant Received Year</th>
                                <th style={{ minWidth: '210px' }}>Amount Received</th>
                                <th style={{ minWidth: '417px' }}>Remarks</th>
                                <th style={{ minWidth: '60px' }}>Status</th>

                            </tr>
                        </thead>
                        {generalDetails?.filter((item) => item.status === 1 && item.grant_type === 'National').length >
                            0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.status === 1 && item.grant_type === 'National' ? (
                                        <tr key={index} className="text-left">
                                            <td className='text-justify'>{item.name}</td>
                                            <td className='text-justify'>{item.details}</td>
                                            <td>{item.year}</td>
                                            <td>{item.amount}</td>
                                            <td className='text-justify'>{item.remarks}</td>
                                            {item.is_approved === 0 ? (
                                                <td>
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <td>
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 1 ? (
                                                <td>
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 3 && (
                                                <td>
                                                    <Button type="button" color="info" size="sm" disabled>
                                                        Reverted by RUSA Admin
                                                    </Button>
                                                </td>
                                            )}
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '150px' }}>Remarks</th>

                                <th style={{ minWidth: '100px' }}>Status</th>
                            </tr>
                        </thead>
                        {generalDetails?.filter((item) => item.status === 0 && item.grant_type === 'National').length >
                            0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.status === 0 && item.grant_type === 'National' ? (
                                        <tr key={index} className="text-left">
                                            <>
                                                <td className='text-justify'>{item.remarks}</td>
                                                {item.is_approved === 0 ? (
                                                    <td>
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 2 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 1 ? (
                                                    <td>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 3 && (
                                                    <td>
                                                        <Button type="button" color="info" size="sm" disabled>
                                                            reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                )}
                                            </>
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            )}
        </div>
    );
}

export default HeadNationalMain;
