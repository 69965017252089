import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import PreviewInformation from '../preview/index.jsx';
import toast from 'react-hot-toast';

const FormArea = (props) => {
    const { formData, setFormData, handleSaveData, activeStep, handleNext } = props;
    const [error, setError] = useState([]);

    const handleSubmit = (e) => {
        const total = Number(formData?.TOTAL_STATE_SHARE) + Number(formData?.TOTAL_CENTRAL_SHARE);
        setFormData({ ...formData, TOTAL_FUND_RECEIVED: String(total) });
        e.preventDefault();
        handleNext();
    };
    const CalcPercentile = () => {
        if (formData?.TOTAL_STATE_SHARE && formData?.TOTAL_CENTRAL_SHARE && formData?.TOTAL_FUND_UTILISED) {
            let totalReceived = Number(formData?.TOTAL_STATE_SHARE) + Number(formData?.TOTAL_CENTRAL_SHARE);
            let totalSpent = Number(formData?.TOTAL_FUND_UTILISED);
            const percent = ((totalSpent / totalReceived) * 100).toFixed(2);
            setFormData({
                ...formData,
                PERCENTAGE_UTILISED: String(percent),
            });
        }
    };
    useEffect(() => {
        CalcPercentile();
    }, [formData?.TOTAL_STATE_SHARE, formData?.TOTAL_CENTRAL_SHARE, formData?.TOTAL_FUND_UTILISED]);
    // console.log(formData);
    return (
        <div>
            <div className="manage">
                <div className="form-section">
                    <div className="info">
                        <div className="mandatory">{/* <sup>*</sup>All fields are mandatory. */}</div>
                    </div>
                    <Container className="form-container " style={{ backgroundColor: '#c5e7f3', padding: '10px' }}>
                        <div>
                            {activeStep === 1 ? (
                                <>
                                    <Form onSubmit={handleSubmit}>
                                        <>
                                            <>
                                                {' '}
                                                <Row className="">
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-1 mt-2" style={{ fontSize: '13px' }}>
                                                            Financial Year *(yyyy-yyyy)
                                                        </Label>
                                                        {/* <Input
                                                            required
                                                            autoComplete="off"
                                                            type="NUMBER"
                                                            value={formData.FINANCIAL_YEAR}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    FINANCIAL_YEAR: e.target.value,
                                                                })
                                                            }
                                                        /> */}

                                                        <div className="d-flex">
                                                            <Input
                                                                autoComplete="off"
                                                                required
                                                                type="text"
                                                                maxLength={4}
                                                                placeholder="Starting Year(YYYY)"
                                                                value={formData.START_YEAR}
                                                                onChange={(e) =>
                                                                    setFormData({
                                                                        ...formData,
                                                                        START_YEAR: e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ''
                                                                        ),
                                                                        
                                                                    })
                                                                }>
                                                                {/* {dates.map((ty, i) => (
                                                        <option key={i}>{ty.label}</option>
                                                    ))} */}
                                                            </Input>
                                                            <span className="bold m-1">-</span>
                                                            <Input
                                                                autoComplete="off"
                                                                required
                                                                disabled
                                                                placeholder="Ending Year(YYYY)"
                                                                type="text"
                                                                maxLength={4}
                                                                value={
                                                                    formData.START_YEAR
                                                                        ? Number(formData.START_YEAR) + 1
                                                                        : ''
                                                                }
                                                                ></Input>
                                                        </div>
                                                    </Col>

                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-1 mt-2" style={{ fontSize: '13px' }}>
                                                            RUSA Phase *
                                                        </Label>
                                                        <Input
                                                            required
                                                            placeholder="select"
                                                            type="select"
                                                            className=""
                                                            autoComplete="off"
                                                            value={formData.RUSA_PHASE}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    RUSA_PHASE: e.target.value,
                                                                })
                                                            }>
                                                            <option value="">Select</option>
                                                            <option value="RUSA 1">RUSA 1</option>
                                                            <option value="RUSA 2">RUSA 2</option>
                                                        </Input>
                                                    </Col>
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-1 mt-2" style={{ fontSize: '13px' }}>
                                                            Total Central Share Received *
                                                        </Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="text"
                                                            value={formData.TOTAL_CENTRAL_SHARE}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    TOTAL_CENTRAL_SHARE: e.target.value.replace(
                                                                        /[^0-9+]/g,
                                                                        ''
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-1 mt-3" style={{ fontSize: '13px' }}>
                                                            Total State Share Received *
                                                        </Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="text"
                                                            value={formData.TOTAL_STATE_SHARE}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    TOTAL_STATE_SHARE: e.target.value.replace(
                                                                        /[^0-9+]/g,
                                                                        ''
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-1 mt-3" style={{ fontSize: '13px' }}>
                                                            Total Fund Received
                                                        </Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="text"
                                                            disabled
                                                            value={
                                                                formData?.TOTAL_STATE_SHARE &&
                                                                formData?.TOTAL_CENTRAL_SHARE
                                                                    ? Number(formData?.TOTAL_STATE_SHARE) +
                                                                      Number(formData?.TOTAL_CENTRAL_SHARE)
                                                                    : ''
                                                            }
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    TOTAL_FUND_RECEIVED: e.target.value.replace(
                                                                        /[^0-9+]/g,
                                                                        ''
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-1 mt-3" style={{ fontSize: '13px' }}>
                                                        Total fund utilised by beneficiaries *
                                                        </Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="text"
                                                            value={formData.TOTAL_FUND_UTILISED}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    TOTAL_FUND_UTILISED: e.target.value.replace(
                                                                        /[^0-9+]/g,
                                                                        ''
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={4} sm={12} my-2>
                                                        <Label className="my-1 mt-3" style={{ fontSize: '13px' }}>
                                                            % of Utilisation *
                                                        </Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="text"
                                                            disabled
                                                            value={formData?.PERCENTAGE_UTILISED}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    PERCENTAGE_UTILISED: e.target.value.replace(
                                                                        /[^0-9+]/g,
                                                                        ''
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                </Row>{' '}
                                            </>
                                            {/* -----------end---------- */}
                                        </>
                                        <Row>
                                            <Col md={12} sm={12} my-2>
                                                <Label className="my-1 mt-3 " style={{ fontSize: '13px' }}>
                                                    Remarks
                                                </Label>
                                                <Input
                                                    autoComplete="off"
                                                    type="text"
                                                    value={formData.REMARKS}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, REMARKS: e.target.value })
                                                    }
                                                />
                                            </Col>
                                        </Row>{' '}
                                        <div className="my-2 mt-4 mb-3">
                                            <Button type="submit">
                                                {/* // onClick={() => (activeStep === 1 ?  : handleSaveData())}> */}
                                                {activeStep == 0 ? 'Submit for Approval' : 'Preview'}
                                            </Button>
                                        </div>
                                    </Form>
                                </>
                            ) : (
                                <>
                                    <PreviewInformation
                                        formData={formData}
                                        activeStep={activeStep}
                                        handleSaveData={handleSaveData}
                                        setEdit={() => props.setActiveStep(1)}
                                        createFund={props.createFund}
                                    />
                                </>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default FormArea;
