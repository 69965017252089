export const general_institutions_type = [
    { label: 'Government' },
    { label: 'Aided' },
    { label: 'Self financing College' },
    { label: 'Autonomous' },
    { label: 'University' },
    { label: 'Others' },
];

export const general_afliated_university = [{ label: 'Kerala' }, { label: 'Annamalai' }];

export const nature_of_college_management = [
    { label: 'Corporate' },
    { label: 'Society' },
    { label: 'Minority' },
    { label: 'Trust' },
    { label: 'Others' },
];

export const districts = [
    { label: 'Tiruvananthapuram' },
    { label: 'Kollam' },
    { label: 'Pathanamthaitta' },
    { label: 'Alappuzha' },
    { label: 'Kottayam' },
    { label: 'Idukki' },
    { label: 'Ernakulam' },
    { label: 'Trissur' },
    { label: 'Palakkad' },
    { label: 'Malappuram' },
    { label: 'Kozhikode' },
    { label: 'Wayanad' },
    { label: 'Kannur' },
    { label: 'Kasargod' },
];

export const MANAGE_LABELS = {
    YEAR: 'NAME',
    TOTAL: 'DESIGNATION',
    STATUS: 'CONTACT_NO',
    DATE: 'EMAIL_ID',
    AMOUNT: 'ACTION',
    ACTIVITIES: 'ACTIVITIES',
};

export const year = [
    { label: '2014 - 2015' },
    { label: '2015 - 2016' },
    { label: '2016 - 2017' },
    { label: '2017 - 2018' },
    { label: '2018 - 2019' },
    { label: '2019 - 2020' },
    { label: '2020 - 2021' },
    { label: '2022 - 2023' },
    { label: '2023 - 2024' },
    { label: '2024 - 2025' },
    { label: '2025 - 2026' },
    { label: '2026 - 2027' },
];
