import React from 'react';
import { Row, Container, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useState } from 'react';
import BuildingDetails from './building_details/index';
import Facilities from './facilities/index';
import ClassroomDetails from './class_room_details/index';
import AuditoriumDetails from './auditorium_details/index';
import SeminarHall from './seminar_hall/index';
import ItAssetDetails from './it_assets_details/index';
import InternetCollection from './internet_connection/index';
import LibraryDetails from './library_details/index';

function Infrastructure() {
    const tabContents = [
        { id: '1', title: 'Building Details' },
        { id: '2', title: 'Facilities' },
        { id: '3', title: 'Classroom Details' },
        { id: '4', title: 'Auditorium Details' },
        { id: '5', title: 'Seminar Hall' },
        { id: '6', title: 'IT Asset Details' },
        { id: '7', title: 'Internet Connection' },
        { id: '8', title: 'library details' },
    ];
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card>
                    <CardBody>
                        <h5 className="header-title mb-3 mt-0">Infrastructure</h5>

                        <>
                            <Nav className="nav nav-pills navtab-bg nav-justified">
                                {tabContents.map((tab, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === tab.id })}
                                                onClick={() => {
                                                    toggle(tab.id);
                                                }}>
                                                <i className={classnames(tab.icon, 'd-sm-none', 'd-block', 'mr-1')}></i>
                                                <span className="d-none d-sm-block">{tab.title}</span>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>

                            <TabContent>
                                {activeTab === '1' ? (
                                    <>
                                        <BuildingDetails />
                                    </>
                                ) : activeTab === '2' ? (
                                    <>
                                        <Facilities />
                                    </>
                                ) : activeTab === '3' ? (
                                    <>
                                        <ClassroomDetails />
                                    </>
                                ) : activeTab === '4' ? (
                                    <>
                                        <AuditoriumDetails />
                                    </>
                                ) : activeTab === '5' ? (
                                    <>
                                        <SeminarHall />
                                    </>
                                ) : activeTab === '6' ? (
                                    <>
                                        <ItAssetDetails />
                                    </>
                                ) : activeTab === '7' ? (
                                    <>
                                        <InternetCollection />
                                    </>
                                ) : (
                                    <>
                                        <LibraryDetails />
                                    </>
                                )}
                            </TabContent>
                        </>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default Infrastructure;
