import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import { finanacial_years } from './datamanager';
import { IoEyeOutline } from 'react-icons/io5';
import { FaFileExcel } from 'react-icons/fa';
import PreparatoryGrantView from './view';
import toast from 'react-hot-toast';
import {
    assistant_grant,
    finance_grant,
    nodal_grant,
    research_grant,
    section_grant,
    state_grant,
    system_grant,
} from '../../../../../helpers/actions/api.functions';
import { getLoggedInUser } from '../../../../../helpers/authUtils';

function PreparatoryGrant() {
    const [showPreparatoryGrantView, setShowPreparatoryGrantView] = useState(false);
    const [data, setData] = useState([]);
    const userData = getLoggedInUser();
    const userRole = userData?.role_id;
    // console.log(userRole);
    const [listData, setListData] = useState([]);
    const [list, setList] = useState([]);
    const [viewData, setViewData] = useState({});

    const getEndpoint = (id) => {
        switch (id) {
            case '9':
                return section_grant();
            case '10':
                return state_grant();
            case '7':
                return research_grant();
            case '8':
                return nodal_grant();
            case '11':
                return assistant_grant();
            case '12':
                return system_grant();
            case '6':
                return finance_grant();
            default:
                return '';
        }
    };
    const createYearArray = () => {
        // console.log('test');
        const newArray = finanacial_years.map((obj1) => {
            const matchingObj = listData.find((obj2) => obj2.year == obj1.label);
            if (matchingObj) {
                return { ...obj1, ...matchingObj };
            } else {
                return obj1;
            }
        });
        // console.log(listData, finanacial_years, newArray);
        setList(newArray);
    };
    useEffect(() => {
        createYearArray();
    }, [listData, finanacial_years]);
    const getStaffListing = (userRole) => {
        getEndpoint(userRole)
            .then((res) => {
                setListData(res);
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
            });
    };

    useEffect(() => {
        if (userRole) {
            getStaffListing(userRole);
        }
    }, [userRole]);
    const handleViewInfo = (date) => {
        // console.log(listData);
        // console.log(date);
        const newObj = listData?.find((obj) => obj.year == date);
        setViewData(newObj);
        if (newObj) {
            setShowPreparatoryGrantView(true);
        } else {
            toast.error('No Details Found');
        }
    };
    return (
        <div>
            {showPreparatoryGrantView === false ? (
                // <Container className="form-container" style={{ padding: '10px' }}>

                <div className="table-section w-100 my-5 ">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ minWidth: '100px' }}>Financial Year</th>
                                <th style={{ minWidth: '150px' }}>Total Amount</th>
                                <th style={{ minWidth: '150px' }}>Status</th>
                                <th style={{ minWidth: '80px' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((item, n) => (
                                <>
                                    <tr key={n} className="text-left">
                                        <td>{item.label}</td>
                                        <td>{item?.amount}</td>
                                        <td>
                                            {' '}
                                            {item?.is_approved === 0 ? (
                                                <td className="text-primary p-0">Pending</td>
                                            ) : item?.is_approved === 1 ? (
                                                <td className="text-success p-0">Approved</td>
                                            ) : item?.is_approved === 2 ? (
                                                <td className="text-warning p-0">Reverted</td>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            <IoEyeOutline
                                                className="table-icon cursor-pointer"
                                                onClick={() => handleViewInfo(item?.label)}
                                            />
                                        </td>
                                    </tr>
                                </>
                            ))}

                            {/* ))} */}
                        </tbody>
                    </Table>
                </div>
            ) : (
                //  </Container>
                <>
                    {' '}
                    {showPreparatoryGrantView && (
                        <PreparatoryGrantView
                            setShowPreparatoryGrantView={setShowPreparatoryGrantView}
                            viewData={viewData}
                            // fetchData={fetchData}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default PreparatoryGrant;
