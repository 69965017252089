import React, { Component } from 'react';
import Routes from './routes/Routes';
import { Toaster } from 'react-hot-toast';
import './assets/css/style.css';
import './assets/scss/theme.scss';
import './index.css';

class App extends Component {
    componentDidMount() {
        // Frame busting script to prevent clickjacking
        if (window.top !== window.self) {
            window.top.location = window.self.location;
        }
    }

    render() {
        return (
            <>
                <Toaster position="top-right" gutter={15} />
                <Routes />
            </>
        );
    }
}

export default App;
