export const user_role = [
    { label: 'DATA OPERATOR' },
    { label: 'FINANCE OFFICER' },
    { label: 'RESEARCH OFFICER' },
    { label: 'NODAL OFFICER' },
    { label: 'SECTION OFFICER' },
    { label: 'STATE PROJECT COORDINATOR' },
    { label: 'ASSISTANT' },
    { label: 'SYSTEM ADMIN' },
];

export const MANAGE_LABELS = {
    // -------------other---------------
};
