export const general_institutions_type = [
    { label: 'Government' },
    { label: 'Aided' },
    { label: 'Self financing College' },
    { label: 'Autonomous' },
    { label: 'University' },
    { label: 'Others' },
];

export const general_afliated_university = [{ label: 'Kerala' }, { label: 'Annamalai' }];

export const nature_of_college_management = [
    { label: 'Corporate' },
    { label: 'Society' },
    { label: 'Minority' },
    { label: 'Trust' },
    { label: 'Others' },
];

export const districts = [
    { label: 'Tiruvananthapuram' },
    { label: 'Kollam' },
    { label: 'Pathanamthaitta' },
    { label: 'Alappuzha' },
    { label: 'Kottayam' },
    { label: 'Idukki' },
    { label: 'Ernakulam' },
    { label: 'Trissur' },
    { label: 'Palakkad' },
    { label: 'Malappuram' },
    { label: 'Kozhikode' },
    { label: 'Wayanad' },
    { label: 'Kannur' },
    { label: 'Kasargod' },
];

export const MANAGE_LABELS = {
    NAME: 'NAME',
    DESIGNATION: 'DESIGNATION',

};

export const COLLEGES = [
    { label: 'CET' },
    { label: 'TKM' },
    { label: 'UKF' },
    { label: 'College Of Engineering Attingal' },
    { label: 'AJ College' },
    { label: 'MG college' },
    { label: 'Marian Engg College' },
    { label: 'Barton Hill' },
    { label: 'LBS' },
    { label: 'NIT' },
    { label: 'Amritha' },
    
    
]