import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, CardBody, Table } from 'reactstrap';
import { FaFileExcel } from 'react-icons/fa';
import { getLoggedInUser } from '../../../../helpers/authUtils';
import toast from 'react-hot-toast';
import {
    assistant_orderProceedings,
    finance_orderProceedings,
    nodal_orderProceedings,
    research_orderProceedings,
    section_orderProceedings,
    state_orderProceedings,
    system_orderProceedings,
} from '../../../../helpers/actions/api.functions';
import moment from 'moment';

const OrdersProceedingsSPD = (props) => {
    const [data, setData] = useState([]);
    // console.log(props);
    const userData = getLoggedInUser();
    const userRole = userData?.role_id;
    // console.log(userRole);

    const getEndpoint = (id) => {
        switch (id) {
            case '9':
                return section_orderProceedings();
            case '10':
                return state_orderProceedings();
            case '7':
                return research_orderProceedings();
            case '8':
                return nodal_orderProceedings();
            case '11':
                return assistant_orderProceedings();
            case '12':
                return system_orderProceedings();
            case '6':
                return finance_orderProceedings();
            default:
                return '';
        }
    };
    const getOrdersListing = (userRole) => {
        getEndpoint(userRole)
            .then((res) => {
                setData(res?.data?.data);
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
            });
    };

    useEffect(() => {
        if (userRole) {
            getOrdersListing(userRole);
        }
    }, [userRole]);
    return (
        <div>
            <Card>
                <CardBody>
                    <Container>
                        <h5 className="header-title my-5">Government Orders & Proceedings</h5>

                        <>
                            

                            <Table striped>
                                <thead className="table-head ml-5 mt-0 ">
                                    <tr>
                                    <th style={{ minWidth: '100px' }}>Date</th>
                                        <th style={{ minWidth: '300px' }}>Govt Orders/ Proceedings details</th>
                                        <th style={{ minWidth: '192px' }}>Uploaded File</th>
                                        <th style={{ minWidth: '329px' }}>Remarks</th>
                                        {/* <th>Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.length > 0 ? (
                                        <>
                                            {data?.map((item, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        {item?.date ? moment(item?.date).format('DD-MM-YYYY') : '--'}
                                                    </td>
                                                    <td>{item?.details}</td>
                                                    <td
                                                        className="text-warning"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            const fileUrl = item?.file;
                                                            if (fileUrl) {
                                                                window.open(fileUrl, '_blank');
                                                            }
                                                        }}>
                                                        View
                                                    </td>
                                                    <td className='text-justify'>{item?.remarks}</td>
                                                    {/* <th>Actions</th> */}
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr className="text-center">
                                            <td colSpan={5}> No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </>
                    </Container>
                </CardBody>
            </Card>
        </div>
    );
};

export default OrdersProceedingsSPD;
