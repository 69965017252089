import React, { useEffect, useState } from 'react';
import { LiaEditSolid } from 'react-icons/lia';
import { MdDelete } from "react-icons/md";
import { Button, Modal, ModalBody, ModalFooter, Table  } from 'reactstrap';
import { api } from '../../../../../../helpers/api';
import Success from '../../success';
import FormArea from './form';

export default function DataOperatorFundUtlisationMain(props) {
    const handleFields = () => {
        return {};
    };

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editIndex, setEditIndex] = useState(null);
    const [edit, setEdit] = useState(false);

    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get('adminusers'); // Assuming api.get works as expected
            const jsonData = response?.data?.data;

            const updatedUsers = jsonData?.user.map((user) => {
                if (user.role.name === 'data-operator') {
                    user.role.name = 'DATA OPERATOR';
                    user.role = 'DATA OPERATOR';
                } else if (user.role.name === 'finance-officer') {
                    user.role.name = 'FINANCE OFFICER';
                    user.role = 'FINANCE OFFICER';
                } else if (user.role.name === 'research-officer') {
                    user.role.name = 'RESEARCH OFFICER';
                    user.role = 'RESEARCH OFFICER';
                } else if (user.role.name === 'nodal-officer') {
                    user.role.name = 'NODAL OFFICER';
                    user.role = 'NODAL OFFICER';
                } else if (user.role.name === 'section-officer') {
                    user.role.name = 'SECTION OFFICER';
                    user.role = 'SECTION OFFICER';
                } else if (user.role.name === 'state-project-coordinator') {
                    user.role.name = 'STATE PROJECT COORDINATOR';
                    user.role = 'STATE PROJECT COORDINATOR';
                } else if (user.role.name === 'assistant') {
                    user.role.name = 'ASSISTANT';
                    user.role = 'ASSISTANT';
                } else if (user.role.name === 'system-admin') {
                    user.role.name = 'SYSTEM ADMIN';
                    user.role = 'SYSTEM ADMIN';
                }

                return user;
            });

            // console.log(updatedUsers);
            setUsers(updatedUsers);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);


    const handleEdit = (id) => {
        const index = users.findIndex((item) => item.id === id);
        setEditIndex(index);
        setFormData({ ...users[index] });
        setOpenForm(true);
        setEdit(true);
    };

    const [formData, setFormData] = useState({ ...handleFields() });
    const [formDatas, setFormDatas] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [success, setSuccess] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const handleSaveData = () => {
        setSuccess(true);
    };
    const handleSuccessAction = () => {
        setSuccess(false);
        fetchData();
        if (Object.keys(formData)?.length > 0) {
            const data = formDatas;
            data.push(formData);
            setFormDatas(data);
            setFormData({ ...handleFields() });
            setOpenForm(false);
            setActiveStep(1);
        } else {
            alert('Please fill the form');
        }
    };
    const handleNext = () => {
        setActiveStep(0);
    };
    const handleDelete = (id) => {
        setConfirmDelete(true);
        setDeleteItemId(id);
    };

    const confirmDeleteAction = async () => {
        try {
            await api.put(`/adminusers/delete/${deleteItemId}`);
            setUsers(users.filter(item => item.id !== deleteItemId));
            fetchData();
            setConfirmDelete(false); // Close the modal after confirming deletion
        } catch (error) {
            // console.error('Error deleting data:', error);
        }
    };
    return (
        <div>
            <>
                {!openForm && (
                    <div className="d-flex justify-content-between width">
                        <h5 className="header-title">RUSA SPD Admin Users</h5>{' '}
                        <div className="my-3">
                            <Button onClick={() => setOpenForm(true)} color="primary">
                                Create
                            </Button>
                        </div>
                    </div>
                )}
                {success && (
                    <Success title="RUSA SPD user created successfully" toggle={handleSuccessAction} open={success} />
                )}
                {openForm && (
                    <>
                        <FormArea
                            formData={formData}
                            handleNext={handleNext}
                            activeStep={activeStep}
                            setFormData={setFormData}
                            handleSaveData={handleSaveData}
                            setActiveStep={setActiveStep}
                            edit={edit}
                        />
                    </>
                )}
                {!openForm && (
                    <div className="table-section w-100 overflow-auto">
                        {/* <div className="mandatory my-3 text-dark">GOVERNMENT ORDERS OR PROCEEDINGS</div> */}
                        <Table striped>
                            <thead className="table-head">
                                <tr className="text-left">
                                    <th style={{ maxWidth: '100px' }} >Name of User</th>
                                    <th style={{ minWidth: '195px' }}>Email ID</th>
                                    <th style={{ minWidth: '175px' }}>Phone Number</th>
                                    <th style={{ minWidth: '175px' }}>Designation</th>
                                    <th style={{ minWidth: '175px' }}>User Role</th>
                                    <th style={{ minWidth: '60px' }}>Actions </th>
                                </tr>
                            </thead>
                           
                                <tbody>
                                {users?.map((item) => (
                                    <tr className="text-left">
                                        <td style={{maxWidth: '150px'}} className='capitalize'>{item.name}</td>
                                        <td style={{ maxWidth: '150px'}}>{item.email}</td>
                                        <td style={{ maxWidth: '100px' }}>{item.phone}</td>
                                        <td style={{ maxWidth: '100px' }} className='text-justify capitalize'>{item.designation}</td>
                                        <td style={{ maxWidth: '100px' }}>{item.role}</td>
                                        <td style={{ fontSize: '20px' }}>
                                        <LiaEditSolid style={{ fontSize: "30px", cursor: 'pointer' }} onClick={() => handleEdit(item.id)}/> 
                                        <MdDelete style={{ fontSize: "30px", cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleDelete(item.id)} />
                                    </td>
                                    </tr>
                                     ))}
                                </tbody>
                           
                        </Table>
                    </div>
                )}
            </>
               {/* Confirmation Modal */}
               <Modal isOpen={confirmDelete} toggle={() => setConfirmDelete(false)}>
                <ModalBody>Are you sure you want to delete this item?</ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={confirmDeleteAction}>Confirm</Button>
                    <Button color="secondary" onClick={() => setConfirmDelete(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
