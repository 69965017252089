import { Card, CardBody, Container, Table } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { getLoggedInUser } from '../../../../helpers/authUtils';
import {
    assistant_staff,
    finance_staff,
    nodal_staff,
    research_staff,
    section_staff,
    state_staff,
    system_staff,
} from '../../../../helpers/actions/api.functions';
import toast from 'react-hot-toast';
function StaffPatternInRUSASPD() {
    const [data, setData] = useState([]);
    const userData = getLoggedInUser();
    const userRole = userData?.role_id;
    // console.log(userRole);

    const getEndpoint = (id) => {
        switch (id) {
            case '9':
                return section_staff();
            case '10':
                return state_staff();
            case '7':
                return research_staff();
            case '8':
                return nodal_staff();
            case '11':
                return assistant_staff();
            case '12':
                return system_staff();
            case '6':
                return finance_staff();
            default:
                return '';
        }
    };
    const getStaffListing = (userRole) => {
        getEndpoint(userRole)
            .then((res) => {
                setData(res?.data?.data);
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
            });
    };

    useEffect(() => {
        if (userRole) {
            getStaffListing(userRole);
        }
    }, [userRole]);
    return (
        <Card>
            <CardBody>
                <Container className="form-container">
                    <h5 className="header-title mt-5">Staff Pattern in RUSA</h5>
                    
                    <>
                        <div className="table-section w-100 mt-5 ">
                            <Table striped>
                                <thead className="table-head">
                                    <tr className="text-left">
                                        <th scope="col">Name</th>
                                        <th scope="col">Designation</th>
                                        <th scope="col">Contact Number</th>
                                        <th scope="col">Email Id</th>
                                    </tr>
                                </thead>
                                {data && data?.length > 0 ? (
                                    <tbody>
                                        {data?.map((item) => (
                                            <tr className="text-left">
                                                <th scope="col">{item?.name}</th>
                                                <th scope="col">{item?.designation}</th>
                                                <th scope="col">{item?.number}</th>
                                                <th scope="col">{item?.email}</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr className="text-center">
                                            <th colSpan={4}>No Data Found</th>
                                        </tr>
                                    </tbody>
                                )}
                            </Table>
                        </div>
                    </>
                </Container>
            </CardBody>
        </Card>
    );
}

export default StaffPatternInRUSASPD;
