import apis from '../apis/api.actions';

export const list_Incubatee = () => apis.getDatas(`v1/admin/incubatee`).then((res) => res);
export const change_status = (data) => apis.createData(`v1/admin/status`, data).then((res) => res);
export const incubatee_details = (id) => apis.getDatas(`v1/admin/incubatee/${id}`).then((res) => res);
export const list_Agencies = () => apis.getDatas(`agencies`).then((res) => res);
//dataoperator
export const create_ordersProceedings = (data) => apis.createData('dataoperator/orders', data).then((res) => res);
export const list_orderProceedings = () => apis.getDatas(`dataoperator/order`).then((res) => res);
export const order_delete = (id) => apis.updateData(`dataoperator/delete/orders/${id}`).then((res) => res);
export const list_staffPattern = () => apis.getDatas(`dataoperator/staffs`).then((res) => res);
export const create_staffPattern = (data) => apis.createData('dataoperator/staff', data).then((res) => res);
export const update_staffPattern = (data, id) => apis.updateData(`dataoperator/staff/${id}`, data).then((res) => res);
export const staff_delete = (id) => apis.updateData(`dataoperator/delete/staff/${id}`).then((res) => res);
export const list_agencies = () => apis.getDatas(`dataoperator/agencies`).then((res) => res);
export const create_agency = (data) => apis.createData('dataoperator/agency', data).then((res) => res);
export const update_agency = (data, id) => apis.updateData(`dataoperator/agency/${id}`, data).then((res) => res);
export const agency_delete = (id) => apis.updateData(`dataoperator/delete/agency/${id}`).then((res) => res);
export const list_budget = () => apis.getDatas(`dataoperator/budgets`).then((res) => res);
export const create_budget = (data) => apis.createData('dataoperator/budgets', data).then((res) => res);
export const update_budget = (data, id) => apis.updateData(`dataoperator/budget/${id}`, data).then((res) => res);
export const budget_delete = (id) => apis.updateData(`dataoperator/delete/budget/${id}`).then((res) => res);
export const list_fundRecieved = () => apis.getDatas(`dataoperator/fund`).then((res) => res);
export const create_fundReceived = (data) => apis.createData('dataoperator/fund', data).then((res) => res);
export const update_fundReceived = (data, id) => apis.updateData(`dataoperator/fund/${id}`, data).then((res) => res);
export const delete_fundReceived = (id) => apis.updateData(`dataoperator/delete/fund/${id}`).then((res) => res);
export const list_fundUtilization = () => apis.getDatas(`dataoperator/fundutlisation`).then((res) => res);
export const create_fundUtilization = (data) => apis.createData('dataoperator/fundutlisation', data).then((res) => res);
export const update_fundUtilization = (data, id) =>
    apis.updateData(`dataoperator/fundutlisation/${id}`, data).then((res) => res);
export const delete_fundUtilization = (id) =>
    apis.updateData(`dataoperator/delete/fundutlisation/${id}`).then((res) => res);
export const list_pfms_completed = () => apis.getDatas(`dataoperator/pfms`).then((res) => res);
export const list_pfms_new = () => apis.getDatas(`dataoperator/new/pfms`).then((res) => res);
export const update_pfms = (data) => apis.updateData(`dataoperator/pfms`, data).then((res) => res);
export const list_fund_stage1 = () => apis.getDatas(`dataoperator/stageone`).then((res) => res);
export const create_fund_stage1 = (data) => apis.createData('dataoperator/stageone', data).then((res) => res);
export const update_fund_stage1 = (data, id) => apis.updateData(`dataoperator/stageone/${id}`, data).then((res) => res);
export const list_fund_stage2 = () => apis.getDatas(`dataoperator/stagetwo`).then((res) => res);
export const create_fund_stage2 = (data) => apis.createData('dataoperator/stagetwo', data).then((res) => res);
export const update_fund_stage2 = (data, id) => apis.updateData(`dataoperator/stagetwo/${id}`, data).then((res) => res);
export const list_fund_grant = () => apis.getDatas(`dataoperator/grant`).then((res) => res);
export const create_fund_grant = (data) => apis.createData('dataoperator/grant', data).then((res) => res);
export const update_fund_grant = (data, id) => apis.updateData(`dataoperator/grant/${id}`, data).then((res) => res);
export const staff = (data, id) => apis.updateData(`dataoperator/grant/${id}`, data).then((res) => res);

// export const list_fund_grant = () => apis.getDatas(`dataoperator/grant`).then((res) => res);


//spdAdmin
export const spd_list_orderProceedings = () => apis.getDatas(`dataoperator/orders`).then((res) => res);
export const spd_list_approve = (id) => apis.updateData(`govtorders/approve/${id}`).then((res) => res);
export const spd_list_revert = (id) => apis.updateData(`govtorders/revert/${id}`).then((res) => res);
export const spd_list_staffPattern = () => apis.getDatas(`dataoperator/staff`).then((res) => res);
export const spd_staff_approve = (id) => apis.updateData(`staffpattern/approve/${id}`).then((res) => res);
export const spd_staff_revert = (id) => apis.updateData(`staffpattern/revert/${id}`).then((res) => res);
export const spd_agency_listing = () => apis.getDatas(`dataoperator/agency`).then((res) => res);
export const spd_agency_approve = (id) => apis.updateData(`accreditedagency/approve/${id}`).then((res) => res);
export const spd_agency_revert = (id) => apis.updateData(`accreditedagency/revert/${id}`).then((res) => res);
export const spd_budget_listing = () => apis.getDatas(`dataoperator/budget`).then((res) => res);
export const spd_budget_approve = (id) => apis.updateData(`budgetdetails/approve/${id}`).then((res) => res);
export const spd_budget_revert = (id) => apis.updateData(`budgetdetails/revert/${id}`).then((res) => res);
export const spd_list_fundRecieved = () => apis.getDatas(`dataoperator/funds`).then((res) => res);
export const spd_fund_approve = (id) => apis.updateData(`fund/approve/${id}`).then((res) => res);
export const spd_fund_revert = (id) => apis.updateData(`fund/revert/${id}`).then((res) => res);
export const spd_list_fundUtilization = () => apis.getDatas(`dataoperator/fund$utilisation`).then((res) => res);
export const spd_fundUtilization_approve = (id) => apis.updateData(`fundutilisation/approve/${id}`).then((res) => res);
export const spd_ffundUtilization_revert = (id) => apis.updateData(`fundutilisation/revert/${id}`).then((res) => res);
export const spd_list_pfms = () => apis.getDatas(`dataoperator/pfms/codes`).then((res) => res);
export const spd_pms_approve = () => apis.updateData(`pfms/approve`).then((res) => res);
export const spd_pms_revert = () => apis.updateData(`pfms/revert`).then((res) => res);
export const spd_list_fund_stage1 = () => apis.getDatas(`dataoperator/equity/stageone`).then((res) => res);
export const spd_stage1_approve = (id) => apis.updateData(`stageone/approve/${id}`).then((res) => res);
export const spd_stage1_revert = (id) => apis.updateData(`stageone/revert/${id}`).then((res) => res);
export const spd_list_fund_grant = () => apis.getDatas(`dataoperator/grants`).then((res) => res);
export const spd_grant_approve = (id) => apis.updateData(`grants/approve/${id}`).then((res) => res);
export const spd_grant_revert = (id) => apis.updateData(`grants/revert/${id}`).then((res) => res);
export const spd_list_fund_stage2 = () => apis.getDatas(`dataoperator/equity/stagetwo`).then((res) => res);
export const spd_stage2_approve = (id) => apis.updateData(`stagetwo/approve/${id}`).then((res) => res);
export const spd_stage2_revert = (id) => apis.updateData(`stagetwo/revert/${id}`).then((res) => res);


//spdAdminUsers
export const nodal_orderProceedings = () => apis.getDatas(`nodal/orders`).then((res) => res);
export const section_orderProceedings = () => apis.getDatas(`section/orders`).then((res) => res);
export const research_orderProceedings = () => apis.getDatas(`research/orders`).then((res) => res);
export const state_orderProceedings = () => apis.getDatas(`state/orders`).then((res) => res);
export const finance_orderProceedings = () => apis.getDatas(`finance/orders`).then((res) => res);
export const system_orderProceedings = () => apis.getDatas(`system/orders`).then((res) => res);
export const assistant_orderProceedings = () => apis.getDatas(`assistant/orders`).then((res) => res);
    
//institutionCoordinator
export const create_pmu = (data) => apis.createData('institution/bog', data).then((res) => res);
export const update_pmu = (data, id) => apis.updateData(`institution/bog/${id}`, data).then((res) => res);
export const list_pmu = () => apis.getDatas(`co-institution-info`).then((res) => res);
export const pmu_approve = (id) => apis.updateData(`bog/approve/${id}`).then((res) => res);
export const pmu_revert = (id) => apis.updateData(`bog/revert/${id}`).then((res) => res);
export const pmu_delete = (id) => apis.updateData(`institution/delete/bog/${id}`).then((res) => res);

/////staff
export const nodal_staff = () => apis.getDatas(`nodal/staff`).then((res) => res);
export const section_staff = () => apis.getDatas(`section/staff`).then((res) => res);
export const research_staff = () => apis.getDatas(`research/staff`).then((res) => res);
export const state_staff = () => apis.getDatas(`state/staff`).then((res) => res);
export const finance_staff = () => apis.getDatas(`finance/staff`).then((res) => res);
export const system_staff = () => apis.getDatas(`system/staff`).then((res) => res);
export const assistant_staff = () => apis.getDatas(`assistant/staff`).then((res) => res);

//agency
export const nodal_agency = () => apis.getDatas(`nodal/agency`).then((res) => res);
export const section_agency = () => apis.getDatas(`section/agency`).then((res) => res);
export const research_agency = () => apis.getDatas(`research/agency`).then((res) => res);
export const state_agency = () => apis.getDatas(`state/agency`).then((res) => res);
export const finance_agency = () => apis.getDatas(`finance/agency`).then((res) => res);
export const system_agency = () => apis.getDatas(`system/agency`).then((res) => res);
export const assistant_agency = () => apis.getDatas(`assistant/agency`).then((res) => res);

//budget
export const nodal_budget = () => apis.getDatas(`nodal/budget`).then((res) => res);
export const section_budget = () => apis.getDatas(`section/budget`).then((res) => res);
export const research_budget = () => apis.getDatas(`research/budget`).then((res) => res);
export const state_budget = () => apis.getDatas(`state/budget`).then((res) => res);
export const finance_budget = () => apis.getDatas(`finance/budget`).then((res) => res);
export const system_budget = () => apis.getDatas(`system/budget`).then((res) => res);
export const assistant_budget = () => apis.getDatas(`assistant/budget`).then((res) => res);

//fund
export const nodal_fund = () => apis.getDatas(`nodal/fund`).then((res) => res);
export const section_fund = () => apis.getDatas(`section/fund`).then((res) => res);
export const research_fund = () => apis.getDatas(`research/fund`).then((res) => res);
export const state_fund = () => apis.getDatas(`state/fund`).then((res) => res);
export const finance_fund = () => apis.getDatas(`finance/fund`).then((res) => res);
export const system_fund = () => apis.getDatas(`system/fund`).then((res) => res);
export const assistant_fund = () => apis.getDatas(`assistant/fund`).then((res) => res);

//fundUtilization
export const nodal_fundUtilization = () => apis.getDatas(`nodal/fundutilisation`).then((res) => res);
export const section_fundUtilization = () => apis.getDatas(`section/fundutilisation`).then((res) => res);
export const research_fundUtilization = () => apis.getDatas(`research/fundutilisation`).then((res) => res);
export const state_fundUtilization = () => apis.getDatas(`state/fundutilisation`).then((res) => res);
export const finance_fundUtilization = () => apis.getDatas(`finance/fundutilisation`).then((res) => res);
export const system_ffundUtilization = () => apis.getDatas(`system/fundutilisation`).then((res) => res);
export const assistant_fundUtilization = () => apis.getDatas(`assistant/fundutilisation`).then((res) => res);

//pfms
export const nodal_pfms = () => apis.getDatas(`nodal/pfms`).then((res) => res);
export const section_pfms = () => apis.getDatas(`section/pfms`).then((res) => res);
export const research_pfms = () => apis.getDatas(`research/pfms`).then((res) => res);
export const state_pfms = () => apis.getDatas(`state/pfms`).then((res) => res);
export const finance_pfms = () => apis.getDatas(`finance/pfms`).then((res) => res);
export const system_pfms = () => apis.getDatas(`system/pfms`).then((res) => res);
export const assistant_pfms = () => apis.getDatas(`assistant/pfms`).then((res) => res);

//stage1
export const nodal_stage1 = () => apis.getDatas(`nodal/stageone`).then((res) => res);
export const section_stage1 = () => apis.getDatas(`section/stageone`).then((res) => res);
export const research_stage1 = () => apis.getDatas(`research/stageone`).then((res) => res);
export const state_stage1 = () => apis.getDatas(`state/stageone`).then((res) => res);
export const finance_stage1 = () => apis.getDatas(`finance/stageone`).then((res) => res);
export const system_stage1 = () => apis.getDatas(`system/stageone`).then((res) => res);
export const assistant_stage1 = () => apis.getDatas(`assistant/stageone`).then((res) => res);

//stage2
export const nodal_stage2 = () => apis.getDatas(`nodal/stagetwo`).then((res) => res);
export const section_stage2 = () => apis.getDatas(`section/stagetwo`).then((res) => res);
export const research_stage2 = () => apis.getDatas(`research/stagetwo`).then((res) => res);
export const state_stage2 = () => apis.getDatas(`state/stagetwo`).then((res) => res);
export const finance_stage2 = () => apis.getDatas(`finance/stagetwo`).then((res) => res);
export const system_stage2 = () => apis.getDatas(`system/stagetwo`).then((res) => res);
export const assistant_stage2 = () => apis.getDatas(`assistant/stagetwo`).then((res) => res);

//grant
export const nodal_grant = () => apis.getDatas(`nodal/grants`).then((res) => res);
export const section_grant = () => apis.getDatas(`section/grants`).then((res) => res);
export const research_grant = () => apis.getDatas(`research/grants`).then((res) => res);
export const state_grant = () => apis.getDatas(`state/grants`).then((res) => res);
export const finance_grant = () => apis.getDatas(`finance/grants`).then((res) => res);
export const system_grant = () => apis.getDatas(`system/grants`).then((res) => res);
export const assistant_grant = () => apis.getDatas(`assistant/grants`).then((res) => res);

//resetpassword
export const nodal_reset = () => apis.getDatas(`nodal/reset`).then((res) => res);
export const section_reset = () => apis.getDatas(`section/reset`).then((res) => res);
export const research_reset = () => apis.getDatas(`research/reset`).then((res) => res);
export const state_reset = () => apis.getDatas(`state/reset`).then((res) => res);
export const finance_reset = () => apis.getDatas(`finance/reset`).then((res) => res);
export const system_reset = () => apis.getDatas(`system/reset`).then((res) => res);
export const assistant_reset = () => apis.getDatas(`assistant/reset`).then((res) => res);
export const institution_reset = () => apis.getDatas(`institution/reset`).then((res) => res);
export const inst_head_reset = () => apis.getDatas(`inst-head/reset`).then((res) => res);
export const inst_admin_reset = () => apis.getDatas(`inst-admin/reset`).then((res) => res);
export const admin_reset = () => apis.getDatas(`admin/reset`).then((res) => res);
export const dataoperator = () => apis.getDatas(`dataoperator/reset`).then((res) => res);

//RUSA1listing
export const nodal_institution = () => apis.getDatas(`nodal/institution`).then((res) => res);
export const section_institution = () => apis.getDatas(`section/institution`).then((res) => res);
export const research_institution = () => apis.getDatas(`research/institution`).then((res) => res);
export const state_institution = () => apis.getDatas(`state/institution`).then((res) => res);
export const finance_institution = () => apis.getDatas(`finance/institution`).then((res) => res);
export const system_institution = () => apis.getDatas(`system/institution`).then((res) => res);
export const assistant_institution = () => apis.getDatas(`assistant/institution`).then((res) => res);