import React from 'react';
import { Row, Container, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useState } from 'react';
import HeadUg from './ug';
import HeadPg from './pg';
import HeadMphil from './mPhil';
import HeadPhd from './Phd';

function RUSAAcademicDetails() {
    const tabContents = [
        { id: '1', title: 'UG Course' },
        { id: '2', title: 'PG Course' },
        { id: '3', title: 'MPhil Course' },
        { id: '4', title: 'PhD Course' },
    ];
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card >
                    <CardBody>
                        <h5 className="header-title mb-3 mt-0">Academic Details</h5>

                        <>
                            <Nav className="nav nav-pills navtab-bg nav-justified">
                                {tabContents.map((tab, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === tab.id })}
                                                onClick={() => {
                                                    toggle(tab.id);
                                                }}>
                                                <i className={classnames(tab.icon, 'd-sm-none', 'd-block', 'mr-1')}></i>
                                                <span className="d-none d-sm-block">{tab.title}</span>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>

                            <TabContent>
                                {activeTab === '1' ? (
                                    <>
                                        <HeadUg />
                                    </>
                                ) : activeTab === '2' ? (
                                    <>
                                        <HeadPg />
                                    </>
                                ) : activeTab === '3' ? (
                                    <>
                                        <HeadMphil />
                                    </>
                                ) : (
                                    <>
                                        <HeadPhd />
                                    </>
                                )}
                            </TabContent>
                        </>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default RUSAAcademicDetails;
