// import React from 'react';
// import { Container, Row, Col, Button } from 'reactstrap';
// const PreviewInformation = (props) => {
//     console.log(props);
//     return (
//         <div>
//             <Button type="button" color="primary" className="edit-button mx-4 my-4 " onClick={() => props.setEdit()} >
//                 Edit
//             </Button>
//             <div className="preview-page">
//                 <Row>
//                     <Col sm={12} md={12} className="my-3">
//                         <Row className="text-dark">
//                             <Col md={4} sm={4}>
//                                 Financial Year
//                             </Col>
//                             <Col md={2} sm={2}>
//                                 :
//                             </Col>
//                             <Col md={6} sm={6}>
//                             {props.formData?.start_year} - {props.formData?.end_year}
//                             </Col>
//                         </Row>
//                     </Col>

//                     <Col sm={12} md={12} className="my-3">
//                         <Row className="text-dark">
//                             <Col md={4} sm={4}>
//                             Details of Budget
//                             </Col>
//                             <Col md={2} sm={2}>
//                                 :
//                             </Col>
//                             <Col md={6} sm={6}>
//                                 {props.formData?.RUSA_PHASE}
//                             </Col>
//                         </Row>
//                     </Col>

//                     <Col sm={12} md={12} className="my-3">
//                         <Row className="text-dark">
//                             <Col md={4} sm={4}>
//                             Head of Account
//                             </Col>
//                             <Col md={2} sm={2}>
//                                 :
//                             </Col>
//                             <Col md={6} sm={6}>
//                                 {props.formData?.TOTAL_CENTRAL_SHARE}
//                             </Col>
//                         </Row>
//                     </Col>
//                     <Col sm={12} md={12} className="my-3">
//                         <Row className="text-dark">
//                             <Col md={4} sm={4}>
//                             Total Outlay
//                             </Col>
//                             <Col md={2} sm={2}>
//                                 :
//                             </Col>
//                             <Col md={6} sm={6}>
//                                 {props.formData?.TOTAL_STATE_SHARE}
//                             </Col>
//                         </Row>
//                     </Col>

//                     <Col sm={12} md={12} className="my-3">
//                         <Row className="text-dark">
//                             <Col md={4} sm={4}>
//                                 Remarks
//                             </Col>
//                             <Col md={2} sm={2}>
//                                 :
//                             </Col>
//                             <Col md={6} sm={6}>
//                                 {props.formData?.REMARKS}
//                             </Col>
//                         </Row>
//                     </Col>
//                     <Col sm={12} md={12} className="my-3">
//                     <Button color="primary" onClick={() => props.handleSaveData()}>
//                         Submit for Approval
//                     </Button>
//                     </Col>

//                 </Row>
//             </div>
//         </div>
//     );
// };

// export default PreviewInformation;
import React from 'react';
import { useState } from 'react';
import { Edit } from 'react-feather';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { MANAGE_LABELS } from '../datamanager';

// const PreviewInformation = (props) => {
//     const{setActiveStep,manageForm}= props

//   return (
//     <div className="view">

//             <div className="edit">
//                 <Button color="primary" className="px-3" onClick={() => manageForm()}>
//                     Edit
//                 </Button>
//             </div>

//                 <Table striped >
//                     <thead className="table-head">
//                         <tr>

//                             <th>Financial Year</th>
//                             <th>Details of Budget </th>
//                             <th>Head of Account </th>
//                             <th>Total Outlay</th>
//                             <th>Remarks</th>
//                             <th></th>
//                             <th></th>
//                         </tr>
//                     </thead>
//                     {props.faculityData2?.map((item, k) => (
//                         <tbody>
//                             <tr>

//                                 <td>{item[MANAGE_LABELS.START_YEAR] ?? '--'}-{item[MANAGE_LABELS.END_YEAR] ?? '--'}</td>
//                                 <td>{item[MANAGE_LABELS.DETAILS] ?? '--'}</td>
//                                 <td>{item[MANAGE_LABELS.HEAD] ?? '--'}</td>
//                                 <td>{item[MANAGE_LABELS.OUTLAY] ?? '--'}</td>
//                                 <td>{item[MANAGE_LABELS.REMARKS] ?? '--'}</td>
//                                 <td></td>
//                                 <td></td>
//                             </tr>
//                         </tbody>
//                     ))}
//                 </Table>
//                 <div className="">
//                     <Button color="primary" className="px-3 my-3" onClick={() => props.setSuccess(true)}>
//                         Submit for approval
//                     </Button>
//                 </div>

//         </div>
//   )
// }
const PreviewInformation = (props) => {
    const { setActiveStep, manageForm } = props;

    return (
        <div className="view">
            <div className="edit">
                <Button color="primary" className="px-3 mt-2" onClick={() => manageForm()}>
                    Edit
                </Button>
            </div>

            <Table striped>
                <thead className="table-head">
                    <tr>
                        <th>Financial Year</th>
                        <th>Details of Budget</th>
                        <th>Head of Account</th>
                        <th>Total Outlay</th>

                        <th>Remarks</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                {props.faculityData2?.map((item, k) => (
                    <tbody>
                        <tr>
                            <td>
                                {item[MANAGE_LABELS.START_YEAR] ?? '--'}-
                                {Number(item[MANAGE_LABELS.START_YEAR]) + 1 ?? '--'}
                            </td>
                            <td>{item[MANAGE_LABELS.DETAILS] ?? '--'}</td>
                            <td>{item[MANAGE_LABELS.HEAD] ?? '--'}</td>
                            <td>{item[MANAGE_LABELS.OUTLAY] ?? '--'}</td>

                            <td>{item[MANAGE_LABELS.REMARKS] ?? '--'}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                ))}
            </Table>
            <div className="">
                <Button
                    color="primary"
                    className="px-3 my-3"
                    onClick={() => {
                        // console.log(props?.faculityData2)
                        props.createBudget(props?.faculityData2);
                        
                        props.setSuccess(true);
                    }}>
                    Submit for approval
                </Button>
            </div>
        </div>
    );
};

export default PreviewInformation;
