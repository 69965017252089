import React from 'react';
import { Table } from 'reactstrap';
import { useState } from 'react';
import { Container, Input, Button, Row, Form, FormGroup } from 'reactstrap';
import { MinusCircle, PlusCircle } from 'react-feather';
import { MANAGE_LABELS } from './datamanager';
import Success from '../../../../../../pages/apps/success';
import PreviewInformation from '../preview';

function Index(props) {
    const Faculityfields2 = {
        [MANAGE_LABELS.year]: null,
        [MANAGE_LABELS.agency]: null,
        [MANAGE_LABELS.details]: null,
        [MANAGE_LABELS.remarks]: null,
    };
    // console.log(props);

    const [faculityData2, SetFaculityData2] = useState(props.faculityData2);

    const [keyField2, SetKeyField2] = useState(0);
    const [success, setSuccess] = useState(false);
    const [activeStep, setActiveStep] = useState(1);

    // console.log(faculityData2);

    // ----------------------------------------------------------
    const faculityTableChange2 = (name, value, index) => {
        let Field2 = faculityData2;
        Field2[index][name] = value;
        SetFaculityData2(Field2);
        SetKeyField2(keyField2 + 1);
    };
    const removeFaculityField2 = (j) => {
        let Field2 = faculityData2.filter((item, N) => j !== N);
        // console.log(Field2);
        SetFaculityData2(Field2);
        SetKeyField2(keyField2 + 1);
    };
    const onAddFaculityFields2 = () => {
        let Field2 = faculityData2;
        Field2.push(Faculityfields2);
        SetFaculityData2(Field2);
        SetKeyField2(keyField2 + 1);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const error = validate();

        // console.log(error);
        if (Object.keys(error).length === 0) {
            setSuccess(true);
        }
    };
    const validate = () => {
        let error = {};
        // console.log('test');
        // faculityData.forEach((item, k) => {
        //     Object.keys(item).map((key) => {
        //         if (!item[key] || item[key] === '') {
        //             error[`${k}_${key}`] = 'please fill this field';
        //         }
        //     });
        // });
// console.log(faculityData2)
        faculityData2.forEach((item, k) => {
            Object.keys(item).map((key) => {
                if (!item[key] || item[key] === '' || item[key] === null) {
                    error[`${k}_${key}`] = 'please fill this field';
                }
            });
        });
        // Object.keys(formData).map((key) => {
        //     if (!formData[key] || formData[key] === '') {
        //         error[key] = 'please fill this field';
        //     }
        // });
        // console.log(error)
        return error;
    };
    const handleSuccessAction = (e) => {
        props.onSubmit({ faculityData2: faculityData2 })
        props.fetchListing()
    };
    const handleNext = () => {
        // console.log('1');
        validate();
        setActiveStep(2);
    };
    return (
        <div>
            {success && (
                <Success
                    title="Accredited Agency Details are submitted for approval by the RUSA Admin"
                    toggle={handleSuccessAction}
                    open={success}
                />
            )}
            <Container className="form-container p-0 mt-0">
                {activeStep === 1 ? (
                    <>
                        <Form className="form-style mt-5" onSubmit={() => handleNext()}>
                            <Table>
                                <thead>
                                    <tr></tr>
                                </thead>
                                <thead
                                    style={{
                                        fontSize: '13px',
                                        backgroundColor: '#3b6eb9',
                                        color: '#fff',
                                        padding: '7px',
                                        marginTop: '15px',
                                        borderRadius: '5px',
                                    }}>
                                    <tr className="text-left">
                                        <th>Year</th>
                                        <th>Accredited Agency Name </th>
                                        <th>Details</th>
                                        <th>Remarks</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className=" text-center" style={{ fontSize: '13px' }}>
                                    <>
                                        {faculityData2.map((item, j) => {
                                            // console.log(item);
                                            return (
                                                <>
                                                    <tr>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                value={item[MANAGE_LABELS.year] ?? ''}
                                                                name={MANAGE_LABELS.year}
                                                                required
                                                                maxLength={4}
                                                                onChange={(e) =>{
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value.replace(/[^0-9+]/g, ''),
                                                                        j
                                                                    )
                                                                }}></Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                value={item[MANAGE_LABELS.agency] ?? ''}
                                                                name={MANAGE_LABELS.agency}
                                                                required
                                                                onChange={(e) =>
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value,
                                                                        j
                                                                    )
                                                                }></Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                value={item[MANAGE_LABELS.details] ?? ''}
                                                                name={MANAGE_LABELS.details}
                                                                required
                                                                onChange={(e) =>
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value,
                                                                        j
                                                                    )
                                                                }></Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                value={item[MANAGE_LABELS.remarks] ?? ''}
                                                                name={MANAGE_LABELS.remarks}
                                                                required
                                                                onChange={(e) =>
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value,
                                                                        j
                                                                    )
                                                                }></Input>
                                                        </td>
                                                        {!props?.newEdit && (
                                                            <td>
                                                                <div className="d-flex">
                                                                    {j === faculityData2.length - 1 && (
                                                                        <div className="pr-2">
                                                                            <PlusCircle
                                                                                onClick={(e) => onAddFaculityFields2()}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {j !== 0 && (
                                                                        <div className="pr-2">
                                                                            <MinusCircle
                                                                                onClick={(e) => removeFaculityField2(j)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        )}
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    </>
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-start mt-4 mx-2">
                                <Row>
                                    <Button type="submit" color="primary"  outline>
                                        Preview
                                    </Button>
                                </Row>
                            </div>
                        </Form>
                    </>
                ) : (
                    <>
                        <PreviewInformation
                            faculityData2={faculityData2}
                            setSuccess={setSuccess}
                            setActiveStep={setActiveStep}
                            
                        />
                    </>
                )}
            </Container>
        </div>
    );
}

export default Index;
