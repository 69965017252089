import React, { useState } from 'react';
import { Plus } from 'react-feather';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Success from '../../../../success';
import PreviewInformation from '../preview';
import { MANAGE_LABELS } from './datamanager';

function ManangeGeneralaInformation(props) {
    const [keyField, setKeyField] = useState(0);
    const [formData, setFormData] = useState(props.data);
    const [flag, setFlag] = useState(props.flag);
    const [status, setStatus] = useState(props.status);
    
    const [activeStep, setActiveStep] = useState(1);
    const [edit, setEdit] = useState(props.edit);
    // const [primaryStep, setPrimaryStep] = useState(props.primaryStep);
    // console.log('2', props.primaryStep);
    // console.log(props.data);

    const fields = {
        [MANAGE_LABELS.START_YEAR]: null,
        [MANAGE_LABELS.END_YEAR]: null,
        [MANAGE_LABELS.RUSA_PHASE]: null,
        [MANAGE_LABELS.COMPONENT]: null,
        [MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED]: null,
        [MANAGE_LABELS.TOTAL_STATE_RECEIVED]: null,
        [MANAGE_LABELS.TOTAL_FUND_RECEIVED]: null,
        [MANAGE_LABELS.REMARKS]: null,
    };

    const handleSubmit = () => {
        const error = validate();
        // console.log(error);
        if (Object.keys(error).length === 0) {
            setActiveStep(2);
        } else {
            // console.log('thiruth');
        }
    };
    // const handleSubmit = () => {
    //     setActiveStep(2);
    // };
    // const handleSuccessAction = (e) => {
    //     // props.onSubmit({ data: formData, flag: flag, status: status });

    //     props.handleCallBack();
    // };
    const handleChange = (name, value, ke) => {
        // console.log(name);
        let d = formData;
        d.forEach((item, ind) => {
            if (ind === ke) {
                item[name] = value;
                if (name === 'START_YEAR') {
                    item['END_YEAR'] = String(Number(value) + 1);
                }
            }
        });

        setFormData(d);
        // console.log(formData);
        setKeyField((f) => f + 1);
    };
    const onAddFields = () => {
        let d = formData;
        d.push(fields);

        setFormData(d);
        setKeyField((j) => j + 1);
    };
    const removeField = (ke) => {
        let d = formData.filter((m, i) => i !== ke);

        setFormData(d);
        setKeyField((j) => j + 1);
    };
    const validate = () => {
        // console.log('hi');
        delete formData.undefined;
        let error = {};
        if (flag === 'Y') {
            formData.forEach((item, k) => {
                Object.keys(item).forEach((key) => {
                    // console.log(key);
                    if (key != 'REMARKS' && key != 'TOTAL_FUND_RECEIVED') {
                        if (!item[key] || item[key] === '') {
                            error[`${k}_${key}`] = 'please fill this field';
                        }
                    }
                    // if ((!key !== 'REMARKS' && key != 'TOTAL_FUND_RECEIVED') && (!item[key] || item[key] === '')) {
                    //     error[`${k}_${key}`] = 'please fill this field';
                    // }
                });
            });
        }

        return error;
    };

    const manageEdit = () => {
        setEdit(true);
        setActiveStep(1);
    };
    // console.log(formData);
    // console.log('success', success);
    // console.log('active step', activeStep);
    return (
        <>
           
            <div className="manage">
                <div className="form-section">
                    <Container className="form-container ms-2">
                        <Form className="form-style">
                            {activeStep === 1 ? (
                                <>
                                    <>
                                        {formData?.map((item, ke) => (
                                            <Row key={ke} className="repeat-box pt-4">
                                                <Col sm={12} md={4}>
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.START_YEAR}>
                                                            Financial Year (yyyy-yyyy)
                                                        </Label>
                                                        <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                                        <div className="d-flex justify-content-between">
                                                            <Input
                                                                autoComplete="off"
                                                                name={MANAGE_LABELS.START_YEAR}
                                                                value={item[MANAGE_LABELS.START_YEAR] ?? ''}
                                                                required
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        e.target.name,
                                                                        e.target.value.replace(/[^0-9.]/g, ''),
                                                                        ke
                                                                    )
                                                                }
                                                                type="text"
                                                                maxLength={4}
                                                                placeholder="Start Year(YYYY)"></Input>
                                                            <span className="bold m-2">-</span>
                                                            <Input
                                                                autoComplete="off"
                                                                required
                                                                disabled
                                                                name={MANAGE_LABELS.END_YEAR}
                                                                value={
                                                                    item[MANAGE_LABELS.START_YEAR]
                                                                        ? Number(item[MANAGE_LABELS.START_YEAR]) + 1
                                                                        : ''
                                                                }
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        e.target.name,
                                                                        e.target.value.replace(/[^0-9.]/g, ''),
                                                                        ke
                                                                    )
                                                                }
                                                                placeholder="End Year(YYYY)"
                                                                type="text"
                                                                maxLength={4}></Input>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={4}>
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.RUSA_PHASE}>RUSA Phase</Label>
                                                        <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                                        <Input
                                                            id={MANAGE_LABELS.RUSA_PHASE}
                                                            onChange={(e) =>
                                                                handleChange(e.target.name, e.target.value, ke)
                                                            }
                                                            value={item[MANAGE_LABELS.RUSA_PHASE] ?? ''}
                                                            name={MANAGE_LABELS.RUSA_PHASE}
                                                            placeholder=""
                                                            // required
                                                            type="select">
                                                            <option value="">-select-</option>
                                                            <option value="RUSA 1">RUSA 1</option>
                                                            <option value="RUSA 2">RUSA 2</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={4}>
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.COMPONENT}>Component Name</Label>
                                                        <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                                        <Input
                                                            id={MANAGE_LABELS.COMPONENT}
                                                            onChange={(e) =>
                                                                handleChange(e.target.name, e.target.value, ke)
                                                            }
                                                            value={item[MANAGE_LABELS.COMPONENT] ?? ''}
                                                            name={MANAGE_LABELS.COMPONENT}
                                                            placeholder=""
                                                            required
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED}>
                                                            Total Central Share Received
                                                        </Label>
                                                        <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                                        <Input
                                                            id={MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED}
                                                            name={MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED}
                                                            required
                                                            value={item[MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED] ?? ''}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    e.target.name,
                                                                    e.target.value.replace(/[^0-9.]/g, ''),
                                                                    ke
                                                                )
                                                            }
                                                            type="text"
                                                            maxLength={10}></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.TOTAL_STATE_RECEIVED}>
                                                            Total State Share Received
                                                        </Label>
                                                        <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                                        <Input
                                                            id={MANAGE_LABELS.TOTAL_STATE_RECEIVED}
                                                            name={MANAGE_LABELS.TOTAL_STATE_RECEIVED}
                                                            required
                                                            value={item[MANAGE_LABELS.TOTAL_STATE_RECEIVED] ?? ''}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    e.target.name,
                                                                    e.target.value.replace(/[^0-9.]/g, ''),
                                                                    ke
                                                                )
                                                            }
                                                            type="text"
                                                            maxLength={10}></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.TOTAL_FUND_RECEIVED}>
                                                            Total Fund Received
                                                        </Label>
                                                        <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                                        <Input
                                                            id={MANAGE_LABELS.TOTAL_FUND_RECEIVED}
                                                            name={MANAGE_LABELS.TOTAL_FUND_RECEIVED}
                                                            disabled
                                                            value={
                                                                item[MANAGE_LABELS.TOTAL_STATE_RECEIVED] &&
                                                                item[MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED]
                                                                    ? Number(item[MANAGE_LABELS.TOTAL_STATE_RECEIVED]) +
                                                                      Number(item[MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED])
                                                                    : ''
                                                            }
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    e.target.name,
                                                                    String(
                                                                        Number(
                                                                            item[MANAGE_LABELS.TOTAL_STATE_RECEIVED]
                                                                        ) +
                                                                            Number(
                                                                                item[
                                                                                    MANAGE_LABELS.TOTAL_CENTRAL_RECEIVED
                                                                                ]
                                                                            )
                                                                    ),
                                                                    ke
                                                                )
                                                            }
                                                            type="text"
                                                            maxLength={10}></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.REMARKS}>Remarks</Label>
                                                        <Input
                                                            id={MANAGE_LABELS.REMARKS}
                                                            name={MANAGE_LABELS.REMARKS}
                                                            value={item[MANAGE_LABELS.REMARKS] ?? ''}
                                                            onChange={(e) =>
                                                                handleChange(e.target.name, e.target.value, ke)
                                                            }
                                                            type="text"></Input>
                                                    </FormGroup>
                                                </Col>

                                                {!props.edit && (
                                                    <Col xs={12} className="py-4">
                                                        {ke === formData.length - 1 && (
                                                            <Button color="primary" onClick={(e) => onAddFields()}>
                                                                Add Details <Plus />
                                                            </Button>
                                                        )}
                                                        {ke !== 0 && (
                                                            <Button
                                                                color="none"
                                                                onClick={(e) => removeField(ke)}
                                                                className="text-danger">
                                                                Remove
                                                            </Button>
                                                        )}
                                                    </Col>
                                                )}
                                            </Row>
                                        ))}
                                        <Row>
                                            <Col className="p-0">
                                                {' '}
                                                {activeStep === 1 ? (
                                                    <Button
                                                        type="button"
                                                        className="my-2"
                                                        color="primary"
                                                        outline
                                                        onClick={handleSubmit}>
                                                        Preview
                                                    </Button>
                                                ) : (
                                                    ''
                                                )}
                                            </Col>
                                        </Row>
                                    </>
                                </>
                            ) : (
                                <>
                                    <PreviewInformation
                                        setEdit={manageEdit}
                                        data={formData}
                                        flag={flag}
                                        handleSubmit={handleSubmit}
                                        handleCallBack={props.handleCallBack}
                                        // setSuccess={setSuccess}
                                        primaryStep={props.primaryStep}
                                        setPrimaryStep={props.setPrimaryStep}
                                        edit={edit}
                                        createFund={props.createFund}
                                    />
                                </>
                            )}
                        </Form>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ManangeGeneralaInformation;
