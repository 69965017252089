import React, { useEffect, useState } from 'react';
import { MinusCircle, PlusCircle } from 'react-feather';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Success from '../../../../success';
import PreviewInformation from '../preview';
import { MANAGE_LABELS } from './datamanager';
import { create_fund_stage1, list_Agencies, update_fund_stage1 } from '../../../../../../helpers/actions/api.functions';
import toast from 'react-hot-toast';
function ManangeGeneralaInformation(props) {
    const [keyField, setKeyField] = useState(0);
    const [formData, setFormData] = useState(props.data);
    const [flag, setFlag] = useState(props.flag);
    const [status, setStatus] = useState(props.status);
    const [remark, setRemark] = useState(props.remark);
    const [success, setSuccess] = useState(false);
    const [activeStep, setActiveStep] = useState(props.activeStep);
    const [data, setData] = useState(formData?.institutions ?? [{ id: 1 }]);
    const [agencies, setAgencies] = useState([]);

    const fields = {
        [MANAGE_LABELS.TOTAL_COST]: null,
        [MANAGE_LABELS.AGREED_COST]: null,
        [MANAGE_LABELS.AGENCY_NAME]: null,
        [MANAGE_LABELS.INSTITUTION_NAME]: null,
        [MANAGE_LABELS.APPROVED_COST]: null,
        [MANAGE_LABELS.AMOUNT_UTILISED]: null,
        [MANAGE_LABELS.AMOUNT_UTILISED]: null,
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const error = validate();
        // console.log(error);
        if (Object.keys(error).length === 0) {
            if (activeStep === 1) {
                props.setActiveStep(2);
            } else {
                handleCreate();
                // setSuccess(true);
            }
        }
    };
    const generateInstitutions = (data) => {
        const sendInst = [];
        data.map((item) => {
            let inst = {
                name: item?.INSTITUTION_NAME,
                approved: item?.APPROVED_COST,
                utilised: item?.AMOUNT_UTILISED,
                percentage: (item?.AMOUNT_UTILISED / item?.APPROVED_COST) * 1000,
            };
            sendInst.push(inst);
        });
        return sendInst;
    };

    const handleCreate = () => {
        // console.log(data, formData)
        const sendData = {
            cost: formData.TOTAL_COST,
            amount: formData.AGREED_COST,
            agency: formData.AGENCY_NAME,
            institutions: generateInstitutions(data),
        };
        // console.log(sendData);
        if (!props?.edit) {
            create_fund_stage1(sendData)
                .then((res) => {
                    setSuccess(true);
                })
                .catch((err) => {
                    toast.error('Something Went Wrong');
                });
        } else {
            update_fund_stage1(sendData, formData?.id)
                .then((res) => {
                    setSuccess(true);
                })
                .catch((err) => {
                    toast.error('Something Went Wrong');
                });
        }
    };
    // const handleNext= () => {
    //     props.setActiveStep(2);
    // }
    useEffect(() => {
        setActiveStep(props.activeStep);
    }, [props.activeStep]);
    const handleSuccessAction = (e) => {
        props.onSubmit({ data: { ...formData, fundingDetails: data }, flag: flag, status: status, remark: remark });
        props.fetchData();
    };

    const handleChange = (name, value) => {
        // console.log(name);
        setFormData((k) => ({ ...k, [name]: value }));

        setKeyField((f) => f + 1);
    };

    const validate = () => {
        let error = {};
        if (flag === 'Y')
            Object.keys(formData).map((key) => {
                if (!formData[key] || formData[key] === '') {
                    error[`${key}`] = 'please fill this field';
                }
            });

        return error;
    };
    // console.log(formData);
    // console.log(data);

    const fetchAgencies = () => {
        list_Agencies()
            .then((res) => {
                setAgencies(res?.data?.data);
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };
    useEffect(() => {
        fetchAgencies();
    }, []);
    // ----------
    // State to manage table data

    // Function to handle adding a new row
    const addRow = () => {
        const newRow = { id: Date.now() };
        setData([...data, newRow]);
    };

    // Function to handle removing a row
    const removeRow = (idToRemove) => {
        // Ensure that there is more than one row before removing
        if (data.length > 1) {
            const updatedData = data.filter((row) => row.id !== idToRemove);
            setData(updatedData);
        } else {
            // Handle the case where there is only one row (e.g., show a message)
            // console.log('Cannot remove the only row');
        }
    };

    // Function to handle input changes
    const handleInputChange = (id, field, value) => {
        // console.log(id, field, value);
        const updatedData = data.map((row) => (row.id === id ? { ...row, [field]: value } : row));
        // console.log(updatedData);
        setData(updatedData);
    };

    // console.log(data);

    const calcPercent = () => {
        const NewDt = [...data];
        data.map((item, i) => {
            if (item?.APPROVED_COST && item?.AMOUNT_UTILISED) {
                NewDt[i].PERCENTAGE_UTILISED = (item?.AMOUNT_UTILISED / item?.APPROVED_COST) * 100;
                setData(NewDt);
            }
        });
    };
    // useEffect(() => {
    //     calcPercent();
    // }, [data]);

    // ----------

    return (
        <>
            {success && (
                <Success
                    title="Equity Initiative utilisation stage 1 details are submitted successfully for approval by the RUSA Admin"
                    toggle={handleSuccessAction}
                    open={success}
                />
            )}

            <div className="manage">
                <div className="form-section">
                    <Form className="form-style" onSubmit={(e) => handleSubmit(e)}>
                        {activeStep === 1 && (
                            <>
                                <>
                                    <Container>
                                        <Row className="repeat-box ">
                                            <Col sm={6} md={6}>
                                                <FormGroup className="mt-2">
                                                    <Label className="label">
                                                        Total Project Cost{' '}
                                                        <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                    </Label>
                                                    <Input
                                                        id={MANAGE_LABELS.TOTAL_COST}
                                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                        value={formData[MANAGE_LABELS.TOTAL_COST] ?? ''}
                                                        name={MANAGE_LABELS.TOTAL_COST}
                                                        placeholder=""
                                                        required
                                                        type="number"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6} md={6}>
                                                <FormGroup className="mt-2">
                                                    <Label className="label">
                                                        Agreed probable amount of Contract{' '}
                                                        <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                    </Label>
                                                    <Input
                                                        id={MANAGE_LABELS.AGREED_COST}
                                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                        value={formData[MANAGE_LABELS.AGREED_COST] ?? ''}
                                                        name={MANAGE_LABELS.AGREED_COST}
                                                        placeholder=""
                                                        required
                                                        type="number"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6} md={6}>
                                                <FormGroup>
                                                    <Label className="label">
                                                        Agency Name{' '}
                                                        <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                    </Label>
                                                    {/* <Input
                                                        id={MANAGE_LABELS.AGENCY_NAME}
                                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                        value={formData[MANAGE_LABELS.AGENCY_NAME] ?? ''}
                                                        name={MANAGE_LABELS.AGENCY_NAME}
                                                        placeholder=""
                                                        required
                                                        type="text"
                                                    /> */}
                                                    <select
                                                        autoComplete="off"
                                                        required
                                                        id={MANAGE_LABELS.AGENCY_NAME}
                                                        name={MANAGE_LABELS.AGENCY_NAME}
                                                        className="form-control"
                                                        value={formData[MANAGE_LABELS.AGENCY_NAME] ?? ''}
                                                        onChange={(e) => handleChange(e.target.name, e.target.value)}>
                                                        <option value="">Select Agency</option>
                                                        {agencies.map((item, i) => (
                                                            <option key={i} value={item?.agency}>
                                                                {item?.agency}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <FormGroup
                                                    style={{
                                                        backgroundColor: '#3b6eb9',
                                                        color: '#fff',
                                                        padding: '7px',
                                                        marginTop: '15px',
                                                        borderRadius: '5px',
                                                    }}>
                                                    <div>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Institution Name{' '}
                                                                        <sup style={{ color: 'red', fontSize: '13px' }}>
                                                                            *
                                                                        </sup>{' '}
                                                                    </th>
                                                                    <th>
                                                                        Approved Cost{' '}
                                                                        <sup style={{ color: 'red', fontSize: '13px' }}>
                                                                            *
                                                                        </sup>{' '}
                                                                    </th>
                                                                    <th>
                                                                        Amount Utilised{' '}
                                                                        <sup style={{ color: 'red', fontSize: '13px' }}>
                                                                            *
                                                                        </sup>{' '}
                                                                    </th>
                                                                    <th>
                                                                        % of Utilisation{' '}
                                                                        <sup style={{ color: 'red', fontSize: '13px' }}>
                                                                            *
                                                                        </sup>{' '}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((row, i) => (
                                                                    <tr key={row.id}>
                                                                        <td className="w-25">
                                                                            <Input
                                                                                type="text"
                                                                                required
                                                                                value={
                                                                                    row[
                                                                                        MANAGE_LABELS.INSTITUTION_NAME
                                                                                    ] ?? ''
                                                                                }
                                                                                className='text-black'
                                                                                id={MANAGE_LABELS.INSTITUTION_NAME}
                                                                                name={MANAGE_LABELS.INSTITUTION_NAME}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row.id,
                                                                                        MANAGE_LABELS.INSTITUTION_NAME,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className="w-25">
                                                                            <Input
                                                                                type="number"
                                                                                required
                                                                                value={
                                                                                    row[MANAGE_LABELS.APPROVED_COST] ??
                                                                                    ''
                                                                                }
                                                                                id={MANAGE_LABELS.APPROVED_COST}
                                                                                name={MANAGE_LABELS.APPROVED_COST}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row.id,
                                                                                        MANAGE_LABELS.APPROVED_COST,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className="w-25">
                                                                            <Input
                                                                                type="number"
                                                                                required
                                                                                value={
                                                                                    row[
                                                                                        MANAGE_LABELS.AMOUNT_UTILISED
                                                                                    ] ?? ''
                                                                                }
                                                                                id={MANAGE_LABELS.AMOUNT_UTILISED}
                                                                                name={MANAGE_LABELS.AMOUNT_UTILISED}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row.id,
                                                                                        MANAGE_LABELS.AMOUNT_UTILISED,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className="w-25">
                                                                            <Input
                                                                                type="number"
                                                                                required
                                                                                value={
                                                                                    row[
                                                                                        MANAGE_LABELS.AMOUNT_UTILISED
                                                                                    ] &&
                                                                                    row[MANAGE_LABELS.APPROVED_COST]
                                                                                        ? (
                                                                                              (row[
                                                                                                  MANAGE_LABELS
                                                                                                      .AMOUNT_UTILISED
                                                                                              ] /
                                                                                                  row[
                                                                                                      MANAGE_LABELS
                                                                                                          .APPROVED_COST
                                                                                                  ]) *
                                                                                              100
                                                                                          ).toFixed(2)
                                                                                        : ''
                                                                                }
                                                                                id={MANAGE_LABELS.PERCENTAGE_UTILISED}
                                                                                name={MANAGE_LABELS.PERCENTAGE_UTILISED}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row.id,
                                                                                        MANAGE_LABELS.PERCENTAGE_UTILISED,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className='d-flex'>
                                                                            {i === data.length - 1 && (
                                                                                <PlusCircle onClick={addRow} />
                                                                            )}
                                                                            {i !== 0 && (
                                                                                <MinusCircle
                                                                                    onClick={() => removeRow(row.id)}
                                                                                />
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Container>
                                </>
                            </>
                        )}
                        {activeStep === 2 && (
                            <PreviewInformation
                                setEdit={() => props.setActiveStep(1)}
                                data={{ ...formData, fundingDetails: data }}
                                flag={flag}
                                status={status}
                                remark={remark}
                                handleSubmit={handleSubmit}
                                fetchData={props?.fetchData}
                            />
                        )}
                        <div className="">
                            <Row className="mt-2 ">
                                <Col
                                    className={`d-flex justify-content-between mt-4 ${
                                        activeStep === 1 ? 'px-2' : 'px-2'
                                    }`}>
                                    <Button type="submit" color="primary" outline>
                                        {activeStep === 1 ? 'Preview' : 'Submit for Approval'}
                                    </Button>
                                </Col>
                            </Row>
                            {/* <Row>
                                {activeStep === 1 && (
                                    <Col className="d-flex justify-content-between mt-4">
                                        <Button type="submit" color="primary" outline onClick={handleSubmit}>
                                            Preview
                                        </Button>
                                    </Col>
                                )}
                            </Row> */}
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default ManangeGeneralaInformation;
