export const getErrorMessage = (error) => {
//   console.log(error, typeof error, Array.isArray(error));

  if (typeof error === 'string') {
      return error;
  }

  if (error && typeof error === 'object') {
    //   console.log('1');
      if (Array.isArray(error)) {
          return String(error[0]);
      } else {
          // Handle non-array objects
          const errorMessage = Object.keys(error).map((item) => {
              if (Array.isArray(error[item])) {
                //   console.log(String(error[item][0]));
                  return String(error[item][0]);
              } else {
                //   console.log('test else');
                  return `${error[item]}`;
              }
          });
          // Join array of error messages into a single string
          return errorMessage.join(', ');
      }
  }

  if (error?.message) {
    //   console.log(error.message);
      if (Array.isArray(error.message)) {
          return error.message.join(', ');
      } else {
          return error.message;
      }
  }

  return 'An unexpected error occurred';
};
