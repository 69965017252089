import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { api } from '../../../../../../../helpers/api';
import Failure from '../../../../../institutions/failure';

function BestPractices(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [revert, setRevert] = useState(false);
  
    const fetchData = async () => {
        try {
            const response = await api.get(`institution/${id}`);
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.best_practice);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`/best-practice/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setGeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };

    return (
        <div>
            {revert && (
                <Failure
                    title="Best Practices details has been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="view">
                <Container className="p-0">
                    <Card>
                        <CardBody className="px-5 pb-5 pt-4">
                            <h5 className="header-title mb-3 mt-0">Best Practices</h5>
                            <div className="table-section w-100 ">
                                <Table striped>
                                    <thead className="table-head">
                                        <tr className="text-left">
                                            <th className="w-25">Describe the Best Practices</th>
                                            <th className="w-75">Remarks</th>
                                            <th className="w-25">Status</th>
                                            <th className="w-25">Actions</th>
                                        </tr>
                                    </thead>
                                    {generalDetails?.length > 0 ? (
                                        <tbody>
                                            {generalDetails.map((item, index) => (
                                                <tr key={index} className="text-left">
                                                    <>
                                                        <td className='text-justify'>{item.best_practices}</td>
                                                        <td className="text-justify">{item.remarks}</td>
                                                        <td>
                                                        {item.is_approved === 0 ? (
                                                            <td>
                                                                <Button
                                                                    disabled
                                                                    color="warning"
                                                                    class="btn btn-secondary"
                                                                    style={{
                                                                        color: '#ED7D31',
                                                                        fontWeight: '400',
                                                                        backgroundColor: '#F5F7F8',
                                                                    }}
                                                                    size="sm">
                                                                    Pending
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 2 ? (
                                                            <td>
                                                                <Button disabled color="danger" size="sm">
                                                                    Reverted by Head
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 1 ? (
                                                            <td>
                                                                <Button disabled color="success" size="sm">
                                                                    Approved by Head
                                                                </Button>
                                                            </td>
                                                        ) : 
                                                            item.is_approved === 3 && (
                                                                <td>
                                                                    <Button type="button" size="sm" color="info" disabled>
                                                                        Reverted by RUSA Admin
                                                                    </Button>
                                                                </td>
                                                            )
                                                        }
                                                        </td>
                                                        <td>
                                                            {item.is_approved === 1 && (
                                                                <td>
                                                                    <Button
                                                                        type="button"
                                                                        size="sm"
                                                                        outline
                                                                        color="primary"
                                                                        onClick={() => handleRevert(item.id)}
                                                                    >  
                                                                        Revert
                                                                    </Button>
                                                                </td>
                                                            )}
                                                        </td>
                                                    </>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    No data Found
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </div>
    );
}

export default BestPractices;
