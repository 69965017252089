import classnames from 'classnames';
import React, { useState } from 'react';
import { Container, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import FundUtilizations from './FundUtilisation';
import FundDetails from './fund_details';

function InfrastructureGrantsComponent() {
    const tabContents = [
        { id: '1', title: 'Fund Details' },
        { id: '2', title: 'Fund Utilisation' },
    ];

    const [activeTab, setActiveTab] = useState('1');
    const [showTabs, setShowTabs] = useState(false); // State to track whether to show tabs or not
    const [primaryStep, setPrimaryStep] = useState(1);

    const toggle = (id) => {
        setActiveTab(id);
    };

    const handleCheckboxChange = () => {
        setShowTabs(!showTabs); // Toggle the state when the checkbox is changed
        setPrimaryStep(2);
    };

    return (
        <div>
            <Container className="form-container">
                <>
                    <>
                        <div className="infrastructuregrantarea">
                            

                            <div className="d-flex mb-4">
                                <div>Component Name : </div>

                                <div className="mx-2">Enhancing quality & excellence in select autonomous colleges</div>
                            </div>
                        </div>
                    </>

                    {/* Only render tabs if showTabs is true */}

                    <>
                        <Nav className="nav nav-pills navtab-bg nav-justified">
                            {tabContents.map((tab, index) => {
                                return (
                                    <NavItem key={index}>
                                        <NavLink
                                            href="#"
                                            className={classnames({ active: activeTab === tab.id })}
                                            onClick={() => {
                                                toggle(tab.id);
                                            }}>
                                            <i className={classnames(tab.icon, 'd-sm-none', 'd-block', 'mr-1')}></i>
                                            <span className="d-none d-sm-block">{tab.title}</span>
                                        </NavLink>
                                    </NavItem>
                                );
                            })}
                        </Nav>

                        <TabContent>
                            {activeTab === '1' ? (
                                <>
                                    <FundDetails />
                                </>
                            ) : (
                                <>
                                    <FundUtilizations />
                                </>
                            )}
                        </TabContent>
                    </>
                </>
            </Container>
        </div>
    );
}

export default InfrastructureGrantsComponent;
