import React from 'react';
import { Row, Container, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useState } from 'react';
import DataOperatorStaffPatternMain from './staff_details';
import DataOperatorFundUtlisationMain from './Fund Received & Utilisation Details/rankholders';
import DataOperatorFundDetailsMain from './Details of Fund Received/rankholders';
function UsersRusa() {
    const tabContents = [
        // { id: '1', title: 'Institution' },
        { id: '1', title: 'RUSA SPD' },
    ];
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card>
                    <CardBody>
                        <DataOperatorFundUtlisationMain />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default UsersRusa;
