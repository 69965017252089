import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';
import Success from '../../../success';
function GeneralInfo(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };

    const handleSaveData = (id) => {
        api.post(`beneficiaries/approve/${id}`)
            .then((response) => {
                // console.log('Approved');
                setSuccess(true);
            })
            .catch((error) => {
                // Handle errors if the POST request fails
                // console.error('Failed');
                // toggleModal();
            });
    };
    const handleRevertData = (id) => {
        api.post(`beneficiaries/revert/${id}`)
            .then((response) => {
                // console.log('revert');
                setRevert(true);
            })
            .catch((error) => {
                // Handle errors if the POST request fails
                // console.error('Failed');
                // toggleModal();
            });
    };

    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data?.beneficiaries;

            const general = jsonData;

            if (general.some((item) => item.status === 0 && item.is_approved === 1)) {
                setHasDataNo(true);
            }

            if (general.some((item) => item.status === 1 && item.is_approved === 1)) {
                setHasDataYes(true)
            }

            const updatedUsers = jsonData?.map((user) => {
                user.status = user.status === 1 ? 'true' : 'false';
                return user;
            });

            

            setgeneralDetails(updatedUsers);
            // console.log(updatedUsers);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handleSuccessAction = () => {
        // fetchData();
        setSuccess(false);
    };
    const handleRevertAction = () => {
        // fetchData();
        setRevert(false);
    };
    // ------
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };
    // -----
    return (
        <div>
            {success && (
                <Success
                    title="RUSA Beneficiary general Information details has been approved"
                    toggle={handleSuccessAction}
                    open={success}
                />
            )}
            {revert && (
                <Success
                    title="RUSA Beneficiary general Information details has been reverted"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <>
                {' '}
                <div className="d-flex justify-content-left mx-3 mb-3">
                    <Row className="label">
                        <label>Are you a Rashtriya Uchchatar Shiksha Abhiyan (RUSA) Beneficiary ?</label>
                    </Row>
                    <Row className="ml-5">:</Row>
                    <Row className="ml-5">
                        {' '}
                        <FormGroup check>
                        <Input
                                    type="radio"
                                    name="showForm"
                                    value="yes"
                                    checked={showForm === true}
                                    onChange={() => setShowForm(true)}
                                />{' '}
                            <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                                
                                Yes
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                        <Input
                                    type="radio"
                                    name="showForm"
                                    value="no"
                                    checked={showForm === false}
                                    onChange={() => setShowForm(false)}
                                />{' '}
                            <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                                
                                No
                            </Label>
                        </FormGroup>
                    </Row>
                </div>
            </>
            {showForm === true ? (
                <div className="table-section w-100">
                    {generalDetails.length > 0 ? (
                        generalDetails.map((data, i) => (
                            <Table striped>
                                <tbody>
                                    <tr style={{ width: '50%' }}>
                                        {data.is_approved === 0 ? (
                                            <td>
                                                <Button
                                                    disabled
                                                    color="warning"
                                                    class="btn btn-secondary"
                                                    style={{
                                                        color: '#ED7D31',
                                                        fontWeight: '400',
                                                        backgroundColor: '#F5F7F8',
                                                    }}
                                                    size="sm">
                                                    Pending
                                                </Button>
                                            </td>
                                        ) : data.is_approved === 2 ? (
                                            <td>
                                                <Button type="button" size="sm" color="danger" disabled>
                                                    Reverted by Head
                                                </Button>
                                            </td>
                                        ) : data.is_approved === 1 ? (
                                            <td>
                                                <Button disabled color="success" size="sm">
                                                    Approved by Head
                                                </Button>
                                            </td>
                                        ) : data.is_approved === 3 ? (
                                            <td>
                                                <Button disabled color="info" size="sm">
                                                    Reverted by RUSA Admin
                                                </Button>
                                            </td>
                                            
                                        ) : (
                                            ''
                                        )}
                                        <td></td>
                                    </tr>
                                    <tr style={{ width: '50%' }}>
                                        <td>
                                            <b>Are you a beneficiary of the Girls amenity center ?</b>
                                        </td>
                                        <td>
                                            {data?.amenity}
                                            <br></br>
                                            {data.amenity_details}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Are you a beneficiary of the Sasthrayan Project ?</b>
                                        </td>
                                        <td>
                                            {data.sasthrayan}
                                            <br></br>
                                            {data.sasthrayan_details}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>
                                                Are you a beneficiary of laptops for physically disabled students’
                                                initiative of RUSA ?
                                            </b>
                                        </td>
                                        <td>
                                            {data.laptop}
                                            <br></br>
                                            {data.laptop_details}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>
                                                Are you a beneficiary of the Videoconferencing setups to connect
                                                colleges by RUSA ?
                                            </b>
                                        </td>
                                        <td>
                                            {data.conference} <br></br>
                                            {data.conference_details}
                                        </td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>
                                                Are you a beneficiary of the E-journals and Laptop distribution
                                                initiative of RUSA ?
                                            </b>
                                        </td>
                                        <td>
                                            {data.e_journals} <br></br>
                                            {data.e_journals_details}
                                        </td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>Remarks</b>
                                        </td>
                                        <td>{data.remarks}</td>
                                    </tr>{' '}
                                </tbody>
                            </Table>
                        ))
                    ) : (
                        <Table striped>
                            <tbody>
                                <React.Fragment>
                                    <tr style={{ width: '50%' }}>
                                        <td>
                                            <b>Are you a beneficiary of the Girls amenity center ?</b>
                                        </td>
                                        <td>---</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Are you a beneficiary of the Sasthrayan Project ?</b>
                                        </td>
                                        <td>---</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>
                                                Are you a beneficiary of laptops for physically disabled students’
                                                initiative of RUSA ?
                                            </b>
                                        </td>
                                        <td>---</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>
                                                Are you a beneficiary of the Videoconferencing setups to connect
                                                colleges by RUSA ?
                                            </b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>
                                                Are you a beneficiary of the E-journals and Laptop distribution
                                                initiative of RUSA ?
                                            </b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                    <tr>
                                        <td>
                                            <b>Remarks</b>
                                        </td>
                                        <td>---</td>
                                    </tr>{' '}
                                </React.Fragment>
                            </tbody>
                        </Table>
                    )}
                </div>
            ) : (
                // <div className="table-section w-100">
                //     {generalDetails.filter((data) => data.status === 'false').length > 0 ? (
                //         generalDetails.map((data, i) => 
                        
                //         (
                //             data.status === 'false' ? (
                //             <Table striped>
                //                 <tbody>
                //                     <tr style={{ width: '50%' }}>
                //                         {data.is_approved === 0 ? (
                //                             <td>
                //                                 <Button
                //                                     disabled
                //                                     color="warning"
                //                                     class="btn btn-secondary"
                //                                     style={{
                //                                         color: '#ED7D31',
                //                                         fontWeight: '400',
                //                                         backgroundColor: '#F5F7F8',
                //                                     }}
                //                                     size="sm">
                //                                     Pending
                //                                 </Button>
                //                             </td>
                //                         ) : data.is_approved === 2 ? (
                //                             <td>
                //                                 <Button type="button" size="sm" color="danger" disabled>
                //                                     Reverted
                //                                 </Button>
                //                             </td>
                //                         ) : data.is_approved === 1 ? (
                //                             <td>
                //                                 <Button disabled color="success" size="sm">
                //                                     Approved
                //                                 </Button>
                //                             </td>
                //                         ) : (
                //                             ''
                //                         )}
                //                         <td></td>
                //                     </tr>
                //                     <tr>
                //                         <td>
                //                             <b>Remarks</b>
                //                         </td>
                //                         <td>{data.affiliated_university}</td>
                //                     </tr>{' '}
                //                 </tbody>
                //             </Table>
                //             ) : (
                //                 ''
                //             )
                //         ))
                //     ) : (
                //         <Table striped>
                //             <tbody>
                //                 <React.Fragment>
                //                     <tr>
                //                         <td>
                //                             <b>Remarks</b>
                //                         </td>
                //                         <td>---</td>
                //                     </tr>{' '}
                //                 </React.Fragment>
                //             </tbody>
                //         </Table>
                //     )}
                // </div>
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '600px' }}>Remarks</th>

                                <th style={{ minWidth: '100px' }}>Status</th>
                            </tr>
                        </thead>
                        {generalDetails?.filter((item) => item.status === "false" ).length >
                        0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.status === 'false'? (
                                        <tr key={index} className="text-left">
                                            <>
                                                <td  className='text-justify'>{item.remarks}</td>
                                                {item.is_approved === 0 ? (
                                                    <td>
                                                        <Button
                                                                                    disabled
                                                                                    color="warning"
                                                                                    class="btn btn-secondary"
                                                                                    style={{
                                                                                        color: '#ED7D31',
                                                                                        fontWeight: '400',
                                                                                        backgroundColor: '#F5F7F8',
                                                                                    }}
                                                                                    size="sm">
                                                                                    Pending
                                                                                </Button>
                                                    </td>
                                                ) : item.is_approved === 2 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 1 ? (
                                                    <td>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved
                                                        </Button>
                                                    </td>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            )}
        </div>
    );
}

export default GeneralInfo;
