import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { api } from '../../../../../../helpers/api';
import Cookies from 'js-cookie'; // Import Cookies from js-cookie
import FailureError from '../error';
const PreviewInformation = (props) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    // console.log(props.edit);

    const handleSaveData = () => {
        if (!props.edit) {
            delete props.formData.name_of_institution;
            delete props.formData.official_email;
            // console.log(props.formData);

            if (props.formData.other_nature !== '') {
                props.formData.name_of_college_management = props.formData.other_nature;
            }
            // console.log(props.formData);
            api.post('institution/general-info', { ...props.formData })
                .then((response) => {
                    // console.log('success:', response.data);
                    props.handleSaveData();
                })
                .catch((error) => {
                    // console.error('fails:', error);
                    toggleModal();
                });
        } else {
            delete props.formData.name_of_institution;
            delete props.formData.official_email;
            props.formData.is_approved = 0;
            if (props.formData.other_nature !== '') {
                props.formData.name_of_college_management = props.formData.other_nature;
            }
            api.post('institution/general-info', { ...props.formData })
                .then((response) => {
                    // Handle the response from the API if needed
                    // console.log('success:', response.data);
                    props.handleSaveData();
                })
                .catch((error) => {
                    // Handle errors if the POST request fails
                    // console.error('fails:', error);
                    toggleModal();
                });
        }
    };
    // const userData = JSON.parse(localStorage.getItem('institution'));
    const userData = JSON.parse(Cookies.get('institution')) || {};

    const handleBack = () => {
        props.setActiveStep(1);
    };

    // console.log(props);
    return (
        <div>
            <div className="d-flex justify-content-end mt-2 mb-3 ">
                {' '}
                <Button color="primary" onClick={() => handleBack()}>
                    Edit
                </Button>
            </div>
            <div className="preview-page">
                <Row>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Name of the Institution
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {userData?.institution_name}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Type of the Institution
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.type_of_institution}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Address with Pincode
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                            <div className='text-justify'>{props.formData?.address}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Affiliated University
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.affiliated_university}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Nature of College Management
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                <div className='text-justify'>{props.formData?.name_of_college_management} <br></br><br /> {props.formData?.other_nature}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Name and Address of the Institution Management
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                            <div className='text-justify'>{props.formData?.name_address_of_institution_management}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Year of Establishment
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.year_of_establishment}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                District
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.district}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Parliamentary Constituency
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.parliamentary_constituency}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Assembly Constituency
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.assembly_constituency}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Revenue District Office
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                            <div className='text-justify'>{props.formData?.revenue_district_office}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Taluk
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                            <div className='text-justify'>{props.formData?.taluk}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                State
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.state || 'Kerala'} {/* Set default value to 'Kerala' */}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Official Email ID
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {userData?.email}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Official Contact / Landline Number
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.official_contact_no}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Name of the Principal
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.principal}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Official Email ID of the Principal
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.official_email_of_principal}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Official Contact No. of the Principal
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {props.formData?.official_contact_no_of_principal}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Remarks
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                <div className="text-justify">{props.formData?.remarks}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-4">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                <Button color="primary" onClick={handleSaveData}>
                                    Submit for Approval
                                </Button>{' '}
                            </Col>
                        </Row>
                    </Col>

                    {/* <Button color="primary" onClick={() => props.handleSaveData()}> */}
                </Row>
            </div>
            <FailureError isOpen={isModalOpen} toggle={toggleModal} />
        </div>
    );
};

export default PreviewInformation;
