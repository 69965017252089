import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Table } from 'reactstrap';
import { api } from '../../../../../../../../../../helpers/api';
import Failure from '../../../../../../../../institutions/failure';
export default function FundDetails(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);

    const { id } = useParams();

    const fetchData = async () => {
        try {
            const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.rusa_phase_two;
            setGeneralDetails(jsonData);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    // let component = props.checkboxA
    //     ? 'Research'
    //     : 'Innovation and quality improvement';

    const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`/phasetwo/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setGeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };
    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    return (
        <div>
            {revert && (
                <Failure
                    title="RUSA 2 Phase Beneficiries details been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="table-section w-100 overflow-auto">
                <Table striped>
                    <thead className="table-head">
                        <tr className="text-left">
                            <th style={{ minWidth: '150px' }}>Component Name</th>
                            <th style={{ minWidth: '150px' }}>Total Fund approved by RUSA (Rs)</th>
                            <th style={{ minWidth: '100px' }}>Total Fund received from RUSA (Rs)</th>
                            <th style={{ minWidth: '100px' }}>Total Fund utilized by RUSA (Rs)</th>
                            <th style={{ minWidth: '100px' }}>Status</th>
                            <th style={{ minWidth: '100px' }}>Actions</th>
                        </tr>
                    </thead>
                    {generalDetails.filter((item) => item.type === 'fund details' && (
                                item.component_name === 'Research'
                                ? 'Research'
                                : 'Innovation and quality improvement'
                            ))
                        .length > 0 ? (
                        <tbody>
                            {generalDetails?.map((item, index) =>
                                item.type === 'fund details' && (
                                    item.component_name === 'Research'
                                    ? 'Research'
                                    : 'Innovation and quality improvement'
                                ) ? (
                                    <tr key={index} className="text-left">
                                        <td>{item.component_name}</td>

                                        <td>{item.fund_approved}</td>

                                        <td>{item.recevied_fund}</td>

                                        <td>{item.total_fund_utilized}</td>

                                        <td>
                                            {item.is_approved === 0 ? (
                                                <td className="d-flex p-0">
                                                    <td>
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <Button disabled color="danger" size="sm">
                                                    Reverted by head
                                                </Button>
                                            ) : item.is_approved === 1 ? (
                                                <Button disabled color="success" size="sm">
                                                    Approved by head
                                                </Button>
                                            ) : item.is_approved === 3 ? (
                                                <Button disabled color="info" size="sm">
                                                    Reverted by RUSA Admin
                                                </Button>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            {item.is_approved === 1 && (
                                                <td>
                                                    <Button
                                                        type="button"
                                                        size="sm"
                                                        outline
                                                        color="primary"
                                                        onClick={() => handleRevert(item.id)}>
                                                        Revert
                                                    </Button>
                                                </td>
                                            )}
                                        </td>
                                    </tr>
                                ) : (
                                    ''
                                )
                            )}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="15" className="text-center">
                                    No data found
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>
        </div>
    );
}
