import React, { useState } from 'react';
import './style.css';

import RUSAPhasebeneficiariesMain from './components/Admin_Phase_beneficiaries';
import RUSAExtraCurricularActivities from './components/RUSA_extra-curricular_activities';
import RUSAAcademicDetails from './components/academic_details';
import Accreditation from './components/accreditation/Accreditation';
import BestPractices from './components/best_practice/BestPractices';
import GenerInformation from './components/general/GeneralInformation';
import InfraStructure from './components/infrastructure/Infrastructure';
import NetJrf from './components/net_jrf/NetJrf';
import OrderSanctions from './components/order_sanctions/OrderSanctions';
import RankHolder from './components/rank_holder/RankHolders';
import ReciviedGrands from './components/recivied_grands/ReciviedGrands';
import StaffMembers from './components/staff_members/StaffMembers';

function DataInstitutionList() {
    const [activeComponent, setActiveComponent] = useState('GenerInformation');

    const handleSetActiveComponent = (componentName) => {
        setActiveComponent(componentName);
    };

    return (
        <div className="App college-list-menu">
            <div className="left-menu">
                <ul className="Institution_list">
                    <li
                        onClick={() => handleSetActiveComponent('GenerInformation')}
                        className={activeComponent === 'GenerInformation' ? 'active' : ''}>
                        General
                    </li>
                    <li
                        onClick={() => handleSetActiveComponent('BestPractices')}
                        className={activeComponent === 'BestPractices' ? 'active' : ''}>
                        Best Practices
                    </li>
                    <li
                        onClick={() => handleSetActiveComponent('Accreditation')}
                        className={activeComponent === 'Accreditation' ? 'active' : ''}>
                        Accreditation
                    </li>
                    <li
                        onClick={() => handleSetActiveComponent('ReciviedGrands')}
                        className={activeComponent === 'ReciviedGrands' ? 'active' : ''}>
                        Details of Received Grants
                    </li>
                    <li
                        onClick={() => handleSetActiveComponent('AcademicDetails')}
                        className={activeComponent === 'AcademicDetails' ? 'active' : ''}>
                        Academic Details
                    </li>
                    <li
                        onClick={() => handleSetActiveComponent('StaffMembers')}
                        className={activeComponent === 'StaffMembers' ? 'active' : ''}>
                        {' '}
                        Details of Staff Members
                    </li>
                    <li
                        onClick={() => handleSetActiveComponent('InfraStructure')}
                        className={activeComponent === 'InfraStructure' ? 'active' : ''}>
                        {' '}
                        Infrastructure
                    </li>
                    <li
                        onClick={() => handleSetActiveComponent('ExtraCarricularActivities')}
                        className={activeComponent === 'ExtraCarricularActivities' ? 'active' : ''}>
                        Sports & Extra Curricular Activities
                    </li>
                    <li
                        onClick={() => handleSetActiveComponent('RankHolder')}
                        className={activeComponent === 'RankHolder' ? 'active' : ''}>
                        Details of Rankholders
                    </li>
                    <li
                        onClick={() => handleSetActiveComponent('NetJrf')}
                        className={activeComponent === 'NetJrf' ? 'active' : ''}>
                        Qualified Students of NET/JRF
                    </li>
                    <li
                        onClick={() => handleSetActiveComponent('OrderSanctions')}
                        className={activeComponent === 'OrderSanctions' ? 'active' : ''}>
                        RUSA Order/Sanctions
                    </li>
                    <li
                        onClick={() => handleSetActiveComponent('PhaseBeneficiaries')}
                        className={activeComponent === 'PhaseBeneficiaries' ? 'active' : ''}>
                        RUSA - Phase Beneficiaries
                    </li>
                </ul>
            </div>
            <div className="right-content overflow-auto">
                {activeComponent === 'GenerInformation' && <GenerInformation />}
                {activeComponent === 'BestPractices' && <BestPractices />}
                {activeComponent === 'Accreditation' && <Accreditation />}
                {activeComponent === 'ReciviedGrands' && <ReciviedGrands />}
                {activeComponent === 'AcademicDetails' && <RUSAAcademicDetails />}
                {activeComponent === 'StaffMembers' && <StaffMembers />}
                {activeComponent === 'InfraStructure' && <InfraStructure />}
                {activeComponent === 'ExtraCarricularActivities' && <RUSAExtraCurricularActivities />}
                {activeComponent === 'RankHolder' && <RankHolder />}
                {activeComponent === 'NetJrf' && <NetJrf />}
                {activeComponent === 'OrderSanctions' && <OrderSanctions />}
                {activeComponent === 'PhaseBeneficiaries' && <RUSAPhasebeneficiariesMain />}
            </div>
        </div>
    );
}

export default DataInstitutionList;
