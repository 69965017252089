import React, { useState } from 'react';
import { Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import PreviewInformation from '../preview/index.jsx';

const FormArea = (props) => {
    const { formData, setFormData, handleSaveData, activeStep, handleNext, setActiveStep } = props;
    const [emailError, setEmailError] = useState('');

    const handleEmailBlur = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!formData.email) {
            setEmailError('Email is required');
        } else if (!emailRegex.test(formData.email)) {
            setEmailError('Invalid email format');
        } else {
            setEmailError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check for email validation before allowing submission
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!formData.email) {
            setEmailError('Email is required');
            return; // Do not proceed with submission if there is an error
        } else if (!emailRegex.test(formData.email)) {
            setEmailError('Invalid email format');
            return; // Do not proceed with submission if there is an error
        } else {
            setEmailError('');
        }

        // Continue with form submission logic
        handleNext();
    };

    return (
        <div>
            <div className="manage">
                
                    <h5 className="header-title mb-1 mt-0">Edit Institution</h5>{' '}
                   
            
                <div className="form-section">
                    <div className="info">
                        <div className="mandatory">{/* <sup>*</sup>All fields are mandatory. */}</div>
                    </div>
                    <Container className="form-container permanent-area">
                        <div>
                            {activeStep === 1 ? (
                                <>
                                    <Form onSubmit={handleSubmit} className="">
                                        <>
                                            <>
                                                {' '}
                                                <Row className="mt-2">
                                                    <Col md={6} sm={12} my-2>
                                                        <Label className="my-2">Institution Name</Label>
                                                        <Input
                                                            required
                                                            maxLength={100}
                                                            autoComplete="off"
                                                            type="text"
                                                            value={formData.institution_name}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    institution_name: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={6} sm={12} my-2>
                                                        <Label className="my-2">Email ID</Label>
                                                        <Input
                                                            required
                                                            maxLength={200}
                                                            autoComplete="off"
                                                            type="email"
                                                            value={formData.email}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    email: e.target.value,
                                                                })
                                                            }
                                                            onBlur={handleEmailBlur} // Add onBlur event for validation
                                                        />
                                                        {emailError && <div className="text-danger">{emailError}</div>}
                                                    </Col>
                                                </Row>{' '}
                                            </>
                                            {/* -----------end---------- */}
                                        </>

                                        <div className="my-4">
                                            <Button type="submit">
                                                {/* // onClick={() => (activeStep === 1 ?  : handleSaveData())}> */}
                                                {activeStep == 0 ? 'Submit for Approval' : 'Preview'}
                                            </Button>
                                        </div>
                                    </Form>
                                </>
                            ) : (
                                <>
                                    <PreviewInformation
                                        formData={formData}
                                        activeStep={activeStep}
                                        handleSaveData={handleSaveData}
                                        setActiveStep={setActiveStep}
                                        edit={props.edit}
                                        setSuccess={props.setSuccess}
                                    />
                                </>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default FormArea;
