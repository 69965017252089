import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
const RankHolders = (props) => {
    // console.log(props);
    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
            try {
                const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected
                // console.log(response);
                const jsonData = response?.data?.data?.data;

                // console.log(jsonData);
                setgeneralDetails(jsonData.gallery);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);
    return (
        <div>
            <div className="view">
                <Container className="p-0">
                    <Card>
                        <CardBody className="p-5">
                            
                            <div className="table-section w-100 ">
                                <Table striped>
                                    <thead className="table-head">
                                        <tr className="text-left">
                                        <th style={{ minWidth: '150px' }}>Image Description</th>
                                  
                                    <th style={{ minWidth: '200px' }}>Image Link</th>
                                    
                                    <th style={{ minWidth: '250px' }}>Remarks</th>
                                    
                                    <th style={{ minWidth: '60px' }}>Status</th>
                                        </tr>
                                    </thead>
                                    {generalDetails?.length > 0 ? (
                                        <tbody>
                                            {generalDetails.map((item, index) => (
                                                <tr key={index} className="text-left">
                                                    <>
                                                        <td>{item.desciption}</td>
                                                        <td>{item.link}</td>
                                                       
                                                        <td className='text-justify'>{item.remarks}</td>
                                                        {item.is_approved === 0 ? (
                                                            <td>
                                                                <Button
                                                                    disabled
                                                                    color="warning"
                                                                    class="btn btn-secondary"
                                                                    style={{
                                                                        color: '#ED7D31',
                                                                        fontWeight: '400',
                                                                        backgroundColor: '#F5F7F8',
                                                                    }}
                                                                    size="sm">
                                                                    Pending
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 2 ? (
                                                            <td>
                                                                <Button type="button" size="sm" color="danger" disabled>
                                                                    Reverted
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 1 ? (
                                                            <td>
                                                                <Button disabled color="success" size="sm">
                                                                    Approved
                                                                </Button>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan="5" className="text-center">
                                                    No data Found
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </div>
    );
};

export default RankHolders;
