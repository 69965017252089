import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';

function HeadNonTeachingStaffs(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;
            setGeneralDetails(jsonData.staff_details);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // ------
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };
    // -----
    return (
        <div>
            <div className="view">
                <Container className="container-width">
                    <div className="table-section w-100 overflow-auto">
                        <Table striped>
                            <thead className="table-head ml-5 mt-0">
                                <tr>
                                    <th style={{ minWidth: '130px' }}>Date as on:</th>
                                    <th style={{ minWidth: '140px' }}>Number of sanctioned post non-teaching /staff</th>
                                    <th style={{ minWidth: '140px' }}>Number of permanent non-teaching /staff</th>
                                    <th style={{ minWidth: '130px' }}>Number of vacancies</th>
                                    <th style={{ minWidth: '377px' }}>Remarks</th>
                                    <th style={{ minWidth: '60px' }}>Status</th>
                                </tr>
                            </thead>
                            {generalDetails.filter((item) => item.type === 'non_teaching').length > 0 ? (
                                <tbody>
                                    {generalDetails.map((item, index) =>
                                        item.type === 'non_teaching' ? (
                                            <tr key={index} className="text-left">
                                                <>
                                                    <td>{formatDate(item.date)}</td>
                                                    <td>{item.approved_staff}</td>
                                                    <td>{item.permanent_staff}</td>
                                                    <td>{item.vacant}</td>
                                                    <td style={{ maxWidth: '200px', textAlign: 'justify' }}>
                                                        {item.remarks}
                                                    </td>

                                                    {item.is_approved === 0 ? (
                                                        <td className="d-flex">
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                            <br></br>
                                                        </td>
                                                    ) : item.is_approved === 2 ? (
                                                        <td>
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 1 ? (
                                                        <td>
                                                            <Button type="button" color="success" size="sm" disabled>
                                                                Approved
                                                            </Button>
                                                        </td>
                                                    ) : (
                                                        item.is_approved === 3 && (
                                                            <td>
                                                                <Button type="button" color="info" size="sm" disabled>
                                                                    Reverted by RUSA admin
                                                                </Button>
                                                            </td>
                                                        )
                                                    )}
                                                </>
                                            </tr>
                                        ) : (
                                            ''
                                        )
                                    )}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            No data Found
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </Table>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default HeadNonTeachingStaffs;
