import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';
function HeadNAAC(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(true);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };

    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get(`admin-institution-info`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.internet;
            const updatedUsers = jsonData?.map((user) => {
                /*if (user.status === 1) {
                    user.status = 'Yes';
                } else if (user.status === 0) {
                    user.status = 'No';
                }
                // console.log(updatedUsers);*/
                return { ...user, status: user.status === 1 ? true : false };
            });
            const internet = jsonData;

            if (internet.some((item) => item.status === 0 && item.is_approved === 1)) {
                setHasDataNo(true);
            }

            if (internet.some((item) => item.status === 1 && item.is_approved === 1)) {
                setHasDataYes(true)
            }             
            setgeneralDetails(updatedUsers);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <>
                {' '}
                <div className="d-flex justify-content-left mx-3">
                    <Row className="label">
                        <label> Does your institution have a high speed internet connection?</label>
                    </Row>
                    <Row className="ml-5">:</Row>
                    <Row className="ml-5">
                        {' '}
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="yes"
                                    checked={showForm}
                                    onChange={() => setShowForm(true)}
                                />{' '}
                            <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                                Yes
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="no"
                                    checked={!showForm}
                                    onChange={() => setShowForm(false)}
                                />{' '}
                            <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                                No
                            </Label>
                        </FormGroup>
                    </Row>
                </div>
            </>
            {showForm ? (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">

                                <th style={{ maxWidth: '155px' }}> Internet Service Provider (ISP)</th>
                                <th style={{ maxWidth: '108px' }}>Upload speed</th>
                                <th style={{ maxWidth: '118px' }}>Download speed</th>
                                <th style={{ minWidth: '277px' }}> Remarks</th>

                                <th style={{ maxWidth: '60px' }}>Status</th>
                            </tr>
                        </thead>
                        {generalDetails.filter((data) => data.status).length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.status ? (
                                        <tr key={index} className="text-left">
                                            <td style={{ maxWidth: '80px' }}>{item.isp}</td>
                                            <td style={{ maxWidth: '80px' }}>{item.upload}</td>
                                            <td style={{ maxWidth: '80px' }}>{item.download}</td>
                                            <td style={{ maxWidth: '277px' }}>{item.remarks}</td>
                                            <td> {item.is_approved === 2 && (
                                                <td style={{ maxWidth: '80px' }}>
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted by head
                                                    </Button>
                                                </td>
                                            )}
                                                {item.is_approved === 1 && (
                                                    <td style={{ maxWidth: '80px' }}>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved by head
                                                        </Button>
                                                    </td>
                                                )}
                                                {item.is_approved === 3 && (
                                                    <Button disabled color="info" size="sm">
                                                        Reverted By RUSA Admin
                                                    </Button>
                                                )}
                                                {item.is_approved === 0 && (
                                                    <td>
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                )}{' '}</td>
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ maxWidth: '250px' }}>Remarks</th>

                                <th style={{ minWidth: '100px' }}>Status</th>
                            </tr>
                        </thead>
                        {generalDetails.filter((data) => !data.status).length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    !item.status ? (
                                        <tr key={index} className="text-left">
                                            <>
                                                <td style={{ maxWidth: "250px" }}>{item.remarks}</td>
                                                {item.is_approved === 2 && (
                                                    <td style={{ maxWidth: '80px' }}>
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted by head
                                                        </Button>
                                                    </td>
                                                )}
                                                {item.is_approved === 3 && (
                                                    <td style={{ maxWidth: '80px' }}>
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                )}
                                                {item.is_approved === 1 && (
                                                    <td style={{ maxWidth: '80px' }}>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved by head
                                                        </Button>
                                                    </td>
                                                )}
                                                {item.is_approved === 0 && (
                                                    <td>
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                )}{' '}
                                            </>
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            )}
        </div>
    );
}

export default HeadNAAC;
