import React, { useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { api } from '../../../../../../../helpers/api';
export default function FundDetails(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await api.get(`/admin-institution-info`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.rusa_phase_two;
            setgeneralDetails(jsonData);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    // let component = props.checkboxA ? 'New Model Colleges' : 'Infrastructure grants to colleges'  


    return (
        <div>
            <div className="table-section w-100 overflow-auto">
                <Table striped>
                    <thead className="table-head">
                        <tr className="text-left">
                            <th style={{ minWidth: '150px' }}>Date as on :</th>

                            <th style={{ minWidth: '200px' }}>Details of Construction</th>

                            <th style={{ minWidth: '200px' }}>Details of Renovation</th>

                            <th style={{ minWidth: '200px' }}>Details of Purchase</th>

                            <th style={{ minWidth: '200px' }}>Other Details</th>
                            <th style={{ minWidth: '250px' }}>Fund Utilized from the total allocated amount (Rs)</th>

                            <th style={{ minWidth: '250px' }}>Details of Physical Progress</th>
                            <th style={{ minWidth: '100px' }}>Status</th>
                        </tr>
                    </thead>
                    {generalDetails.filter((item) => item.type === 'fund utilized' && (
                                    item.component_name === 'New model colleges'
                                    ? 'New model colleges'
                                    : 'Infrastructure grants to colleges'
                                )).length > 0 ? (
                            <tbody>
                                {generalDetails?.map((item, index) =>
                                    item.type === 'fund utilized' && (
                                        item.component_name === 'New model colleges'
                                        ? 'New model colleges'
                                        : 'Infrastructure grants to colleges'
                                    ) ? (
                                    <tr key={index} className="text-left">
                                        <td>{item.date}</td>

                                        <td>{item.construction_details}</td>

                                        <td>{item.renovation_details}</td>

                                        <td>{item.purchase_details}</td>

                                        <td>{item.other_details}</td>

                                        <td>{item.fund_utilized_details}</td>

                                        <td>{item.physical_progress_details}</td>

                                        <td>
                                            {item.is_approved === 0 ? (
                                                <td className="d-flex px-0">
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <Button disabled color="danger" size="sm">
                                                    Reverted by head
                                                </Button>
                                            ) : item.is_approved === 1 ? (
                                                <Button disabled color="success" size="sm">
                                                    Approved by head
                                                </Button>
                                            ) : item.is_approved === 3 ? (
                                                <Button disabled color="info" size="sm">
                                                    Reverted by RUSA Admin
                                                </Button>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                ) : (
                                    ''
                                )
                            )}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="15" className="text-center">
                                    No data found
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>
        </div>
    );
}
