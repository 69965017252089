import React, { useEffect, useState } from 'react';
import { Edit } from 'react-feather';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';
import ManangeGeneralaInformation from './manage';
import { MANAGE_LABELS } from './manage/datamanager';
import StatusNoForm from './noform';

const fields = {
    [MANAGE_LABELS.NAME_OF_ACCREDITING_AGENCY]: null,
    [MANAGE_LABELS.AGENCY_TYPE]: null,
    [MANAGE_LABELS.DATE_OF_ACCREDITATION]: null,
    [MANAGE_LABELS.VALID_UPTO]: null,
    [MANAGE_LABELS.GRADE_SCORE]: null,
    [MANAGE_LABELS.OTHER_REMARKS]: null,
};
const initial_values = [fields];

function GeneralInfo() {
    const [generalData, setGeneralData] = useState(initial_values);
    const [formData, setformData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [newReg, setNewReg] = useState(true);
    const [flag, setFlag] = useState('Y');
    const [status, setStatus] = useState('');
    const [primaryStep, setPrimaryStep] = useState(1);
    const [otherDetails, setotherDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(1);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };
    const [editIndex, setEditIndex] = useState(null);

    const handleEdit = (id) => {
        const data = [];
        otherDetails.forEach((item) => {
            if (item.id === id) {
                const { is_approved, grade, present_accreditation_cycle, ...rest } = item;
                // console.log(rest);
                data.push(rest);
            }
        });
        // console.log(data);
        // setEditIndex(index);
        setGeneralData(data);
        setPrimaryStep(2);
        setEdit(true);
    };
    const handleEditNo = (id) => {
        const data = [];
        otherDetails.forEach((item) => {
            if (item.id === id) {
                const { is_approved, grade, present_accreditation_cycle, ...rest } = item;
                // console.log(rest);
                data.push(rest);
            }
        });
        // console.log(data);
        // setEditIndex(index);
        setGeneralData(data);
        setActiveStep(2);
        setEdit(true);
    };
    const handleCancelEdit = () => {
        setEditIndex(null);
        setGeneralData({ ...fields() });
        setPrimaryStep(1);
    };

    // console.log('1', primaryStep);
    const manageEdit = () => {
        setEdit(true);
    };
    const manageForm = () => {
        setActiveStep(2);
        const createFields = [
            {
                [MANAGE_LABELS.OTHER_REMARKS]: null,
            },
        ];
        setGeneralData(createFields);
    };
    const onSubmit = (data) => {
        // setGeneralData(data.data);
        setFlag(data.flag);
        setStatus(data.status);
        setEdit(false);
        setNewReg(false);
    };
    const fetchData = async () => {
        try {
            const response = await api.get(`co-institution-info`);
            const jsonData = response?.data?.data?.data?.other_accreditation;

            const accreditation = jsonData;

            if (accreditation.some((item) => item.other_status === 0 && (item.is_approved === 2 || item.is_approved === 3))) {
                setHasDataNo(true);
            }

            if (accreditation.some((item) => item.other_status === 1 && (item.is_approved === 2 || item.is_approved === 3))) {
                setHasDataYes(true)
            }

            setotherDetails(jsonData);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    const handleCallBack = () => {
        fetchData();
        setPrimaryStep(1);
        setActiveStep(1);
    };
    const handleCreate = () => {
        setPrimaryStep(2);

        const createFields = [
            {
                [MANAGE_LABELS.NAME_OF_ACCREDITING_AGENCY]: null,
                [MANAGE_LABELS.AGENCY_TYPE]: null,
                [MANAGE_LABELS.DATE_OF_ACCREDITATION]: null,
                [MANAGE_LABELS.VALID_UPTO]: null,
                [MANAGE_LABELS.GRADE_SCORE]: null,
                [MANAGE_LABELS.OTHER_REMARKS]: null,
            },
        ];
        setGeneralData(createFields);
        // console.log(generalData);
    };
    // ------
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };
    // -----
    return (
        <div className="generalinfo">
            <div className="d-flex justify-content-left ml-3 mt-2">
                <Row>Does your institution posses any Other accreditation?</Row>
                <Row className="ml-5">:</Row>
                <Row className="ml-5">
                    <FormGroup check>
                    <Input type="radio" name="flag" checked={flag === 'Y'} onChange={() => setFlag('Y')} /> 
                    <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>Yes
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                    <Input type="radio" name="flag" checked={flag === 'N'} onChange={() => setFlag('N')} /> 
                    <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                            No
                        </Label>
                    </FormGroup>
                </Row>
            </div>
            {flag === 'Y' && (
                <>
                    {primaryStep === 1 ? (
                        <>
                            <div className="d-flex justify-content-end mb-3">
                                {' '}
                                <Button onClick={() => handleCreate()} color="primary">
                                    Create
                                </Button>
                            </div>
                            <div className="table-section w-100 overflow-auto ">
                                <Table striped>
                                    <thead className="table-head ">
                                        <tr className="text-left">
                                            {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                            <th style={{ minWidth: '150px' }}>Accrediting agency</th>
                                            <th style={{ minWidth: '120px' }}>Agency Type</th>
                                            <th style={{ minWidth: '180px' }}>Date of Accreditation</th>
                                            <th style={{ minWidth: '113px' }}>Valid Upto</th>
                                            <th style={{ minWidth: '200px' }}>Accreditation Score/ Grade</th>
                                            <th style={{ minWidth: '307px' }}>Remarks</th>
                                            <th style={{ minWidth: '80px' }}>Status</th>
                                            <th style={{ minWidth: '70px' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {otherDetails.filter((data) => data.other_status === 1).length > 0 ? (
                                            otherDetails.map((item, i) =>
                                                item.other_status === 1 ? (
                                                    <tr key={i} className="text-left">
                                                        {/* <td>{i + 1}</td> */}
                                                        <td>{item.accrediation_agency_name}</td>
                                                        <td>{item.agency_type}</td>
                                                        <td>{formatDate(item.accreditation_date)}</td>
                                                        <td>{formatDate(item.valid_until)}</td>
                                                        <td>{item.score}</td>
                                                        <td style={{ textAlign: 'justify' }} className="w-25">
                                                            {item.remarks}
                                                        </td>
                                                        <td>
                                                            {item.is_approved === 0 ? (
                                                                <Button
                                                                    disabled
                                                                    color="warning"
                                                                    class="btn btn-secondary"
                                                                    style={{
                                                                        color: '#ED7D31',
                                                                        fontWeight: '400',
                                                                        backgroundColor: '#F5F7F8',
                                                                    }}
                                                                    size="sm">
                                                                    Pending
                                                                </Button>
                                                            ) : item.is_approved === 2 ? (
                                                                <Button disabled color="danger" size="sm">
                                                                    Reverted by head
                                                                </Button>
                                                            ) : item.is_approved === 1 ? (
                                                                <Button disabled color="success" size="sm">
                                                                    Approved by head
                                                                </Button>
                                                            ) : item.is_approved === 3 ? (
                                                                <Button disabled color="info" size="sm">
                                                                    Reverted by RUSA Admin
                                                                </Button>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.is_approved === 2 ? (
                                                                <Edit
                                                                    className="me-3 cursor-pointer"
                                                                    onClick={() => handleEdit(item.id)}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.is_approved === 3 ? (
                                                                <Edit
                                                                    className="me-3 cursor-pointer"
                                                                    onClick={() => handleEdit(item.id)}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan={8} className="text-center">
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    ) : (
                        <>
                            {' '}
                            {newReg || edit ? (
                                <ManangeGeneralaInformation
                                    // data={generalData}
                                    data={generalData}
                                    setGeneralData={setGeneralData}
                                    edit={edit}
                                    setFlag={setFlag}
                                    flag={flag}
                                    status={status}
                                    handleCallBack={handleCallBack}
                                    setStatus={setStatus}
                                    fields={fields}
                                    onSubmit={onSubmit}
                                    setEdit={manageEdit}
                                    primaryStep={primaryStep}
                                    setPrimaryStep={setPrimaryStep}
                                    handleCancelEdit={handleCancelEdit}
                                    {...otherDetails[editIndex]}
                                    fetchData={fetchData}
                                />
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </>
            )}
            {flag === 'N' && (
                <>
                    {activeStep === 1 ? (
                        <>
                            <div className="d-flex justify-content-end mb-3">
                                {' '}
                                <Button onClick={manageForm} color="primary">
                                    Create
                                </Button>
                            </div>
                            <div className="table-section w-100 ">
                                <Table striped>
                                    <thead className="table-head">
                                        <tr className="text-left">
                                        <th style={{ minWidth: '879px' }}>Remarks</th>
                                                                <th style={{ minWidth: '115px' }}>Status</th>
                                                                <th style={{ minWidth: '60px' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {otherDetails.filter((item) => item.other_status === 0).length > 0 ? (
                                            otherDetails.map((item, i) =>
                                                item.other_status === 0 ? (
                                                    <tr key={i} className="text-left">
                                                        {/* <td>{i + 1}</td> */}

                                                        <td style={{ textAlign: 'justify' }} className="w-75">
                                                            {item.remarks}
                                                        </td>
                                                        <td>
                                                            {item.is_approved === 0 ? (
                                                                <Button
                                                                    disabled
                                                                    color="warning"
                                                                    class="btn btn-secondary"
                                                                    style={{
                                                                        color: '#ED7D31',
                                                                        fontWeight: '400',
                                                                        backgroundColor: '#F5F7F8',
                                                                    }}
                                                                    size="sm">
                                                                    Pending
                                                                </Button>
                                                            ) : item.is_approved === 2 ? (
                                                                <Button disabled color="danger" size="sm">
                                                                    Reverted By Head
                                                                </Button>
                                                            ) : item.is_approved === 1 ? (
                                                                <Button disabled color="success" size="sm">
                                                                    Approved By Head
                                                                </Button>
                                                            ) : item.is_approved === 3 ? (
                                                                <Button disabled color="info" size="sm">
                                                                    Reverted By RUSA Admin
                                                                </Button>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.is_approved === 2 ? (
                                                                <Edit
                                                                    className="me-3 cursor-pointer"
                                                                    onClick={() => handleEditNo(item.id)}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.is_approved === 3 ? (
                                                                <Edit
                                                                    className="me-3 cursor-pointer"
                                                                    onClick={() => handleEditNo(item.id)}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan={3} className="text-center">
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    ) : (
                        <>
                            <StatusNoForm
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                                data={generalData}
                                flag={flag}
                                setFlag={setFlag}
                                edit={edit}
                                setEdit={setEdit}
                                handleCallBack={handleCallBack}
                                fetchData={fetchData}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default GeneralInfo;
