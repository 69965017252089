import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'reactstrap';
import { MANAGE_LABELS, year } from '../manage/datamanager';
import { Edit, PlusSquare } from 'react-feather';
import { FaEye } from 'react-icons/fa';
import { IoEyeSharp } from 'react-icons/io5';

function GeneralInformation(props) {
    const { listData } = props;
    const [list, setList] = useState([]);

    const createYearArray = () => {
        const newArray = year.map((obj1) => {
            const matchingObj = listData.find((obj2) => obj2.year == obj1.label);
            if (matchingObj) {
                return { ...obj1, ...matchingObj };
            } else {
                return obj1;
            }
        });
        setList(newArray)
    };
    useEffect(() => {
        createYearArray();
    }, [listData, year]);
    // console.log(list)

    return (
        <div className="view mt-3">
            <Table striped>
                <thead className="table-head">
                    <tr className="text-left">
                        <th style={{ minWidth: '200px' }}>Financial Year </th>
                        <th style={{ minWidth: '200px' }}>Total Amount</th>
                        <th style={{ minWidth: '200px' }}>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, n) => (
                        <>
                            <tr key={n} className="text-left">
                                <td>{item.label}</td>
                                <td>{item?.amount}</td>
                                <td> {item?.is_approved === 0 ? (
                                        <td className="text-primary p-0">Pending</td>
                                    ) : item?.is_approved === 1 ? (
                                        <td className="text-success p-0">Approved</td>
                                    ) : item?.is_approved === 2 ?(
                                        <td className="text-warning p-0">Reverted</td>
                                    ) : (
                                        ""
                                    )}</td>
                                <td className="d-flex justify-content-start">
                                    <Edit
                                        className="me-3 cursor-pointer"
                                        size={20}
                                        onClick={() => props.handleEdit(item.label)}
                                    />
                                    <IoEyeSharp
                                        className="ml-3 cursor-pointer"
                                        size={20}
                                        onClick={() => props.handleViewInfo(item?.label)}
                                    />
                                    {/* <Edit className="ml-3 cursor-pointer" size={20} onClick={() => props.setEdit(item?.label)} /> */}
                                </td>
                            </tr>
                        </>
                    ))}

                    {/* ))} */}
                </tbody>
            </Table>
        </div>
    );
}

export default GeneralInformation;
