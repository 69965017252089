import React from 'react';
import { useState } from 'react';
import { Edit } from 'react-feather';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { MANAGE_LABELS } from '../manage/datamanager';
import { create_agency, update_agency } from '../../../../../../helpers/actions/api.functions';

const PreviewInformation = (props) => {
    const { setActiveStep } = props;

    const handleSubmit = () => {
        // console.log(props);
        const data = props?.faculityData2;
        if (props?.edit === true) {
            const id = data[0].id;
            update_agency(data, id)
                .then((res) => {
                    // console.log(res);
                    props.setSuccess(true);
                    props.setEdit(false);
                    props.setNewEdit(false);
                })
                .catch((err) => {
                    // console.log(err);
                });
        } else {
            create_agency(data)
                .then((res) => {
                    // console.log(res);
                    props.setSuccess(true);
                    props.setEdit(false);
                    props.setNewEdit(false);
                })
                .catch((err) => {
                    // console.log(err);
                });
        }
        // props.setSuccess(true)
    };
    return (
        <div className="view">
            <div className="edit">
                <Button color="primary" className="px-3" onClick={() => setActiveStep(1)}>
                    Edit
                </Button>
            </div>

            <Table striped>
                <thead className="table-head">
                    <tr>
                        <th>Year</th>
                        <th>Accredited Agency Name </th>
                        <th>Details</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                {props.faculityData2?.map((item, k) => (
                    <tbody>
                        <tr>
                            <td>{item[MANAGE_LABELS.year] ?? '--'}</td>
                            <td>{item[MANAGE_LABELS.agency] ?? '--'}</td>
                            <td>{item[MANAGE_LABELS.details] ?? '--'}</td>
                            <td>{item[MANAGE_LABELS.remarks] ?? '--'}</td>
                        </tr>
                    </tbody>
                ))}
            </Table>
            <div className="">
                <Button color="primary" className="px-3 my-3" onClick={() => handleSubmit()}>
                    Submit for approval
                </Button>
            </div>
        </div>
    );
};

export default PreviewInformation;
