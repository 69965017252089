import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Container } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
const DPR = (props) => {
    // console.log(props);
    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
            try {
                const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected
                // console.log(response);
                const jsonData = response?.data?.data?.data;

                // console.log(jsonData);
                setgeneralDetails(jsonData.dpr);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);
    // console.log(props);
    return (
        <div>
            <div className="view">
                <Container className="container-width">
                    <div className='mt-5'>
                        <table class="table">
                        <thead className="table-head ml-5 mt-0">
                                <tr>
                                    <th style={{maxWidth:"150px"}}>DPR</th>
                                    <th>Approved plan for Construction</th>
                                    <th>Approved plan for Renovation</th>
                                    <th>Remarks</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            {generalDetails?.length > 0 ? (
                                <tbody>
                                    {generalDetails.map((item, index) =>
                                       <tr key={index} className="text-left">
                                       <td style={{maxWidth:"150px"}}>{item.link}</td>
                                       
                                       <td>
                                           <a href={item.construction_plan} target="_blank" rel="noopener noreferrer">
                                               View
                                           </a>
                                       </td>
                                       <td>
                                           <a href={item.renovation_plan} target="_blank" rel="noopener noreferrer">
                                               View
                                           </a>
                                       </td>
                                       <td>{item.remarks}</td>
                                       {item.is_approved === 0 ? (
                                           <td>
                                               <Button
                                                   disabled
                                                   color="warning"
                                                   class="btn btn-secondary"
                                                   style={{
                                                       color: '#ED7D31',
                                                       fontWeight: '400',
                                                       backgroundColor: '#F5F7F8',
                                                   }}
                                                   size="sm">
                                                   Pending
                                               </Button>
                                           </td>
                                       ) : item.is_approved === 2 ? (
                                           <td>
                                               <Button type="button" size="sm" color="danger" disabled>
                                                   Reverted
                                               </Button>
                                           </td>
                                       ) : item.is_approved === 1 ? (
                                           <td>
                                               <Button type="button" color="success" size="sm" disabled>
                                                   Approved
                                               </Button>
                                           </td>
                                       ) : (
                                           ''
                                       )}
                                   </tr>
                                    )}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            No data Found
                                        </td>
                                    </tr>
                                </tbody>
                            )}

                        </table>

                    </div>
                </Container>
            </div>
        </div>
    );
};

export default DPR;
