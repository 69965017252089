import React from 'react';
import { Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Container } from 'reactstrap';
import classnames from 'classnames';
import { useState } from 'react';

import UGDetails from './UG';

function InstitutionsListSPD() {
    const tabContents = [];
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card>
                    <CardBody>
                        <>
                            <UGDetails />
                        </>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default InstitutionsListSPD;
