import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';

function HeadFacilities(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);
    const { id } = useParams();

    const fetchData = async () => {
        try {
            const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected

            const jsonData = response?.data?.data?.data;

            // console.log(jsonData);
            setGeneralDetails(jsonData.facility);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    return (
        <div className="view">
            {revert && (
                <Failure
                    title="Infrastructure facilities details has been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <Container className="p-0 my-4">
                <Card>
                    <CardBody className="p-0">
                        <div className="table-section">
                            {generalDetails.length > 0 ? (
                                generalDetails.map((data, i) => (
                                    <Table style={{ width: '100%', tableLayout: 'fixed' }} key={i}>
                                        <tbody>
                                            <tr>
                                                <div className="w-50 d-flex justify-content-start ">
                                                    <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                        {data.is_approved === 0 ? (
                                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                                <Button
                                                                    disabled
                                                                    color="warning"
                                                                    class="btn btn-secondary"
                                                                    style={{
                                                                        color: '#ED7D31',
                                                                        fontWeight: '400',
                                                                        backgroundColor: '#F5F7F8',
                                                                    }}
                                                                    size="sm">
                                                                    Pending
                                                                </Button>
                                                            </td>
                                                        ) : data.is_approved === 2 ? (
                                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                                <Button type="button" size="sm" color="danger" disabled>
                                                                    Reverted by Head
                                                                </Button>
                                                            </td>
                                                        ) : data.is_approved === 1 ? (
                                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                                <Button
                                                                    type="button"
                                                                    color="success"
                                                                    size="sm"
                                                                    disabled>
                                                                    Approved by Head
                                                                </Button>
                                                            </td>
                                                        ) : (
                                                            data.is_approved === 3 && (
                                                                <td
                                                                    style={{
                                                                        minWidth: '500px',
                                                                        whiteSpace: 'pre-wrap',
                                                                    }}>
                                                                    <Button
                                                                        type="button"
                                                                        color="info"
                                                                        size="sm"
                                                                        disabled>
                                                                        Reverted by RUSA Admin
                                                                    </Button>
                                                                </td>
                                                            )
                                                        )}
                                                    </td>
                                                    <div></div>
                                                </div>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}></td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {' '}
                                                    Total number of research rooms and labs
                                                </td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {data.rooms_labs ?? '--'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {' '}
                                                    Number of staff rooms
                                                </td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {data.staffrooms ?? '--'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {' '}
                                                    Total Number of rest rooms/toilets
                                                </td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {data.rooms_toilets ?? '--'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    Total Number of rest rooms/toilets for Girls (out of total)
                                                </td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {data.girls_toilets ?? '--'}
                                                </td>
                                            </tr>{' '}
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    Total Number of rest rooms/toilets for Boys (out of total)
                                                </td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {data.boys_toilets ?? '--'}
                                                </td>
                                            </tr>{' '}
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {' '}
                                                    Total Number of rest rooms/toilets for Transgenders (out of total)
                                                </td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {data.transgenders_toilet ?? '--'}
                                                </td>
                                            </tr>{' '}
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    Total Number of Disabled friendly classrooms
                                                </td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {data.friendly_classroom ?? '--'}
                                                </td>
                                            </tr>{' '}
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {' '}
                                                    Total Number of canteen
                                                </td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {data.canteen ?? '--'}
                                                </td>
                                            </tr>{' '}
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    Total Number of Smart classrooms
                                                </td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {data.smart_classroom ?? '--'}
                                                </td>
                                            </tr>{' '}
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {' '}
                                                    Total Number of EDUSAT rooms / studios(dedicated room for live
                                                    online classes & broadcasting and enabled with projector and
                                                    Laptop/Desktop
                                                </td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {data.edustat ?? '--'}
                                                </td>
                                            </tr>{' '}
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    {' '}
                                                    Extra facilities the institution possess for aiding Social Science,
                                                    Humanities & Business Studies subject
                                                </td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    <div className="text-justify">{data.extra_facilities ?? '--'}</div>
                                                </td>
                                            </tr>{' '}
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}> Remarks</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                    <div className="text-justify">{data.remarks ?? '--'}</div>
                                                </td>
                                            </tr>{' '}
                                        </tbody>
                                    </Table>
                                ))
                            ) : (
                                <Table striped>
                                    <tbody>
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                {' '}
                                                Total number of research rooms and labs
                                            </td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                Number of staff rooms
                                            </td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                {' '}
                                                Total Number of rest rooms/toilets
                                            </td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                {' '}
                                                Total Number of rest rooms/toilets for Girls (out of total)
                                            </td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                Total Number of rest rooms/toilets for Boys (out of total)
                                            </td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                Total Number of rest rooms/toilets for Transgenders (out of total)
                                            </td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                Total Number of Disabled friendly classrooms
                                            </td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                {' '}
                                                Total Number of canteen
                                            </td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                {' '}
                                                Total Number of Smart classrooms
                                            </td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                {' '}
                                                Total Number of EDUSAT rooms / studios(dedicated room for live online
                                                classes & broadcasting and enabled with projector and Laptop/Desktop
                                            </td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>
                                                {' '}
                                                Extra facilities the institution possess for aiding Social Science,
                                                Humanities & Business Studies subject
                                            </td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}> Remarks</td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                    </tbody>
                                </Table>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}
export default HeadFacilities;
