import React, {useEffect, useState} from 'react';
import { Button, Card, CardBody, Container, Table , Row, Col} from 'reactstrap';
import { api } from '../../../../../helpers/api';

function GeneralInformation(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);

    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setgeneralDetails(jsonData.classrooms);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        
        <div className="view">
            <Container className="p-0 my-4">
                <Card>
                    <CardBody className="p-0">
                        {/* <h5 className="header-title mb-3 mt-0">Institution General Information</h5> */}
                        {/* <div className="edit">
                        <Button color="primary" className="px-3" onClick={() => props.setNewReg(true)}>
                            Create
                        </Button> 
                    </div> */}
                        <div className="table-section" style={{ textAlign: 'justify' }}>
                            {generalDetails.length > 0 ? (
                                generalDetails.map((data, i) => (
                                    <Table style={{ width: '100%', tableLayout: 'fixed' }} striped key={i}>
                                        <tbody>
                                            <tr style={{ width: '50%' }}>
                                                {data.is_approved === 0 ? (
                                                    <td>
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                ) : data.is_approved === 2 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="warning" disabled>
                                                            Reverted
                                                        </Button>
                                                    </td>
                                                ) : data.is_approved === 1 ? (
                                                    <td>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved
                                                        </Button>
                                                    </td>
                                                ) : data.is_approved === 3 && (
                                                    <td>
                                                        <Button disabled color="info" size="sm">
                                                            Reverted by head
                                                        </Button>
                                                    </td>
                                                )}
                                                <td></td>
                                            </tr>
                                            <tr style={{ width: '40%' }}>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Total number of Classrooms</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.classroom ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 25</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.upto25 ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 50</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.upto50 ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 100</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.upto100 ?? '--'}</td>
                                            </tr>{' '}
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity more than 100</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{data.above100 ?? '--'}</td>
                                            </tr>{' '}
                                           
                                            <tr>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}> Remarks</td>
                                                <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}><div className="text-justify">{data.remarks ?? '--'}</div></td>
                                            </tr>{' '}
                                        </tbody>
                                    </Table>
                                ))
                            ) : (
                                <Table striped>
                                    <tbody>
                                        <tr style={{ width: '50%' }}>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}> Total number of Classroom</td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 25</td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 50</td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity upto 100</td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>Number of classrooms with seating capacity more than 100</td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                       
                                        <tr>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}> Remarks</td>
                                            <td style={{ minWidth: '500px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>{' '}
                                    </tbody>
                                </Table>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default GeneralInformation;
