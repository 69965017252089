import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';

function National(props) {
    const [formDatas, setFormDatas] = useState([]);
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [showForm, setShowForm] = useState(true);
    const [revert, setRevert] = useState(false);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };

    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get(`institution/${id}`); // Assuming api.get works as expected
            // console.log(response);
            const jsonData = response?.data?.data?.data?.grants;

            // console.log(jsonData);

            const nationalGrants = jsonData.filter((item) => item.grant_type === 'National');

            if (nationalGrants.some((item) => item.status === 0 && item.is_approved === 1)) {
                setHasDataNo(true);
            }

            if (nationalGrants.some((item) => item.status === 1 && item.is_approved === 1)) {
                setHasDataYes(true)
            }

            setGeneralDetails(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);

    const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`/grant/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setGeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    return (
        <div>
            {revert && (
                <Failure
                    title="National Agencies Grant details has been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <>
                {' '}
                <div className="d-flex justify-content-left mx-3 mt-2">
                    <Row className="label">
                        <label>Have you received any research grants from National Agencies ?</label>
                    </Row>
                    <Row className="ml-5">:</Row>
                    <Row className="ml-5">
                        {' '}
                        <FormGroup check>
                            <Input
                                type="radio"
                                name="showForm"
                                value="yes"
                                checked={showForm === true}
                                onChange={() => setShowForm(true)}
                            />{' '}
                            <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>

                                Yes
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                type="radio"
                                name="showForm"
                                value="no"
                                checked={showForm === false}
                                onChange={() => setShowForm(false)}
                            />{' '}
                            <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>

                                No
                            </Label>
                        </FormGroup>
                    </Row>
                </div>
            </>
            {showForm === true ? (
                <div className="table-section w-100 my-3 overflow-auto">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '170px' }}>Agency Name</th>
                                <th style={{ minWidth: '290px' }}>Details of Grant Received</th>
                                <th style={{ minWidth: '191px' }}>Grant Received in (year)</th>
                                <th style={{ minWidth: '210px' }}>Amount Received</th>
                                <th style={{ minWidth: '417px' }}>Remarks</th>
                                <th style={{ minWidth: '60px' }}>Status</th>
                                <th style={{ minWidth: '60px' }}>Actions</th>
                            </tr>
                        </thead>
                        {generalDetails?.length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.status === 1 && item.grant_type === 'National' ? (
                                        <tr key={index} className="text-left">
                                            <td className="text-justify">{item.name}</td>
                                            <td className="text-justify">{item.details}</td>
                                            <td>{item.year}</td>
                                            <td>{item.amount}</td>
                                            <td className="text-justify">{item.remarks}</td>
                                            <td>
                                                {item.is_approved === 0 ? (
                                                    <td className="d-flex">
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 2 ? (
                                                    <td>
                                                        <Button type="button" size="sm" color="danger" disabled>
                                                            Reverted by Head
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 1 ? (
                                                    <td>
                                                        <Button type="button" color="success" size="sm" disabled>
                                                            Approved by Head
                                                        </Button>
                                                    </td>
                                                ) : item.is_approved === 3 && (
                                                    <td>
                                                        <Button type="button" color="info" size="sm" disabled>
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    </td>
                                                )}
                                            </td>
                                            <td>
                                                {item.is_approved === 1 && (
                                                    <td>
                                                        <Button
                                                            type="button"
                                                            size="sm"
                                                            outline
                                                            color="primary"
                                                            onClick={() => handleRevert(item.id)}
                                                        >
                                                            Revert
                                                        </Button>
                                                    </td>
                                                )}
                                            </td>
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '150px' }}>Remarks</th>

                                <th style={{ minWidth: '100px' }}>Status</th>
                                <th style={{ minWidth: '100px' }}>Actions</th>
                            </tr>
                        </thead>
                        {generalDetails?.filter((item) => item.status === 0 && item.grant_type === 'National').length >
                            0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.status === 0 && item.grant_type === 'National' ? (
                                        <tr key={index} className="text-left">
                                            <>
                                                <td className="text-justify">{item.remarks}</td>
                                                <td>
                                                    {item.is_approved === 0 ? (
                                                        <td>
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 2 ? (
                                                        <td>
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted by Head
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 1 ? (
                                                        <td>
                                                            <Button type="button" color="success" size="sm" disabled>
                                                                Approved by Head
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 3 && (
                                                        <td>
                                                            <Button type="button" color="info" size="sm" disabled>
                                                                Reverted by RUSA Admin
                                                            </Button>
                                                        </td>
                                                    )}
                                                </td>
                                                <td>
                                                    {item.is_approved === 1 && (
                                                        <td>
                                                            <Button
                                                                type="button"
                                                                size="sm"
                                                                outline
                                                                color="primary"
                                                                onClick={() => handleRevert(item.id)}
                                                            >
                                                                Revert
                                                            </Button>
                                                        </td>
                                                    )}
                                                </td>
                                            </>
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            )}
        </div>
    );
}

export default National;
