import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import Success from '../../../../../../success';
import Failure from '../../../../../../institutions/failure';
import { api } from '../../../../../../../../helpers/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function DetailsOfBogHead(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [revert, setRevert] = useState(false);
    const [formData, setFormData] = useState([]);

    
    const { id } = useParams();

    
        // Function to fetch data
        const fetchData = async () => {
            try {
                const response = await api.get(`institution/${id}`); // Assuming api.get works as expected
                // console.log(response);
                const jsonData = response?.data?.data?.data;

                // console.log(jsonData);
                setGeneralDetails(jsonData.details_bog);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        useEffect(() => {
        fetchData();
    }, [id]);

    const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`/bog/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setGeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };
    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    return (
        <div>
             {revert && (
                <Failure
                    title="Details of BoG has been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="view">
                <Container className="container-width">
                    <div className="table-section w-100 overflow-auto">
                        <Table striped>
                            <thead className="table-head ml-5 mt-0 ">
                                <tr>
                                    <th>Name</th>
                                    <th>Designation</th>
                                    <th>Remarks</th>
                                    <th style={{ minLength: '150px' }}>Status</th>
                                    <th style={{ minLength: '100px' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
        {generalDetails.filter((item) => item.type === 'bog').length > 0 ? (
            generalDetails.map((item, k) => {
                if (item.type === 'bog') {
                    return (
                        <tr key={k}>
                            <td>{item.name}</td>
                            <td>{item.designation}</td>
                            <td>{item.remarks}</td>
                            <td style={{ maxWidth: '5n0px' }}>
                                                    {item.is_approved === 0 && (
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 1 && (
                                                        <Button disabled color="success" size="sm">
                                                            Approved by Head
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 2 && (
                                                        <Button disabled color="danger" size="sm">
                                                            Reverted by Head
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 3 && (
                                                        <Button disabled color="info" size="sm">
                                                            Reverted by RUSA Admin
                                                        </Button>
                                                    )}
                                                </td>
                                                <td>
                                                            {item.is_approved === 1 && (
                                                                <td>
                                                                    <Button
                                                                        type="button"
                                                                        size="sm"
                                                                        outline
                                                                        color="primary"
                                                                        onClick={() => handleRevert(item.id)}
                                                                    >  
                                                                        Revert
                                                                    </Button>
                                                                </td>
                                                            )}
                                                        </td>
                        </tr>
                    );
                } else {
                    return null; // Returning null for non-'bog' items
                }
            })
        ) : (
            <tr>
                <td colSpan="5" className="text-center">
                    No data found
                </td>
            </tr>
        )}
    </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default DetailsOfBogHead;
