import React from 'react';
import { Table } from 'reactstrap';
import { useState, useEffect } from 'react';
import { Container, Input, Button, Row, Form, Col, FormGroup, Label } from 'reactstrap';
import { MinusCircle, PlusCircle } from 'react-feather';
import { MANAGE_LABELS } from './datamanager';
import PreviewInformation from '../preview';
import Success from '../../../success';
import moment from 'moment/moment';

function Index(props) {
    const Faculityfields2 = {
        [MANAGE_LABELS.NAME]: null,
        [MANAGE_LABELS.DESIGNATION]: null,
        [MANAGE_LABELS.CONTACT_NO]: null,
        [MANAGE_LABELS.EMAIL_ID]: null,
    };
    // console.log(props);

    const [faculityData2, SetFaculityData2] = useState(props.faculityData2);
    const [totalAmount, setTotalAmount] = useState(Number(props?.totalAmount) ?? 0);

    const [keyField2, SetKeyField2] = useState(0);
    const [success, setSuccess] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [edit, setEdit] = useState(0);
    const [year1, setYear1] = useState();
    const [year2, setYear2] = useState();
    // console.log(faculityData2);

    const getYear = () => {
        const newYear = props.year.split(' - ');
        setYear1(moment(newYear[0]).format('YYYY'));
        setYear2(moment(newYear[1]).format('YYYY'));
    };

    useEffect(() => {
        getYear();
    }, [props.year]);

    // ----------------------------------------------------------
    const faculityTableChange2 = (name, value, index) => {
        let Field2 = faculityData2;
        Field2[index][name] = value;
        SetFaculityData2(Field2);
        SetKeyField2(keyField2 + 1);
        updateTotalAmount();
    };
    const removeFaculityField2 = (j) => {
        let Field2 = faculityData2.filter((item, N) => j !== N);
        // console.log(Field2);
        SetFaculityData2(Field2);
        SetKeyField2(keyField2 + 1);
    };
    const onAddFaculityFields2 = () => {
        let Field2 = faculityData2;
        Field2.push(Faculityfields2);
        SetFaculityData2(Field2);
        SetKeyField2(keyField2 + 1);
        updateTotalAmount();
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const error = validate();

        // console.log(error);
        if (Object.keys(error).length === 0) {
            if (activeStep === 1) {
                setActiveStep(2);
            } else {
                setSuccess(true);
            }
            setSuccess(false);
        }
    };
    const validate = () => {
        let error = {};

        // faculityData.forEach((item, k) => {
        //     Object.keys(item).map((key) => {
        //         if (!item[key] || item[key] === '') {
        //             error[`${k}_${key}`] = 'please fill this field';
        //         }
        //     });
        // });
        // faculityData2.forEach((item, k) => {
        //     Object.keys(item).map((key) => {
        //         if (!item[key] || item[key] === '') {
        //             error[`${k}_${key}`] = 'please fill this field';
        //         }
        //     });
        // });
        // Object.keys(formData).map((key) => {
        //     if (!formData[key] || formData[key] === '') {
        //         error[key] = 'please fill this field';
        //     }
        // });
        return error;
    };
    const handleSuccessAction = (e) => {
        props.onSubmit({ faculityData2: faculityData2 });
        props.fetchData()
    };

    // Function to update the total amount

    const updateTotalAmount = () => {
        let total = 0;
        faculityData2.forEach((item) => {
            const amount = parseFloat(item[MANAGE_LABELS.AMOUNT]) || 0;
            total += amount;
        });
        setTotalAmount(total);
    };
    useEffect(() => {
        // Update the total amount whenever faculityData2 changes
        updateTotalAmount();
    }, [faculityData2]);
    // console.log(year1, year2);
    return (
        <div>
            {success && (
                <Success
                    title="Preparatory Grant Utilisation details are submitted successfully for approval by the RUSA Admin."
                    toggle={handleSuccessAction}
                    open={success}
                />
            )}

            <div className="mt-5 preview-page">
                {activeStep === 1 ? (
                    <>
                        <Form className="form-style" onSubmit={(e) => handleSubmit(e)}>
                            <Row>
                                <Col sm={6} md={6}>
                                    <FormGroup>
                                        <Label style={{ color: 'black' }}>Financial Year </Label>
                                        <Input
                                            id={MANAGE_LABELS.YEAR}
                                            // onChange={(e) => handleChange(e.target.name, e.target.value)}
                                            value={props.year}
                                            disabled
                                            // name={MANAGE_LABELS.TOTAL_COST}
                                            placeholder=""
                                            type="text"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6} md={6}>
                                    <FormGroup>
                                        <Label style={{ color: 'black' }}>Total Amount</Label>
                                        <Input
                                            id={MANAGE_LABELS.AMOUNT}
                                            // onChange={(e) => handleChange(e.target.name, e.target.value)}
                                            value={totalAmount}
                                            disabled
                                            type="number"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Table striped>
                                <thead className="table-head">
                                    <tr>
                                        <th>
                                            Date <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                        </th>
                                        <th>
                                            Amount Utilised <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                        </th>
                                        <th>
                                            RUSA Activities <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className=" text-center" style={{ fontSize: '13px' }}>
                                    <>
                                        {faculityData2.map((item, j) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>
                                                            <Input
                                                                type="date"
                                                                value={item[MANAGE_LABELS.DATE] ?? ''}
                                                                name={MANAGE_LABELS.DATE}
                                                                min={`${year1}-04-01`}
                                                                max={`${year2}-03-31`}
                                                                required
                                                                onChange={(e) =>
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value,
                                                                        j
                                                                    )
                                                                }></Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                value={item[MANAGE_LABELS.AMOUNT] ?? ''}
                                                                name={MANAGE_LABELS.AMOUNT}
                                                                required
                                                                onChange={(e) =>
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value.replace(/[^0-9]/g, ''),
                                                                        j
                                                                    )
                                                                }></Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                value={item[MANAGE_LABELS.ACTIVITIES] ?? ''}
                                                                name={MANAGE_LABELS.ACTIVITIES}
                                                                required
                                                                onChange={(e) =>
                                                                    faculityTableChange2(
                                                                        e.target.name,
                                                                        e.target.value,
                                                                        j
                                                                    )
                                                                }></Input>
                                                        </td>

                                                        <td>
                                                            <div className="d-flex">
                                                                {j === faculityData2.length - 1 && (
                                                                    <div className="pr-2">
                                                                        <PlusCircle
                                                                            onClick={(e) => onAddFaculityFields2()}
                                                                        />
                                                                    </div>
                                                                )}
                                                                {j !== 0 && (
                                                                    <div className="pr-2">
                                                                        <MinusCircle
                                                                            onClick={(e) => {
                                                                                removeFaculityField2(j);
                                                                                updateTotalAmount();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    </>
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-start mt-4 mx-2">
                                <Row>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        // onClick={() => {
                                        // setActiveStep(2);
                                        //  setSuccess(false);
                                        // }}
                                        outline>
                                        Preview
                                    </Button>
                                </Row>
                            </div>
                        </Form>
                    </>
                ) : (
                    <>
                        <PreviewInformation
                            faculityData2={faculityData2}
                            setSuccess={setSuccess}
                            setActiveStep={setActiveStep}
                            handleEdit={props.handleEdit}
                            currentyear={props.year}
                            totalAmount={totalAmount}
                            edit={props.edit}
                            id={props?.viewData?.id ? props?.viewData?.id : null}
                            fetchData = {props.fetchData}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default Index;
