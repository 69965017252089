import React from 'react';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { MANAGE_LABELS } from '../manage/datamanager';

function PreviewInformation(props) {
    // console.log(props.status);
    // console.log(props);

    const generateInstitutions = (data) => {
        // console.log(data)
        const sendInst = [];
        data.map((item) => {
            let inst = {
                name: item?.INSTITUTION_NAME,
                approved: item?.APPROVED_COST,
                utilised: item?.AMOUNT_UTILISED,
                percentage: (item?.AMOUNT_UTILISED / item?.APPROVED_COST) * 1000,
            };
            sendInst.push(inst);
        });
        return sendInst
    };

    const handleSubmit = () => {
        const sendData = {
            cost: props?.data.AGENCY_NAME,
            amount: props?.data.AGREED_COST,
            agency: props?.data.AGENCY_NAME,
            institutions: generateInstitutions(props?.data?.fundingDetails),
        };
        // console.log(sendData)
    };
    return (
        <div>
            <div className="view">
                {/* <div className="banner">OTHER ACCREDITATIONS</div> */}

                <div className="edit mt-2">
                    <Button color="primary" className="px-3" onClick={() => props.setEdit()}>
                        Edit
                    </Button>
                </div>

                <div className="table-section w-100 ">
                    <>
                        <div className="preview-page">
                            <Row>
                                <Col sm={12} md={12} className="my-3">
                                    <Row className="text-dark">
                                        <Col md={4} sm={4}>
                                            Total Project Cost
                                        </Col>
                                        <Col md={2} sm={2}>
                                            :
                                        </Col>
                                        <Col md={6} sm={6}>
                                            {props.data[MANAGE_LABELS.TOTAL_COST] ?? '--'}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12} md={12} className="my-3">
                                    <Row className="text-dark">
                                        <Col md={4} sm={4}>
                                            Agreed probable amount of Contract
                                        </Col>
                                        <Col md={2} sm={2}>
                                            :
                                        </Col>
                                        <Col md={6} sm={6}>
                                            {props.data[MANAGE_LABELS.AGREED_COST] ?? '--'}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12} md={12} className="my-3">
                                    <Row className="text-dark">
                                        <Col md={4} sm={4}>
                                            Agency Name
                                        </Col>
                                        <Col md={2} sm={2}>
                                            :
                                        </Col>
                                        <Col md={6} sm={6}>
                                            {props.data[MANAGE_LABELS.AGENCY_NAME] ?? '--'}
                                        </Col>
                                    </Row>
                                </Col>

                                <Table striped>
                                    <thead>
                                        <tr className="text-left">
                                            <th style={{ minWidth: '50px' }}>Institution Name</th>
                                            <th style={{ minWidth: '150px' }}>Approved Cost</th>
                                            <th style={{ minWidth: '150px' }}>Amount Utilised </th>
                                            <th style={{ minWidth: '150px' }}>% of Utilisation</th>
                                        </tr>
                                    </thead>
                                    {props.data.fundingDetails?.map((item, k) => (
                                        <>
                                            <tbody>
                                                <tr className="text-left">
                                                    <td>{item.INSTITUTION_NAME ?? '--'}</td>
                                                    <td>{item.APPROVED_COST ?? '--'}</td>
                                                    <td>{item.AMOUNT_UTILISED ?? '--'}</td>
                                                    <td>{(item?.AMOUNT_UTILISED / item?.APPROVED_COST) * 1000}</td>
                                                </tr>
                                            </tbody>
                                        </>
                                    ))}
                                </Table>
                            </Row>
                            
                            {/* <Row>
                                    <Col
                                        className='d-flex justify-content-between mt-4'>
                                        <Button type="submit" color="primary" outline onClick={() => handleSubmit()}>
                                            Submit for Approval
                                        </Button>
                                    </Col>
                                </Row> */}
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
}

export default PreviewInformation;

{
    /* <Table striped>
    <thead>
        <tr className="text-left">
            <th style={{ minWidth: '50px' }}>#</th>
            <th style={{ minWidth: '150px' }}>Date of Accreditation</th>
            <th style={{ minWidth: '150px' }}>Valid Upto</th>
            <th style={{ minWidth: '100px' }}>NAAC Grade</th>
            <th style={{ minWidth: '100px' }}>NAAC Score</th>
            <th style={{ minWidth: '250px' }}>Present NAAC Accreditation Cycle</th>
        </tr>
    </thead>
    {props.data?.map((props.data, k) => (
        <tbody>
            <tr className="text-left">
                <th scope="row">{k + 1}</th>
                <td>{props.data[MANAGE_LABELS.ACCREDITATION_DATE] ?? '--'}</td>
                <td>{props.data[MANAGE_LABELS.VALID_UPTO] ?? '--'}</td>
                <td>{props.data[MANAGE_LABELS.ACCREDITATION_GRADE] ?? '--'}</td>
                <td>{props.data[MANAGE_LABELS.ACCREDITATION_CYCLE] ?? '--'}</td>
                <td>{props.data[MANAGE_LABELS.ACCREDITATION_CYCLE] ?? '--'}</td>
            </tr>
        </tbody>
    ))}
</Table>; */
}
