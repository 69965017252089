import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Button, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';

function HeadMphil(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);

    const { id } = useParams();

    const fetchData = async () => {
        try {
            const response = await api.get(`institution-dataoperator/${id}`);
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.academic_details);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };

    return (
        <div>
            {revert && (
                <Failure
                    title="Academic MPhill details has been Reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="view">
                {/* <Container className="container-width"> */}
                <div className="table-section w-100 overflow-auto">
                    <Table striped>
                        <thead className="table-head ml-5 mt-0 ">
                            <tr>
                                <th style={{ minWidth: '129px' }}>Academic Year (YYYY-YYYY)</th>
                                <th style={{ minWidth: '142px' }}>Course Type</th>
                                <th style={{ minWidth: '205px' }}>Course Discipline</th>
                                <th style={{ minWidth: '205px' }}>Course Offered</th>
                                <th style={{ minWidth: '138px' }}>No.of Seats </th>
                                <th style={{ minWidth: '168px' }}>Total no.of Students</th>
                                <th style={{ minWidth: '164px' }}>No.of Male Students</th>
                                <th style={{ minWidth: '176px' }}>No.of Female Students</th>
                                <th style={{ minWidth: '147px' }}>No.of SC Students</th>
                                <th style={{ minWidth: '212px' }}>No.of SC Female Students</th>
                                <th style={{ minWidth: '147px' }}>No.of ST Students</th>
                                <th style={{ minWidth: '200px' }}>No.of ST Female Students</th>
                                <th style={{ minWidth: '500px' }}>Remarks</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        {generalDetails.filter((item) => item.course_type === 'MPHIL').length > 0 ? (
                            <tbody>
                                {generalDetails.map((item, index) =>
                                    item.course_type === 'MPHIL' ? (
                                        <tr key={index} className="text-left">
                                            <>
                                                <td className="text-justify">{item.year}</td>
                                                <td className="text-justify">{item.type}</td>
                                                <td className="text-justify">{item.discipline}</td>
                                                <td className="text-justify">{item.course}</td>
                                                <td className="text-justify">{item.seats}</td>
                                                <td className="text-justify">{item.students}</td>
                                                <td className="text-justify">{item.male}</td>
                                                <td className="text-justify">{item.female}</td>
                                                <td className="text-justify">{item.sc}</td>
                                                <td className="text-justify">{item.sc_female}</td>
                                                <td className="text-justify">{item.st}</td>
                                                <td className="text-justify">{item.st_female}</td>
                                                <td className="text-justify">{item.remarks}</td>
                                                <td>
                                                    {item.is_approved === 0 ? (
                                                        <td className="d-flex">
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 2 ? (
                                                        <td>
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 1 ? (
                                                        <td>
                                                            <Button type="button" color="success" size="sm" disabled>
                                                                Approved
                                                            </Button>
                                                        </td>
                                                    ) : (
                                                        item.is_approved === 3 && (
                                                            <td>
                                                                <Button type="button" color="info" size="sm" disabled>
                                                                    Reverted by RUSA Admin
                                                                </Button>
                                                            </td>
                                                        )
                                                    )}
                                                </td>
                                            </>
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="15" className="text-center">
                                        No Data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
                {/* </Container> */}
            </div>
        </div>
    );
}

export default HeadMphil;
