import React from 'react';
import { Row, Container, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useState } from 'react';

import GovtOrderProceedings from './rankholders';

function DataOperatorGovtOrderProceedings() {
    
    const [activeTab, setActiveTab] = useState('1');
    
    
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card>
                    <CardBody>
                        <>
                            <TabContent>
                                {activeTab === '1' ? (
                                    <>
                        {/* <div className="mandatory my-3 text-dark">GOVERNMENT ORDERS OR PROCEEDINGS</div> */}
                        <h5 className="header-title">Government Orders & Proceedings</h5>

                                        <GovtOrderProceedings />
                                    </>
                                ) : (
                                    <>{/* <StaffDetails /> */}</>
                                )}
                            </TabContent>
                        </>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default DataOperatorGovtOrderProceedings;
