import React, { useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { api } from '../../../../../../../helpers/api';
export default function FundDetails(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await api.get(`/admin-institution-info`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.rusa_phase_one;
            setgeneralDetails(jsonData);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    let component = 'Infrastructure grants to universities';
    return (
        <div>
            <div className="table-section w-100 overflow-auto">
                <Table striped>
                    <thead className="table-head">
                        <tr className="text-left">
                            <th style={{ minWidth: '150px' }}>Component Name</th>
                            <th style={{ minWidth: '150px' }}>Total Fund approved by RUSA (Rs)</th>
                            <th style={{ minWidth: '100px' }}>Total Fund received from RUSA (Rs)</th>
                            <th style={{ minWidth: '100px' }}>Total Fund utilized by RUSA (Rs)</th>
                            <th style={{ minWidth: '100px' }}>Status</th>
                        </tr>
                    </thead>
                    {generalDetails.filter((item) => item.type === 'fund details' && item.component_name === component)
                        .length > 0 ? (
                        <tbody>
                            {generalDetails?.map((item, index) =>
                                item.type === 'fund details' && item.component_name === component ? (
                                    <tr key={index} className="text-left">
                                        <td>{item.component_name}</td>

                                        <td>{item.fund_approved}</td>

                                        <td>{item.recevied_fund}</td>

                                        <td>{item.total_fund_utilized}</td>

                                        <td>
                                            {item.is_approved === 0 ? (
                                                <td className="d-flex px-0">
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <Button disabled color="danger" size="sm">
                                                    Reverted by head
                                                </Button>
                                            ) : item.is_approved === 1 ? (
                                                <Button disabled color="success" size="sm">
                                                    Approved by head
                                                </Button>
                                            ) : item.is_approved === 3 ? (
                                                <Button disabled color="info" size="sm">
                                                    Reverted by RUSA Admin
                                                </Button>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                ) : (
                                    ''
                                )
                            )}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="15" className="text-center">
                                    No data found
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>
        </div>
    );
}
