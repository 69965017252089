import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';

function HeadNccNss(props) {
    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);
    const { id } = useParams();

    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected
            // console.log(response);
            const jsonData = response?.data?.data?.data;

            // console.log(jsonData);
            setgeneralDetails(jsonData.sports_common);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);
    // revert

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    // end revert
    return (
        <div>
            {revert && (
                <Failure
                    title="NCC & NSS details has been Reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="table-section w-100 my-3 overflow-auto">
                <Table striped>
                    <thead className="table-head">
                        <tr className="text-left">
                            {/* <th style={{ minWidth: '50px' }}>#</th> */}
                            <th style={{ minWidth: '150px' }}>Academic Year</th>
                            <th style={{ minWidth: '300px' }}>Total No of Students in NCC (Army) unit</th>
                            <th style={{ minWidth: '350px' }}>Total No of Students in NCC (Air force) unit</th>
                            <th style={{ minWidth: '300px' }}>Total No of Students in NCC (Navy) unit </th>
                            <th style={{ minWidth: '300px' }}>Total No of Students in NSS unit</th>
                            <th style={{ minWidth: '500px' }}>Other items and corresponding number of students</th>

                            <th style={{ minWidth: '600px' }}>Remarks</th>

                            <th style={{ minWidth: '100px' }}>Status</th>
                        </tr>
                    </thead>
                    {generalDetails.filter((item) => item.type === 'ncc').length > 0 ? (
                        <tbody>
                            {generalDetails.map((item, index) =>
                                item.type === 'ncc' ? (
                                    <tr key={index} className="text-left">
                                        <td>{item.year}</td>
                                        <td>{item.air_force}</td>
                                        <td>{item.army}</td>
                                        <td>{item.navy}</td>
                                        <td>{item.nss}</td>
                                        <td className="text-justify">{item.item}</td>

                                        <td className="text-justify">{item.remarks}</td>
                                        <td>
                                            {item.is_approved === 0 ? (
                                                <td className="d-flex">
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                    <br></br>
                                                </td>
                                            ) : item.is_approved === 2 ? (
                                                <td>
                                                    <Button type="button" size="sm" color="danger" disabled>
                                                        Reverted by head
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 1 ? (
                                                <td>
                                                    <Button type="button" color="success" size="sm" disabled>
                                                        Approved by head
                                                    </Button>
                                                </td>
                                            ) : item.is_approved === 3 ? (
                                                <td>
                                                    <Button type="button" size="sm" color="info" disabled>
                                                        Reverted by RUSA Admin
                                                    </Button>
                                                </td>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                ) : (
                                    ''
                                )
                            )}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="9" className="text-center">
                                    No data Found
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>
        </div>
    );
}
export default HeadNccNss;
