import React, { useEffect, useState } from 'react';
import { MinusCircle, PlusCircle } from 'react-feather';
import toast from 'react-hot-toast';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import {
    create_fund_stage2,
    list_Agencies,
    update_fund_stage2
} from '../../../../../../helpers/actions/api.functions';
import Success from '../../../../success';
import PreviewInformation from '../preview';
import { MANAGE_LABELS } from './datamanager';
function ManangeGeneralaInformation(props) {
    const [keyField, setKeyField] = useState(0);
    const [formData, setFormData] = useState(props.data);
    const [flag, setFlag] = useState(props.flag);
    const [status, setStatus] = useState(props.status);
    const [remark, setRemark] = useState(props.remark);
    const [success, setSuccess] = useState(false);
    const [activeStep, setActiveStep] = useState(props.activeStep);
    const [data, setData] = useState(formData?.institutions ??[{ id: 1 }]);
    const [agencies, setAgencies] = useState([]);
    const [totalC , setTotalC] = useState(null)
    const [agrC, setAgrC] = useState(null)

    const fields = {
        [MANAGE_LABELS.TOTAL_COST]: null,
        [MANAGE_LABELS.AGREED_COST]: null,
        [MANAGE_LABELS.AGENCY_NAME]: null,
        [MANAGE_LABELS.INSTITUTION_NAME]: null,
        [MANAGE_LABELS.APPROVED_COST]: null,
        [MANAGE_LABELS.AMOUNT_UTILISED]: null,
        [MANAGE_LABELS.AMOUNT_UTILISED]: null,
    };
    useEffect(() => {
        let cost = 0; // Initialize cost variable to 0
        let agrcost = 0; // Initialize agrcost variable to 0
    
        data.forEach((item) => {
            // Check if PROJECT_COST is valid and add it to cost
            if (item?.PROJECT_COST && item.PROJECT_COST !== "") {
                cost += Number(item.PROJECT_COST);
            }
            // Check if AGREED_CONTRACT_AMOUNT is valid and add it to agrcost
            if (item?.AGREED_CONTRACT_AMOUNT && item.AGREED_CONTRACT_AMOUNT !== "") {
                agrcost += Number(item.AGREED_CONTRACT_AMOUNT);
            }
        });
    
        // Update state variables after calculating the total sum
        setTotalC(cost);
        setAgrC(agrcost);
        setFormData((k) => ({ ...k, "PROJECT_COST" : totalC , "AGREED_CONTRACT_AMOUNT" : agrC }));
        // console.log(cost); // Log the total cost
    }, [data, data?.length]); // Include data array in
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData((k) => ({ ...k, "PROJECT_COST" : totalC , "AGREED_CONTRACT_AMOUNT" : agrC }));
        const error = validate();
        // console.log(error);
        if (Object.keys(error).length === 0) {
            if (activeStep === 1) {
                props.setActiveStep(2);
            } else {
                handleCreate();
            }
        }
    };
    const fetchAgencies = () => {
        list_Agencies()
            .then((res) => {
                setAgencies(res?.data?.data);
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };
    useEffect(() => {
        fetchAgencies();

    }, []);
    const generateInstitutions = (data) => {
        const sendInst = [];
        data.map((item) => {
            let inst = {
                name: item?.INSTITUTION_NAME,
                cost: item?.PROJECT_COST,
                contract: item?.AGREED_CONTRACT_AMOUNT,
                agency: item?.AGENCY_NAME,
                approved: item?.APPROVED_COST,
                utilised: item?.AMOUNT_UTILISED,
                percentage: (item?.AMOUNT_UTILISED / item?.APPROVED_COST) * 1000,
            };
            sendInst.push(inst);
        });
        return sendInst;
    };

    const handleCreate = () => {
        // console.log(data, formData)
        const sendData = {
            cost: totalC,
            total_cost: totalC,
            amount: agrC,
            institutions: generateInstitutions(data),
        };
        // console.log(sendData);
        if (!props?.edit) {
            create_fund_stage2(sendData)
                .then((res) => {
                    setSuccess(true);
                })
                .catch((err) => {
                    toast.error('Something Went Wrong');
                });
        } else {
            update_fund_stage2(sendData, formData?.id)
                .then((res) => {
                    setSuccess(true);
                })
                .catch((err) => {
                    toast.error('Something Went Wrong');
                });
        }
    };
    // const handleNext= () => {
    //     props.setActiveStep(2);
    // }
    useEffect(() => {
        setActiveStep(props.activeStep);
    }, [props.activeStep]);
    const handleSuccessAction = (e) => {
        props.onSubmit({ data: { ...formData, fundingDetails: data }, flag: flag, status: status, remark: remark });
        props.fetchData();
    };

    const handleChange = (name, value) => {
        // console.log(name);
        setFormData((k) => ({ ...k, [name]: value }));

        setKeyField((f) => f + 1);
    };

    const validate = () => {
        let error = {};
        if (flag === 'Y')
            Object.keys(formData).map((key) => {
        if( (key !== 'AGREED_CONTRACT_AMOUNT' && key !== 'PROJECT_COST')){
            if (!formData[key] || formData[key] === '' ) {
                error[`${key}`] = 'please fill this field';
            }
        }
               
            });

        return error;
    };
    // console.log(formData);
    // console.log(data);

    // ----------
    // State to manage table data

    // Function to handle adding a new row
    const addRow = () => {
        const newRow = { id: Date.now() };
        setData([...data, newRow]);
    };

    // Function to handle removing a row
    const removeRow = (idToRemove) => {
        // Ensure that there is more than one row before removing
        if (data.length > 1) {
            const updatedData = data.filter((row) => row.id !== idToRemove);
            setData(updatedData);
        } else {
            // Handle the case where there is only one row (e.g., show a message)
            // console.log('Cannot remove the only row');
        }
    };

    // Function to handle input changes
    const handleInputChange = (id, field, value) => {
        // console.log(id, field, value);
        const updatedData = data.map((row) => (row.id === id ? { ...row, [field]: value } : row));
        // console.log(updatedData);
        setData(updatedData);
    };
// console.log(data)
    // ----------
   
    
// console.log(data)
    return (
        <>
            {success && (
                <Success
                    title="Equity Initiative utilisation stage 2 details are submitted successfully for approval by the RUSA Admin"
                    toggle={handleSuccessAction}
                    open={success}
                />
            )}
            <div className="manage">
                <div className="form-section" style={{ margin: '15px 0' }}>
                    {/* <Container className="form-container "> */}
                    <Container>
                        <Form className="form-style my-0" onSubmit={(e) => handleSubmit(e)}>
                            {activeStep === 1 && (
                                <>
                                    <>
                                        <Row className="repeat-box" style={{ padding: '20px 0' }}>
                                            <Col sm={6} md={6}>
                                                <FormGroup>
                                                    <Label style={{ fontSize: '13px' }}>
                                                        Total Project Cost{' '}
                                                        <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                    </Label>
                                                    <Input
                                                        id={MANAGE_LABELS.TOTAL_COST}
                                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                        value={totalC ?? ''}
                                                        name={MANAGE_LABELS.TOTAL_COST}
                                                        placeholder=""
                                                        // required
                                                        type="number"
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6} md={6}>
                                                <FormGroup>
                                                    <Label style={{ fontSize: '13px' }}>
                                                        Agreed probable amount of Contract{' '}
                                                        <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                    </Label>
                                                    <Input
                                                        id={MANAGE_LABELS.AGREED_CONTRACT_AMOUNT}
                                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                        value={agrC ?? ''}
                                                        name={MANAGE_LABELS.AGREED_CONTRACT_AMOUNT}
                                                        placeholder=""
                                                        // required
                                                        type="number"
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={12} md={12}>
                                                <FormGroup
                                                    style={{
                                                        backgroundColor: '#3b6eb9',
                                                        color: '#fff',
                                                        padding: '7px',
                                                        marginTop: '15px',
                                                        borderRadius: '5px',
                                                    }}>
                                                    <div>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ fontSize: '13px' }}>
                                                                        Institution Name{' '}
                                                                        <sup style={{ color: 'red', fontSize: '13px' }}>
                                                                            *
                                                                        </sup>{' '}
                                                                    </th>
                                                                    <th style={{ fontSize: '13px' }}>
                                                                        Project Cost{' '}
                                                                        <sup style={{ color: 'red', fontSize: '13px' }}>
                                                                            *
                                                                        </sup>{' '}
                                                                    </th>
                                                                    <th style={{ fontSize: '13px' }}>
                                                                        Agreed Contract Amount{' '}
                                                                        <sup style={{ color: 'red', fontSize: '13px' }}>
                                                                            *
                                                                        </sup>{' '}
                                                                    </th>
                                                                    <th style={{ fontSize: '13px' }}>
                                                                        Agency Name{' '}
                                                                        <sup style={{ color: 'red', fontSize: '13px' }}>
                                                                            *
                                                                        </sup>{' '}
                                                                    </th>
                                                                    <th style={{ fontSize: '13px' }}>
                                                                        Approved Cost{' '}
                                                                        <sup style={{ color: 'red', fontSize: '13px' }}>
                                                                            *
                                                                        </sup>{' '}
                                                                    </th>
                                                                    <th style={{ fontSize: '13px' }}>
                                                                        Amount Utilised{' '}
                                                                        <sup style={{ color: 'red', fontSize: '13px' }}>
                                                                            *
                                                                        </sup>{' '}
                                                                    </th>
                                                                    <th style={{ fontSize: '13px' }}>
                                                                        % of Utilisation{' '}
                                                                        <sup style={{ color: 'red', fontSize: '13px' }}>
                                                                            *
                                                                        </sup>{' '}
                                                                    </th>
                                                                    
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((row, i) => (
                                                                    <tr key={row.id}>
                                                                        <td>
                                                                            <Input
                                                                                type="text"
                                                                                value={
                                                                                    row[
                                                                                        MANAGE_LABELS.INSTITUTION_NAME
                                                                                    ] ?? ''
                                                                                }
                                                                                id={MANAGE_LABELS.INSTITUTION_NAME}
                                                                                name={MANAGE_LABELS.INSTITUTION_NAME}
                                                                                className='text-black'
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row.id,
                                                                                        MANAGE_LABELS.INSTITUTION_NAME,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input
                                                                                type="number"
                                                                                value={
                                                                                    row[MANAGE_LABELS.PROJECT_COST] ??
                                                                                    ''
                                                                                }
                                                                                id={MANAGE_LABELS.PROJECT_COST}
                                                                                name={MANAGE_LABELS.PROJECT_COST}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row.id,
                                                                                        MANAGE_LABELS.PROJECT_COST,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input
                                                                                type="number"
                                                                                value={
                                                                                    row[
                                                                                        MANAGE_LABELS
                                                                                            .AGREED_CONTRACT_AMOUNT
                                                                                    ] ?? ''
                                                                                }
                                                                                id={
                                                                                    MANAGE_LABELS.AGREED_CONTRACT_AMOUNT
                                                                                }
                                                                                name={
                                                                                    MANAGE_LABELS.AGREED_CONTRACT_AMOUNT
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row.id,
                                                                                        MANAGE_LABELS.AGREED_CONTRACT_AMOUNT,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {/* <Input
                                                                                type="text"
                                                                                value={
                                                                                    row[MANAGE_LABELS.AGENCY_NAME] ?? ''
                                                                                }
                                                                                id={MANAGE_LABELS.AGENCY_NAME}
                                                                                name={MANAGE_LABELS.AGENCY_NAME}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row.id,
                                                                                        MANAGE_LABELS.AGENCY_NAME,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            /> */}
                                                                            <select
                                                                                autoComplete="off"
                                                                                required
                                                                                id={MANAGE_LABELS.AGENCY_NAME}
                                                                                name={MANAGE_LABELS.AGENCY_NAME}
                                                                                className="form-control"
                                                                                value={
                                                                                    row[MANAGE_LABELS.AGENCY_NAME] ?? ''
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row?.id,
                                                                                        MANAGE_LABELS.AGENCY_NAME,
                                                                                        e.target.value
                                                                                    )
                                                                                }>
                                                                                <option value="">Select Agency</option>
                                                                                {/* <option value="Test">Test</option> */}
                                                                                {agencies.map((item, i) => (
                                                                                    <option
                                                                                        key={i}
                                                                                        value={item?.agency}>
                                                                                        {item?.agency}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <Input
                                                                                type="number"
                                                                                value={
                                                                                    row[MANAGE_LABELS.APPROVED_COST] ??
                                                                                    ''
                                                                                }
                                                                                id={MANAGE_LABELS.APPROVED_COST}
                                                                                name={MANAGE_LABELS.APPROVED_COST}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row.id,
                                                                                        MANAGE_LABELS.APPROVED_COST,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input
                                                                                type="number"
                                                                                value={
                                                                                    row[
                                                                                        MANAGE_LABELS.AMOUNT_UTILISED
                                                                                    ] ?? ''
                                                                                }
                                                                                id={MANAGE_LABELS.AMOUNT_UTILISED}
                                                                                name={MANAGE_LABELS.AMOUNT_UTILISED}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row.id,
                                                                                        MANAGE_LABELS.AMOUNT_UTILISED,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input
                                                                                type="number"
                                                                                value={
                                                                                    row[
                                                                                        MANAGE_LABELS.AMOUNT_UTILISED
                                                                                    ] &&
                                                                                    row[MANAGE_LABELS.APPROVED_COST]
                                                                                        ? (
                                                                                              (row[
                                                                                                  MANAGE_LABELS
                                                                                                      .AMOUNT_UTILISED
                                                                                              ] /
                                                                                                  row[
                                                                                                      MANAGE_LABELS
                                                                                                          .APPROVED_COST
                                                                                                  ]) *
                                                                                              100
                                                                                          ).toFixed(2)
                                                                                        : '--'
                                                                                }
                                                                                id={MANAGE_LABELS.PERCENTAGE_UTILISED}
                                                                                name={MANAGE_LABELS.PERCENTAGE_UTILISED}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        row.id,
                                                                                        MANAGE_LABELS.PERCENTAGE_UTILISED,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className='d-flex'>
                                                                            {i === data.length - 1 && (
                                                                                <PlusCircle onClick={addRow} />
                                                                            )}
                                                                            {i !== 0 && (
                                                                                <MinusCircle
                                                                                    onClick={() => removeRow(row.id)}
                                                                                />
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </>
                                </>
                            )}
                            {activeStep === 2 && (
                                <PreviewInformation
                                    setEdit={() => props.setActiveStep(1)}
                                    data={{ ...formData, fundingDetails: data ,
                                        // TOTAL_COST: totalC, // Pass totalC as prop
                                        // AGREED_CONTRACT_AMOUNT: agrC, // Pass agrC as prop
                                    }}
                                    flag={flag}
                                    status={status}
                                    remark={remark}
                                    handleSubmit={handleSubmit}
                                />
                            )}
                            <div className="">
                                <Row>
                                    <Col
                                        className={`d-flex justify-content-between mt-4 ${
                                            activeStep === 1 ? 'px-0' : 'px-2'
                                        }`}>
                                        <Button type="submit" color="primary" outline>
                                            {activeStep === 1 ? 'Preview' : 'Submit for Approval'}
                                        </Button>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col
                                        className='d-flex justify-content-between mt-4'>
                                        <Button type="submit" color="primary" outline onClick={handleSubmit}>
                                            Preview
                                        </Button>
                                    </Col>
                                </Row> */}
                            </div>
                        </Form>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ManangeGeneralaInformation;
