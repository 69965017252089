import React from 'react';
import { Row, Container, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useState } from 'react';

import SANCTIONS from './best practise';

function BestPractiseInstitution() {
    const tabContents = [{ id: '1', title: 'NAAC' }];
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card>
                    <CardBody>
                        <h5 className="header-title mb-0 mt-0">Best Practices</h5>

                        <>
                            <TabContent>
                                <>
                                    <SANCTIONS />
                                </>
                            </TabContent>
                        </>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default BestPractiseInstitution;
