import React from 'react';
import { Row, Container, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useState } from 'react';
import DataOperatorStaffPatternMain from './staff_details';
function PreparatoryGrantUtilisation() {
    const tabContents = [{ id: '1', title: 'STAFF PATTERN IN RUSA' }];
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            <DataOperatorStaffPatternMain />
        </div>
    );
}

export default PreparatoryGrantUtilisation;
