import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';

function AdminRankHolder(props) {
 
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);





    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.dpr);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <div>
            <div className="view">
                <Container className="p-0">
                   
                        
                            
                            <div className="table-section w-100 overflow-auto">
                                <Table striped>
                                <thead className="table-head ml-5 mt-0">
                                <tr>
                                    <th style={{minWidth:"150px"}}>DPR</th>
                                    <th style={{minWidth:"200px"}}>Approved plan for Construction</th>
                                    <th style={{minWidth:"200px"}}>Approved plan for Renovation</th>
                                    <th style={{minWidth:"300px"}}>Remarks</th>
                                    <th style={{minWidth:"150px"}}>Status</th>
                                </tr>
                            </thead>
                                    {generalDetails.length > 0 ? (
                                        <tbody>
                                            {generalDetails.map((item, index) => (
                                                <tr key={index} className="text-left">
                                                    <td  style={{maxWidth:"150px"}}>{item.link}</td>
                                                   
                                                    <td>
                                                        <a href={item.construction_plan} target="_blank" rel="noopener noreferrer">
                                                            View
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={item.renovation_plan} target="_blank" rel="noopener noreferrer">
                                                            View
                                                        </a>
                                                    </td>
                                                    <td className='text-justify'>{item.remarks}</td>
                                                    {item.is_approved === 0 ? (
                                                        <td>
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 2 ? (
                                                        <td>
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted by Head
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 1 ? (
                                                        <td>
                                                            <Button type="button" color="success" size="sm" disabled>
                                                                Approved by Head
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 3 ? (
                                                        <td>
                                                            <Button type="button" color="info" size="sm" disabled>
                                                                Reverted by RUSA Admin
                                                            </Button>
                                                        </td>
                                                    ) : (
                                                        ''
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan="9" className="text-center">
                                                    No data Found
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </Table>

                            </div>
                       
                    
                </Container>
            </div>
        </div>
    );
}
export default AdminRankHolder;
