import React, { useEffect, useState } from 'react';
import FormArea from './form';
import { MANAGE_LABELS } from './datamanager';
import { Button, Table } from 'reactstrap';
import Success from '../../success';
import {
    create_fundUtilization,
    delete_fundUtilization,
    list_fundRecieved,
    list_fundUtilization,
    update_fundUtilization,
} from '../../../../../../helpers/actions/api.functions';
import toast from 'react-hot-toast';
import { Edit, Trash2 } from 'react-feather';
import { getErrorMessage } from '../../../../../../components/genError';
export default function DataOperatorFundUtlisationMain(props) {
    const handleFields = () => {
        return {};
    };
    const [formData, setFormData] = useState({ ...handleFields() });
    const [formDatas, setFormDatas] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [flag, setFlag] = useState('Y');
    const [success, setSuccess] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [listData, setListData] = useState([]);
    const [indEdit, setIndEdit] = useState(false);

    const handleSaveData = () => {
        setSuccess(true);
    };
    const handleSuccessAction = () => {
        setSuccess(false);
        if (Object.keys(formData)?.length > 0) {
            const data = formDatas;
            data.push(formData);
            setFormDatas(data);
            setFormData({ ...handleFields() });
            setOpenForm(false);
            setActiveStep(1);
        } else {
            alert('Please fill the form');
        }
    };
    const handleNext = () => {
        setActiveStep(0);
    };
    const fetchData = () => {
        list_fundUtilization()
            .then((res) => {
                setListData(res?.data?.data);
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const createFund = (data) => {
        // console.log(data, 'create budget');

        const objData = {
            year: `${data?.START_YEAR}-${Number(data?.START_YEAR) + 1}`,
            remarks: data?.REMARKS,
            phase: data?.RUSA_PHASE,
            utilisation: data?.PERCENTAGE_UTILISED,
            total_fund_institution: data?.TOTAL_FUND_UTILISED,
            total_central: data?.TOTAL_CENTRAL_SHARE,
            total_state: data?.TOTAL_STATE_SHARE,
            total_fund: `${Number(data?.TOTAL_CENTRAL_SHARE) + Number(data?.TOTAL_STATE_SHARE)}`,
        };
        // console.log(objData, indEdit);
        if (!indEdit) {
            create_fundUtilization(objData)
                .then((res) => {
                    toast.success('Budget Added Successfully');
                    setSuccess(true);
                    fetchData();
                    // setEdit(false);
                    setOpenForm(false);

                    // setPrimaryStep(2)
                })
                .catch((err) => {
                    toast.error(`${getErrorMessage(err?.response?.data?.error)}`, {
                        duration: 4000,
                        position: 'top-right',
                    });
                });
        } else {
            // console.log('hey hey hey');
            update_fundUtilization(objData, data?.id)
                .then((res) => {
                    setSuccess(true);
                    fetchData();
                    setOpenForm(false);
                    // setPrimaryStep(1);
                })
                .catch((err) => {
                    toast.error(`${getErrorMessage(err.response.data.error)}`, {
                        duration: 4000,
                        position: 'top-right',
                    });
                });
        }
    };
    const handleDelete = (id) => {
        delete_fundUtilization(id)
            .then((res) => {
                fetchData();
                toast.success('Fund Received & Utilisation Details Deleted Successfully');
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };
    const setEditData = (item, id) => {
        const dt = String(item?.year);
        // console.log(dt);
        const newYear = dt.split('-');
        // console.log(newYear);
        const Faculityfields3 = {
            START_YEAR: newYear[0] ?? '--',
            END_YEAR: newYear[1] ?? '--',
            RUSA_PHASE: item?.phase,
            PERCENTAGE_UTILISED: item?.utilisation,
            TOTAL_FUND_UTILISED: item?.total_fund_institution,
            TOTAL_CENTRAL_SHARE: item?.total_central,
            TOTAL_STATE_SHARE: item?.total_state,
            TOTAL_FUND_RECEIVED: item?.total_fund,
            REMARKS: item?.remarks,
            id: item?.id,
        };
        // console.log(Faculityfields3);
        setFormData(Faculityfields3);
        // setEdit(true);
        setActiveStep(1);
        setOpenForm(true);
    };
    const handleCallBack = () => {
        setSuccess(false);
        setOpenForm(false);
    };
    return (
        <div>
            {success && (
                <Success
                    title=" Fund Received & Utilisation Details are submitted for approval by the RUSA Admin."
                    toggle={handleCallBack}
                    open={success}
                />
            )}
            <>
                {/* {success && (
                <Success
                    title="Fund received & Utilization details are submitted for approval by the RUSA Admin."
                    toggle={handleCallBack}
                    open={success}
                />
            )} */}
                {!openForm && (
                    <div className="d-flex justify-content-end">
                        {' '}
                        <Button onClick={() => setOpenForm(true)} color="primary">
                            Create
                        </Button>
                    </div>
                )}

                {openForm && (
                    <>
                        <FormArea
                            formData={formData}
                            handleNext={handleNext}
                            activeStep={activeStep}
                            setFormData={setFormData}
                            handleSaveData={handleSaveData}
                            setActiveStep={setActiveStep}
                            createFund={createFund}
                        />
                    </>
                )}
                {!openForm && (
                    <div className="table-section w-100 mt-3 overflow-auto">
                        {/* <div className="mandatory my-3 text-dark">GOVERNMENT ORDERS OR PROCEEDINGS</div> */}
                        <Table striped>
                            <thead className="table-head">
                                <tr className="text-left">
                                    <th style={{ minWidth: '200px', verticalAlign: 'middle' }}>Financial Year</th>
                                    <th style={{ minWidth: '115px', verticalAlign: 'middle' }}>RUSA Phase</th>
                                    <th style={{ minWidth: '222px', verticalAlign: 'middle' }}>
                                        Total Central Share Received
                                    </th>
                                    <th style={{ minWidth: '215px', verticalAlign: 'middle' }}>
                                        Total State Share Received
                                    </th>
                                    <th style={{ minWidth: '170px', verticalAlign: 'middle' }}>Total Fund Received </th>
                                    <th style={{ minWidth: '260px', verticalAlign: 'middle' }}>
                                    Total fund utilised by beneficiaries
                                    </th>
                                    <th style={{ minWidth: '135px', verticalAlign: 'middle' }}>% of Utilisation </th>
                                    <th style={{ minWidth: '100px', verticalAlign: 'middle' }}>Remarks </th>
                                    <th style={{ minWidth: '100px', verticalAlign: 'middle' }}>Status </th>
                                    <th style={{ minWidth: '100px', verticalAlign: 'middle' }}>Actions </th>
                                </tr>
                            </thead>
                            {listData.length > 0 ? (
                                listData?.map((item) => (
                                    <tbody>
                                        <tr className="text-left">
                                            <td>{item.year}</td>
                                            <td>{item.phase}</td>
                                            <td>{item.total_central}</td>
                                            <td>{item.total_state}</td>
                                            <td>{item.total_fund}</td>
                                            <td>{item.total_fund_institution}</td>
                                            <td>{item.utilisation}</td>
                                            <td>{item.remarks ?? '--'}</td>
                                            <td>
                                                {item.is_approved === 0 ? (
                                                    <Button
                                                        disabled
                                                        color="warning"
                                                        class="btn btn-secondary"
                                                        style={{
                                                            color: '#ED7D31',
                                                            fontWeight: '400',
                                                            backgroundColor: '#F5F7F8',
                                                        }}
                                                        size="sm">
                                                        Pending
                                                    </Button>
                                                ) : item.is_approved === 2 ? (
                                                    <Button disabled color="danger" size="sm">
                                                        Reverted By Head
                                                    </Button>
                                                ) : item.is_approved === 1 ? (
                                                    <Button disabled color="success" size="sm">
                                                        Approved By Head
                                                    </Button>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td>
                                                <Edit
                                                    className="me-3 cursor-pointer"
                                                    onClick={() => {
                                                        setEditData(item, item.id);
                                                        setIndEdit(true);
                                                    }}
                                                />
                                                {/* <Trash2
                                                            className="ml-3 cursor-pointer"
                                                            onClick={() => handleDelete(item.id)}
                                                        /> */}
                                                <Trash2
                                                    className="ml-3 text-danger"
                                                    onClick={() => handleDelete(item.id)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                ))
                            ) : (
                                <tbody>
                                    <tr className="text-center">
                                        <td colSpan={9}>No Data Found</td>
                                    </tr>
                                </tbody>
                            )}
                        </Table>
                    </div>
                )}
            </>
        </div>
    );
}
