import React, { useState } from 'react';
import { Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import PreviewInformation from '../preview/index.jsx';

const FormArea = (props) => {
    const { formData, setFormData, handleSaveData, activeStep, handleNext, handleCancelEdit, edit, setActiveStep } =
        props;

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        // Validate the 'Best practices' field
        if (!formData.best_practices || formData.best_practices.trim() === '') {
            newErrors.best_practices = 'Please fill up the details within a 500 character limit';
        }

        // Add more validations for other fields if needed

        setErrors(newErrors);

        // Return true if there are no errors, otherwise return false
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            handleNext(edit);
            // console.log(edit);
        }
    };

    return (
        <div>
            <div className="manage">
                <div className="form-section">
                   
                    <Container className="form-container permanent-area my-4">
                        <div>
                            {activeStep === 1 ? (
                                <>
                                    <Form onSubmit={handleSubmit}>
                                        <>
                                            <>
                                                {' '}
                                                <Row className="my-3">
                                                    <Col md={12} sm={12} my-2>
                                                        <Label className="mb-2">
                                                            Describe the Best practices implemented by the college <sup style={{ color: 'red', fontSize: '13px' }}>*</sup> <div>eg: Learning Management System, Rain water harvesting,
                                                            Solar plant installations and so on </div> 
                                                            
                                                        </Label>
                                                        <Input
                                                            autoComplete="off"
                                                            type="text"
                                                            
                                                            maxLength={250}
                                                            value={formData.best_practices || ''}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    best_practices: e.target.value,
                                                                })
                                                            }
                                                        />
                                                        {!errors.best_practices && (
                                                           <div style={{ color: '#2971b6' }}>(Limit your description to 250 characters or less)</div>
                                                        )}
                                                        {errors.best_practices && (
                                                            <div className="text-danger">{errors.best_practices}</div>
                                                        )}
                                                    </Col>
                                                </Row>{' '}
                                            </>
                                            {/* -----------end---------- */}
                                        </>
                                        <Row>
                                            <Col md={12} sm={12} my-2>
                                                <Label className="my-2">Remarks</Label>
                                                <Input
                                                    maxLength={1000}
                                                    autoComplete="off"
                                                    type="text"
                                                    value={formData.remarks || ''}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, remarks: e.target.value })
                                                    }
                                                />
                                                <div style={{ color: '#2971b6' }}>
                                                    (Limit your description to 1000 characters or less)
                                                </div>
                                            </Col>
                                        </Row>{' '}
                                        <div className="my-4">
                                            <Button type="submit">Preview</Button>
                                            {edit && (
                                                <Button type="button" onClick={handleCancelEdit} className="ml-2">
                                                    Cancel Edit
                                                </Button>
                                            )}
                                        </div>
                                    </Form>
                                </>
                            ) : (
                                <>
                                    <PreviewInformation
                                        formData={formData}
                                        edit={edit}
                                        activeStep={activeStep}
                                        handleSaveData={handleSaveData}
                                        setActiveStep={setActiveStep}
                                    />
                                </>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default FormArea;
