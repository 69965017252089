import classnames from 'classnames';
import React, { useState } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import FaculityDetails from './Faculity_details';
import StaffDetails from './staff_details';
function AdminStaffDeatails() {
    const tabContents = [
        { id: '1', title: 'Faculty Details' },
        { id: '2', title: 'Non-teaching Staff Details' },
    ];
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card>
                    <CardBody>
                        <h5 className="header-title mb-3 mt-0">Details of Staff Members</h5>

                        <>
                            <Nav className="nav nav-pills navtab-bg nav-justified">
                                {tabContents.map((tab, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === tab.id })}
                                                onClick={() => {
                                                    toggle(tab.id);
                                                }}>
                                                <i className={classnames(tab.icon, 'd-sm-none', 'd-block', 'mr-1')}></i>
                                                <span className="d-none d-sm-block">{tab.title}</span>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>

                            <TabContent>
                                {activeTab === '1' ? (
                                    <>
                                        <FaculityDetails />
                                    </>
                                ) : (
                                    <>
                                        <StaffDetails />
                                    </>
                                )}
                            </TabContent>
                        </>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default AdminStaffDeatails;
