import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import { api } from '../../../../../../../helpers/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import DprComponent from './DPR';
import GeneralInfoComponents from './general_info';
import RUSA1 from './RUSA1';
import RUSA2 from './RUSA2';
import DetailsOfBOG from './details_of_BOG';
import DetailsOfPMU from './details_of_PMU';
import GalleryComponent from './Gallery';

function Accreditation() {
    const { id } = useParams();
    const tabContentsOne = [
        { id: '1', title: 'General Info', component: GeneralInfoComponents },
        { id: '2', title: 'Details of BoG', component: DetailsOfBOG },
        { id: '3', title: 'Details of PMU', component: DetailsOfPMU },
        { id: '4', title: 'RUSA 1', component: RUSA1 },
        { id: '5', title: 'DPR', component: DprComponent },
        { id: '6', title: 'Gallery', component: GalleryComponent },
    ];
    const tabContentsTwo = [
        { id: '1', title: 'General Info', component: GeneralInfoComponents },
        { id: '2', title: 'Details of BoG', component: DetailsOfBOG },
        { id: '3', title: 'Details of PMU', component: DetailsOfPMU },
        { id: '4', title: 'RUSA 2', component: RUSA2 },
        { id: '5', title: 'DPR', component: DprComponent },
        { id: '6', title: 'Gallery', component: GalleryComponent },
    ];

    const tabContentsComman = [
        { id: '1', title: 'General Info', component: GeneralInfoComponents },
        { id: '2', title: 'Details of BoG', component: DetailsOfBOG },
        { id: '3', title: 'Details of PMU', component: DetailsOfPMU },
        { id: '4', title: 'RUSA 1', component: RUSA1 },
        { id: '5', title: 'RUSA 2', component: RUSA2 },
        { id: '6', title: 'DPR', component: DprComponent },
        { id: '7', title: 'Gallery', component: GalleryComponent },
    ];
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState(true);
    const [phase, setPhase] = useState(null);

    useEffect(() => {
        const fetchDataAndUpdateTab = async () => {
            try {
                const response = await api.get(`institution/${id}`);
                const phase = response?.data?.data?.data?.phase;

                console.log(response);
                // Update active tab based on the phase from API response
                setPhase(phase);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        // Call the function to fetch data and update active tab
        fetchDataAndUpdateTab();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Card>
                <CardBody>
                    {phase === 'RUSA 1' ? (
                        <Container className="form-container">
                            <h5 className="header-title mb-3 mt-0">RUSA Phase Beneficiaries</h5>

                            <Nav className="nav nav-pills navtab-bg nav-justified">
                                {tabContentsOne.map((tab) => (
                                    <NavItem key={tab.id}>
                                        <NavLink
                                            href="#"
                                            className={activeTab === tab.id ? 'active' : ''}
                                            onClick={() => setActiveTab(tab.id)}>
                                            {tab.title}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>

                            <TabContent>
                                {tabContentsOne.map((tab) => (
                                    <React.Fragment key={tab.id}>
                                        {activeTab === tab.id && tab.component && <tab.component />}
                                    </React.Fragment>
                                ))}
                            </TabContent>
                        </Container>
                    ) : phase === 'RUSA 2' ? (
                        <Container className="form-container">
                            <h5 className="header-title mb-3 mt-0">RUSA Phase Beneficiaries</h5>

                            <Nav className="nav nav-pills navtab-bg nav-justified">
                                {tabContentsTwo.map((tab) => (
                                    <NavItem key={tab.id}>
                                        <NavLink
                                            href="#"
                                            className={activeTab === tab.id ? 'active' : ''}
                                            onClick={() => setActiveTab(tab.id)}>
                                            {tab.title}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>

                            <TabContent>
                                {tabContentsTwo.map((tab) => (
                                    <React.Fragment key={tab.id}>
                                        {activeTab === tab.id && tab.component && <tab.component />}
                                    </React.Fragment>
                                ))}
                            </TabContent>
                        </Container>
                    ) : phase === 'RUSA 1 & 2' ? (
                        <Container className="form-container">
                            <h5 className="header-title mb-3 mt-0">RUSA Phase Beneficiaries</h5>

                            <Nav className="nav nav-pills navtab-bg nav-justified">
                                {tabContentsComman.map((tab) => (
                                    <NavItem key={tab.id}>
                                        <NavLink
                                            href="#"
                                            className={activeTab === tab.id ? 'active' : ''}
                                            onClick={() => setActiveTab(tab.id)}>
                                            {tab.title}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>

                            <TabContent>
                                {tabContentsComman.map((tab) => (
                                    <React.Fragment key={tab.id}>
                                        {activeTab === tab.id && tab.component && <tab.component />}
                                    </React.Fragment>
                                ))}
                            </TabContent>
                        </Container>
                    ) : (
                        ''
                    )}
                </CardBody>
            </Card>
        </div>
    );
}

export default Accreditation;
