import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function HeadSeminarHall(props) {
    // console.log(props.status);
    // console.log(props.remark);
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const { id } = useParams();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected

                const jsonData = response?.data?.data?.data;

                // console.log(jsonData);
                setgeneralDetails(jsonData.seminar_halls);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);
    return (
        <div>
            {generalDetails.length > 0 ? (
                generalDetails.map((data, i) => (
                    <div className="view">
                        <Container className="container-width ml-1">
                            <div className="table-section w-100">
                                
                                    <>
                                        <div className="preview-page mx-0">
                                            <Row>
                                                <Col className='my-3'>
                                                    <>
                                                        {data.is_approved === 0 && (
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 1 && (
                                                            <Button disabled color="success" size="sm">
                                                                Approved
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 2 && (
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted
                                                            </Button>
                                                        )}
                                                    </>
                                                </Col>
                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={4} sm={4}>
                                                        Total Number of seminar halls
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={7} sm={7}>
                                                            {data.seminar_count ?? '--'}
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col sm={12} md={12} className="mt-3 mb-1">
                                                    {' '}
                                                    <div className="d-flex justify-content-center w-100">
                                                        <Table striped className="">
                                                            <thead className="table-head">
                                                                <tr className="text-left">
                                                                    <th style={{maxWidth:"400px"}}>
                                                                    Seminar Hall details
                                                                    </th>
                                                                    <th style={{maxWidth:"400px"}}>
                                                                        Seating capacity
                                                                    </th>
                                                                    <th style={{maxWidth:"400px"}}>Remarks</th>
                                                                    {/* <th style={{ minWidth: '150px' }}>Actions</th> */}
                                                                </tr>
                                                            </thead>
                                                            {data.details ? (
                                                                <tbody>
                                                                    {data.details.map((item, k) => (
                                                                        <tr className="text-left" key={k}>
                                                                            <td className='text-justify' style={{maxWidth:"400px"}}>{item.detail ?? '--'}</td>
                                                                            <td style={{maxWidth:"400px"}}>{item.capacity ?? '--'}</td>
                                                                            <td className='text-justify' style={{maxWidth:"400px"}}>{item.remarks ?? '--'}</td>
                                                                            {/* <td>
                                                                <FaRegEdit className="edit-icon" />
                                                            </td> */}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            ) : (
                                                                <tbody>
                                                                    <tr className="text-center">
                                                                        <td colSpan="3">No data found</td>
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                
                            </div>
                        </Container>
                    </div>
                ))
            ) : (
                <>
                    <div className="view">
                        <Container className="container-width ml-1">
                            <div className="table-section w-100 ">
                                <>
                                    <div className="preview-page">
                                        <Row>
                                            <Col sm={12} md={12} className="my-3">
                                                <Row className="text-dark">
                                                    <Col md={4} sm={4}>
                                                        Total number of Seminar Halls
                                                    </Col>
                                                    <Col md={1} sm={1}>
                                                        :
                                                    </Col>
                                                    <Col md={7} sm={7}>
                                                        --
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col sm={12} md={12} className="my-3">
                                                <div className="d-flex justify-content-center w-100">
                                                    <Table striped className="">
                                                        <thead className="table-head">
                                                            <tr className="text-left">
                                                                <th style={{ minWidth: '50px' }}>Seminar Hall details</th>
                                                                <th style={{ minWidth: '150px' }}>Seating capacity</th>
                                                                <th style={{ minWidth: '150px' }}>Remarks</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr className="text-center">
                                                                <td colSpan="3">No data found</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            </div>
                        </Container>
                    </div>
                </>
            )}
        </div>
        // <div>
        //     <div className="view">
        //         <Container className="p-0">
        //             <Card>
        //                 <CardBody className="p-0">
        //                     <div className="table-section w-100 ">
        //                         <>
        //                             <div className="preview-page">
        //                                 <Row>
        //                                     <Col sm={12} md={12} className="my-3">
        //                                         <Row className="text-dark">
        //                                             <Col md={4} sm={4}>
        //                                                 Total Number of seminar halls
        //                                             </Col>
        //                                             <Col md={2} sm={2}>
        //                                                 :
        //                                             </Col>
        //                                             <Col md={6} sm={6}></Col>
        //                                         </Row>
        //                                     </Col>

        //                                     <div className="d-flex justify-content-center w-100">
        //                                         <Table striped className="">
        //                                             <thead className="table-head">
        //                                                 <tr className="text-left">
        //                                                     <th style={{ minWidth: '50px' }}>Seminar Hall Details</th>
        //                                                     <th style={{ minWidth: '150px' }}> Seating Capacity</th>
        //                                                     <th style={{ minWidth: '150px' }}> Remarks</th>
        //                                                     <th style={{ minWidth: '150px' }}> Actions</th>
        //                                                 </tr>
        //                                             </thead>
        //                                         </Table>
        //                                     </div>
        //                                 </Row>
        //                             </div>
        //                         </>
        //                     </div>
        //                 </CardBody>
        //             </Card>
                    
        //         </Container>
        //     </div>
        // </div>
    );
}

export default HeadSeminarHall;
