import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { api } from '../../../../../../../helpers/api';
import Failure from '../../../../../institutions/failure';
const RankHolders = (props) => {
    // console.log(props);
    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [revert, setRevert] = useState(false);

   
        // Function to fetch data
        const fetchData = async () => {
            try {
                const response = await api.get(`institution/${id}`); // Assuming api.get works as expected
                 // console.log(response);
                const jsonData = response?.data?.data?.data;

                // console.log(jsonData);
                setGeneralDetails(jsonData.rank);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        useEffect(() => {
            fetchData();
        }, [id]);

        const handleRevert = async (itemId) => {
            try {
                const response = await api.post(`/rank/resubmit/${itemId}`);
                const updatedDetails = generalDetails.map((item) =>
                    item.id === itemId ? { ...item, is_approved: 3 } : item
                );
                setGeneralDetails(updatedDetails);
                setRevert(true);
            } catch (error) {
                // console.error('Error reverting data:', error);
            }
        };
    
        const handleRevertAction = () => {
            fetchData();
            setRevert(false);
        };

    
    return (
        <div>
             {revert && (
                <Failure
                    title="Rank Holders details has been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="view">
                <Container className="p-0">
                    <Card>
                        <CardBody className="p-5">
                            <h5 className="header-title mb-3 mt-0">Details of Rank Holders</h5>
                            <div className="table-section w-100 overflow-auto">
                                <Table striped>
                                    <thead className="table-head">
                                        <tr className="text-left">
                                        <th style={{ minWidth: '150px' }}>Academic Year</th>
                                    <th style={{ minWidth: '195px' }}>Number of Rank Holders</th>
                                    <th style={{ minWidth: '200px' }}>Subject</th>
                                    
                                    <th style={{ minWidth: '250px' }}>Remarks</th>
                                    
                                    <th style={{ minWidth: '60px' }}>Status</th>
                                    <th style={{ minWidth: '60px' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    {generalDetails?.length > 0 ? (
                                        <tbody>
                                            {generalDetails.map((item, index) => (
                                                <tr key={index} className="text-left">
                                                    <>
                                                        <td>{item.year}</td>
                                                        <td>{item.rank}</td>
                                                        <td className='text-justify'>{item.subject}</td>
                                                        <td className='text-justify'>{item.remarks}</td>
                                                        <td>
                                                        {item.is_approved === 0 ? (
                                                            <td>
                                                                <Button
                                                                    disabled
                                                                    color="warning"
                                                                    class="btn btn-secondary"
                                                                    style={{
                                                                        color: '#ED7D31',
                                                                        fontWeight: '400',
                                                                        backgroundColor: '#F5F7F8',
                                                                    }}
                                                                    size="sm">
                                                                    Pending
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 2 ? (
                                                            <td>
                                                                <Button type="button" size="sm" color="danger" disabled>
                                                                    Reverted by Head
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 1 ? (
                                                            <td>
                                                                <Button disabled color="success" size="sm">
                                                                    Approved by Head
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 3 && (
                                                            <td>
                                                                <Button type="button" size="sm" color="info" disabled>
                                                                    Reverted by RUSA Admin
                                                                </Button>
                                                            </td>
                                                            
                                                        )}
                                                        </td>
                                                        <td>
                                                            {item.is_approved === 1 && (
                                                                <td>
                                                                    <Button
                                                                        type="button"
                                                                        size="sm"
                                                                        outline
                                                                        color="primary"
                                                                        onClick={() => handleRevert(item.id)}
                                                                    >  
                                                                        Revert
                                                                    </Button>
                                                                </td>
                                                            )}
                                                        </td>
                                                    </>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan="5" className="text-center">
                                                    No data Found
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </div>
    );
};

export default RankHolders;
