import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';

function HeadLibraryDetails(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [showForm, setShowForm] = useState(true);
    const [revert, setRevert] = useState(false);
    const [hasDataYes, setHasDataYes] = useState(false);
    const [hasDataNo, setHasDataNo] = useState(false);
    const highlightedStyle = {
        fontWeight: '700'
    };
    // Function to fetch data
    const fetchData = async () => {
        try {
            const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected
            // console.log(response);
            const jsonData = response?.data?.data?.data?.library;

            const internet = jsonData;

            if (internet.some((item) => item.status === 0 && item.is_approved === 1 )) {
                setHasDataNo(true);
            }

            if (internet.some((item) => item.status === 1 && item.is_approved === 1 )) {
                setHasDataYes(true)
            }              
            setgeneralDetails(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    // end revert

    return (
        <div>
            {revert && (
                <Failure
                    title="Library details has been Reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <>
                {' '}
                <div className="d-flex justify-content-left mx-3">
                    <Row className="label">
                        <label>Is there library in your institution campus ?</label>
                    </Row>
                    <Row className="ml-5">:</Row>
                    <Row className="ml-5">
                        {' '}
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="yes"
                                    checked={showForm === true}
                                    onChange={() => setShowForm(true)}
                                />{' '}
                            <Label check className={hasDataYes ? 'blink' : ''} style={highlightedStyle}>
                                Yes
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                                <Input
                                    type="radio"
                                    name="showForm"
                                    value="no"
                                    checked={showForm === false}
                                    onChange={() => setShowForm(false)}
                                />{' '}
                            <Label check className={hasDataNo ? 'blink' : ''} style={highlightedStyle}>
                                No
                            </Label>
                        </FormGroup>
                    </Row>
                </div>
            </>
            {showForm === true ? (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th>Permanent Librarian?</th>
                                <th>Subscribed/Purchased any consortiums</th>
                                <th>No. of books</th>
                                <th style={{ maxWidth: '150px' }}>Remarks</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        {generalDetails.filter((data) => data.status === 1).length > 0 ? (
                            <tbody>
                                {generalDetails.map(
                                    (item, index) =>
                                        item.status === 1 && (
                                            <tr key={index} className="text-left">
                                                <td>{item.permanent_librarian ? 'Yes' : 'No'}</td>
                                                <td>{item.institution_subscription ? 'Yes' : 'No'}</td>
                                                <td>{item.physical_books ?? '--'}</td>
                                                <td style={{ maxWidth: '150px' }}>{item.remarks}</td>
                                                <td>
                                                    {item.is_approved === 0 ? (
                                                        <td className="d-flex">
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                            <br></br>
                                                        </td>
                                                    ) : item.is_approved === 2 ? (
                                                        <td>
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted by head
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 1 ? (
                                                        <td>
                                                            <Button type="button" color="success" size="sm" disabled>
                                                                Approved by head
                                                            </Button>
                                                        </td>
                                                    ) : item.is_approved === 3 ? (
                                                        <td>
                                                            <Button type="button" color="info" size="sm" disabled>
                                                                Reverted by RUSA Admin
                                                            </Button>
                                                        </td>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            ) : (
                <div className="table-section w-100 my-3">
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                <th style={{ maxWidth: '350px' }}>Remarks</th>
                                <th style={{ minWidth: '100px' }}>Status</th>
                            </tr>
                        </thead>
                        {generalDetails.filter((data) => data.status === 0).length > 0 ? (
                            <tbody>
                                {generalDetails.map(
                                    (item, index) =>
                                        item.status === 0 && (
                                            <tr key={index} className="text-left">
                                                <>
                                                    <td style={{ maxWidth: '350px' }}>{item.remarks}</td>
                                                    <td>
                                                        {item.is_approved === 0 ? (
                                                            <td className="d-flex">
                                                                <Button
                                                                    disabled
                                                                    color="warning"
                                                                    class="btn btn-secondary"
                                                                    style={{
                                                                        color: '#ED7D31',
                                                                        fontWeight: '400',
                                                                        backgroundColor: '#F5F7F8',
                                                                    }}
                                                                    size="sm">
                                                                    Pending
                                                                </Button>
                                                                <br></br>
                                                            </td>
                                                        ) : item.is_approved === 2 ? (
                                                            <td>
                                                                <Button type="button" size="sm" color="danger" disabled>
                                                                    Reverted by head
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 1 ? (
                                                            <td>
                                                                <Button
                                                                    type="button"
                                                                    color="success"
                                                                    size="sm"
                                                                    disabled>
                                                                    Approved by head
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 3 ? (
                                                            <td>
                                                                <Button type="button" color="info" size="sm" disabled>
                                                                    Approved by RUSA Admin
                                                                </Button>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </>
                                            </tr>
                                        )
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        No data Found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            )}
        </div>
    );
}

export default HeadLibraryDetails;
