import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import { FaFileExcel } from "react-icons/fa";
import { getLoggedInUser } from '../../../../../helpers/authUtils';
import toast from 'react-hot-toast';
import { assistant_fund, finance_fund, nodal_fund, research_fund, section_fund, state_fund, system_fund } from '../../../../../helpers/actions/api.functions';


function FundReceived() {
    const [data, setData] = useState([]);
    const userData = getLoggedInUser();
    const userRole = userData?.role_id;
    // console.log(userRole);

    const getEndpoint = (id) => {
        switch (id) {
            case '9':
                return section_fund();
            case '10':
                return state_fund();
            case '7':
                return research_fund();
            case '8':
                return nodal_fund();
            case '11':
                return assistant_fund();
            case '12':
                return system_fund();
            case '6':
                return finance_fund();
            default:
                return '';
        }
    };
    const getStaffListing = (userRole) => {
        getEndpoint(userRole)
            .then((res) => {
                setData(res?.data?.data);
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
            });
    };

    useEffect(() => {
        if (userRole) {
            getStaffListing(userRole);
        }
    }, [userRole]);
    return (
        <div>
        <div className="view ">
            
            
        </div>
        <Container className="form-container" style={{ padding: '10px' }}>
        

        
        <div className="table-section w-100 my-5 ">
                   
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                
                                <th style={{ minWidth: '80px' }}>Financial Year</th>
                                <th style={{ minWidth: '80px' }}>RUSA Phase</th>
                                <th style={{ minWidth: '80px' }}>Component Name</th>
                                <th style={{ minWidth: '80px' }}>Total Central Share Received</th>
                                <th style={{ minWidth: '80px' }}>Total State Share Received</th>
                                <th style={{ minWidth: '80px' }}>Total Fund Received</th>
                                <th style={{ minWidth: '80px' }}>Remarks</th>
                                <th style={{ minWidth: '80px' }}>Status</th>
                                
                            </tr>
                        </thead>
                        
                        {data && data?.length > 0 ? (
                                    <tbody>
                                        {data?.map((item) => (
                                            <tr className="text-left">
                                                <td scope="col">{item?.year}</td>
                                                <td scope="col">{item?.phase}</td>
                                                <td scope="col">{item?.component}</td>
                                                <td scope="col">{item?.total_central}</td>
                                                <td scope="col">{item?.total_state}</td>
                                                <td scope="col">{item?.total_fund}</td>
                                                <td scope="col">{item?.remarks ?? "--"}</td>
                                                <td style={{ maxWidth: '5n0px' }}>
                                                    {item.is_approved === 0 && (
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 1 && (
                                                        <Button disabled color="success" size="sm">
                                                            Approved
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 2 && (
                                                        <Button disabled color="danger" size="sm">
                                                            Reverted 
                                                        </Button>
                                                    )}
                                                    </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr className="text-center">
                                            <th colSpan={8}>No Data Found</th>
                                        </tr>
                                    </tbody>
                                )}
                     
                    </Table>
                </div>
        </Container>        
    </div>
    );
}

export default FundReceived;
