import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import {
    districts,
    general_afliated_university,
    general_institutions_type,
    location_Data,
    nature_of_college_management,
} from '../datamanager';
import Cookies from 'js-cookie';
import PreviewInformation from '../preview/index.jsx';

const FormArea = (props) => {
    const {
        formData,
        setFormData,
        handleSaveData,
        activeStep,
        handleNext,
        handleCancelEdit,
        edit,
        setActiveStep,
        handleEdit,
    } = props;

    const [emailError, setEmailError] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Check if the entered year is valid
        if (formData.isValidYear === false) {
            // Update the error state with a message
            setError('Please enter a valid year (1980 - 2040).');
            // Optionally, you can return to exit the function and prevent further execution
            return;
        }
        if (validateEmail(formData.official_email_of_principal)) {
            // Proceed with form submission
            // ...
        } else {
            // Display an error message or prevent form submission
            // ...
        }
        handleNext(edit);
    };

    const [showOtherInput, setShowOtherInput] = useState(false);

    useEffect(() => {
        if (props.formData.other_nature !== null && props.formData.name_of_college_management === 'Others') {
            setShowOtherInput(true);
        }
    }, []);

    const handleCollegeManagementChange = (e) => {
        const selectedValue = e.target.value;

        setFormData({
            ...formData,
            name_of_college_management: selectedValue,
            other_nature: '',
        });

        setShowOtherInput(selectedValue === 'Others');
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setFormData({
            ...formData,
            official_email_of_principal: emailValue,
        });

        if (validateEmail(emailValue)) {
            setEmailError('');
        } else {
            setEmailError('Please enter a valid email address');
        }
    };

    const handleOtherNatureChange = (e) => {
        const otherNatureValue = e.target.value;

        setFormData({
            ...formData,
            other_nature: otherNatureValue,
        });
    };

    const userData = JSON.parse(Cookies.get('institution')) || {};

    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    return (
        <div>
            <div className="manage">
                <div className="form-section">
                    <Container className="form-container" style={{ backgroundColor: '#c5e7f3', padding: '20px' }}>
                        <div>
                            {activeStep === 1 ? (
                                <>
                                    <div className="top-header">
                                        <div className="info">
                                            <div className="nb">
                                                NB: Please fill the details in RUSA scheme context.
                                            </div>
                                        </div>
                                    </div>
                                    <Form onSubmit={handleSubmit}>
                                        <>
                                            <>
                                                {' '}
                                                <Row className="my-3">
                                                    <Col md={6} sm={6} my-2>
                                                        <Label className="my-2">
                                                            Name of the Institution
                                                            <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                        </Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            maxLength={200}
                                                            type="text"
                                                            value={userData?.institution_name}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    best_practices: e.target.value,
                                                                })
                                                            }></Input>
                                                    </Col>
                                                    <Col md={6} sm={6} my-2>
                                                        <Label className="my-2">
                                                            Type of the Institution
                                                            <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                        </Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="select"
                                                            value={formData.type_of_institution || ''}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    type_of_institution: e.target.value,
                                                                })
                                                            }>
                                                            <option value="">- select -</option>
                                                            {general_institutions_type.map((ty, i) => (
                                                                <option key={i}>{ty.label}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>{' '}
                                            </>
                                            {/* -----------end---------- */}
                                        </>
                                        <Row>
                                            <Col md={12} sm={12} my-2>
                                                <Label className="my-2">
                                                    Address with Pincode
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    autoComplete="off"
                                                    type="textarea"
                                                    maxLength={200}
                                                    required
                                                    value={formData.address || ''}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, address: e.target.value })
                                                    }
                                                />
                                                <div style={{ color: '#2971b6' }}>
                                                    (Limit your description to 200 characters or less)
                                                </div>
                                            </Col>
                                        </Row>{' '}
                                        <Row className="my-3">
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    Affiliated University
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    type="select"
                                                    value={formData.affiliated_university || ''}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            affiliated_university: e.target.value,
                                                        })
                                                    }>
                                                    <option value="">- select -</option>
                                                    {general_afliated_university.map((ty, i) => (
                                                        <option key={i}>{ty.label}</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    Nature of College Management
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                                </Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    type="select"
                                                    value={formData.name_of_college_management}
                                                    onChange={handleCollegeManagementChange}>
                                                    <option value="">- Select -</option>
                                                    {nature_of_college_management.map((ty, i) => (
                                                        <option key={i} value={ty.label}>
                                                            {ty.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </Row>{' '}
                                        {showOtherInput && (
                                            <Row>
                                                <Col md={12} sm={12} my-2>
                                                    <Label className="my-2">
                                                        Specify Nature of College management{' '}
                                                    </Label>
                                                    <Input
                                                        autoComplete="off"
                                                        maxLength={200}
                                                        type="text"
                                                        value={formData.other_nature || ''}
                                                        onChange={handleOtherNatureChange}
                                                    />
                                                    <div style={{ color: '#2971b6' }}>
                                                    (Limit your description to 200 characters or less)
                                                </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col md={12} sm={12} my-2>
                                                <Label className="my-2">
                                                    Name and Address of the Institution Management
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    autoComplete="off"
                                                    type="textarea"
                                                    required
                                                    maxLength={200}
                                                    value={formData.name_address_of_institution_management || ''}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            name_address_of_institution_management: e.target.value,
                                                        })
                                                    }
                                                />
                                                <div style={{ color: '#2971b6' }}>
                                                    (Limit your description to 200 characters or less)
                                                </div>
                                            </Col>
                                        </Row>{' '}
                                        <Row className="my-3">
                                            <Col md={6} sm={12}>
                                                <Label className="my-2">Year of Establishment (YYYY format)</Label>
                                                <span style={{ color: 'red' }}>*</span>
                                                <Input
                                                    autoComplete="off"
                                                    required
                                                    placeholder="yyyy"
                                                    type="text"
                                                    maxLength="4"
                                                    value={formData.year_of_establishment}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value.replace(/[^0-9.]/g, '');
                                                        const isValidYear = inputValue >= 1980 && inputValue <= 2040;

                                                        setFormData({
                                                            ...formData,
                                                            year_of_establishment: inputValue,
                                                            isValidYear: isValidYear,
                                                        });
                                                    }}
                                                />
                                                {/* Display the error message directly under the input field */}
                                                {error && (
                                                    <p style={{ color: 'red', margin: '0', paddingTop: '5px' }}>
                                                        {error}
                                                    </p>
                                                )}
                                                <div style={{ color: '#2971b6' }}>
                                                    (The year must be between 1980 and 2040)
                                                </div>
                                            </Col>
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    District<sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    type="select"
                                                    value={formData.district || ''}
                                                    onChange={(e) => {
                                                        // const selectedDistrict = e.target.value;
                                                        // setSelectedDistrict(selectedDistrict);
                                                        setFormData({ ...formData, district: e.target.value });

                                                        // const districtData = location_Data.find(
                                                        //     (item) => item.name === selectedDistrict
                                                        // );
                                                        // const parliamentaryOptions = districtData
                                                        //     ? districtData.parle_const
                                                        //     : [];
                                                        // setParliamentaryOptions(parliamentaryOptions);

                                                        // const assemblyOptions = districtData
                                                        //     ? districtData.assembly_const
                                                        //     : [];
                                                        // setAssemblyOptions(assemblyOptions);
                                                    }}
                                                    disabled={edit}>
                                                    <option value="">- select -</option>
                                                    {districts.map((districtItem, index) => (
                                                        <option key={index} value={districtItem.label}>
                                                            {districtItem.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </Row>{' '}
                                        <Row className="my-3">
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    Parliamentary Constituency
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    type="select"
                                                    value={formData.parliamentary_constituency || ''}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            parliamentary_constituency: e.target.value,
                                                        })
                                                    }>
                                                    <option value="">- select -</option>

                                                    {/* {location_Data?.filter(item=>item.name===formData.district)?.parle_const} */}
                                                    {location_Data
                                                        ?.find((item) => item.name === formData.district)
                                                        ?.parle_const?.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </Input>
                                            </Col>
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    Assembly Constituency
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    type="select"
                                                    value={formData.assembly_constituency || ''}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            assembly_constituency: e.target.value,
                                                        })
                                                    }>
                                                    <option value="">- select -</option>
                                                    {location_Data
                                                        ?.find((item) => item.name === formData.district)
                                                        ?.assembly_const?.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </Input>
                                            </Col>
                                        </Row>{' '}
                                        <Row className="my-3">
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    Revenue District Office
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    maxLength={100}
                                                    type="text"
                                                    value={formData.revenue_district_office || ''}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            revenue_district_office: e.target.value,
                                                        })
                                                    }></Input>
                                                <div style={{ color: '#2971b6' }}>
                                                    (Limit your description to 100 characters or less)
                                                </div>
                                            </Col>
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    Taluk<sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    required
                                                    maxLength={100}
                                                    autoComplete="off"
                                                    type="text"
                                                    value={formData.taluk || ''}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            taluk: e.target.value,
                                                        })
                                                    }></Input>
                                                <div style={{ color: '#2971b6' }}>
                                                    (Limit your description to 100 characters or less)
                                                </div>
                                            </Col>
                                        </Row>{' '}
                                        <Row className="my-3">
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    State<sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    required
                                                    disabled
                                                    autoComplete="off"
                                                    type="text"
                                                    value={formData.state || 'Kerala'} // Set the initial value to 'Kerala'
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            state: e.target.value,
                                                        })
                                                    }></Input>
                                            </Col>
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    Official Email ID
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    required
                                                    maxLength={200}
                                                    autoComplete="off"
                                                    type="email"
                                                    value={userData?.email}
                                                    disabled={props.edit}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            email: e.target.value,
                                                        })
                                                    }></Input>
                                            </Col>
                                        </Row>{' '}
                                        <Row className="my-3">
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    Official Contact / Landline Number
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    required
                                                    type="text"
                                                    minLength={10}
                                                    maxLength={12}
                                                    value={formData.official_contact_no || ''}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            official_contact_no: e.target.value.replace(/[^0-9]/g, ''),
                                                        })
                                                    }></Input>
                                            </Col>
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    Name of the Principal
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    maxLength={100}
                                                    type="text"
                                                    value={formData.principal || ''}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            principal: e.target.value,
                                                        })
                                                    }></Input>
                                            </Col>
                                        </Row>{' '}
                                        <Row className="my-3">
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    Official Email ID of the Principal
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                                                </Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    type="email"
                                                    maxLength={200}
                                                    value={formData.official_email_of_principal || ''}
                                                    onChange={handleEmailChange}
                                                    invalid={emailError !== ''}
                                                />
                                                {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                                            </Col>
                                            <Col md={6} sm={6} my-2>
                                                <Label className="my-2">
                                                    Official Contact No. of the Principal
                                                    <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                                                </Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    type="text"
                                                    // pattern="\d{10}"
                                                    minLength={10}
                                                    maxLength={12}
                                                    value={formData.official_contact_no_of_principal || ''}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            official_contact_no_of_principal: e.target.value.replace(
                                                                /[^0-9]/g,
                                                                ''
                                                            ),
                                                        })
                                                    }></Input>
                                            </Col>
                                        </Row>{' '}
                                        <Row>
                                            <Col md={12} sm={12} my-2>
                                                <Label className="my-2">Remarks</Label>
                                                <Input
                                                    autoComplete="off"
                                                    type="textarea"
                                                    maxLength={1000}
                                                    value={formData.remarks || ''}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, remarks: e.target.value })
                                                    }
                                                />
                                                <div style={{ color: '#2971b6' }}>
                                                    (Limit your description to 1000 characters or less)
                                                </div>
                                            </Col>
                                        </Row>{' '}
                                        <div className="my-3">
                                            {!emailError ? (
                                                <>
                                                    <Button type="submit">
                                                        {edit === true ? 'Preview' : 'Submit for Approval'}
                                                    </Button>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            {edit && (
                                                <Button type="button" onClick={handleCancelEdit} className="ml-2">
                                                    Cancel Edit
                                                </Button>
                                            )}
                                        </div>
                                    </Form>{' '}
                                </>
                            ) : (
                                <>
                                    <PreviewInformation
                                        formData={formData}
                                        edit={edit}
                                        activeStep={activeStep}
                                        handleSaveData={handleSaveData}
                                        setActiveStep={setActiveStep}
                                    />
                                </>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default FormArea;
