import React from 'react';
import { useState } from 'react';
import ManangeGeneralaInformation from './manage';
import GeneralInformation from './view';
import { MANAGE_LABELS } from './manage/datamanager';

const Faculityfields2 = {
    [MANAGE_LABELS.YEAR]: null,
    [MANAGE_LABELS.ACCREDITED_AGENCY_NAME]: null,
    [MANAGE_LABELS.DETAILS]: null,
    [MANAGE_LABELS.REMARKS]: null,
};
const initial_values2 = [Faculityfields2];
function DataOperatorStaffPatternMain() {
    const [faculityData2, setFaculityData2] = useState(initial_values2);
    const [edit, setEdit] = useState(false);
    const [newReg, setNewReg] = useState(true);

    const manageEdit = () => {
        setEdit(true);
    };
    const onSubmit = (data) => {
        // console.log(data);

        setFaculityData2(data.faculityData2);

        setEdit(false);
        setNewReg(false);
    };

    return (
        <div className="generalinfo">
            {newReg || edit ? (
                <ManangeGeneralaInformation
                    faculityData2={faculityData2}
                    setFaculityData2={setFaculityData2}
                    edit={edit}
                    onSubmit={onSubmit}
                />
            ) : (
                <GeneralInformation
                    setEdit={manageEdit}
                    setFaculityData2={setFaculityData2}
                    faculityData2={faculityData2}
                />
            )}
        </div>
    );
}

export default DataOperatorStaffPatternMain;
