import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';
import { getLoggedInUser } from '../../../../../helpers/authUtils';
import Success from '../../../success';
import FormArea from './form';
export default function ViewComponent(props) {
    const handleFields = () => {
        return {};
    };
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({ ...handleFields() });
    const [formDatas, setFormDatas] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const { id } = useParams();
    const [flag, setFlag] = useState('Y');
    const [success, setSuccess] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [edit, setEdit] = useState();

    const [editIndex, setEditIndex] = useState(null);
    const [course, setCourse] = useState([]);
    const userData = getLoggedInUser();
    const user = userData?.role_id;
    const handleEdit = (id) => {
        const index = course.findIndex((item) => item.id === id);
        setEditIndex(index);
        setFormData({ ...course[index] });
        setOpenForm(true);
        setEdit(true);
    };
    const handleSaveData = () => {
        setSuccess(true);
    };
    const handleSuccessAction = () => {
        setSuccess(false);
        fetchData();
        if (Object.keys(formData)?.length > 0) {
            const data = formDatas;
            data.push(formData);
            setFormDatas(data);
            setFormData({ ...handleFields() });
            setOpenForm(false);
            setActiveStep(1);
        } else {
            alert('Please fill the form');
        }
    };
    const handleNext = () => {
        setActiveStep(0);
    };

    let fetchData;
    if (user === '9') {
        fetchData = async () => {
            try {
                const response = await api.get(`/section/courses`); // Assuming api.get works as expected

                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setCourse(jsonData);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '10') {
        fetchData = async () => {
            try {
                const response = await api.get(`/state/courses`); // Assuming api.get works as expected

                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setCourse(jsonData);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '7') {
        fetchData = async () => {
            try {
                const response = await api.get(`/research/courses`); // Assuming api.get works as expected

                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setCourse(jsonData);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '8') {
        fetchData = async () => {
            try {
                const response = await api.get(`/nodal/courses`); // Assuming api.get works as expected

                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setCourse(jsonData);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '11') {
        fetchData = async () => {
            try {
                const response = await api.get(`/assistant/courses`); // Assuming api.get works as expected

                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setCourse(jsonData);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '12') {
        fetchData = async () => {
            try {
                const response = await api.get(`/system/courses`); // Assuming api.get works as expected

                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setCourse(jsonData);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '6') {
        fetchData = async () => {
            try {
                const response = await api.get(`/finance/courses`); // Assuming api.get works as expected

                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setCourse(jsonData);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else {
    }

    useEffect(() => {
        fetchData();
    }, [id]);

    // const fetchData = async () => {
    //     try {
    //         const response = await api.get('courses'); // Assuming api.get works as expected
    //         // console.log(response);
    //         const jsonData = response?.data?.data;

    //         console.log(jsonData);
    //         setCourse(jsonData);
    //         setLoading(false);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    console.log(course);
    return (
        <div>
            <>
                {!openForm && (
                    <div className="d-flex justify-content-between my-5">
                        {' '}
                        <h5 className="header-title">Institution Course Type</h5>
                    </div>
                )}
                {success && (
                    <Success
                        title="Institution Course Type Created Successfully."
                        toggle={handleSuccessAction}
                        open={success}
                    />
                )}
                {openForm && (
                    <>
                        <FormArea
                            formData={formData}
                            handleNext={handleNext}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            setFormData={setFormData}
                            handleSaveData={handleSaveData}
                        />
                    </>
                )}
                {!openForm && (
                    <div className="table-section w-100 ">
                        {/* <div className="mandatory my-3 text-dark">Details of Rank holders</div> */}
                        <Table striped>
                            <thead className="table-head">
                                <tr className="text-left">
                                    <th style={{ minWidth: '150px' }}>Course Type</th>
                                    <th style={{ minWidth: '150px' }}>Specialization</th>
                                </tr>
                            </thead>
                            {course?.map((item, id) => {
                                console.log(course, 'course');
                                console.log('item', item);
                                return (
                                    <tbody key={id}>
                                        <tr className="text-left">
                                            <td>{item.course}</td>
                                            <td>{item.specialisation}</td>
                                        </tr>
                                    </tbody>
                                );
                            })}
                        </Table>
                    </div>
                )}
            </>
        </div>
    );
}
