import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import {
    assistant_fundUtilization,
    finance_fundUtilization,
    nodal_fundUtilization,
    research_fundUtilization,
    section_fundUtilization,
    state_fundUtilization,
    system_ffundUtilization,
} from '../../../../../helpers/actions/api.functions';
import { getLoggedInUser } from '../../../../../helpers/authUtils';
import toast from 'react-hot-toast';

function FundingUtilization() {
    const [data, setData] = useState([]);
    const userData = getLoggedInUser();
    const userRole = userData?.role_id;
    // console.log(userRole);

    const getEndpoint = (id) => {
        switch (id) {
            case '9':
                return section_fundUtilization();
            case '10':
                return state_fundUtilization();
            case '7':
                return research_fundUtilization();
            case '8':
                return nodal_fundUtilization();
            case '11':
                return assistant_fundUtilization();
            case '12':
                return system_ffundUtilization();
            case '6':
                return finance_fundUtilization();
            default:
                return '';
        }
    };
    const getStaffListing = (userRole) => {
        getEndpoint(userRole)
            .then((res) => {
                setData(res?.data?.data);
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
            });
    };

    useEffect(() => {
        if (userRole) {
            getStaffListing(userRole);
        }
    }, [userRole]);
    return (
        <div>
            <div className="view "></div>
            {/* <Container className="form-container" style={{ padding: '10px' }}> */}
            <div className="table-section w-100 my-5  ">
                <Table striped>
                    <thead className="table-head">
                        <tr className="text-left">
                            <th style={{ minWidth: '80px' }}>Financial Year</th>
                            <th style={{ minWidth: '80px' }}>RUSA Phase</th>
                            <th style={{ minWidth: '80px' }}>Total Central Share Received</th>
                            <th style={{ minWidth: '80px' }}>Total State Share Received</th>
                            <th style={{ minWidth: '80px' }}>Total Fund Received</th>
                            <th style={{ minWidth: '80px' }}>Total fund utilised by beneficiaries</th>
                            <th style={{ minWidth: '80px' }}>% of Utilization</th>
                            <th style={{ minWidth: '80px' }}>Remarks</th>
                            <th style={{ minWidth: '80px' }}>Status</th>
                        </tr>
                    </thead>
                    {/* {College_names.map((item, id) => {
                                
                                return (
                                    <tbody key={id}>
                                        <tr className="text-left">
                                            <td>{id+1}</td>
                                            <td>{item.label}</td>
                                            <td><FiEdit /></td>
                                            
                                        </tr>
                                    </tbody>
                                );
                            })} */}
                    {data && data?.length > 0 ? (
                        <tbody>
                            {data?.map((item) => (
                                <tr className="text-left">
                                    <td className="capitalize">{item?.year}</td>
                                    <td className="capitalize">{item?.phase}</td>

                                    <td>{item?.total_central}</td>
                                    <td>{item?.total_state}</td>
                                    <td>{item?.total_fund}</td>
                                    <td>{item?.total_fund_institution}</td>
                                    <td>{item?.utilisation}</td>
                                    <td>{item?.remarks ? item?.remarks : '--'}</td> 
                                    <td style={{ maxWidth: '5n0px' }}>
                                                    {item.is_approved === 0 && (
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 1 && (
                                                        <Button disabled color="success" size="sm">
                                                            Approved
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 2 && (
                                                        <Button disabled color="danger" size="sm">
                                                            Reverted 
                                                        </Button>
                                                    )}
                                                    </td>
                                    {/* //test */}
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr className="text-center">
                                <th colSpan={9}>No Data Found</th>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>
            {/* </Container> */}
        </div>
    );
}

export default FundingUtilization;
