import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { api } from '../../../../../../helpers/api';
import FailureError from '../error';
const PreviewInformation = (props) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };
    const [isSubmitting, setIsSubmitting] = useState(false);
    // console.log(props.edit);

    const handleSaveData = () => {
        if (isSubmitting) {
            // If already submitting, do nothing
            return;
        }

        setIsSubmitting(true);
        if (!props.edit) {
            // If not in edit mode, make a POST request
            api.post('institution/best-practice', { ...props.formData })
                .then((response) => {
                    // Handle the response from the API if needed
                    // console.log('success:', response.data);
                    props.handleSaveData();
                })
                .catch((error) => {
                    // Handle errors if the POST request fails
                    // console.error('fails:', error);
                    toggleModal();
                });
        } else {
            // If in edit mode, make a PUT request
            props.formData.is_approved = 0;
            api.put(`/institution/best-practice/${props.formData.id}`, props.formData)
                .then((response) => {
                    props.handleSaveData();
                })
                .catch((error) => {
                    // Handle error, maybe show an error message
                    // console.error('Error updating data:', error);
                    toggleModal();
                });
        }
    };

    const handleBack = () => {
        props.setActiveStep(1);
    };

    // console.log(props);
    return (
        <div>
            <div className="d-flex justify-content-end my-4">
                {' '}
                <Button color="primary" onClick={() => handleBack()}>
                    Edit
                </Button>
            </div>
            <div className="preview-page">
                <Row>
                    <Col sm={12} md={12} className="mb-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                'Best practices' implemented by the college
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                            <div style={{ textAlign: 'justify' }}>{props.formData?.best_practices}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Remarks
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                <div style={{ textAlign: 'justify' }}>{props.formData?.remarks}</div>
                            </Col>
                        </Row>
                    </Col>

                    {/* <Button color="primary" onClick={() => props.handleSaveData()}> */}
                    <Button color="primary" className="mx-3 my-2" onClick={handleSaveData} disabled={isSubmitting}>
                        Submit for Approval
                    </Button>
                </Row>
            </div>
            <FailureError isOpen={isModalOpen} toggle={toggleModal} />
        </div>
    );
};

export default PreviewInformation;
