import React from 'react';
import * as FeatherIcon from 'react-feather';
import { Redirect, Route } from 'react-router-dom';

import { getActiveUser, isUserAuthenticated } from '../helpers/authUtils';
import InstitutionList from '../pages/apps/Rusa head/Institutions_List/institution_list';
import UsersRusa from '../pages/apps/Rusa head/RUSA Funding Details';
import ReportMain from '../pages/apps/Rusa head/Report';
import CollegeAdminAccreditationMain from '../pages/apps/college_admin/accreditation';
import CollegePractiseMain from '../pages/apps/college_admin/best_practices';
import CollegeGeneralMain from '../pages/apps/college_admin/college_general';
import DataOperatorAccredatedAgencies from '../pages/apps/data_operator/Accredited Agencies';
import DataOperatorRusaFundUtilisationDetails from '../pages/apps/data_operator/Fund Utilisation Details by RUSA SPD';
import DataOperatorGovtOrderProceedings from '../pages/apps/data_operator/Government order proceedings';
import DataOperatorRusaFunding from '../pages/apps/data_operator/RUSA Funding Details';
import DataOperatorPattern from '../pages/apps/data_operator/Staff Pattern in RUSA';
import { default as BestPractiseInstitution } from '../pages/apps/institutions/best practises new';
import GeneralInfoMain from '../pages/apps/institutions/general info';

import AccreditedSPD from '../pages/apps/SPD Users/Accredited_Agencies';
import FundingUtilisationSPD from '../pages/apps/SPD Users/Fund_utilisation_details_by_RUSASPD';
import FundingDetailsSPD from '../pages/apps/SPD Users/Funding_Details';
import InstitutionPfmsSPD from '../pages/apps/SPD Users/Institution_PFMS_Details';
import InstitutionsListSPD from '../pages/apps/SPD Users/Institutions_List';
import RusaOneSPD from '../pages/apps/SPD Users/RUSA_1';
import RusaTwoSPD from '../pages/apps/SPD Users/RUSA_2';
import StaffPatternInRUSASPD from '../pages/apps/SPD Users/Staff_Pattern_in_RUSA';
import CourseTypeSPD from '../pages/apps/SPD Users/institution_course_type';
import OrdersProceedingsSPD from '../pages/apps/SPD Users/order_proceedings';
import AdminPhasebeneficiariesMain from '../pages/apps/college_admin/Admin_Phase_beneficiaries';
import AdminAcademicDetails from '../pages/apps/college_admin/Admin_academic_details';
import AdminGrand from '../pages/apps/college_admin/Admin_grands';

import AdminInfrastructure from '../pages/apps/college_admin/Admin_infrastructure_new';
import AdminExtraCurricularActivities from '../pages/apps/college_admin/admin_extra-curricular_activities';
import AdminNetJrf from '../pages/apps/college_admin/admin_net_jrf';
import AdminStaffDeatails from '../pages/apps/college_admin/staff_member';
import InstitutionPFMSdetails from '../pages/apps/data_operator/Institution PFMS details';
import DataOpInstitutionList from '../pages/apps/data_operator/Institutions_List';
import DataInstitutionList from '../pages/apps/data_operator/Institutions_List/institution_list';
import HeadPhasebeneficiaries from '../pages/apps/institution_head/head_Phase_beneficiaries';

// auth
// college admin
const CollegeAdminUser = React.lazy(() => import('../pages/apps/college_admin/users'));
const AdminRankHolder = React.lazy(() => import('../pages/apps/college_admin/admin_rank_holder'));
const AdminOrderSanctionsa = React.lazy(() => import('../pages/apps/college_admin/admin_order_sanctions'));

// institution coodinator
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const GeneralInfo = React.lazy(() => import('../pages/apps/institutions/general info'));
const BestPratices = React.lazy(() => import('../pages/apps/institutions/best practises new'));
const Accreditation = React.lazy(() => import('../pages/apps/institutions/accreditation'));
const StaffMember = React.lazy(() => import('../pages/apps/institutions/staff_member'));
const Infrastructure = React.lazy(() => import('../pages/apps/institutions/infrastructure'));
const Activities = React.lazy(() => import('../pages/apps/institutions/extra_caricular_activities'));
const Rankholders = React.lazy(() => import('../pages/apps/institutions/rankholders'));
const NETJRF = React.lazy(() => import('../pages/apps/institutions/net__jrf'));
const OrdersSanctions = React.lazy(() => import('../pages/apps/institutions/order-sanctions'));
const PhaseBeneficiries = React.lazy(() => import('../pages/apps/institutions/phase_beneficiaries'));
// const FinancialDetails = React.lazy(() => import('../pages/apps/institutions/finanacial_details'));
const AcademicDetails = React.lazy(() => import('../pages/apps/institutions/academic-details'));
const ReceivedGrants = React.lazy(() => import('../pages/apps/institutions/received_grants'));
//end institution coodinator
// institution institution head
const HeadGeneral = React.lazy(() => import('../pages/apps/institution_head/head_general'));
const HeadAccreditation = React.lazy(() => import('../pages/apps/institution_head/head_Accreditation'));
const HeadBestPractices = React.lazy(() => import('../pages/apps/institution_head/head_best_practices'));
const HeadGrands = React.lazy(() => import('../pages/apps/institution_head/head_grands'));
const HeadAcademicDetails = React.lazy(() => import('../pages/apps/institution_head/head_academic_details'));
const HeadStaffMembers = React.lazy(() => import('../pages/apps/institution_head/Head_staff_members'));
const HeadInfrastructure = React.lazy(() => import('../pages/apps/institution_head/head_infrastructure'));
const HeadExtraCurricularActivities = React.lazy(() =>
    import('../pages/apps/institution_head/head_extra-curricular_activities')
);
const HeadNetJRF = React.lazy(() => import('../pages/apps/institution_head/head_net_jrf'));
const HeadOderSanctions = React.lazy(() => import('../pages/apps/institution_head/head_order_sanctions'));
const HeadRankHolder = React.lazy(() => import('../pages/apps/institution_head/head_rank_holder'));
//end institution head

// RUSA ADMIN
const OrdersProceedings = React.lazy(() => import('../pages/apps/Rusa head/order_proceedings'));
const StaffPatternInRUSA = React.lazy(() => import('../pages/apps/Rusa head/Staff_Pattern_in_RUSA'));
const InstitutionsList = React.lazy(() => import('../pages/apps/Rusa head/Institutions_List'));
const RUSAONE = React.lazy(() => import('../pages/apps/Rusa head/RUSA_1'));
const RUSATWO = React.lazy(() => import('../pages/apps/Rusa head/RUSA_2'));
const COURSETYPE = React.lazy(() => import('../pages/apps/Rusa head/institution_course_type'));
const PMSDETAILS = React.lazy(() => import('../pages/apps/Rusa head/Institution_PFMS_Details'));
const ACCREDITEDAGENCIES = React.lazy(() => import('../pages/apps/Rusa head/Accredited_Agencies/index.jsx'));
const FUNDINGDETAILS = React.lazy(() => import('../pages/apps/Rusa head/Funding_Details'));
const FUNDUTILISATIONDETAILS = React.lazy(() => import('../pages/apps/Rusa head/Fund_utilisation_details_by_RUSASPD'));
const LISTOFAGENCIES = React.lazy(() => import('../pages/apps/Rusa head/list_of_agencies'));

// SPD USERS
const InstitutionListSPDUser = React.lazy(() => import('../pages/apps/SPD Users/Institutions_List/institution_list'));
const spdReport = React.lazy(() => import('../pages/apps/SPD Users/Report/index'));
// const spdInstitutionDetails = React.lazy(() => import('../pages/apps/SPD Users/Report/institution_details'));
// const spdExtraCurricularActivities = React.lazy(() =>
//     import('../pages/apps/SPD Users/Report/report_extra_curricular_activities')
// );
// const spdfundUtilizationDetails = React.lazy(() =>
//     import('../pages/apps/SPD Users/Report/report_fund_utilization_details')
// );

const SPDlistOfAgencies = React.lazy(() => import('../pages/apps/SPD Users/list_of_agencies'));

// sub-menus
const ReportAcademicDetails = React.lazy(() => import('../pages/apps/Rusa head/Report/report_academic_details'));
const InstitutionDetails = React.lazy(() => import('../pages/apps/Rusa head/Report/institution_detailss'));
const ExtraCurricularActivities = React.lazy(() => import('../pages/apps/Rusa head/Report/reportActivities'));
const FundUtilizationDetails = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/report_fund_utilization_details_new')
);

const RUSA1 = React.lazy(() =>
import('../pages/apps/SPD Users/RUSA_1/index.jsx')
);
const RUSA2 = React.lazy(() =>
import('../pages/apps/SPD Users/RUSA_2/index.jsx')
);



// spdsub-menus

//spd reports

const SPDReportAcademicNew = React.lazy(() => import('../pages/apps/SPD Users/Report/report_academic_details'));
const SPDInstitutionDetails = React.lazy(() => import('../pages/apps/SPD Users/Report/institution_detailss'));
const SPDExtraCurricularActivities = React.lazy(() => import('../pages/apps/SPD Users/Report/reportActivities'));
const SPDFundUtilizationDetails = React.lazy(() => import('../pages/apps/SPD Users/Report/report_fund_utilization_details_new'));
    
// spd report seperate tables
// academic_details
const SPDReportAcademic = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/report_academic_details/table/academicReport')
);
const SPDReportRank = React.lazy(() => import('../pages/apps/SPD Users/Report/report_academic_details/table/reportRank'));
const SPDNetReport = React.lazy(() => import('../pages/apps/SPD Users/Report/report_academic_details/table/netReport'));
const SPDStaffReport = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/report_academic_details/table/staffReport')
);
// institution details
const SPDGeneralReport = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/institution_detailss/table/general/index.jsx')
);
const SPDAccreditaionReport = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/institution_detailss/table/accreditation/index.jsx')
);
const SPDInfrastructureReport = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/institution_detailss/table/infrastructure/index.jsx')
);
const SPDBeneficiaryReport = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/institution_detailss/table/beneficiary/index.jsx')
);
// ExtracurricularActivities details
const SPDStateReport = React.lazy(() => import('../pages/apps/SPD Users/Report/reportActivities/table/state'));
const SPDFacilitiesActivitiesReport = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/reportActivities/table/facilities')
);
const SPDInternationalActivitiesReport = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/reportActivities/table/international')
);
const SPDNccNssActivitiesReport = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/reportActivities/table/ncc_nss')
);
const SPDNationalActivitiesReport = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/reportActivities/table/national')
);
// fund utilization rusa one
const SPDFundUtilizationReportsone = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/report_fund_utilization_details_new/table/index.jsx')
);

// fund utilization rusa two
const SPDFundUtilizationReports = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/report_fund_utilization_details_new/RUSA2/index.jsx')
);
// fund utilization both phases
const SPDFundUtilizationReportsBoth = React.lazy(() =>
    import('../pages/apps/SPD Users/Report/report_fund_utilization_details_new/both_phases/index.jsx')
);






// report seperate tables
// academic_details
const ReportAcademic = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/report_academic_details/table/academicReport')
);
const ReportRank = React.lazy(() => import('../pages/apps/Rusa head/Report/report_academic_details/table/reportRank'));
const NetReport = React.lazy(() => import('../pages/apps/Rusa head/Report/report_academic_details/table/netReport'));
const StaffReport = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/report_academic_details/table/staffReport')
);
// institution details
const GeneralReport = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/institution_detailss/table/general/index.jsx')
);
const AccreditaionReport = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/institution_detailss/table/accreditation/index.jsx')
);
const InfrastructureReport = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/institution_detailss/table/infrastructure/index.jsx')
);
const BeneficiaryReport = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/institution_detailss/table/beneficiary/index.jsx')
);
// ExtracurricularActivities details
const StateReport = React.lazy(() => import('../pages/apps/Rusa head/Report/reportActivities/table/state'));
const FacilitiesActivitiesReport = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/reportActivities/table/facilities')
);
const InternationalActivitiesReport = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/reportActivities/table/international')
);
const NccNssActivitiesReport = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/reportActivities/table/ncc_nss')
);
const NationalActivitiesReport = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/reportActivities/table/national')
);
// fund utilization rusa one
const FundUtilizationReportsone = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/report_fund_utilization_details_new/table/index.jsx')
);

// fund utilization rusa two
const FundUtilizationReports = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/report_fund_utilization_details_new/RUSA2/index.jsx')
);
// fund utilization both phases
const FundUtilizationReportsBoth = React.lazy(() =>
    import('../pages/apps/Rusa head/Report/report_fund_utilization_details_new/both_phases/index.jsx')
);
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const loggedInUser = getActiveUser();
    // console.log(loggedInUser);
    let activerole = Number(loggedInUser?.role_id) || undefined;
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isUserAuthenticated()) {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
                }

                // check if route is restricted by role
                if (activerole) {
                    if (roles && roles.indexOf(activerole) === -1) {
                        // role not authorised so redirect to home page
                        return <Redirect to={{ pathname: '/' }} />;
                    }
                }
                // authorised so return component
                return <Component {...props} />;
            }}
        />
    );
};

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    header: 'Menu',
    component: Dashboard,
    roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    route: PrivateRoute,
};

const collegeAdmin = {
    name: 'College Admin',
    icon: FeatherIcon.Archive,
    roles: [2],
    children: [
        {
            path: '/institution-admin-general',
            name: 'General',
            component: CollegeGeneralMain,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-best-practices',
            name: 'Best practices ',
            component: CollegePractiseMain,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-accreditation',
            name: 'Accreditation ',
            component: CollegeAdminAccreditationMain,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-received-grants',
            name: ' Details of Received Grants ',
            component: AdminGrand,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-academic-details',
            name: 'Academic Details ',
            component: AdminAcademicDetails,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-staff_member',
            name: 'Details of Staff Members ',
            component: AdminStaffDeatails,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-infrastructure',
            name: 'Infrastructure',
            component: AdminInfrastructure,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-extra_caricular_activities',
            name: 'Sports & Extra Curricular Activities',
            component: AdminExtraCurricularActivities,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-rank-holders',
            name: 'Details of Rank holders',
            component: AdminRankHolder,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-qualified-students-of-net-jrf',
            name: 'Qualified Students of NET/JRF',
            component: AdminNetJrf,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-orders-sanctions',
            name: 'RUSA-Orders/Sanctions',
            component: AdminOrderSanctionsa,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-phase-beneficiries',
            name: 'RUSA-Phase Beneficiaries',
            component: AdminPhasebeneficiariesMain,
            route: PrivateRoute,
            roles: [2],
        },
        {
            path: '/institution-admin-Users',
            name: ' Users',
            component: CollegeAdminUser,
            route: PrivateRoute,
            roles: [2],
        },
    ],
};
const institutionalCodinator = {
    name: 'Institutional Data',
    icon: FeatherIcon.Archive,
    roles: [4],
    children: [
        {
            path: '/generals',
            name: 'General',
            component: GeneralInfoMain,
            route: PrivateRoute,
            roles: [4],
        },

        {
            path: '/bestPractice',
            name: 'Best practices ',
            component: BestPractiseInstitution,
            route: PrivateRoute,
            roles: [4],
        },
        {
            path: '/Accreditation',
            name: 'Accreditation ',
            component: Accreditation,
            route: PrivateRoute,
            roles: [4],
        },
        {
            path: '/received_grants',
            name: ' Details of Received Grants ',
            component: ReceivedGrants,
            route: PrivateRoute,
            roles: [4],
        },
        {
            path: '/academic-details',
            name: 'Academic Details ',
            component: AcademicDetails,
            route: PrivateRoute,
            roles: [4],
        },
        {
            path: '/staff_member',
            name: 'Details of Staff Members ',
            component: StaffMember,
            route: PrivateRoute,
            roles: [4],
        },
        {
            path: '/infrastructure',
            name: 'Infrastructure',
            component: Infrastructure,
            route: PrivateRoute,
            roles: [4],
        },
        {
            path: '/extra_caricular_activities',
            name: 'Sports & Extra curricular activities',
            component: Activities,
            route: PrivateRoute,
            roles: [4],
        },
        {
            path: '/rank-holders',
            name: 'Details of Rank holders',
            component: Rankholders,
            route: PrivateRoute,
            roles: [4],
        },
        {
            path: '/qualified-students-of-net-jrf',
            name: 'Qualified Students of NET/JRF',
            component: NETJRF,
            route: PrivateRoute,
            roles: [4],
        },
        {
            path: '/ic-orders-sanctions',
            name: 'RUSA-Orders/Sanctions',
            component: OrdersSanctions,
            route: PrivateRoute,
            roles: [4],
        },
        {
            path: '/ic-phase-beneficiries',
            name: 'RUSA-Phase Beneficiaries',
            component: PhaseBeneficiries,
            route: PrivateRoute,
            roles: [4],
        },
    ],
};
const institutionalHead = {
    name: 'Institutional Head',
    icon: FeatherIcon.Archive,
    roles: [3],
    children: [
        {
            path: '/institution-head-general',
            name: 'General ',
            component: HeadGeneral,
            route: PrivateRoute,
            roles: [3],
        },
        {
            path: '/institution-head-best-practices',
            name: 'Best practices ',
            component: HeadBestPractices,
            route: PrivateRoute,
            roles: [3],
        },
        {
            path: '/institution-head-accreditation',
            name: 'Accreditation ',
            component: HeadAccreditation,
            route: PrivateRoute,
            roles: [3],
        },
        {
            path: '/institution-head-received-grants',
            name: ' Details of Received Grants ',
            component: HeadGrands,
            route: PrivateRoute,
            roles: [3],
        },
        {
            path: '/institution-head-academic-details',
            name: 'Academic Details ',
            component: HeadAcademicDetails,
            route: PrivateRoute,
            roles: [3],
        },
        {
            path: '/institution-head-staff_member',
            name: 'Details of Staff Members ',
            component: HeadStaffMembers,
            route: PrivateRoute,
            roles: [3],
        },
        {
            path: '/institution-head-infrastructure',
            name: 'Infrastructure',
            component: HeadInfrastructure,
            route: PrivateRoute,
            roles: [3],
        },
        {
            path: '/institution_head_extra_caricular_activities',
            name: 'Sports & Extra curricular activities',
            component: HeadExtraCurricularActivities,
            route: PrivateRoute,
            roles: [3],
        },
        {
            path: '/institution-head-rank-holders',
            name: 'Details of Rank holders',
            component: HeadRankHolder,
            route: PrivateRoute,
            roles: [3],
        },
        {
            path: '/institution-head-qualified-students-of-net-jrf',
            name: 'Qualified Students of NET/JRF',
            component: HeadNetJRF,
            route: PrivateRoute,
            roles: [3],
        },
        {
            path: '/orders-sanctions',
            name: 'RUSA-Orders/Sanctions',
            component: HeadOderSanctions,
            route: PrivateRoute,
            roles: [3],
        },
        {
            path: '/phase-beneficiries',
            name: 'RUSA-Phase Beneficiaries',
            component: HeadPhasebeneficiaries,
            route: PrivateRoute,
            roles: [3],
        },
    ],
};
const DataOperator = {
    name: 'Data Operator',
    icon: FeatherIcon.Archive,
    roles: [5],
    children: [
        {
            path: '/dataoperator-govt-proceedings',
            name: 'Government Orders & Proceedings',
            component: DataOperatorGovtOrderProceedings,
            route: PrivateRoute,
            roles: [5],
        },
        {
            path: '/dataoperator-institutions',
            name: 'Institutions List',
            component: DataOpInstitutionList,
            route: PrivateRoute,
            roles: [5],
        },

        {
            path: '/dataoperator-staff-pattern',
            name: 'Staff Pattern in RUSA',
            component: DataOperatorPattern,
            route: PrivateRoute,
            roles: [5],
        },
        {
            path: '/do-institution-pfms-details',
            name: 'PFMS Unique Codes',
            component: InstitutionPFMSdetails,
            route: PrivateRoute,
            roles: [5],
        },
        {
            path: '/dataoperator-accredated-agencies',
            name: 'Accredited Agencies',
            component: DataOperatorAccredatedAgencies,
            route: PrivateRoute,
            roles: [5],
        },
        {
            path: '/dataoperator-rusafund',
            name: 'RUSA Funding Details',
            component: DataOperatorRusaFunding,
            route: PrivateRoute,
            roles: [5],
        },
        {
            path: '/dataoperator-rusafund-details',
            name: 'Fund Utilisation Details by RUSA SPD',
            component: DataOperatorRusaFundUtilisationDetails,
            route: PrivateRoute,
            roles: [5],
        },
    ],
};
const RusaAdmin = {
    name: 'Rusa Admin',
    icon: FeatherIcon.Archive,
    roles: [1],
    children: [
        {
            path: '/order_proceedings',
            name: 'Government Orders & Proceedings',
            component: OrdersProceedings,
            route: PrivateRoute,
            roles: [1],
        },

        {
            path: '/Staff_Pattern_in_RUSA',
            name: 'Staff Pattern in RUSA ',
            component: StaffPatternInRUSA,
            route: PrivateRoute,
            roles: [1],
        },
        {
            path: '/Institutions_List',
            name: 'Institutions List ',
            component: InstitutionsList,
            route: PrivateRoute,
            roles: [1],
        },
        {
            path: '/RusaAdmin_Rusa_one',
            name: 'RUSA 1',
            component: RUSAONE,
            route: PrivateRoute,
            roles: [1],
        },
        {
            path: '/RusaAdmin_Rusa_two',
            name: 'RUSA 2',
            component: RUSATWO,
            route: PrivateRoute,
            roles: [1],
        },

        {
            path: '/r_a_list_of_agencies',
            name: 'List of Agencies',
            component: LISTOFAGENCIES,
            route: PrivateRoute,
            roles: [1],
        },

        {
            path: '/RusaAdmin_course_type',
            name: 'Institution Course Type',
            component: COURSETYPE,
            route: PrivateRoute,
            roles: [1],
        },
        {
            path: '/RusaAdmin_pms_details',
            name: 'Institution PFMS Details',
            component: PMSDETAILS,
            route: PrivateRoute,
            roles: [1],
        },
        {
            path: '/RusaAdmin_accredited_agencies',
            name: 'Accredited Agencies',
            component: ACCREDITEDAGENCIES,
            route: PrivateRoute,
            roles: [1],
        },
        {
            path: '/RusaAdmin_funding_details',
            name: 'Funding Details',
            component: FUNDINGDETAILS,
            route: PrivateRoute,
            roles: [1],
        },
        {
            path: '/RusaAdmin_fund_utilisation_details',
            name: 'Fund Utilisation Details',
            component: FUNDUTILISATIONDETAILS,
            route: PrivateRoute,
            roles: [1],
        },

        {
            path: '/RusaAdmin_report',
            name: 'Reports',
            component: ReportMain,
            route: PrivateRoute,
            roles: [1],
            children: [
                {
                    id: 1,
                    name: 'Academic Details',
                    path: '/report-academic-details',
                    component: ReportAcademicDetails,
                    route: PrivateRoute,
                    roles: [1],
                },
                {
                    id: 2,
                    name: 'Institution Details',
                    path: '/report-institution-details',
                    component: InstitutionDetails,
                    route: PrivateRoute,
                    roles: [1],
                },
                {
                    id: 3,
                    name: 'Extra Curricular Activities',
                    path: '/report-extra-curricular-activites',
                    component: ExtraCurricularActivities,
                    route: PrivateRoute,
                    roles: [1],
                },
                {
                    id: 4,
                    name: 'Fund Utilisation Details',
                    path: '/report-fund-utilisation-details-new',
                    component: FundUtilizationDetails,
                    route: PrivateRoute,
                    roles: [1],
                },
            ],
        },
        {
            path: '/users-rusa',
            name: 'Users',
            component: UsersRusa,
            route: PrivateRoute,
            roles: [1],
        },
    ],
};
const FinanceOfficer = {
    name: 'Finanace officer',
    icon: FeatherIcon.Archive,
    roles: [6],
    children: [
        {
            path: '/order_proceedings_finance_officier',
            name: 'Government Orders & Proceedings',
            component: OrdersProceedingsSPD,
            route: PrivateRoute,
            roles: [6],
        },

        {
            path: '/Staff_Pattern_in_RUSA_finance_officier',
            name: 'Staff Pattern in RUSA ',
            component: StaffPatternInRUSASPD,
            route: PrivateRoute,
            roles: [6],
        },
        {
            path: '/Institutions_List_finance_officier',
            name: 'Institutions List ',
            component: InstitutionsListSPD,
            route: PrivateRoute,
            roles: [6],
        },
        {
            path: '/Rusa_one_finance_officier',
            name: 'RUSA 1',
            component: RUSA1,
            route: PrivateRoute,
            roles: [6],
        },
        {
            path: '/Rusa_two_finance_officier',
            name: 'RUSA 2',
            component: RUSA2,
            route: PrivateRoute,
            roles: [6],
        },
       
        {
            path: '/Course_type_finance_officier',
            name: 'Institution Course Type',
            component: CourseTypeSPD,
            route: PrivateRoute,
            roles: [6],
        },
        {
            path: '/RusaAdmin_pms_details_finance_officier',
            name: 'Institution PFMS Details',
            component: InstitutionPfmsSPD,
            route: PrivateRoute,
            roles: [6],
        },
        {
            path: '/RusaAdmin_accredited_agencies_finance_officier',
            name: 'Accredited Agencies',
            component: AccreditedSPD,
            route: PrivateRoute,
            roles: [6],
        },
        {
            path: '/RusaAdmin_funding_details_finance_officier',
            name: 'Funding Details',
            component: FundingDetailsSPD,
            route: PrivateRoute,
            roles: [6],
        },
        {
            path: '/RusaAdmin_fund_utilisation_details_finance_officier',
            name: 'Fund Utilisation Details',
            component: FundingUtilisationSPD,
            route: PrivateRoute,
            roles: [6],
        },

        {
            path: '/f_o_list_of_agencies',
            name: 'List of Agencies',
            component: SPDlistOfAgencies,
            route: PrivateRoute,
            roles: [6],
        },

        {
            path: '/RusaAdmin_report',
            name: 'Reports',
            component: ReportMain,
            route: PrivateRoute,
            roles: [1],
            children: [
                {
                    id: 1,
                    name: 'Academic Details',
                    path: '/finance-report-academic-details',
                    component: SPDReportAcademicNew,
                    route: PrivateRoute,
                    roles: [6],
                },
                {
                    id: 2,
                    name: 'Institution Details',
                    path: '/finance-report-institution-details',
                    component: SPDInstitutionDetails,
                    route: PrivateRoute,
                    roles: [6],
                },
                {
                    id: 3,
                    name: 'Extra Curricular Activities',
                    path: '/finance-report-extra-curricular-activites',
                    component: SPDExtraCurricularActivities,
                    route: PrivateRoute,
                    roles: [6],
                },
                {
                    id: 4,
                    name: 'Fund Utilisation Details',
                    path: '/finance-report-fund-utilisation-details',
                    component: SPDFundUtilizationDetails,
                    route: PrivateRoute,
                    roles: [6],
                },
            ],
        },
    ],
};
const ResearchOfficer = {
    name: 'Research officer',
    icon: FeatherIcon.Archive,
    roles: [7],
    children: [
        {
            path: '/order_proceedings_research_officer',
            name: 'Government Orders & Proceedings',
            component: OrdersProceedingsSPD,
            route: PrivateRoute,
            roles: [7],
        },

        {
            path: '/Staff_Pattern_in_RUSA_research_officer',
            name: 'Staff Pattern in RUSA ',
            component: StaffPatternInRUSASPD,
            route: PrivateRoute,
            roles: [7],
        },
        {
            path: '/Institutions_List_research_officer',
            name: 'Institutions List ',
            component: InstitutionsListSPD,
            route: PrivateRoute,
            roles: [7],
        },
        {
            path: '/Rusa_one_research_officer',
            name: 'RUSA 1',
            component: RUSA1,
            route: PrivateRoute,
            roles: [7],
        },
        {
            path: '/Rusa_two_research_officer',
            name: 'RUSA 2',
            component: RUSA2,
            route: PrivateRoute,
            roles: [7],
        },
        {
            path: '/Course_type_research_officer',
            name: 'Institution Course Type',
            component: CourseTypeSPD,
            route: PrivateRoute,
            roles: [7],
        },
        {
            path: '/RusaAdmin_pfms_details_research_officer',
            name: 'Institution PFMS Details',
            component: InstitutionPfmsSPD,
            route: PrivateRoute,
            roles: [7],
        },
        {
            path: '/RusaAdmin_accredited_agencies_research_officer',
            name: 'Accredited Agencies',
            component: AccreditedSPD,
            route: PrivateRoute,
            roles: [7],
        },
        {
            path: '/RusaAdmin_funding_details_research_officer',
            name: 'Funding Details',
            component: FundingDetailsSPD,
            route: PrivateRoute,
            roles: [7],
        },
        {
            path: '/RusaAdmin_fund_utilisation_details_research_officer',
            name: 'Fund Utilisation Details',
            component: FundingUtilisationSPD,
            route: PrivateRoute,
            roles: [7],
        },
        {
            path: '/r_o_list_of_agencies',
            name: 'List of Agencies',
            component: SPDlistOfAgencies,
            route: PrivateRoute,
            roles: [7],
        },

        {
            path: '/RusaAdmin_report',
            name: 'Reports',
            component: ReportMain,
            route: PrivateRoute,
            roles: [7],
            children: [
                {
                    id: 1,
                    name: 'Academic Details',
                    path: '/research-report-academic-details',
                    component: SPDReportAcademicNew,
                    route: PrivateRoute,
                    roles: [7],
                },
                {
                    id: 2,
                    name: 'Institution Details',
                    path: '/research-report-institution-details',
                    component: SPDInstitutionDetails,
                    route: PrivateRoute,
                    roles: [7],
                },
                {
                    id: 3,
                    name: 'Extra Curricular Activities',
                    path: '/research-report-extra-curricular-activites',
                    component: SPDExtraCurricularActivities,
                    route: PrivateRoute,
                    roles: [7],
                },
                {
                    id: 4,
                    name: 'Fund Utilisation Details',
                    path: '/research-report-fund-utilisation-details',
                    component: SPDFundUtilizationDetails,
                    route: PrivateRoute,
                    roles: [7],
                },
            ],
        },
    ],
};
const SectionOfficer = {
    name: 'Section officer',
    icon: FeatherIcon.Archive,
    roles: [9],
    children: [
        {
            path: '/order_proceedings_section_officer',
            name: 'Government Orders & Proceedings',
            component: OrdersProceedingsSPD,
            route: PrivateRoute,
            roles: [9],
        },

        {
            path: '/Staff_Pattern_in_RUSA_section_officer',
            name: 'Staff Pattern in RUSA ',
            component: StaffPatternInRUSASPD,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/Institutions_List_section_officer',
            name: 'Institutions List ',
            component: InstitutionsListSPD,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/Rusa_one_section_officer',
            name: 'RUSA 1',
            component: RUSA1,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/Rusa_two_section_officer',
            name: 'RUSA 2',
            component: RUSA2,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/Course_type_section_officer',
            name: 'Institution Course Type',
            component: CourseTypeSPD,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/RusaAdmin_pfms_details_section_officer',
            name: 'Institution PFMS Details',
            component: InstitutionPfmsSPD,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/RusaAdmin_accredited_agencies_section_officer',
            name: 'Accredited Agencies',
            component: AccreditedSPD,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/RusaAdmin_funding_details_section_officer',
            name: 'Funding Details',
            component: FundingDetailsSPD,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/RusaAdmin_fund_utilisation_details_section_officer',
            name: 'Fund Utilisation Details',
            component: FundingUtilisationSPD,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/s_o_list_of_agencies',
            name: 'List of Agencies',
            component: SPDlistOfAgencies,
            route: PrivateRoute,
            roles: [9],
        },

        {
            path: '/section_report',
            name: 'Reports',
            component: spdReport,
            route: PrivateRoute,
            roles: [9],
            children: [
                {
                    id: 1,
                    name: 'Academic Details',
                    path: '/section_report-academic-details',
                    component: SPDReportAcademicNew,
                    route: PrivateRoute,
                    roles: [9],
                },
                {
                    id: 2,
                    name: 'Institution Details',
                    path: '/section_reportinstitution-details',
                    component: SPDInstitutionDetails,
                    route: PrivateRoute,
                    roles: [9],
                },
                {
                    id: 3,
                    name: 'Extra Curricular Activities',
                    path: '/section_report-extra-curricular-activites',
                    component: SPDExtraCurricularActivities,
                    route: PrivateRoute,
                    roles: [9],
                },
                {
                    id: 4,
                    name: 'Fund Utilisation Details',
                    path: '/section_report-fund-utilisation-details',
                    component: SPDFundUtilizationDetails,
                    route: PrivateRoute,
                    roles: [9],
                },
            ],
        },
    ],
};
const StateProjectCoodinator = {
    name: 'State project coodinator',
    icon: FeatherIcon.Archive,
    roles: [10],
    children: [
        {
            path: '/order_proceedings_project_coordinator',
            name: 'Government Orders & Proceedings',
            component: OrdersProceedingsSPD,
            route: PrivateRoute,
            roles: [10],
        },

        {
            path: '/Staff_Pattern_in_RUSA_project_coordinator',
            name: 'Staff Pattern in RUSA ',
            component: StaffPatternInRUSASPD,
            route: PrivateRoute,
            roles: [10],
        },
        {
            path: '/Institutions_List_project_coordinator',
            name: 'Institutions List ',
            component: InstitutionsListSPD,
            route: PrivateRoute,
            roles: [10],
        },
        {
            path: '/Rusa_one_project_coordinator',
            name: 'RUSA 1',
            component: RUSA1,
            route: PrivateRoute,
            roles: [10],
        },
        {
            path: '/Rusa_two_project_coordinator',
            name: 'RUSA 2',
            component: RUSA2,
            route: PrivateRoute,
            roles: [10],
        },
        {
            path: '/Course_type_project_coordinator',
            name: 'Institution Course Type',
            component: CourseTypeSPD,
            route: PrivateRoute,
            roles: [10],
        },
        {
            path: '/RusaAdmin_pfms_details_project_coordinator',
            name: 'Institution PFMS Details',
            component: InstitutionPfmsSPD,
            route: PrivateRoute,
            roles: [10],
        },
        {
            path: '/RusaAdmin_accredited_agencies_project_coordinator',
            name: 'Accredited Agencies',
            component: AccreditedSPD,
            route: PrivateRoute,
            roles: [10],
        },
        {
            path: '/RusaAdmin_funding_details_project_coordinator',
            name: 'Funding Details',
            component: FundingDetailsSPD,
            route: PrivateRoute,
            roles: [10],
        },
        {
            path: '/RusaAdmin_fund_utilisation_details_project_coordinator',
            name: 'Fund Utilisation Details',
            component: FundingUtilisationSPD,
            route: PrivateRoute,
            roles: [10],
        },
        {
            path: '/s_p_c_list_of_agencies',
            name: 'List of Agencies',
            component: SPDlistOfAgencies,
            route: PrivateRoute,
            roles: [10],
        },

        {
            path: '/RusaAdmin_report',
            name: 'Reports',
            component: ReportMain,
            route: PrivateRoute,
            roles: [1],
            children: [
                {
                    id: 1,
                    name: 'Academic Details',
                    path: '/state-report-academic-details',
                    component: SPDReportAcademicNew,
                    route: PrivateRoute,
                    roles: [10],
                },
                {
                    id: 2,
                    name: 'Institution Details',
                    path: '/state-report-institution-details',
                    component: SPDInstitutionDetails,
                    route: PrivateRoute,
                    roles: [10],
                },
                {
                    id: 3,
                    name: 'Extra Curricular Activities',
                    path: '/state-report-extra-curricular-activites',
                    component: SPDExtraCurricularActivities,
                    route: PrivateRoute,
                    roles: [10],
                },
                {
                    id: 4,
                    name: 'Fund Utilisation Details',
                    path: '/state-report-fund-utilisation-details',
                    component: SPDFundUtilizationDetails,
                    route: PrivateRoute,
                    roles: [10],
                },
            ],
        },
    ],
};
const Assistant = {
    name: 'Assistant',
    icon: FeatherIcon.Archive,
    roles: [11],
    children: [
        {
            path: '/order_proceedings_assistant',
            name: 'Government Orders & Proceedings',
            component: OrdersProceedingsSPD,
            route: PrivateRoute,
            roles: [11],
        },
        {
            path: '/Staff_Pattern_in_RUSA_assistant',
            name: 'Staff Pattern in RUSA ',
            component: StaffPatternInRUSASPD,
            route: PrivateRoute,
            roles: [11],
        },
        {
            path: '/Institutions_List_assistant',
            name: 'Institutions List ',
            component: InstitutionsListSPD,
            route: PrivateRoute,
            roles: [11],
        },
        {
            path: '/Rusa_one_assistant',
            name: 'RUSA 1',
            component: RUSA1,
            route: PrivateRoute,
            roles: [11],
        },
        {
            path: '/Rusa_two_assistant',
            name: 'RUSA 2',
            component: RUSA2,
            route: PrivateRoute,
            roles: [11],
        },
        {
            path: '/Course_type_assistant',
            name: 'Institution Course Type',
            component: CourseTypeSPD,
            route: PrivateRoute,
            roles: [11],
        },
        {
            path: '/RusaAdmin_pfms_details_assistant',
            name: 'Institution PFMS Details',
            component: InstitutionPfmsSPD,
            route: PrivateRoute,
            roles: [11],
        },
        {
            path: '/RusaAdmin_accredited_agencies_assistant',
            name: 'Accredited Agencies',
            component: AccreditedSPD,
            route: PrivateRoute,
            roles: [11],
        },
        {
            path: '/RusaAdmin_funding_details_assistant',
            name: 'Funding Details',
            component: FundingDetailsSPD,
            route: PrivateRoute,
            roles: [11],
        },
        {
            path: '/RusaAdmin_fund_utilisation_details_assistant',
            name: 'Fund Utilisation Details',
            component: FundingUtilisationSPD,
            route: PrivateRoute,
            roles: [11],
        },

        {
            path: '/assi_list_of_agencies',
            name: 'List of Agencies',
            component: SPDlistOfAgencies,
            route: PrivateRoute,
            roles: [11],
        },

        {
            path: '/RusaAdmin_report',
            name: 'Reports',
            component: ReportMain,
            route: PrivateRoute,
            roles: [11],
            children: [
                {
                    id: 1,
                    name: 'Academic Details',
                    path: '/assistant-report-academic-details',
                    component: SPDReportAcademicNew,
                    route: PrivateRoute,
                    roles: [11],
                },
                {
                    id: 2,
                    name: 'Institution Details',
                    path: '/assistant-report-institution-details',
                    component: SPDInstitutionDetails,
                    route: PrivateRoute,
                    roles: [11],
                },
                {
                    id: 3,
                    name: 'Extra Curricular Activities',
                    path: '/assistant-report-extra-curricular-activites',
                    component: SPDExtraCurricularActivities,
                    route: PrivateRoute,
                    roles: [11],
                },
                {
                    id: 4,
                    name: 'Fund Utilisation Details',
                    path: '/assistant-report-fund-utilisation-details',
                    component: SPDFundUtilizationDetails,
                    route: PrivateRoute,
                    roles: [11],
                },
            ],
        },
    ],
};
const SystemAdmin = {
    name: 'System admin',
    icon: FeatherIcon.Archive,
    roles: [12],
    children: [
        {
            path: '/order_proceedings_sysadmin',
            name: 'Government Orders & Proceedings',
            component: OrdersProceedingsSPD,
            route: PrivateRoute,
            roles: [12],
        },

        {
            path: '/Staff_Pattern_in_RUSA_sysadmin',
            name: 'Staff Pattern in RUSA ',
            component: StaffPatternInRUSASPD,
            route: PrivateRoute,
            roles: [12],
        },
        {
            path: '/Institutions_List_sysadmin',
            name: 'Institutions List ',
            component: InstitutionsListSPD,
            route: PrivateRoute,
            roles: [12],
        },
        {
            path: '/Rusa_one_sysadmin',
            name: 'RUSA 1',
            component: RUSA1,
            route: PrivateRoute,
            roles: [12],
        },
        {
            path: '/Rusa_two_sysadmin',
            name: 'RUSA 2',
            component: RUSA2,
            route: PrivateRoute,
            roles: [12],
        },
        {
            path: '/Course_type_sysadmin',
            name: 'Institution Course Type',
            component: CourseTypeSPD,
            route: PrivateRoute,
            roles: [12],
        },
        {
            path: '/RusaAdmin_pfms_details_sysadmin',
            name: 'Institution PFMS Details',
            component: InstitutionPfmsSPD,
            route: PrivateRoute,
            roles: [12],
        },
        {
            path: '/RusaAdmin_accredited_agencies_sysadmin',
            name: 'Accredited Agencies',
            component: AccreditedSPD,
            route: PrivateRoute,
            roles: [12],
        },
        {
            path: '/RusaAdmin_funding_details_sysadmin',
            name: 'Funding Details',
            component: FundingDetailsSPD,
            route: PrivateRoute,
            roles: [12],
        },
        {
            path: '/RusaAdmin_fund_utilisation_details_sysadmin',
            name: 'Fund Utilisation Details',
            component: FundingUtilisationSPD,
            route: PrivateRoute,
            roles: [12],
        },

        {
            path: '/s_a_list_of_agencies',
            name: 'List of Agencies',
            component: SPDlistOfAgencies,
            route: PrivateRoute,
            roles: [12],
        },

        {
            path: '/RusaAdmin_report',
            name: 'Reports',
            component: ReportMain,
            route: PrivateRoute,
            roles: [1],
            children: [
                {
                    id: 1,
                    name: 'Academic Details',
                    path: '/system-report-academic-details',
                    component: SPDReportAcademicNew,
                    route: PrivateRoute,
                    roles: [12],
                },
                {
                    id: 2,
                    name: 'Institution Details',
                    path: '/system-report-institution-details',
                    component: SPDInstitutionDetails,
                    route: PrivateRoute,
                    roles: [12],
                },
                {
                    id: 3,
                    name: 'Extra Curricular Activities',
                    path: '/system-report-extra-curricular-activites',
                    component: SPDExtraCurricularActivities,
                    route: PrivateRoute,
                    roles: [12],
                },
                {
                    id: 4,
                    name: 'Fund Utilisation Details',
                    path: '/system-report-fund-utilisation-details',
                    component: SPDFundUtilizationDetails,
                    route: PrivateRoute,
                    roles: [12],
                },
            ],
        },
    ],
};
const NodalOfficer = {
    name: 'Nodal officer',
    icon: FeatherIcon.Archive,
    roles: [8],
    children: [
        {
            path: '/order_proceedings_nodal_officer',
            name: 'Government Orders & Proceedings',
            component: OrdersProceedingsSPD,
            route: PrivateRoute,
            roles: [8],
        },

        {
            path: '/Staff_Pattern_in_RUSA_nodal_officer',
            name: 'Staff Pattern in RUSA ',
            component: StaffPatternInRUSASPD,
            route: PrivateRoute,
            roles: [8],
        },
        {
            path: '/Institutions_List_nodal_officer',
            name: 'Institutions List ',
            component: InstitutionsListSPD,
            route: PrivateRoute,
            roles: [8],
        },
        {
            path: '/Rusa_one_nodal_officer',
            name: 'RUSA 1',
            component: RUSA1,
            route: PrivateRoute,
            roles: [8],
        },
        {
            path: '/Rusa_two_nodal_officer',
            name: 'RUSA 2',
            component: RUSA2,
            route: PrivateRoute,
            roles: [8],
        },
        {
            path: '/Course_type_nodal_officer',
            name: 'Institution Course Type',
            component: CourseTypeSPD,
            route: PrivateRoute,
            roles: [8],
        },
        {
            path: '/RusaAdmin_pfms_details_nodal_officer',
            name: 'Institution PFMS Details',
            component: InstitutionPfmsSPD,
            route: PrivateRoute,
            roles: [8],
        },
        {
            path: '/RusaAdmin_accredited_agencies_nodal_officer',
            name: 'Accredited Agencies',
            component: AccreditedSPD,
            route: PrivateRoute,
            roles: [8],
        },
        {
            path: '/RusaAdmin_funding_details_nodal_officer',
            name: 'Funding Details',
            component: FundingDetailsSPD,
            route: PrivateRoute,
            roles: [8],
        },
        {
            path: '/RusaAdmin_fund_utilisation_details_nodal_officer',
            name: 'Fund Utilisation Details',
            component: FundingUtilisationSPD,
            route: PrivateRoute,
            roles: [8],
        },
        {
            path: '/n_o_list_of_agencies',
            name: 'List of Agencies',
            component: SPDlistOfAgencies,
            route: PrivateRoute,
            roles: [8],
        },

        {
            path: '/RusaAdmin_report',
            name: 'Reports',
            component: ReportMain,
            route: PrivateRoute,
            roles: [1],
            children: [
                {
                    id: 1,
                    name: 'Academic Details',
                    path: '/nodal-report-academic-details',
                    component: SPDReportAcademicNew,
                    route: PrivateRoute,
                    roles: [8],
                },
                {
                    id: 2,
                    name: 'Institution Details',
                    path: '/nodal-report-institution-details',
                    component: SPDInstitutionDetails,
                    route: PrivateRoute,
                    roles: [8],
                },
                {
                    id: 3,
                    name: 'Extra Curricular Activities',
                    path: '/nodal-report-extra-curricular-activites',
                    component: SPDExtraCurricularActivities,
                    route: PrivateRoute,
                    roles: [8],
                },
                {
                    id: 4,
                    name: 'Fund Utilisation Details',
                    path: '/nodal-report-fund-utilisation-details',
                    component: SPDFundUtilizationDetails,
                    route: PrivateRoute,
                    roles: [8],
                },
            ],
        },
    ],
};

const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

const flattenRoutes = (routes) => {
    let flatRoutes = [
        {
            path: '/incubation-detail/:id',
            component: Dashboard,
            route: PrivateRoute,
            roles: [9],
        },
        {
            path: '/InstitutionList/:id',
            component: InstitutionList,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/dataoperator-InstitutionList/:id',
            component: DataInstitutionList,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/Institutions_List_nodal_officer/:id',
            component: InstitutionListSPDUser,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/Institutions_List_sysadmin/:id',
            component: InstitutionListSPDUser,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/Institutions_List_assistant/:id',
            component: InstitutionListSPDUser,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/Institutions_List_project_coordinator/:id',
            component: InstitutionListSPDUser,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/Institutions_List_section_officer/:id',
            component: InstitutionListSPDUser,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/Institutions_List_research_officer/:id',
            component: InstitutionListSPDUser,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/Institutions_List_finance_officier/:id',
            component: InstitutionListSPDUser,
            route: PrivateRoute,
            // roles: [9],
        },




        // ----academic-reports-tables-----
        {
            path: '/report_academic_details/academic/:id',
            component: ReportAcademic,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_academic_details/rank/:id',
            component: ReportRank,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_academic_details/net/:id',
            component: NetReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_academic_details/staff/:id',
            component: StaffReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ---------institution details report tables-----------
        {
            path: '/report_institution_details/general/:id',
            component: GeneralReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_institution_details/accreditation/:id',
            component: AccreditaionReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_institution_details/infrastructure/:id',
            component: InfrastructureReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_institution_details/beneficiary/:id',
            component: BeneficiaryReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ExtracurricularActivitiesReport-----
        {
            path: '/report_activities/state/:id',
            component: StateReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_activities/national/:id',
            component: NationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_activities/international/:id',
            component: InternationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_activities/facilities/:id',
            component: FacilitiesActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_activities/nss_ncc/:id',
            component: NccNssActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // fund utilization
        {
            path: '/report_fund/rusaone/:id',
            component: FundUtilizationReportsone,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_fund/rusatwo/:id',
            component: FundUtilizationReports,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/report_fund/both/:id',
            component: FundUtilizationReportsBoth,
            route: PrivateRoute,
            // roles: [9],
        },


           // spd reports section officer

           // ----academic-reports-tables-----
           {
            path: '/spd_section_report_academic_details/academic/:id',
            component: SPDReportAcademic,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_academic_details/rank/:id',
            component: SPDReportRank,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_academic_details/net/:id',
            component: SPDNetReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_academic_details/staff/:id',
            component: SPDStaffReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ---------institution details report tables-----------
        {
            path: '/spd_section_report_institution_details/general/:id',
            component: SPDGeneralReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_institution_details/accreditation/:id',
            component: SPDAccreditaionReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_institution_details/infrastructure/:id',
            component: SPDInfrastructureReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_institution_details/beneficiary/:id',
            component: SPDBeneficiaryReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ExtracurricularActivitiesReport-----
        {
            path: '/spd_section_report_activities/state/:id',
            component: SPDStateReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_activities/national/:id',
            component: SPDNationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_activities/international/:id',
            component: SPDInternationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_activities/facilities/:id',
            component: SPDFacilitiesActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_activities/nss_ncc/:id',
            component: SPDNccNssActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // fund utilization
        {
            path: '/spd_section_report_fund/rusaone/:id',
            component: SPDFundUtilizationReportsone,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_fund/rusatwo/:id',
            component: SPDFundUtilizationReports,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_section_report_fund/both/:id',
            component: SPDFundUtilizationReportsBoth,
            route: PrivateRoute,
            // roles: [9],
        },

            // spd reports state project codinator

           // ----academic-reports-tables-----
           {
            path: '/spd_state_report_academic_details/academic/:id',
            component: SPDReportAcademic,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_academic_details/rank/:id',
            component: SPDReportRank,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_academic_details/net/:id',
            component: SPDNetReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_academic_details/staff/:id',
            component: SPDStaffReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ---------institution details report tables-----------
        {
            path: '/spd_state_report_institution_details/general/:id',
            component: SPDGeneralReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_institution_details/accreditation/:id',
            component: SPDAccreditaionReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_institution_details/infrastructure/:id',
            component: SPDInfrastructureReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_institution_details/beneficiary/:id',
            component: SPDBeneficiaryReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ExtracurricularActivitiesReport-----
        {
            path: '/spd_state_report_activities/state/:id',
            component: SPDStateReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_activities/national/:id',
            component: SPDNationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_activities/international/:id',
            component: SPDInternationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_activities/facilities/:id',
            component: SPDFacilitiesActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_activities/nss_ncc/:id',
            component: SPDNccNssActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // fund utilization
        {
            path: '/spd_state_report_fund/rusaone/:id',
            component: SPDFundUtilizationReportsone,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_fund/rusatwo/:id',
            component: SPDFundUtilizationReports,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_state_report_fund/both/:id',
            component: SPDFundUtilizationReportsBoth,
            route: PrivateRoute,
            // roles: [9],
        },


            // spd reports research officer

           // ----academic-reports-tables-----
           {
            path: '/spd_research_report_academic_details/academic/:id',
            component: SPDReportAcademic,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_academic_details/rank/:id',
            component: SPDReportRank,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_academic_details/net/:id',
            component: SPDNetReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_academic_details/staff/:id',
            component: SPDStaffReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ---------institution details report tables-----------
        {
            path: '/spd_research_report_institution_details/general/:id',
            component: SPDGeneralReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_institution_details/accreditation/:id',
            component: SPDAccreditaionReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_institution_details/infrastructure/:id',
            component: SPDInfrastructureReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_institution_details/beneficiary/:id',
            component: SPDBeneficiaryReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ExtracurricularActivitiesReport-----
        {
            path: '/spd_research_report_activities/state/:id',
            component: SPDStateReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_activities/national/:id',
            component: SPDNationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_activities/international/:id',
            component: SPDInternationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_activities/facilities/:id',
            component: SPDFacilitiesActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_activities/nss_ncc/:id',
            component: SPDNccNssActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // fund utilization
        {
            path: '/spd_research_report_fund/rusaone/:id',
            component: SPDFundUtilizationReportsone,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_fund/rusatwo/:id',
            component: SPDFundUtilizationReports,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_research_report_fund/both/:id',
            component: SPDFundUtilizationReportsBoth,
            route: PrivateRoute,
            // roles: [9],
        },


            // spd reports nodal officer

           // ----academic-reports-tables-----
           {
            path: '/spd_nodal_report_academic_details/academic/:id',
            component: SPDReportAcademic,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_academic_details/rank/:id',
            component: SPDReportRank,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_academic_details/net/:id',
            component: SPDNetReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_academic_details/staff/:id',
            component: SPDStaffReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ---------institution details report tables-----------
        {
            path: '/spd_nodal_report_institution_details/general/:id',
            component: SPDGeneralReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_institution_details/accreditation/:id',
            component: SPDAccreditaionReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_institution_details/infrastructure/:id',
            component: SPDInfrastructureReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_institution_details/beneficiary/:id',
            component: SPDBeneficiaryReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ExtracurricularActivitiesReport-----
        {
            path: '/spd_nodal_report_activities/state/:id',
            component: SPDStateReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_activities/national/:id',
            component: SPDNationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_activities/international/:id',
            component: SPDInternationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_activities/facilities/:id',
            component: SPDFacilitiesActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_activities/nss_ncc/:id',
            component: SPDNccNssActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // fund utilization
        {
            path: '/spd_nodal_report_fund/rusaone/:id',
            component: SPDFundUtilizationReportsone,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_fund/rusatwo/:id',
            component: SPDFundUtilizationReports,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_nodal_report_fund/both/:id',
            component: SPDFundUtilizationReportsBoth,
            route: PrivateRoute,
            // roles: [9],
        },


            // spd reports assistant

           // ----academic-reports-tables-----
           {
            path: '/spd_assistant_report_academic_details/academic/:id',
            component: SPDReportAcademic,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_academic_details/rank/:id',
            component: SPDReportRank,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_academic_details/net/:id',
            component: SPDNetReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_academic_details/staff/:id',
            component: SPDStaffReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ---------institution details report tables-----------
        {
            path: '/spd_assistant_report_institution_details/general/:id',
            component: SPDGeneralReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_institution_details/accreditation/:id',
            component: SPDAccreditaionReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_institution_details/infrastructure/:id',
            component: SPDInfrastructureReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_institution_details/beneficiary/:id',
            component: SPDBeneficiaryReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ExtracurricularActivitiesReport-----
        {
            path: '/spd_assistant_report_activities/state/:id',
            component: SPDStateReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_activities/national/:id',
            component: SPDNationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_activities/international/:id',
            component: SPDInternationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_activities/facilities/:id',
            component: SPDFacilitiesActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_activities/nss_ncc/:id',
            component: SPDNccNssActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // fund utilization
        {
            path: '/spd_assistant_report_fund/rusaone/:id',
            component: SPDFundUtilizationReportsone,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_fund/rusatwo/:id',
            component: SPDFundUtilizationReports,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_assistant_report_fund/both/:id',
            component: SPDFundUtilizationReportsBoth,
            route: PrivateRoute,
            // roles: [9],
        },



            // spd reports system

           // ----academic-reports-tables-----
           {
            path: '/spd_system_report_academic_details/academic/:id',
            component: SPDReportAcademic,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_academic_details/rank/:id',
            component: SPDReportRank,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_academic_details/net/:id',
            component: SPDNetReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_academic_details/staff/:id',
            component: SPDStaffReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ---------institution details report tables-----------
        {
            path: '/spd_system_report_institution_details/general/:id',
            component: SPDGeneralReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_institution_details/accreditation/:id',
            component: SPDAccreditaionReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_institution_details/infrastructure/:id',
            component: SPDInfrastructureReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_institution_details/beneficiary/:id',
            component: SPDBeneficiaryReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ExtracurricularActivitiesReport-----
        {
            path: '/spd_system_report_activities/state/:id',
            component: SPDStateReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_activities/national/:id',
            component: SPDNationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_activities/international/:id',
            component: SPDInternationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_activities/facilities/:id',
            component: SPDFacilitiesActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_activities/nss_ncc/:id',
            component: SPDNccNssActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // fund utilization
        {
            path: '/spd_system_report_fund/rusaone/:id',
            component: SPDFundUtilizationReportsone,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_fund/rusatwo/:id',
            component: SPDFundUtilizationReports,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_system_report_fund/both/:id',
            component: SPDFundUtilizationReportsBoth,
            route: PrivateRoute,
            // roles: [9],
        },




           // spd reports finance

           // ----academic-reports-tables-----
           {
            path: '/spd_finance_report_academic_details/academic/:id',
            component: SPDReportAcademic,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_academic_details/rank/:id',
            component: SPDReportRank,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_academic_details/net/:id',
            component: SPDNetReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_academic_details/staff/:id',
            component: SPDStaffReport,
            route: PrivateRoute,
            // roles: [9],
        },
        
        // ---------institution details report tables-----------
        {
            path: '/spd_finance_report_institution_details/general/:id',
            component: SPDGeneralReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_institution_details/accreditation/:id',
            component: SPDAccreditaionReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_institution_details/infrastructure/:id',
            component: SPDInfrastructureReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_institution_details/beneficiary/:id',
            component: SPDBeneficiaryReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // ExtracurricularActivitiesReport-----
        {
            path: '/spd_finance_report_activities/state/:id',
            component: SPDStateReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_activities/national/:id',
            component: SPDNationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_activities/international/:id',
            component: SPDInternationalActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_activities/facilities/:id',
            component: SPDFacilitiesActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_activities/nss_ncc/:id',
            component: SPDNccNssActivitiesReport,
            route: PrivateRoute,
            // roles: [9],
        },
        // fund utilization
        {
            path: '/spd_finance_report_fund/rusaone/:id',
            component: SPDFundUtilizationReportsone,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_fund/rusatwo/:id',
            component: SPDFundUtilizationReports,
            route: PrivateRoute,
            // roles: [9],
        },
        {
            path: '/spd_finance_report_fund/both/:id',
            component: SPDFundUtilizationReportsBoth,
            route: PrivateRoute,
            // roles: [9],
        },


        


        
    ];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};
// userRoute, notificationRoutes
const appRoutes = [
    RusaAdmin,
    collegeAdmin,
    institutionalHead,
    institutionalCodinator,
    DataOperator,
    FinanceOfficer,
    ResearchOfficer,
    NodalOfficer,
    SectionOfficer,
    StateProjectCoodinator,
    Assistant,
    SystemAdmin,
];

const allRoutes = [rootRoute, dashboardRoutes, ...appRoutes, authRoutes];

const authProtectedRoutes = [dashboardRoutes, ...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);
export { allFlattenRoutes, allRoutes, authProtectedRoutes };
