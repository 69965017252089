import React, { useState } from 'react';
import { Plus } from 'react-feather';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Success from '../../../../success';
import PreviewInformation from '../preview';
import { MANAGE_LABELS } from './datamanager';

function ManangeGeneralaInformation(props) {
    const [keyField, setKeyField] = useState(0);
    const [formData, setFormData] = useState(props.data);
    const [flag, setFlag] = useState(props.flag);
    const [status, setStatus] = useState(props.status);
    const [success, setSuccess] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [edit, setEdit] = useState(props.edit);
    // const [primaryStep, setPrimaryStep] = useState(props.primaryStep);
    // console.log('2', props.primaryStep);
    // console.log(props.data);

    // console.log(props.create);
    const fields = {
        [MANAGE_LABELS.NAME_OF_ACCREDITING_AGENCY]: null,
        [MANAGE_LABELS.AGENCY_TYPE]: null,
        [MANAGE_LABELS.DATE_OF_ACCREDITATION]: null,
        [MANAGE_LABELS.VALID_UPTO]: null,
        [MANAGE_LABELS.GRADE_SCORE]: null,
        [MANAGE_LABELS.OTHER_REMARKS]: null,
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const error = validate();
        // console.log(error);
        if (Object.keys(error).length === 0) {
            if (activeStep === 1) {
                setActiveStep(2);
            } else {
                props.setPrimaryStep(1);
                setSuccess(true);
                // Clear form fields by setting formData to an empty array
                setFormData([fields]);
            }
        }
    };
    const handleSuccessAction = (e) => {
        // props.onSubmit({ data: formData, flag: flag, status: status });

        props.handleCallBack();
    };
    const handleChange = (name, value, ke) => {
        // console.log(name);
        let d = formData;
        d.forEach((item, ind) => {
            if (ind === ke) {
                item[name] = value;
            }
        });

        setFormData(d);
        setKeyField((f) => f + 1);
    };
    // Function to get the next day's date in the 'YYYY-MM-DD' format
    function getNextDayDate() {
        const today = new Date();
        today.setDate(today.getDate() + 1); // Add 1 day to get the next day

        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Pad the month and day with leading zeros if needed
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        return `${year}-${month}-${day}`;
    }

    const handleGradeScoreChange = (e, ke) => {
        let inputValue = e.target.value.toUpperCase();

        // Remove one character if backspace is pressed and the input is not empty
        if (e.key === 'Backspace' && inputValue.length > 0) {
            inputValue = inputValue.slice(0, -1);
        }

        // Validate the input using the regex
        const isValidInput = /^[A-F0-9]{1,4}$/.test(inputValue);

        if (isValidInput || inputValue === '') {
            handleChange(e.target.name, inputValue, ke);
        }
        // Optionally, you can show a message to the user if the input is invalid
    };

    const onAddFields = () => {
        let d = formData;
        d.push(fields);

        setFormData(d);
        setKeyField((j) => j + 1);
    };
    const removeField = (ke) => {
        let d = formData.filter((m, i) => i !== ke);

        setFormData(d);
        setKeyField((j) => j + 1);
    };
    const validate = () => {
        let error = {};
        if (flag === 'Y') {
            formData.forEach((item, k) => {
                Object.keys(item).forEach((key) => {
                    if (key !== MANAGE_LABELS.OTHER_REMARKS && (!item[key] || item[key] === '')) {
                        error[`${k}_${key}`] = 'please fill this field';
                    }
                });
            });
        }

        return error;
    };

    const manageEdit = () => {
        setEdit(true);
        setActiveStep(1);
    };
    // console.log(formData);
    // console.log('success', success);
    // console.log('active step', activeStep);
    return (
        <>
            {success && (
                <Success
                    title="Other accreditation details submitted for approval by the Institution head"
                    toggle={handleSuccessAction}
                    open={success}
                />
            )}
            <div className="manage">
                <div className="form-section">
                    <Container className="form-container ms-2 mt-3">
                        <Form className="form-style" onSubmit={handleSubmit}>
                            {activeStep === 1 ? (
                                <>
                                    <>
                                        {formData?.map((item, ke) => (
                                            <Row key={ke} className="repeat-box">
                                                <Col sm={12} md={4} className="mt-3">
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.NAME_OF_ACCREDITING_AGENCY}>
                                                            Name of accrediting agency{' '}
                                                            <sup style={{ color: 'red', fontSize: '14px' }}>*</sup>{' '}
                                                        </Label>
                                                        <Input
                                                            id={MANAGE_LABELS.NAME_OF_ACCREDITING_AGENCY}
                                                            name={MANAGE_LABELS.NAME_OF_ACCREDITING_AGENCY}
                                                            value={item[MANAGE_LABELS.NAME_OF_ACCREDITING_AGENCY] ?? ''}
                                                            placeholder=""
                                                            required
                                                            maxLength={50}
                                                            onChange={(e) =>
                                                                handleChange(e.target.name, e.target.value, ke)
                                                            }
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={4} className="mt-3">
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.AGENCY_TYPE}>
                                                            Agency type{' '}
                                                            <sup style={{ color: 'red', fontSize: '14px' }}>*</sup>{' '}
                                                        </Label>
                                                        <Input
                                                            id={MANAGE_LABELS.AGENCY_TYPE}
                                                            onChange={(e) =>
                                                                handleChange(e.target.name, e.target.value, ke)
                                                            }
                                                            value={item[MANAGE_LABELS.AGENCY_TYPE] ?? ''}
                                                            name={MANAGE_LABELS.AGENCY_TYPE}
                                                            placeholder=""
                                                            required
                                                            maxLength={50}
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={4} className="mt-3">
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.DATE_OF_ACCREDITATION}>
                                                            Date of Accreditation{' '}
                                                            <sup style={{ color: 'red', fontSize: '14px' }}>*</sup>{' '}
                                                        </Label>
                                                        <Input
                                                            id={MANAGE_LABELS.DATE_OF_ACCREDITATION}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;

                                                                // Limit the date input to 10 characters
                                                                if (inputValue.length <= 10) {
                                                                    handleChange(e.target.name, inputValue, ke);
                                                                }
                                                            }}
                                                            value={item[MANAGE_LABELS.DATE_OF_ACCREDITATION] ?? ''}
                                                            name={MANAGE_LABELS.DATE_OF_ACCREDITATION}
                                                            placeholder=""
                                                            required
                                                            type="date"
                                                            min="2018-01-01"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.VALID_UPTO}>
                                                            Valid Upto{' '}
                                                            <sup style={{ color: 'red', fontSize: '14px' }}>*</sup>{' '}
                                                        </Label>
                                                        <Input
                                                            id={MANAGE_LABELS.VALID_UPTO}
                                                            name={MANAGE_LABELS.VALID_UPTO}
                                                            required
                                                            value={item[MANAGE_LABELS.VALID_UPTO] ?? ''}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;

                                                                // Limit the date input to 10 characters
                                                                if (inputValue.length <= 10) {
                                                                    handleChange(e.target.name, inputValue, ke);
                                                                }
                                                            }}
                                                            type="date"
                                                            min={item[MANAGE_LABELS.DATE_OF_ACCREDITATION] || '2018-01-01'}
                                                            // Disable if Date of Accreditation is selected
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.GRADE_SCORE}>
                                                            Accreditation Score/ Grade{' '}
                                                            <sup style={{ color: 'red', fontSize: '14px' }}>*</sup>{' '}
                                                        </Label>
                                                        <Input
                                                            id={MANAGE_LABELS.GRADE_SCORE}
                                                            name={MANAGE_LABELS.GRADE_SCORE}
                                                            required
                                                            value={item[MANAGE_LABELS.GRADE_SCORE] ?? ''}
                                                            onChange={(e) => handleGradeScoreChange(e, ke)}
                                                            maxLength={3}
                                                            type="text"
                                                        />
                                                        <div style={{ color: '#2971b6' }}>
                                                            Maximum three characters allowed
                                                        </div>
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={12}>
                                                    <FormGroup>
                                                        <Label for={MANAGE_LABELS.OTHER_REMARKS}>Remarks</Label>
                                                        <Input
                                                            id={MANAGE_LABELS.OTHER_REMARKS}
                                                            name={MANAGE_LABELS.OTHER_REMARKS}
                                                            value={item[MANAGE_LABELS.OTHER_REMARKS] ?? ''}
                                                            onChange={(e) =>
                                                                handleChange(e.target.name, e.target.value, ke)
                                                            }
                                                            maxLength={1000}
                                                            type="text"></Input>
                                                        <div style={{ color: '#2971b6' }}>
                                                            Limit your description to 1000 characters or less
                                                        </div>
                                                    </FormGroup>
                                                </Col>

                                                {!props.edit && (
                                                    <Col xs={12} className="pt-2 pb-4">
                                                        {ke === formData.length - 1 && (
                                                            <Button color="primary" onClick={(e) => onAddFields()}>
                                                                Add Details <Plus />
                                                            </Button>
                                                        )}
                                                        {ke !== 0 && (
                                                            <Button
                                                                color="none"
                                                                onClick={(e) => removeField(ke)}
                                                                className="text-danger">
                                                                Remove
                                                            </Button>
                                                        )}
                                                    </Col>
                                                )}
                                            </Row>
                                        ))}
                                    </>
                                </>
                            ) : (
                                <>
                                    <PreviewInformation
                                        setEdit={manageEdit}
                                        data={formData}
                                        flag={flag}
                                        handleSubmit={handleSubmit}
                                        handleCallBack={props.handleCallBack}
                                        setSuccess={setSuccess}
                                        primaryStep={props.primaryStep}
                                        setPrimaryStep={props.setPrimaryStep}
                                        edit={edit}
                                    />
                                </>
                            )}

                            {activeStep === 1 ? (
                                <Button
                                    type="submit"
                                    className="my-2 "
                                    color="primary"
                                    outline
                                    style={{ marginLeft: '-11px' }}>
                                    Preview
                                </Button>
                            ) : (
                                ''
                            )}
                        </Form>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ManangeGeneralaInformation;
