import React from 'react';
import { Edit } from 'react-feather';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Col, FormGroup, Row, Input, Label } from 'reactstrap';
import { MANAGE_LABELS } from '../manage/datamanager';

const DataViewInfo = (props) => {
    const [formData, setFormdata] = useState([]);
    const { setViewInfo, viewData } = props;

    return (
        <>
            <div className="d-flex justify-content-start">
                <div
                    className="cursor-pointer bredcrumb_main"
                    // style={{ color: 'red', ':hover': { color: 'blue' } }}
                    onClick={() => setViewInfo(false)}>
                    Preparatory Grant Utilisation &nbsp;
                </div>

                
            </div>

            <div className="preview-page">
                <Row>
                    <Col sm={6} md={6}>
                        <FormGroup>
                            <Label style={{ color: 'black' }}>Financial Year </Label>
                            <Input
                                id={MANAGE_LABELS.YEAR}
                                // onChange={(e) => handleChange(e.target.name, e.target.value)}
                                value={viewData.year}
                                disabled
                                // name={MANAGE_LABELS.TOTAL_COST}
                                placeholder=""
                                required
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={6}>
                        <FormGroup>
                            <Label style={{ color: 'black' }}>Total Amount</Label>
                            <Input
                                id={MANAGE_LABELS.YEAR}
                                // onChange={(e) => handleChange(e.target.name, e.target.value)}
                                value={viewData?.amount}
                                disabled
                                placeholder=""
                                required
                                type="number"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Table striped>
                    <thead className="table-head">
                        <tr className="text-left">
                            <th>
                                Date <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                            </th>
                            <th>
                                Amount Utilised <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                            </th>
                            <th>
                                RUSA Activities <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                            </th>
                            
                        </tr>
                    </thead>
                    {viewData?.details.length > 0 ? (
                        viewData?.details?.map((item, k) => (
                            <tbody key={k}>
                                <tr className="text-left">
                                    <td>{item?.date ?? '--'}</td>
                                    <td>{item?.utilised ?? '--'}</td>
                                    <td>{item?.activities ?? '--'}</td>
                                    
                                </tr>
                            </tbody>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" className="text-center">
                                No Data Found
                            </td>
                        </tr>
                    )}
                </Table>
            </div>
        </>
    );
};

export default DataViewInfo;
