import React from 'react';
import { Edit } from 'react-feather';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, Col, FormGroup, Row, Input, Label } from 'reactstrap';
import { MANAGE_LABELS } from '../manage/datamanager';
import { create_fund_grant, update_fund_grant } from '../../../../../../../helpers/actions/api.functions';
import toast from "react-hot-toast"
const PreviewInformation = (props) => {
    const { setActiveStep } = props;
    // console.log(props);
    const generateInstitutions = (data) => {
        // console.log(data);
        const sendInst = [];
        data.map((item) => {
            let inst = {
                date: item[MANAGE_LABELS.DATE],
                activities: item[MANAGE_LABELS.ACTIVITIES],
                utilised: item[MANAGE_LABELS.AMOUNT],
            };
            sendInst.push(inst);
        });
        return sendInst;
    };

    const handleSubmit = () => {
        const sendData = {
            year: props?.currentyear,
            amount: props?.totalAmount,
            details: generateInstitutions(props.faculityData2),
        };
        if(!props.id) {
        create_fund_grant(sendData).then((res) =>{
            props.setSuccess(true)
            
        }).catch((err)=>{
            toast.error("Something Went Wrong")
        }) } else {
            update_fund_grant(sendData, props?.id).then((res) =>{
                props.setSuccess(true)
            }).catch((err)=>{
                toast.error("Something Went Wrong")
            });
        }
    };
    // const handleSubmit = () => {

    //     props.setSuccess(true)
    // }
    return (
        <div className="view">
            <Container>
                <div className="edit">
                    <Button color="primary" onClick={() => setActiveStep(1)}>
                        Edit
                    </Button>
                </div>
                <Row>
                    <Col sm={6} md={6}>
                        <FormGroup>
                            <Label style={{ color: 'black' }}>Financial Year </Label>
                            <Input
                                id={MANAGE_LABELS.YEAR}
                                // onChange={(e) => handleChange(e.target.name, e.target.value)}
                                value={props.currentyear}
                                disabled
                                // name={MANAGE_LABELS.TOTAL_COST}
                                placeholder=""
                                required
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={6}>
                        <FormGroup>
                            <Label style={{ color: 'black' }}>Total Amount</Label>
                            <Input
                                id={MANAGE_LABELS.YEAR}
                                // onChange={(e) => handleChange(e.target.name, e.target.value)}
                                value={props.totalAmount}
                                disabled
                                placeholder=""
                                required
                                type="number"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Table striped>
                    <thead>
                        <tr>
                            <th>
                                Date <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>{' '}
                            </th>
                            <th>
                                Amount Utilised <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                            </th>
                            <th>
                                RUSA Activities <sup style={{ color: 'red', fontSize: '13px' }}>*</sup>
                            </th>
                        </tr>
                    </thead>
                    {props.faculityData2?.map((item, k) => (
                        <tbody>
                            <tr>
                                <td>{item[MANAGE_LABELS.DATE]  ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.AMOUNT] ?? '--'}</td>
                                <td>{item[MANAGE_LABELS.ACTIVITIES] ?? '--'}</td>
                            </tr>
                        </tbody>
                    ))}
                </Table>
                <div className="">
                    <Button color="primary" onClick={() => handleSubmit()}>
                        Submit for approval
                    </Button>
                </div>
            </Container>
        </div>
    );
};

export default PreviewInformation;
