

import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
 import FundUtilizations from './FundUtilisation';
 import FundDetails from './fund_details';
import { api } from '../../../../../../../../../helpers/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const MyForm = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const fetchData = async () => {
        try {
            const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.rusa_phase_two;
            setGeneralDetails(jsonData);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            {/* <div className="mt-5 mb-3">RUSA Phase : RUSA 1</div> */}
            <div className="d-flex">
                Component Name <span className="px-2">:</span>
                <div className="d-flex flex-column">
                    {generalDetails.length > 0 && (
                        <div className="mx-2"> 
                             {generalDetails[0].component_name === 'Research' 
                                ? 'Research' 
                                : generalDetails[0].component_name === 'Innovation and quality improvement'
                                ? 'Innovation and quality improvement'
                                : ''}
                        </div>
                    )}
                </div>
            </div>

            <Nav className="nav nav-pills navtab-bg nav-justified">
                <NavItem>
                    <NavLink
                        href="#"
                        className={activeTab === '1' ? 'active' : ''}
                        onClick={() => setActiveTab('1')}
                    >
                        Fund Details
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        href="#"
                        className={activeTab === '2' ? 'active' : ''}
                        onClick={() => setActiveTab('2')}
                    >
                        Fund Utilisation
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <FundDetails generalDetails={generalDetails} />
                </TabPane>
                <TabPane tabId="2">
                    <FundUtilizations generalDetails={generalDetails} />
                </TabPane>
            </TabContent>
        </div>
    );
};

export default MyForm;


