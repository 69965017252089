import React, { useEffect } from 'react';
import { useState } from 'react';
import ManangeGeneralaInformation from './manage';
import GeneralInformation from './view';
import { MANAGE_LABELS } from './manage/datamanager';
import DataViewInfo from './dataview';
import { list_fund_grant } from '../../../../../../helpers/actions/api.functions';
import toast from 'react-hot-toast';
const Faculityfields2 = {
    [MANAGE_LABELS.DATE]: null,
    [MANAGE_LABELS.AMOUNT]: null,
    [MANAGE_LABELS.ACTIVITIES]: null,
    // [MANAGE_LABELS.ACTION]: null,
};
const initial_values2 = [Faculityfields2];
function DataOperatorStaffPatternMain() {
    const [faculityData2, setFaculityData2] = useState(initial_values2);
    const [edit, setEdit] = useState(false);
    const [newReg, setNewReg] = useState(false);
    const [viewinfo, setViewInfo] = useState(false);
    const [year, setYear] = useState(null);
    const [listData, setListData] = useState([]);
    const [viewData, setViewData] = useState({});
    const [details, setDetails] = useState([]);

    const manageEdit = () => {
        setEdit(true);
    };
    const onSubmit = (data) => {
        // console.log(data);

        setFaculityData2(data.faculityData2);

        setEdit(false);
        setNewReg(false);
    };
    const handleEdit = (selectedYear) => {
        setYear(selectedYear);
        const fields = {
            [MANAGE_LABELS.DATE]: null,
            [MANAGE_LABELS.AMOUNT]: null,
            [MANAGE_LABELS.ACTIVITIES]: null,
        };
        const newObj = listData?.find((obj) => obj.year == selectedYear);
        const objArray = [];
        if (newObj) {
            newObj.details.map((item) => {
                let fields = {
                    [MANAGE_LABELS.DATE]: item?.date,
                    [MANAGE_LABELS.AMOUNT]: item?.utilised,
                    [MANAGE_LABELS.ACTIVITIES]: item?.activities,
                };
                objArray.push(fields);
            });
        }
        setViewData(newObj);

        setFaculityData2(objArray.length > 0 ? objArray : [fields]);
        setEdit(true);
    };
    const handleView = () => {
        setEdit(true);
        setNewReg(true);
    };

    const fetchData = () => {
        list_fund_grant()
            .then((res) => {
                setListData(res);
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleViewInfo = (date) => {
        // console.log(listData);
        // console.log(date);
        const newObj = listData?.find((obj) => obj.year == date);
        setViewData(newObj);
        if (newObj) {
            setViewInfo(true);
        } else {
            toast.error('No Details Found');
        }
    };

    return (
        <div className="generalinfo">
            {newReg || edit ? (
                <ManangeGeneralaInformation
                    faculityData2={faculityData2}
                    setFaculityData2={setFaculityData2}
                    edit={edit}
                    onSubmit={onSubmit}
                    handleEdit={handleEdit}
                    setYear={setYear}
                    year={year}
                    totalAmount={viewData?.amount}
                    viewData={viewData}
                    fetchData={fetchData}
                />
            ) : viewinfo ? (
                <DataViewInfo
                    setEdit={manageEdit}
                    setViewInfo={setViewInfo}
                    viewinfo={viewinfo}
                    year={year}
                    viewData={viewData}
                />
            ) : (
                <GeneralInformation
                    setEdit={manageEdit}
                    setFaculityData2={setFaculityData2}
                    faculityData2={faculityData2}
                    handleEdit={handleEdit}
                    setYear={setYear}
                    year={year}
                    handleView={handleView}
                    handleViewInfo={handleViewInfo}
                    listData={listData}
                />
            )}
        </div>
    );
}

export default DataOperatorStaffPatternMain;
