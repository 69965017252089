import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { list_fund_stage2 } from '../../../../../helpers/actions/api.functions';
import ManangeGeneralaInformation from './manage';
import { MANAGE_LABELS } from './manage/datamanager';
import GeneralInformation from './view';
const fields = {
    [MANAGE_LABELS.ACCREDITATION_SCORE]: null,
    [MANAGE_LABELS.ACCREDITATION_CYCLE]: null,
    [MANAGE_LABELS.VALID_UPTO]: null,
    [MANAGE_LABELS.ACCREDITATION_DATE]: null,
    [MANAGE_LABELS.ACCREDITATION_GRADE]: null,
    [MANAGE_LABELS.NAAC_REMARKS]: null,
};
const initial_values = fields;

function StageOneMain() {
    const [generalData, setGeneralData] = useState({});
    const [edit, setEdit] = useState(false);
    const [newReg, setNewReg] = useState(false);
    const [flag, setFlag] = useState('Y');
    const [status, setStatus] = useState('');
    const [remark, setRemark] = useState('');
    const [activeStep, setActiveStep] = useState(1);
    const [listData, setListData] = useState({});
    const manageEdit = () => {
        setEdit(true);
    };
    const onSubmit = (data) => {
        // console.log(data);
        setGeneralData(data.data);
        setFlag(data.flag);
        setStatus(data.status);
        setRemark(data.remark);
        setEdit(false);
        setNewReg(false);
    };
    const fetchData = () => {
        list_fund_stage2()
            .then((res) => {
                setListData(res[0]);
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };
    useEffect(() => {
        fetchData();
        setEdit(false);
    }, []);
    const handleCreate = () => {
        const fieldsNew = {
            [MANAGE_LABELS.ACCREDITATION_SCORE]: null,
            [MANAGE_LABELS.ACCREDITATION_CYCLE]: null,
            [MANAGE_LABELS.VALID_UPTO]: null,
            [MANAGE_LABELS.ACCREDITATION_DATE]: null,
            [MANAGE_LABELS.ACCREDITATION_GRADE]: null,
            [MANAGE_LABELS.NAAC_REMARKS]: null,
        };
        setGeneralData([fieldsNew]);
        setNewReg(true);
        setActiveStep(1);
    };
    const reverseGen = (data) => {
        const instData = [];
        // console.log(data);
        data.map((item, i) => {
            let data = {
                id: i,
                INSTITUTION_NAME: item?.name,
                PROJECT_COST: item?.cost,
                AGREED_CONTRACT_AMOUNT: item?.contract,
                AGENCY_NAME: item?.agency,
                APPROVED_COST: item?.approved,
                AMOUNT_UTILISED: item?.utilised,
            };
            instData.push(data);
        });
        return instData;
    };
    const handleEdit = () => {
        // console.log(listData);
        const fieldsNew = {
            [MANAGE_LABELS.TOTAL_COST]: listData?.total_cost,
            [MANAGE_LABELS.AGREED_CONTRACT_AMOUNT]: listData?.amount,
            id: listData?.id,
            institutions: reverseGen(listData?.institutions),
        };
        setGeneralData(fieldsNew);
        setEdit(true);
        setActiveStep(1);
    };
    return (
        <div className="generalinfo">
            {newReg || edit ? (
                <ManangeGeneralaInformation
                    data={generalData}
                    setGeneralData={setGeneralData}
                    edit={edit}
                    setFlag={setFlag}
                    flag={flag}
                    status={status}
                    setStatus={setStatus}
                    fields={fields}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    onSubmit={onSubmit}
                    remark={remark}
                    setRemark={setRemark}
                    fetchData={fetchData}
                />
            ) : (
                <GeneralInformation
                    setEdit={manageEdit}
                    data={generalData}
                    status={status}
                    flag={flag}
                    remark={remark}
                    setNewReg={setNewReg}
                    newReg={newReg}
                    setActiveStep={setActiveStep}
                    handleCreate={handleCreate}
                    listData={listData}
                    handleEdit={handleEdit}
                    
                />
            )}
        </div>
    );
}

export default StageOneMain;
