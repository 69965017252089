import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Container, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';

function HeadFacultyDetails(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false)
    const fetchData = async () => {
        try {
            const response = await api.get(`institution/${id}`);
            const jsonData = response?.data?.data?.data;
            setGeneralDetails(jsonData.staff_details);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`/staff/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setGeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };

    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };

    // ------
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };
    // -----
    return (
        <div>
            {revert && (
                <Failure
                    title="Faculty details has been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="view">
                <Container className="container-width">
                    <div className="table-section w-100 overflow-auto">
                        <Table striped>
                            <thead className="table-head ml-5 mt-0">
                                <tr>
                                    <th style={{ minWidth: '100px' }}>Date as on:</th>
                                    <th style={{ minWidth: '110px' }}>Number of sanctioned faculty posts </th>
                                    <th style={{ minWidth: '100px' }}>Number of Permanent Faculties</th>
                                    <th style={{ minWidth: '100px' }}>Number of Guest Faculties</th>
                                    <th style={{ minWidth: '100px' }}>Number of Contract Faculties</th>
                                    <th style={{ minWidth: '100px' }}>Number of Vacancies</th>
                                    <th style={{ minWidth: '280px' }}>Remarks</th>

                                    <th style={{ minWidth: '60px' }}>Status</th>
                                    <th style={{ minWidth: '60px' }}>Actions</th>
                                </tr>
                            </thead>
                            {generalDetails.filter((item) => item.type === 'faculty').length > 0 ? (
                                <tbody>
                                    {generalDetails.map((item, index) =>
                                        item.type === 'faculty' ? (
                                            <tr key={index} className="text-left">
                                                <>
                                                    <td>{formatDate(item.date)}</td>
                                                    <td>{item.approved_staff}</td>
                                                    <td>{item.permanent_staff}</td>
                                                    <td>{item.guest}</td>
                                                    <td>{item.contract_staff}</td>
                                                    <td>{item.vacant}</td>
                                                    <td style={{ maxWidth: '200px', textAlign: 'justify' }}>
                                                        {item.remarks}
                                                    </td>
                                                    <td>
                                                        {item.is_approved === 0 ? (
                                                            <td className="d-flex">
                                                                <Button
                                                                    disabled
                                                                    color="warning"
                                                                    class="btn btn-secondary"
                                                                    style={{
                                                                        color: '#ED7D31',
                                                                        fontWeight: '400',
                                                                        backgroundColor: '#F5F7F8',
                                                                    }}
                                                                    size="sm">
                                                                    Pending
                                                                </Button>
                                                                <br></br>
                                                            </td>
                                                        ) : item.is_approved === 2 ? (
                                                            <td>
                                                                <Button type="button" size="sm" color="danger" disabled>
                                                                    Reverted by Head
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 1 ? (
                                                            <td>
                                                                <Button type="button" color="success" size="sm" disabled>
                                                                    Approved by Head
                                                                </Button>
                                                            </td>
                                                        ) : item.is_approved === 3 && (
                                                            <td>
                                                                <Button type="button" size="sm" color="info" disabled>
                                                                    Reverted by RUSA Admin
                                                                </Button>
                                                            </td>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {item.is_approved === 1 && (
                                                            <td>
                                                                <Button
                                                                    type="button"
                                                                    size="sm"
                                                                    outline
                                                                    color="primary"
                                                                    onClick={() => handleRevert(item.id)}
                                                                >
                                                                    Revert
                                                                </Button>
                                                            </td>
                                                        )}
                                                    </td>
                                                </>
                                            </tr>
                                        ) : (
                                            ''
                                        )
                                    )}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="9" className="text-center">
                                            No data Found
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </Table>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default HeadFacultyDetails;
