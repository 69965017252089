import React from 'react';
import { Row, Container, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useState } from 'react';

import SANCTIONS from './Sanctions';

function CourseTypeSPD() {
    const tabContents = [{ id: '1', title: 'NAAC' }];
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <SANCTIONS />
            </Container>
        </div>
    );
}

export default CourseTypeSPD;
