import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Table } from 'reactstrap';
import { FaFileExcel } from 'react-icons/fa';
import {
    assistant_stage1,
    finance_stage1,
    nodal_stage1,
    research_stage1,
    section_stage1,
    state_stage1,
    system_stage1,
} from '../../../../../helpers/actions/api.functions';
import toast from 'react-hot-toast';
import { getLoggedInUser } from '../../../../../helpers/authUtils';

const StageFirst = () => {
    const [data, setData] = useState([]);
    const userData = getLoggedInUser();
    const userRole = userData?.role_id;
    // console.log(userRole);

    const getEndpoint = (id) => {
        switch (id) {
            case '9':
                return section_stage1();
            case '10':
                return state_stage1();
            case '7':
                return research_stage1();
            case '8':
                return nodal_stage1();
            case '11':
                return assistant_stage1();
            case '12':
                return system_stage1();
            case '6':
                return finance_stage1();
            default:
                return '';
        }
    };
    const getStaffListing = (userRole) => {
        getEndpoint(userRole)
            .then((res) => {
                setData(res[0]);
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
            });
    };

    useEffect(() => {
        if (userRole) {
            getStaffListing(userRole);
        }
    }, [userRole]);
    return (
        <div>
            {data?.is_approved === 0  && (
                <div className="mt-2 mb-4 d-flex justify-content-end">
                   <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                </div>
            )}
            {data?.is_approved === 2 && (
                <div className="mt-2 mb-4 d-flex justify-content-end">
                    <Button type="button" color="danger">
                        Reverted
                    </Button>
                </div>
            )}
            {data?.is_approved === 1 && (
                <div className="mt-2 mb-4 d-flex justify-content-end">
                    <Button type="button" color="success">
                        Approved
                    </Button>
                </div>
            )}
            <div className="preview-page">
                <Row>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Total Project Cost
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {data?.cost}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Agreed Probable Amount of Contract
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {data?.amount}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Agency Name
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={7} sm={7}>
                                {data?.agency}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            {/* <div>
                <FaFileExcel className="excel-icon my-3" />
            </div> */}

            {/* <div className="mandatory my-3 text-dark">Details of Rank holders</div> */}
            <Table striped className="mt-5">
                <thead className="table-head">
                    <tr className="text-left">
                        {/* <th style={{ minWidth: '50px' }}>#</th> */}
                        <th style={{ minWidth: '150px' }}>Institution Name</th>
                        <th style={{ minWidth: '150px' }}>Approved Cost</th>
                        <th style={{ minWidth: '100px' }}>Amount Utilised</th>
                        <th style={{ minWidth: '100px' }}>% of Utilisation</th>
                    </tr>
                </thead>
                {data?.institutions?.length > 0 ? (
                    <tbody>
                        {data?.institutions.map((item, i) => (
                            <tr className="text-left" key={i}>
                                <td>{item?.name}</td>
                                <td>{item?.approved}</td>
                                <td>{item?.utilised}</td>
                                <td>{item?.percentage}</td>
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="text-left">
                            <td></td>
                            <td></td>
                            <td>No data found</td>
                            <td></td>
                        </tr>
                    </tbody>
                )}
            </Table>

            {/* <Col className="d-flex justify-content-start mt-4 ml-2 p-0">
                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                               >
                                                                Approve
                                                            </Button>

                                                            <Button
                                                                color="primary"
                                                                outline
                                                                className="ml-3"
                                                                >
                                                                Resubmit
                                                            </Button>
                                                        </Col> */}
        </div>
    );
};

export default StageFirst;
