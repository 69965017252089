import React, { useEffect } from 'react';
import { useState } from 'react';
import ManangeGeneralaInformation from './form';
import GeneralInformation from './view';
import { MANAGE_LABELS } from './datamanager';
import {
    budget_delete,
    create_budget,
    list_budget,
    update_budget,
} from '../../../../../../helpers/actions/api.functions';
import toast from 'react-hot-toast';
import { getErrorMessage } from '../../../../../../components/genError';

const Faculityfields2 = {
    [MANAGE_LABELS.YEAR]: null,
    [MANAGE_LABELS.DETAILS]: null,
    [MANAGE_LABELS.HEAD]: null,
    [MANAGE_LABELS.OUTLAY]: null,
    [MANAGE_LABELS.REMARKS]: null,
};
const initial_values2 = [Faculityfields2];
function DataOperatorStaffPatternMain() {
    const [faculityData2, setFaculityData2] = useState(initial_values2);
    const [edit, setEdit] = useState(false);
    const [newReg, setNewReg] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [indData, setIndData] = useState({});
    const [listData, setListData] = useState([]);
    const [indEdit, setIndEdit] = useState(false);

    const fetchList = () => {
        list_budget()
            .then((res) => {
                setListData(res?.data?.data);
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };
    const setEditData = () => {
        const dt = String(indData?.year);
        // console.log(dt);
        const newYear = dt.split('-');
        // console.log(newYear);
        const Faculityfields3 = {
            [MANAGE_LABELS.START_YEAR]: newYear[0] ?? '--',
            [MANAGE_LABELS.END_YEAR]: newYear[1] ?? '--',
            [MANAGE_LABELS.DETAILS]: indData?.details,
            [MANAGE_LABELS.HEAD]: indData?.account,
            [MANAGE_LABELS.OUTLAY]: indData?.total,
            [MANAGE_LABELS.REMARKS]: indData?.remarks,
            id: indData?.id,
        };
        // console.log(Faculityfields3);
        setFaculityData2([Faculityfields3]);
        setEdit(true);
    };
    useEffect(() => {
        fetchList();
    }, []);
    useEffect(() => {
        if (indEdit) {
            setEditData();
        }
    }, [indData, indEdit]);
    const generateObject = (data, i) => {
        const objData = {
            year: `${data?.START_YEAR}-${data?.END_YEAR}`,
            details: data?.DETAILS,
            account: data?.HEAD,
            total: data?.OUTLAY,
            remarks: data?.REMARKS,
        };

        return objData;
    };
    const deleteOrder = (id) => {
        budget_delete(id)
            .then((res) => {
                toast.success('Budget Deleted successfully');
                fetchList()
            })
            .catch((err) => {
                toast.error('Something Went Wrong');
            });
    };
    const createBudget = (data) => {
        // console.log(data, 'create budget');
        const payload = [];
        data.map((item, i) => {
            payload.push(generateObject(item, i));
        });
        // console.log(payload);
        if (!indEdit) {
            create_budget(payload)
                .then((res) => {
                    toast.success('Budget Added Successfully');
                    fetchList();
                    setEdit(false);
                })
                .catch((err) => {
                    toast.error(`${getErrorMessage(err.response.data.error)}`, {
                        duration: 4000,
                        position: 'top-right',
                    });
                });
        } else {
            update_budget(payload[0], data[0]?.id)
                .then((res) => {
                    toast.success('Budget Updated Successfully');
                    fetchList();
                    setEdit(false);
                    setIndEdit(false);
                })
                .catch((err) => {
                    toast.error(`${getErrorMessage(err.response.data.error)}`, {
                        duration: 4000,
                        position: 'top-right',
                    });
                });
        }
    };
    const manageEdit = () => {
        setEdit(true);
        setNewReg(true);
        const Faculityfields2 = {
            [MANAGE_LABELS.YEAR]: null,
            [MANAGE_LABELS.DETAILS]: null,
            [MANAGE_LABELS.HEAD]: null,
            [MANAGE_LABELS.OUTLAY]: null,
            [MANAGE_LABELS.REMARKS]: null,
        };
        setFaculityData2([Faculityfields2]);
    };
    const onSubmit = (data) => {
        // console.log(data);

        setFaculityData2(data.faculityData2);
        setIndEdit(false);
        setIndData({});
        setFaculityData2([Faculityfields2]);
        setEdit(false);
        setNewReg(false);
    };

    return (
        <div className="generalinfo">
            {newReg || edit ? (
                <ManangeGeneralaInformation
                    faculityData2={faculityData2}
                    setFaculityData2={setFaculityData2}
                    edit={edit}
                    onSubmit={onSubmit}
                    setActiveStep={setActiveStep}
                    createBudget={createBudget}
                    indEdit={indEdit}
                />
            ) : (
                <GeneralInformation
                    setEdit={manageEdit}
                    setFaculityData2={setFaculityData2}
                    faculityData2={faculityData2}
                    list={listData}
                    setIndData={setIndData}
                    setIndEdit={setIndEdit}
                    deleteOrder={deleteOrder}
                />
            )}
        </div>
    );
}

export default DataOperatorStaffPatternMain;
