import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';

function HeadSportFacilities(props) {
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [revert, setRevert] = useState(false);
    const { id } = useParams();

    const fetchData = async () => {
        try {
            const response = await api.get(`institution-dataoperator/${id}`); // Assuming api.get works as expected

            const jsonData = response?.data?.data?.data;

            // console.log(jsonData);
            setGeneralDetails(jsonData.sports);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);



    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    return (
        <div className="view">
            {revert && (
                <Failure
                    title="Infrastructure facilities details has been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <Container className="p-0 my-4">
                <Card>
                    <CardBody className="p-0">
                        <div className="table-section">
                            {generalDetails.length > 0 ? (
                                generalDetails.map((data, i) => (
                                    <Table style={{ width: '100%', tableLayout: 'fixed' }} striped key={i}>
                                        <tbody>
                                            <tr style={{ width: '100%' }}>
                                                <div className="w-50 d-flex justify-content-start ">
                                                    <td>
                                                        {data.is_approved === 0 ? (
                                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>
                                                                <Button
                                                                    disabled
                                                                    color="warning"
                                                                    class="btn btn-secondary"
                                                                    style={{
                                                                        color: '#ED7D31',
                                                                        fontWeight: '400',
                                                                        backgroundColor: '#F5F7F8',
                                                                    }}
                                                                    size="sm">
                                                                    Pending
                                                                </Button>
                                                            </td>
                                                        ) : data.is_approved === 2 ? (
                                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>
                                                                <Button type="button" size="sm" color="danger" disabled>
                                                                    Reverted by Head
                                                                </Button>
                                                            </td>
                                                        ) : data.is_approved === 1 ? (
                                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>
                                                                <Button
                                                                    type="button"
                                                                    color="success"
                                                                    size="sm"
                                                                    disabled>
                                                                    Approved by Head
                                                                </Button>
                                                            </td>
                                                        ) : (
                                                            data.is_approved === 3 && (
                                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>
                                                                    <Button
                                                                        type="button"
                                                                        color="info"
                                                                        size="sm"
                                                                        disabled>
                                                                        Reverted by RUSA Admin
                                                                    </Button>
                                                                </td>
                                                            )
                                                        )}
                                                      
                                                    </td>
                                                 
                                                      
                                                    
                                                </div>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>
                                                Total number of sanctioned posts in Physical Education Department
                                                </td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.faculities ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>
                                                    Number of permanent faculties in the Department
                                                </td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.permanent ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Number of Guest faculties in the Department</td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.guest ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Gymnasium</td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.gym ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Swimming pool</td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.pool ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Indoor Stadium</td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.stadium ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Playground</td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.playground ?? '--'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>
                                                    Applicable games for which the playground is suitable
                                                </td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>
                                                    {data.games ? JSON.parse(data.games).join(', ') : '--'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Remarks</td>
                                                <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{data.remarks ?? '--'}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                ))
                            ) : (
                                <Table striped>
                                    <tbody>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>
                                            Total number of sanctioned posts in Physical Education Department
                                            </td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Number of permanent faculties in the Department</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Number of Guest faculties in the Department</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Gymnasium</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Swimming pool</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Indoor Stadium</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Playground</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>
                                                Applicable games for which the playground is suitable
                                            </td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>Remarks</td>
                                            <td style={{ minWidth: '300px', whiteSpace: 'pre-wrap' }}>{'--'}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}
export default HeadSportFacilities;
