import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import {
    assistant_pfms,
    finance_pfms,
    nodal_pfms,
    research_pfms,
    section_pfms,
    state_pfms,
    system_pfms,
} from '../../../../helpers/actions/api.functions';
import { getLoggedInUser } from '../../../../helpers/authUtils';

function InstitutionPfmsSPD() {
    const [data, setData] = useState([]);
    const userData = getLoggedInUser();
    const userRole = userData?.role_id;
    // console.log(userRole);

    const getEndpoint = (id) => {
        switch (id) {
            case '9':
                return section_pfms();
            case '10':
                return state_pfms();
            case '7':
                return research_pfms();
            case '8':
                return nodal_pfms();
            case '11':
                return assistant_pfms();
            case '12':
                return system_pfms();
            case '6':
                return finance_pfms();
            default:
                return '';
        }
    };
    const getStaffListing = (userRole) => {
        getEndpoint(userRole)
            .then((res) => {
                setData(res?.data?.data);
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
            });
    };

    useEffect(() => {
        if (userRole) {
            getStaffListing(userRole);
        }
    }, [userRole]);
    return (
        <div>
            <div className="view "></div>
            <Container className="form-container" style={{ padding: '10px' }}>
                <h5 className="header-title mt-5 ">Institution PFMS Details</h5>

                <div className="table-section w-100 mt-5 ">
                    <Table striped>
                        <thead className="table-head mt-5 ml-3 mr-5">
                            <tr className="text-left">
                                <th style={{ minWidth: '100px' }}>Institution Name</th>
                                <th style={{ minWidth: '150px' }}>PFMS Unique Code</th>
                                <th style={{ minWidth: '150px' }}>Status</th>
                            </tr>
                        </thead>
                        {/* {College_names.map((item, id) => {
                                
                                return (
                                    <tbody key={id}>
                                        <tr className="text-left">
                                            <td>{id+1}</td>
                                            <td>{item.label}</td>
                                            <td><FiEdit /></td>
                                        </tr>
                                    </tbody>
                                );
                            })} */}
                        {data.length > 0 ? (
                            <tbody>
                                {data.map((item, i) => (
                                    <tr className="text-left" key={i}>
                                        <td scope="col">{item?.institution}</td>
                                        <td scope="col">{item?.unique_code}</td>
                                        <td style={{ maxWidth: '5n0px' }}>
                                                    {item.is_approved === 0 && (
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 1 && (
                                                        <Button disabled color="success" size="sm">
                                                            Approved
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 2 && (
                                                        <Button disabled color="danger" size="sm">
                                                            Reverted 
                                                        </Button>
                                                    )}</td>
                                        
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="text-center">
                                    <td colSpan={4}>No Data Found</td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            </Container>
        </div>
    );
}

export default InstitutionPfmsSPD;
