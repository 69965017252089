import React, { useEffect, useState } from 'react';
import { AlignCenter } from 'react-feather';
import { Button, Card, CardBody, Container, Table, Row, Col } from 'reactstrap';
import { assistant_agency, finance_agency, nodal_agency, research_agency, section_agency, state_agency, system_agency } from '../../../../helpers/actions/api.functions';
import { getLoggedInUser } from '../../../../helpers/authUtils';
import toast from 'react-hot-toast';
function AccreditedSPD() {
    const [data, setData] = useState([]);
    const userData = getLoggedInUser();
    const userRole = userData?.role_id;
    // console.log(userRole);

    const getEndpoint = (id) => {
        switch (id) {
            case '9':
                return section_agency();
            case '10':
                return state_agency();
            case '7':
                return research_agency();
            case '8':
                return nodal_agency();
            case '11':
                return assistant_agency();
            case '12':
                return system_agency();
            case '6':
                return finance_agency();
            default:
                return '';
        }
    };
    const getStaffListing = (userRole) => {
        getEndpoint(userRole)
            .then((res) => {
                setData(res?.data?.data);
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
            });
    };

    useEffect(() => {
        if (userRole) {
            getStaffListing(userRole);
        }
    }, [userRole]);
    return (
        <div>
            


            <Card>
                <CardBody>
                    <Container>
                        <h5 className="header-title my-5"> Details of Accredited Agencies</h5>

                        <>
                            {/* <div>
                                <FaFileExcel className="excel-icon mb-2" style={{color:"green"}}/>
                            </div> */}

                            <Table striped>
                                <thead className="table-head ml-5 mt-0 ">
                                    <tr>
                                        <th>Year</th>
                                        <th>Accredited Agency Name</th>
                                        <th>Details</th>
                                        <th>Remarks</th>
                                        <th style={{ minWidth: '60px' }}>Status</th>
                                    </tr>
                                </thead>
                                {data && data?.length > 0 ? (
                                    <tbody>
                                        {data?.map((item) => (
                                            <tr className="text-left">
                                                <td scope="col">{item?.year}</td>
                                                <td scope="col">{item?.agency}</td>
                                                <td scope="col">{item?.details}</td>
                                                <td scope="col" className='text-justify'>{item?.remarks}</td>
                                                <td style={{ maxWidth: '5n0px' }}>
                                                    {item.is_approved === 0 && (
                                                        <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 1 && (
                                                        <Button disabled color="success" size="sm">
                                                            Approved
                                                        </Button>
                                                    )}
                                                    {item.is_approved === 2 && (
                                                        <Button disabled color="danger" size="sm">
                                                            Reverted 
                                                        </Button>
                                                    )}
                                                    </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr className="text-center">
                                            <th colSpan={5}>No Data Found</th>
                                        </tr>
                                    </tbody>
                                )}
                            </Table>
                            {/* <Button outline> Approve</Button>
                            <Button outline style={{ marginLeft: '10px' }}>
                                {' '}
                                Resubmit
                            </Button> */}
                        </>
                    </Container>
                </CardBody>
            </Card>
        </div>
    );
}

export default AccreditedSPD;
