import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Table } from 'reactstrap';
import { assistant_stage2, finance_stage2, nodal_stage2, research_stage2, section_stage2, state_stage2, system_stage2 } from '../../../../../helpers/actions/api.functions';
import { getLoggedInUser } from '../../../../../helpers/authUtils';
import toast from "react-hot-toast"
const StageSecond = () => {
    const [data, setData] = useState([]);
    const userData = getLoggedInUser();
    const userRole = userData?.role_id;
    // console.log(userRole);

    const getEndpoint = (id) => {
        switch (id) {
            case '9':
                return section_stage2();
            case '10':
                return state_stage2();
            case '7':
                return research_stage2();
            case '8':
                return nodal_stage2();
            case '11':
                return assistant_stage2();
            case '12':
                return system_stage2();
            case '6':
                return finance_stage2();
            default:
                return '';
        }
    };
    const getStaffListing = (userRole) => {
        getEndpoint(userRole)
            .then((res) => {
                setData(res[0]);
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
            });
    };

    useEffect(() => {
        if (userRole) {
            getStaffListing(userRole);
        }
    }, [userRole]);
    return (
        <div>
            {data?.is_approved === 0  && (
                <div className="mt-2 mb-4 d-flex justify-content-end">
                   <Button
                                                            disabled
                                                            color="warning"
                                                            class="btn btn-secondary"
                                                            style={{
                                                                color: '#ED7D31',
                                                                fontWeight: '400',
                                                                backgroundColor: '#F5F7F8',
                                                            }}
                                                            size="sm">
                                                            Pending
                                                        </Button>
                </div>
            )}
            {data?.is_approved === 2 && (
                <div className="mt-2 mb-4 d-flex justify-content-end">
                    <Button type="button" color="danger">
                        Reverted
                    </Button>
                </div>
            )}
            {data?.is_approved === 1 && (
                <div className="mt-2 mb-4 d-flex justify-content-end">
                    <Button type="button" color="success">
                        Approved
                    </Button>
                </div>
            )}
            
                
                <div className="preview-page">
                    <Row>
                        <Col sm={12} md={12} className="my-3">
                            <Row className="text-dark">
                                <Col md={4} sm={4}>
                                    Total Project Cost
                                </Col>
                                <Col md={2} sm={2}>
                                    :
                                </Col>
                                <Col md={6} sm={6}>
                                    {data?.total_cost}
                                </Col>
                            </Row>
                        </Col>

                        <Col sm={12} md={12} className="my-3">
                            <Row className="text-dark">
                                <Col md={4} sm={4}>
                                    Agreed Probable Amount of Contract
                                </Col>
                                <Col md={2} sm={2}>
                                    :
                                </Col>
                                <Col md={6} sm={6}>
                                    {data?.amount}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="table-section w-100 mt-5">
                    {/* <div className="mandatory my-3 text-dark">Details of Rank holders</div> */}
                    <Table striped>
                        <thead className="table-head">
                            <tr className="text-left">
                                {/* <th style={{ minWidth: '50px' }}>#</th> */}
                                <th style={{ minWidth: '80px' }}>Institution Name</th>
                                <th style={{ minWidth: '80px' }}>Project Cost</th>
                                <th style={{ minWidth: '80px' }}>Agreed Contract Amount</th>
                                <th style={{ minWidth: '80px' }}>Agency Name</th>
                                <th style={{ minWidth: '80px' }}>Approved Cost</th>
                                <th style={{ minWidth: '80px' }}>Amount Utilised</th>
                                <th style={{ minWidth: '80px' }}>% of Utilisation</th>
                            </tr>
                        </thead>
                        {data?.institutions?.length > 0 ? (
                    <tbody>
                        {data?.institutions.map((item, i) => (
                            <tr className="text-left" key={i}>
                                <td>{item?.name}</td>
                                <td>{item?.cost}</td>
                                <td>{item?.contract}</td>
                                <td>{item?.agency}</td>
                                <td>{item?.approved}</td>
                                <td>{item?.utilised}</td>
                                <td>{item?.percentage}</td>
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="text-left">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>No data found</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                )}
                    </Table>
                </div>
            
        </div>
    );
};

export default StageSecond;
