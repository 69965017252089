export const MANAGE_LABELS = {
    // -------------other---------------
    START_YEAR:'START_YEAR',
    END_YEAR:'END_YEAR',
    RUSA_PHASE: 'RUSA_PHASE',
    COMPONENT: 'COMPONENT',
    TOTAL_CENTRAL_RECEIVED: 'TOTAL_CENTRAL_RECEIVED',
    TOTAL_STATE_RECEIVED: 'TOTAL_STATE_RECEIVED',
    TOTAL_FUND_RECEIVED: 'TOTAL_FUND_RECEIVED',
    REMARKS: 'REMARKS',
};
