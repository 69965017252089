
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Table } from 'reactstrap';
import { api } from '../../../../helpers/api';
import { getLoggedInUser } from '../../../../helpers/authUtils';

export default function ViewComponent(props) {
    const [loading, setLoading] = useState(true);
    const [institutionList, setInstitutionList] = useState([]);
    const userData = getLoggedInUser();
    const user = userData?.role_id;

    
    

    let fetchData;
    if (user === '9') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-section');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setInstitutionList(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '10') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-state');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setInstitutionList(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '7') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-research');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setInstitutionList(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '8') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-nodal');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setInstitutionList(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '11') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-assistant');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setInstitutionList(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '12') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-system');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setInstitutionList(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else if (user === '6') {
        fetchData = async () => {
            try {
                const response = await api.get('institution-finance');
                // // console.log(response);
                const jsonData = response?.data?.data;

                // console.log(jsonData);
                setInstitutionList(jsonData?.institutions);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    } else {
    }

    useEffect(() => {
        fetchData();
    }, []);
    // console.log(institutionList);
   
    return (
        <div className="view">
            <Container className="p-0">
                <Card>
                    <CardBody className="px-5 pb-5 pt-4">
                        <h5 className="header-title mb-3 mt-0">RUSA 2 Institutions</h5>
                        <div className="table-section w-100 ">
                            <Table striped>
                                <thead className="table-head">
                                    <tr className="text-left">
                                        <th className="w-25">Name of Istitution</th>
                                    </tr>
                                </thead>
                                {institutionList.filter((item) => item.phase === "RUSA 2" || item.phase === "RUSA 1 & 2").length > 0 ? (
                                    <tbody>
                                        {institutionList.map((item, index) => (
                                            item.phase === "RUSA 2" || item.phase === "RUSA 1 & 2" ?
                                            (<tr key={index} className="text-left">
                                                <>
                                                    <td className="text-justify">{item.institution_name}</td>
                                                  
                                                </>
                                            </tr>):("")
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan="4" className="text-center">
                                                No data Found
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}
