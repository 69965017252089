import classnames from 'classnames';
import React, { useState } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import HeadAuditoriumDetails from './head_auditorium_details';
import HeadBuildingDetails from './head_building_details';
import HeadClassroomDetails from './head_class_room_details';
import HeadFacilities from './head_facilities';
import HeadInternetCollection from './head_internet_connection';
import HeadItAssetDetails from './head_it_assets_details';
import HeadLibraryDetails from './head_library_details';
import HeadSeminarHall from './head_seminar_hall';

function AdminInfrastructure() {
    const tabContents = [
        { id: '1', title: 'Building Details' },
        { id: '2', title: 'Facilities' },
        { id: '3', title: 'Classroom Details' },
        { id: '4', title: 'Auditorium Details' },
        { id: '5', title: 'Seminar Hall' },
        { id: '6', title: 'IT Asset Details' },
        { id: '7', title: 'Internet Connection' },
        { id: '8', title: 'library details' },
    ];
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (id) => {
        setActiveTab(id);
    };
    return (
        <div>
            {' '}
            <Container className="form-container">
                <Card>
                    <CardBody>
                        <h5 className="header-title mb-3 mt-0">Infrastructure</h5>

                        <>
                            <Nav className="nav nav-pills navtab-bg nav-justified">
                                {tabContents.map((tab, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === tab.id })}
                                                onClick={() => {
                                                    toggle(tab.id);
                                                }}>
                                                <i className={classnames(tab.icon, 'd-sm-none', 'd-block', 'mr-1')}></i>
                                                <span className="d-none d-sm-block">{tab.title}</span>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>

                            <TabContent>
                                {activeTab === '1' ? (
                                    <>
                                        <HeadBuildingDetails />
                                    </>
                                ) : activeTab === '2' ? (
                                    <>
                                        <HeadFacilities />
                                    </>
                                ) : activeTab === '3' ? (
                                    <>
                                        <HeadClassroomDetails />
                                    </>
                                ) : activeTab === '4' ? (
                                    <>
                                        <HeadAuditoriumDetails />
                                    </>
                                ) : activeTab === '5' ? (
                                    <>
                                        <HeadSeminarHall />
                                    </>
                                ) : activeTab === '6' ? (
                                    <>
                                        <HeadItAssetDetails />
                                    </>
                                ) : activeTab === '7' ? (
                                    <>
                                        <HeadInternetCollection />
                                    </>
                                ) : (
                                    <>
                                        <HeadLibraryDetails />
                                    </>
                                )}
                            </TabContent>
                        </>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default AdminInfrastructure;
