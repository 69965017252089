// @flow

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Cookies from 'js-cookie'; // Import Cookies from js-cookie

import { api, fetchJSON, refreshheaderToken } from '../../helpers/api';

import { FORGET_PASSWORD, LOGIN_USER, LOGOUT_USER, REGISTER_USER } from './constants';

import {
    forgetPasswordFailed,
    forgetPasswordSuccess,
    loginUserFailed,
    loginUserSuccess,
    registerUserFailed,
    registerUserSuccess,
} from './actions';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
    if (user) {
        // Use Cookies.set() to set the cookie
        Cookies.set('user', JSON.stringify(user));
    } else {
        // Use Cookies.remove() to remove the cookie
        Cookies.remove('user');
    }
};

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
    try {
        const response = yield call(api.post, 'login', { email: username, password });
        // console.log(response);
        if (response.status === 200) {
            refreshheaderToken(response?.data?.data?.token);

            const user = { ...response.data.data.user, token: response?.data?.data?.token };

            let data = { ...user };
            refreshheaderToken(response?.data?.token);

            // Use Cookies.set() to set the token cookie
            Cookies.set('token', response?.data?.data?.token);

            const institutionData = response?.data?.data?.institution;
            if (institutionData) {
                // Use Cookies.set() to set the institution cookie
                Cookies.set('institution', JSON.stringify(institutionData));
            }

            setSession(data);
            window.location.reload();

            yield put(loginUserSuccess(response.data));
        } else {
            yield put(loginUserFailed(response.message));
        }
    } catch (error) {
        // console.log(error);
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error.response.data.message;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);

        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/register', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ username }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchRegisterUser), fork(watchForgetPassword)]);
}

export default authSaga;
