import React, { useState } from 'react';
import { api } from '../../../../../../helpers/api';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Form,
    Button,
    Table,
} from 'reactstrap';
import FailureError from '../error';
const PreviewInformation = (props) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const handleSaveData = () => {
    //     if (isSubmitting) {
    //         // If already submitting, do nothing
    //         return;
    //     }

    //     setIsSubmitting(true);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };
    const formDataWithoutPassword = { ...props.formData };
    formDataWithoutPassword.role = 'institution-admin';
    const handleSaveData = () => {
        if (!props.edit) {
            if (isSubmitting) {
                // If already submitting, do nothing
                return;
            }
            setIsSubmitting(true);
    
            api.post('institution', formDataWithoutPassword)
                .then((response) => {
                    // Handle the response from the API if needed
                    // console.log('Institution creation success:', response.data);
                    props.setSuccess(true);
                })
                .catch((error) => {
                    // Handle errors if the POST request fails
                    // console.error('Institution creation fails:', error);
                    toggleModal();
                })
                .finally(() => {
                    setIsSubmitting(false); // Reset isSubmitting state after API call is complete
                });
            } else {
                const payload = { ...props.formData }; // Use formData instead of data
                payload.is_approved = 1;
        
                api.put(`/institution/${props.formData.id}`, payload) // Use formData instead of data
                    .then((response) => {
                        props.setSuccess(true);
                    })
                    .catch((error) => {
                        // Handle error, maybe show an error message
                        // console.error('Error updating data:', error);
                    });
            }
    };
    
    return (
        <div>
            <div className="d-flex justify-content-end my-3">
                {' '}
                <Button color="primary" onClick={() => props.setActiveStep(1)}>
                    Edit
                </Button>
            </div>
            <div className="preview-page">
                <Row>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Institution Name
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={6} sm={6} className='capitalize'>
                                {props.formData?.institution_name}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Email ID
                            </Col>
                            <Col md={1} sm={1}>
                                :
                            </Col>
                            <Col md={6} sm={6}>
                                {props.formData?.email}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="my-3">
                    <Button color="primary" onClick={handleSaveData} disabled={isSubmitting}>
                        Submit
                    </Button>
                </div>
            </div>
            <FailureError isOpen={isModalOpen} toggle={toggleModal} />
        </div>
    );
};


export default PreviewInformation;
