import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Container } from 'reactstrap';
import { api } from '../../../../../../../../helpers/api';
import Failure from '../../../../../../institutions/failure';
const DPR = (props) => {
    // console.log(props);
    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [generalDetails, setGeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [revert, setRevert] = useState(false);

    
        // Function to fetch data
        const fetchData = async () => {
            try {
                const response = await api.get(`institution/${id}`); // Assuming api.get works as expected
                // console.log(response);
                const jsonData = response?.data?.data?.data;

                // console.log(jsonData);
                setGeneralDetails(jsonData.dpr);
            } catch (error) {
                // console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        useEffect(() => {
        fetchData();
    }, [id]);
    // console.log(props);
    const handleRevert = async (itemId) => {
        try {
            const response = await api.post(`/dpr/resubmit/${itemId}`);
            const updatedDetails = generalDetails.map((item) =>
                item.id === itemId ? { ...item, is_approved: 3 } : item
            );
            setGeneralDetails(updatedDetails);
            setRevert(true);
        } catch (error) {
            // console.error('Error reverting data:', error);
        }
    };
    const handleRevertAction = () => {
        fetchData();
        setRevert(false);
    };
    return (
        <div>
            {revert && (
                <Failure
                    title="Details of DPR has been reverted by RUSA Admin"
                    toggle={handleRevertAction}
                    open={revert}
                />
            )}
            <div className="view">
                <Container className="container-width">
                    <div className='overflow-auto'>
                        <table class="table">
                        <thead className="table-head ml-5 mt-0">
                                <tr>
                                    <th style={{minWidth:"150px"}}>DPR</th>
                                    <th style={{minWidth:"250px"}}>Approved plan for Construction</th>
                                    <th style={{minWidth:"250px"}}>Approved plan for Renovation</th>
                                    <th style={{minWidth:"300px"}}>Remarks</th>
                                    <th style={{minWidth:"150px"}}>Status</th>
                                    <th style={{minWidth:"150px"}}>Actions</th>
                                </tr>
                            </thead>
                            {generalDetails?.length > 0 ? (
                                <tbody>
                                    {generalDetails.map((item, index) =>
                                       <tr key={index} className="text-left">
                                       <td style={{maxWidth:"150px"}}>{item.link}</td>
                                       
                                       <td>
                                           <a href={item.construction_plan} target="_blank" rel="noopener noreferrer">
                                               View
                                           </a>
                                       </td>
                                       <td>
                                           <a href={item.renovation_plan} target="_blank" rel="noopener noreferrer">
                                               View
                                           </a>
                                       </td>
                                       <td className='text-justify'>{item.remarks}</td>
                                       <td>
                                       {item.is_approved === 0 ? (
                                           <td>
                                               <Button
                                                   disabled
                                                   color="warning"
                                                   class="btn btn-secondary"
                                                   style={{
                                                       color: '#ED7D31',
                                                       fontWeight: '400',
                                                       backgroundColor: '#F5F7F8',
                                                   }}
                                                   size="sm">
                                                   Pending
                                               </Button>
                                           </td>
                                       ) : item.is_approved === 2 ? (
                                           <td>
                                               <Button type="button" size="sm" color="danger" disabled>
                                                   Reverted by Head
                                               </Button>
                                           </td>
                                       ) : item.is_approved === 1 ? (
                                           <td>
                                               <Button type="button" color="success" size="sm" disabled>
                                                   Approved by Head
                                               </Button>
                                           </td>
                                       ) : item.is_approved === 3 ? (
                                           <td>
                                               <Button type="button" color="info" size="sm" disabled>
                                                   Reverted by RUSA Admin
                                               </Button>
                                           </td>
                                       ) : (
                                           ''
                                       )}
                                       </td>
                                        <td>
                                                            {item.is_approved === 1 && (
                                                                <td>
                                                                    <Button
                                                                        type="button"
                                                                        size="sm"
                                                                        outline
                                                                        color="primary"
                                                                        onClick={() => handleRevert(item.id)}
                                                                    >  
                                                                        Revert
                                                                    </Button>
                                                                </td>
                                                            )}
                                                        </td>
                                   </tr>
                                    )}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            No data Found
                                        </td>
                                    </tr>
                                </tbody>
                            )}

                        </table>

                    </div>
                </Container>
            </div>
        </div>
    );
};

export default DPR;
