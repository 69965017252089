import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { api } from '../../../../../../helpers/api';
const PreviewInformation = (props) => {
    // console.log(props);
    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };
    const handleSaveData = () => {
        if (!props.edit) {
            const accrediation_status = true;
            const payload = { ...props.formData };
            payload.accrediation_status = accrediation_status;
            
            api.post('courses', payload)
                .then((response) => {
                    // Handle the response from the API if needed
                    // console.log('success:', response.data);
                    props.handleSaveData();
                })
                .catch((error) => {
                    // Handle errors if the POST request fails
                    // console.error('fails:', error);
                    toggleModal();
                });
        } else {
            // If in edit mode, make a PUT request
            const data = props.formData;

            api.put(`courses/${props.formData.id}`, data)
                .then((response) => {
                    props.handleSaveData();
                })
                .catch((error) => {
                    // Handle error, maybe show an error message
                    // console.error('Error updating data:', error);
                    toggleModal();
                });
        }
    };
    return (
        <div>
            <div className="preview-page">
                <Row>
                    <Col md={12} sm={12}>
                        <div className="d-flex justify-content-end">
                            <div>
                                <Button color="primary" onClick={() => props.setActiveStep(1)}>
                                    Edit
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Course Type
                            </Col>
                            <Col md={2} sm={2}>
                                :
                            </Col>
                            <Col md={6} sm={6}>
                                {props.formData?.course}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Specializations
                            </Col>
                            <Col md={2} sm={2}>
                                :
                            </Col>
                            <Col md={6} sm={6}>
                                {props.formData?.specialisation}
                            </Col>
                        </Row>
                    </Col>

                    {/* <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Actions
                            </Col>
                            <Col md={2} sm={2}>
                                :
                            </Col>
                            <Col md={6} sm={6}>
                                {props.formData?.SUBJECT}
                            </Col>
                        </Row>
                    </Col> */}

                    {/* <Col sm={12} md={12} className="my-3">
                        <Row className="text-dark">
                            <Col md={4} sm={4}>
                                Remarks
                            </Col>
                            <Col md={2} sm={2}>
                                :
                            </Col>
                            <Col md={6} sm={6}>
                                {props.formData?.REMARKS}
                            </Col>
                        </Row>
                    </Col> */}

                    <Row className="px-2 mt-3">
                        <Col md={12} sm={12}>
                            <Button color="primary" onClick={() => handleSaveData()}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Row>
            </div>
        </div>
    );
};

export default PreviewInformation;
