import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { api } from '../../../../../helpers/api';

function HeadAuditoriumDetails(props) {
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await api.get(`admin-institution-info`); // Assuming api.get works as expected
            const jsonData = response?.data?.data?.data?.buildings;
            setgeneralDetails(jsonData);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // const handleEdit = (id) => {
    //     // const index = generalDetails.findIndex((item) => item.id === id);
    //     // console.log(index);
    //     // setEditIndex(index);
    //     // props.setGeneralData({ ...generalDetails[index] });
    //     props.setNewReg(true);
    //     props.setEdit(true);

    // };

    return (
        <div>
            <div className="view">
                {/* <div className="banner">OTHER ACCREDITATIONS</div> */}
                <Container className="container-width">
                    {}
                    <div className="table-section w-100">
                        {generalDetails?.length > 0 ? (
                            <>
                                {' '}
                                {generalDetails?.map((data, i) => (
                                    <>
                                        <div className="preview-page mx-0">
                                            <Row>
                                                <Col className='my-4'>
                                                    <>
                                                        {data.is_approved === 0 && (
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 1 && (
                                                            <Button disabled color="success" size="sm">
                                                                Approved
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 2 && (
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted
                                                            </Button>
                                                        )}
                                                        {data.is_approved === 3 && (
                                                            <Button type="button" size="sm" color="danger" disabled>
                                                                Reverted by head
                                                            </Button>
                                                        )}
                                                    </>
                                                </Col>
                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={6} sm={6}>
                                                            Total Area of land possessed by the institution (in acres)
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={5} sm={5}>
                                                            {data.area ?? '--'}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={6} sm={6}>
                                                        Whether all the buildings are disabled friendly
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={5} sm={5}>
                                                            {data.disabled === 1 ? 'Yes' : 'No'}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={6} sm={6}>
                                                            if Yes /No, Give details :
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={5} sm={5}>
                                                        <div className="text-justify">{data.details ?? '--'}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={6} sm={6}>
                                                            Total Number of newly constructed buildings in the last 5
                                                            years
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={5} sm={5}>
                                                            {data.building_count ?? '--'}
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col sm={12} md={12} className="">
                                                <div className="d-flex justify-content-center w-100">
                                                    <Table striped className="">
                                                    <thead className="table-head" >
                                                    <tr>
                                                                <th colSpan={2}>
                                                                Provide details of the funding agency of each building
                                                                </th>
                                                                
                                                            </tr>
                                                    </thead>
                                                        <thead className="table-head">
                                                            
                                                            <tr className="text-left">
                                                                <th style={{ maxWidth: '525px' }}>Building Details</th>
                                                                <th style={{ maxWidth: '525px' }}>Funding Agency</th>
                                                            </tr>
                                                        </thead>
                                                        {data.funding_agencies ? (
                                                            <tbody>
                                                                {data.funding_agencies.map((item, k) => (
                                                                    <tr className="text-left" key={k}>
                                                                        <td
                                                                           
                                                                            style={{
                                                                                textAlign: 'justify', maxWidth: '525px'
                                                                            }}>
                                                                            {item.agency_name ?? '--'}
                                                                        </td>
                                                                        <td
                                                                           
                                                                            style={{
                                                                                textAlign: 'justify', maxWidth: '525px'
                                                                            }}>
                                                                            {item.building_name ?? '--'}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                <tr className="text-center">
                                                                    <td colSpan="3">No data found</td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </Table>
                                                </div>
                                            </Col>

                                                <Col sm={12} md={12} className="my-3">
                                                    <Row className="text-dark">
                                                        <Col md={6} sm={6}>
                                                            Remarks
                                                        </Col>
                                                        <Col md={1} sm={1}>
                                                            :
                                                        </Col>
                                                        <Col md={5} sm={5}>
                                                        <div className="text-justify">{data.remarks ?? '--'}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className="preview-page mx-0">
                                    <Row>
                                        <Col sm={12} md={12} className="my-3">
                                            <Row className="text-dark">
                                                <Col md={6} sm={6}>
                                                    Total Area of land possessed by the institution (in acres)
                                                </Col>
                                                <Col md={1} sm={1}>
                                                    :
                                                </Col>
                                                <Col md={5} sm={5}>
                                                    --
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12} md={12} className="my-3">
                                            <Row className="text-dark">
                                                <Col md={6} sm={6}>
                                                Whether all the buildings are disabled friendly
                                                </Col>
                                                <Col md={1} sm={1}>
                                                    :
                                                </Col>
                                                <Col md={5} sm={5}>
                                                    --
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12} md={12} className="my-3">
                                            <Row className="text-dark">
                                                <Col md={6} sm={6}>
                                                    if Yes /No, Give details :
                                                </Col>
                                                <Col md={1} sm={1}>
                                                    :
                                                </Col>
                                                <Col md={5} sm={5}>
                                                    --
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12} md={12} className="my-3">
                                            <Row className="text-dark">
                                                <Col md={6} sm={6}>
                                                    Total Number of newly constructed buildings in the last 5 years
                                                </Col>
                                                <Col md={1} sm={1}>
                                                    :
                                                </Col>
                                                <Col md={5} sm={5}>
                                                    --
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col sm={12} md={12} className="mt-3 mb-1">
                                            {' '}
                                            <div className="d-flex justify-content-center w-100">
                                                <Table striped className="">
                                                    <thead className="table-head">
                                                        <tr className="text-left">
                                                            <th style={{ minWidth: '50px' }}>Building Details</th>
                                                            <th style={{ minWidth: '150px' }}>Funding Agency</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr className="text-center">
                                                            <td colSpan={2}>No data found</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>

                                        <Col sm={12} md={12} className="my-3">
                                            <Row className="text-dark">
                                                <Col md={6} sm={6}>
                                                    Remarks
                                                </Col>
                                                <Col md={1} sm={1}>
                                                    :
                                                </Col>
                                                <Col md={5} sm={5}>
                                                    --
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        )}
                    </div>
                </Container>
            </div>
        </div>
    );
}
export default HeadAuditoriumDetails;
