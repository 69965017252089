import React from 'react';
import { Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import PreviewInformation from '../preview/index.jsx';

const FormArea = (props) => {
    const { formData, setFormData, handleSaveData, activeStep, handleNext, setActiveStep } = props;
    const handleSubmit = (e) => {
        e.preventDefault();
        handleNext();
    };
    return (
        <div>
            <div className="manage">
                <div className="form-section">
                    <div className="info">
                        <div className="mandatory">{/* <sup>*</sup>All fields are mandatory. */}</div>
                    </div>
                    <Container className="form-container permanent-area">
                        <div>
                            {activeStep === 1 ? (
                                <>
                                    <Form onSubmit={handleSubmit}>
                                        <>
                                            <>
                                                {' '}
                                                <Row className="my-3">
                                                    <Col md={6} sm={12} my-2>
                                                        <Label className="my-2">
                                                            Select Course Type<sup className="text-danger">*</sup>
                                                        </Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="select"
                                                            value={formData.course}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    course: e.target.value,
                                                                })
                                                            }>
                                                            <option value="">- select -</option>
                                                            <option value="PG">PG</option>
                                                            <option value="UG">UG</option>
                                                            <option value="MPhill">Mphil</option>
                                                            <option value="Phd">Phd</option>
                                                        </Input>
                                                    </Col>

                                                    <Col md={6} sm={12} my-2>
                                                        <Label className="my-2">
                                                            Specialization<sup className="text-danger">*</sup>
                                                        </Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="text"
                                                            value={formData.specialisation}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    specialisation: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Col>
                                                    {/* <Col md={4} sm={12} my-2>
                                                        <Label className="my-2">Subject</Label>
                                                        <Input
                                                            required
                                                            autoComplete="off"
                                                            type="text"
                                                            value={formData.SUBJECT}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    SUBJECT: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Col> */}
                                                </Row>{' '}
                                            </>
                                            {/* -----------end---------- */}
                                        </>
                                        {/* <Row>
                                            <Col md={12} sm={12} my-2>
                                                <Label className="my-2">Remarks</Label>
                                                <Input
                                                    required
                                                    autoComplete="off"
                                                    type="text"
                                                    value={formData.REMARKS}
                                                    onChange={(e) =>
                                                        setFormData({ ...formData, REMARKS: e.target.value })
                                                    }
                                                />
                                            </Col>
                                        </Row> */}{' '}
                                        <div className="my-3">
                                            <Button type="submit">
                                                {/* // onClick={() => (activeStep === 1 ?  : handleSaveData())}> */}
                                                {activeStep == 0 ? 'Submit for Approval' : 'Submit'}
                                            </Button>
                                        </div>
                                    </Form>
                                </>
                            ) : (
                                <>
                                    <PreviewInformation
                                        formData={formData}
                                        activeStep={activeStep}
                                        handleSaveData={handleSaveData}
                                        setActiveStep={setActiveStep}
                                    />
                                </>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default FormArea;
