import React, { useEffect, useState } from 'react';
import { Edit } from 'react-feather';
import { api } from '../../../../../helpers/api';
import FormArea from './form';
import Cookies from 'js-cookie';
import { Button, Table } from 'reactstrap';
import Success from '../../../success';
export default function ViewComponent(props) {
    const handleFields = () => {
        return {};
    };
    const [formData, setFormData] = useState({ ...handleFields() });
    const [formDatas, setFormDatas] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [success, setSuccess] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [edit, setEdit] = useState(false);
    const [generalDetails, setgeneralDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleEdit = (id) => {
        const index = generalDetails.findIndex((item) => item.id === id);
        setEditIndex(index);
        setFormData({ ...generalDetails[index] });
        setOpenForm(true);
        setEdit(true);
    };
    const handleCreate = () => {
        setOpenForm(true);
    };

    const handleCancelEdit = () => {
        setEditIndex(null);
        setFormData({ ...handleFields() });
        setOpenForm(false);
    };
    const handleSaveData = () => {
        setSuccess(true);
    };
    const handleSuccessAction = () => {
        setSuccess(false);
        fetchData();
        if (Object.keys(formData)?.length > 0) {
            const data = formDatas;
            data.push(formData);
            setFormDatas(data);
            setFormData({ ...handleFields() });
            setOpenForm(false);
            setActiveStep(1);
        } else {
            alert('Please fill the form');
        }
    };
    const handleNext = (edit) => {
        // console.log(edit);
        setActiveStep(0);
    };

    const fetchData = async () => {
        try {
            const response = await api.get(`co-institution-info`);
            const jsonData = response?.data?.data?.data?.general_info;
            const updatedUsers = jsonData?.map((user) => {
                // if (!['Society', 'Corporate', 'Minority', 'Trust'].includes(user.name_of_college_management)) {
                if (
                    user.name_of_college_management === 'Society' ||
                    user.name_of_college_management === 'Corporate' ||
                    user.name_of_college_management === 'Minority' ||
                    user.name_of_college_management === 'Trust'
                ) {
                    user.name_of_college_management = user.name_of_college_management;
                } else {
                    user.other_nature = user.name_of_college_management;
                    user.name_of_college_management = 'Others';
                }
                return user;
            });
            setgeneralDetails(updatedUsers);
            // console.log(jsonData);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const userData = JSON.parse(Cookies.get('institution'));
    return (
        <div>
            <>
                {success && (
                    <Success
                        title="General Information submitted for approval by the Institution head"
                        toggle={handleSuccessAction}
                        open={success}
                    />
                )}
                {openForm && (
                    <>
                        <FormArea
                            formData={formData}
                            handleNext={handleNext}
                            activeStep={activeStep}
                            setFormData={setFormData}
                            handleSaveData={handleSaveData}
                            handleCancelEdit={handleCancelEdit}
                            edit={edit}
                            handleEdit={handleEdit}
                            {...generalDetails[editIndex]}
                            setActiveStep={setActiveStep}
                        />
                    </>
                )}
                {!openForm && (
                    <div className="table-section w-100 ">
                        <div className="table-section">
                            {generalDetails.length > 0 ? (
                                generalDetails.map((data, i) => (
                                    <Table striped key={i}>
                                        <tbody>
                                            <React.Fragment>
                                                <tr style={{ width: '50%' }}>
                                                    {data.is_approved === 0 ? (
                                                        <td>
                                                            <Button
                                                                disabled
                                                                color="warning"
                                                                class="btn btn-secondary"
                                                                style={{
                                                                    color: '#ED7D31',
                                                                    fontWeight: '400',
                                                                    backgroundColor: '#F5F7F8',
                                                                }}
                                                                size="sm">
                                                                Pending
                                                            </Button>
                                                        </td>
                                                    ) : data.is_approved === 2 ? (
                                                        <td>
                                                            <Button disabled color="danger" size="sm">
                                                                Reverted by Head
                                                            </Button>
                                                        </td>
                                                    ) : data.is_approved === 1 ? (
                                                        <td>
                                                            <Button disabled color="success" size="sm">
                                                                Approved by Head
                                                            </Button>
                                                        </td>
                                                    ) : data.is_approved === 3 && (
                                                        <td>
                                                            <Button disabled color="info" size="sm">
                                                                Reverted by RUSA Admin
                                                            </Button>
                                                        </td>
                                                    )}
                                                    <td className="d-flex justify-content-end">
                                                        {data.is_approved === 2 ? (
                                                            <Edit
                                                                className="me-3 cursor-pointer"
                                                                onClick={() => handleEdit(data.id)}
                                                            />
                                                        ) : data.is_approved === 3 ? (
                                                            <Edit
                                                                className="me-3 cursor-pointer"
                                                                onClick={() => handleEdit(data.id)}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr className="w-100">
                                                    <td className="w-50">
                                                        <b>INSTITUTION NAME</b>
                                                    </td>
                                                    <td className="w-50">{userData?.institution_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="w-50">
                                                        <b>INSTITUTION ADDRESS</b>
                                                    </td>
                                                    <td className="w-50 text-justify">{data.address}</td>
                                                </tr>
                                                <tr>
                                                    <td className="w-50">
                                                        <b>INSTITUTION TYPE</b>
                                                    </td>
                                                    <td className="w-50">{data.type_of_institution}</td>
                                                </tr>
                                                <tr>
                                                    <td className="w-50">
                                                        <b>MANAGEMENT NATURE</b>
                                                    </td>
                                                    <td className="w-50 text-justify">
                                                        {data.name_of_college_management} <br></br>
                                                        {data.other_nature}
                                                    </td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>MANAGEMENT ADDRESS</b>
                                                    </td>
                                                    <td className="w-50  text-justify">
                                                        {data.name_address_of_institution_management}
                                                    </td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>AFFILIATED UNIVERSITY</b>
                                                    </td>
                                                    <td className="w-50">{data.affiliated_university}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>ESTABLISHMENT YEAR</b>
                                                    </td>
                                                    <td className="w-50">{data.year_of_establishment}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>DISTRICT</b>
                                                    </td>
                                                    <td className="w-50">{data.district}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>PARLIAMENTARY CONSTITUENCY</b>
                                                    </td>
                                                    <td className="w-50">{data.parliamentary_constituency}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>ASSEMBLY CONSTITUENCY</b>
                                                    </td>
                                                    <td className="w-50">{data.assembly_constituency}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>REVENUE DISTRICT OFFICE</b>
                                                    </td>
                                                    <td className="w-50 text-justify">
                                                        {data.revenue_district_office}
                                                    </td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>TALUK</b>
                                                    </td>
                                                    <td className="w-50 text-justify">{data.taluk}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>STATE</b>
                                                    </td>
                                                    <td className="w-50">{data.state || 'Kerala'}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>OFFICIAL EMAIL ID</b>
                                                    </td>
                                                    <td className="w-50">{userData?.email}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>OFFICIAL LANDLINE NUMBER</b>
                                                    </td>
                                                    <td className="w-50">{data.official_contact_no}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>NAME OF PRINCIPAL</b>
                                                    </td>
                                                    <td className="w-50">{data.principal}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>PRINCIPAL EMAIL ID</b>
                                                    </td>
                                                    <td className="w-50">{data.official_email_of_principal}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>CONTACT NUMBER OF PRINCIPAL</b>
                                                    </td>
                                                    <td className="w-50">{data.official_contact_no_of_principal}</td>
                                                </tr>{' '}
                                                <tr>
                                                    <td className="w-50">
                                                        <b>REMARKS</b>
                                                    </td>
                                                    <td className="w-50 text-justify">{data.remarks}</td>
                                                </tr>{' '}
                                            </React.Fragment>
                                        </tbody>
                                    </Table>
                                ))
                            ) : (
                                <Table striped>
                                    <tbody>
                                        <React.Fragment>
                                            <tr style={{ width: '50%' }}>
                                                <td></td>
                                                <td className="d-flex justify-content-end">
                                                    <Edit
                                                        className="me-3 cursor-pointer"
                                                        onClick={() => handleCreate()}
                                                    />
                                                </td>
                                            </tr>
                                            <tr style={{ width: '50%' }}>
                                                <td>
                                                    <b>INSTITUTION NAME</b>
                                                </td>
                                                <td>{userData?.institution_name}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>INSTITUTION ADDRESS</b>
                                                </td>
                                                <td>---</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>INSTITUTION TYPE</b>
                                                </td>
                                                <td>---</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>MANAGEMENT NATURE</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>MANAGEMENT ADDRESS</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>AFFILIATED UNIVERSITY</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>ESTABLISHMENT YEAR</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>DISTRICT</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>PARLIAMENTARY CONSTITUENCY</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>ASSEMBLY CONSTITUENCY</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>REVENUE DISTRICT</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>TALUK</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>STATE</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>OFFICIAL MAIL</b>
                                                </td>
                                                <td>{userData?.email}</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>OFFICIAL LANDLINE</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>PRINCIPAL NAME</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>PRINCIPAL MAIL</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>PRINCIPAL PHONE</b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                            <tr>
                                                <td>
                                                    <b>REMARKS </b>
                                                </td>
                                                <td>---</td>
                                            </tr>{' '}
                                        </React.Fragment>
                                    </tbody>
                                </Table>
                            )}
                        </div>
                    </div>
                )}
            </>
        </div>
    );
}
