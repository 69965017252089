import React, { useEffect, useState } from 'react';
import { api } from '../../../../../helpers/api';
import AutonomousComponent from './AutonomousComponent';
import FacultyImprovementsComponent from './FacultyImprovementsComponent';
import InfrastructureGrantsComponent from './InfrastructureGrantsComponent';

const InstitutionTypePhaseBeneficiaries = () => {
    const [generalDetails, setGeneralDetails] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await api.get('admin-institution-info');
            const jsonData = response?.data?.data?.data;
            // console.log(jsonData);
            setGeneralDetails(jsonData.general_info);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const type = generalDetails[0]?.type_of_institution;

    let ComponentToRender;

    switch (type) {
        case 'University':
            ComponentToRender = InfrastructureGrantsComponent;
            break;
        case 'Autonomous College':
            ComponentToRender = AutonomousComponent;
            break;
        case 'Self Financing College':
            ComponentToRender = AutonomousComponent;
            break;
        case 'Aided College':
            ComponentToRender = AutonomousComponent;
            break;
        case 'Government College':
            ComponentToRender = AutonomousComponent;
            break;
        default:
            ComponentToRender = FacultyImprovementsComponent;
            break;
    }

    return <ComponentToRender />;
};

export default InstitutionTypePhaseBeneficiaries;
