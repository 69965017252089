import React, { useEffect, useState } from 'react';
import FormArea from './form';
import { MANAGE_LABELS } from './datamanager';
import { Button, Table } from 'reactstrap';
import Success from '../../../success';
import {
    create_ordersProceedings,
    list_orderProceedings,
    order_delete,
} from '../../../../../helpers/actions/api.functions';
import { getErrorMessage } from '../../../../../components/genError';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Trash2 } from 'react-feather';
export default function ViewComponent(props) {
    const handleFields = () => {
        return {};
    };
    const [formData, setFormData] = useState({ ...handleFields() });
    const [formDatas, setFormDatas] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [flag, setFlag] = useState('Y');
    const [success, setSuccess] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const handleSaveData = () => {
        const payload = new FormData();

        if (formData?.DATE) {
            payload.append('date', formData?.DATE);
        }
        if (formData?.GOVT_ORDER_PROCEEDINGS_DETAILS) {
            payload.append('details', formData?.GOVT_ORDER_PROCEEDINGS_DETAILS);
        }
        if (formData?.UPLOAD_FILE) {
            payload.append('file', formData?.UPLOAD_FILE);
        }
        if (formData?.REMARKS) {
            payload.append('remarks', formData?.REMARKS);
        }
        payload.append('is_approved', 0);
        // console.log(payload);
        create_ordersProceedings(payload)
            .then((res) => {
                setSuccess(true);
            })
            .catch((err) => {
                // console.log(getErrorMessage(err.response.data.error));
                toast.error(`${getErrorMessage(err.response.data.error)}`, { duration: 4000, position: 'top-right' });
            });
    };
    const getOrdersListing = () => {
        list_orderProceedings()
            .then((res) => {
                setFormDatas(res?.data?.data);
            })
            .catch((err) => {
                toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
            });
    };

    useEffect(() => {
        getOrdersListing();
    }, []);
    const handleSuccessAction = () => {
        setSuccess(false);
        if (Object.keys(formData)?.length > 0) {
            const data = formDatas;
            data.push(formData);
            // setFormDatas(data);
            setFormData({ ...handleFields() });
            setOpenForm(false);
            setActiveStep(1);
        } else {
            alert('Please fill the form');
        }
    };
    const handleNext = () => {
        setActiveStep(0);
    };
    const handleBack = () => {
        setOpenForm(true);
        setActiveStep(1);
    };
    const deleteOrder = (id) => {
        order_delete(id)
            .then(() => {
                toast.error(`Order Deleted Successfully`, { duration: 4000, position: 'top-right' });
                getOrdersListing()
            })
            .catch((err) => {
                toast.error(`${getErrorMessage(err.response.data.error)}`, { duration: 4000, position: 'top-right' });
            });
    };
    return (
        <div>
            <>
                {!openForm && (
                    <div className="d-flex justify-content-end my-3 ">
                        {' '}
                        <Button onClick={() => setOpenForm(true)} color="primary">
                            Create
                        </Button>
                    </div>
                )}
                {success && (
                    <Success
                        title="Govt Orders & Proceedings submitted for approval by the RUSA Admin"
                        toggle={handleSuccessAction}
                        open={success}
                    />
                )}

                {openForm && (
                    <>
                        <FormArea
                            formData={formData}
                            handleNext={handleNext}
                            activeStep={activeStep}
                            setFormData={setFormData}
                            handleSaveData={handleSaveData}
                            setActiveStep={setActiveStep}
                            handleBack={handleBack}
                        />
                    </>
                )}
                {!openForm && (
                    <div className="table-section w-100 ">
                        <Table striped>
                            <thead className="table-head">
                                <tr className="text-left">
                                    <th style={{ minWidth: '150px' }}>Date</th>
                                    <th style={{ minWidth: '150px' }}>Govt Orders & Proceedings details</th>
                                    <th style={{ minWidth: '100px' }}>Uploaded File</th>
                                    <th style={{ minWidth: '100px' }}>Remarks </th>
                                    <th style={{ minWidth: '100px' }}>Actions </th>
                                </tr>
                            </thead>
                            {formDatas.length > 0 ? (
                                formDatas?.map((item) => (
                                    <tbody>
                                        <tr className="text-left">
                                            <td>{item?.date ? moment(item?.date).format('DD-MM-YYYY') : '--'}</td>
                                            <td>{item?.details}</td>
                                            <td
                                                className="text-warning"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    const fileUrl = item?.file;
                                                    if (fileUrl) {
                                                        window.open(fileUrl, '_blank');
                                                    }
                                                }}>
                                                View
                                            </td>
                                            <td>{item?.remarks}</td>
                                            <td>
                                                {/* <Button type="button" color="danger">
                                                    Delete
                                                </Button> */}
                                                <Trash2 className="" onClick={() => deleteOrder(item?.id)} />
                                            </td>
                                        </tr>
                                    </tbody>
                                ))
                            ) : (
                                <tbody>
                                    <tr className="text-center">
                                        <td colSpan={5}>No Data</td>
                                    </tr>
                                </tbody>
                            )}
                        </Table>
                    </div>
                )}
            </>
        </div>
    );
}
