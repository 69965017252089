import React, { useEffect } from 'react';
import { useState } from 'react';
import ManangeGeneralaInformation from './manage';
import GeneralInformation from './view';
import { MANAGE_LABELS } from './manage/datamanager';
import { list_agencies } from '../../../../../helpers/actions/api.functions';
import toast from 'react-hot-toast';

const Faculityfields2 = {
    [MANAGE_LABELS.year]: null,
    [MANAGE_LABELS.agency]: null,
    [MANAGE_LABELS.details]: null,
    [MANAGE_LABELS.remarks]: null,
};
const initial_values2 = [Faculityfields2];
function DataOperatorStaffPatternMain() {

const [faculityData2, setFaculityData2] = useState(initial_values2);
const [indData, setIndData] = useState({});
const [listData, setListData] = useState({});
const [edit, setEdit] = useState(false);
const [newReg, setNewReg] = useState(false);
const [newEdit, setNewEdit] = useState(false);

const manageEdit = () => {
    // console.log(indData);
    // console.log('manageEdit');
    const newData = [indData];
    setFaculityData2(newData);
    setEdit(true);
};
useEffect(() => {
    if (newEdit === true) {
        manageEdit();
    }
}, [indData, newEdit]);

const getOrdersListing = () => {
    list_agencies()
        .then((res) => {
            setListData(res?.data?.data);
        })
        .catch((err) => {
            toast.error(`Something Went Wrong`, { duration: 4000, position: 'top-right' });
        });
};

useEffect(() => {
    getOrdersListing();
    setEdit(false);
    setNewEdit(false);
    setFaculityData2(initial_values2);
    setIndData({});
}, []);
const onSubmit = (data) => {
    // console.log(data);
    setEdit(false);
    setNewEdit(false);
    setFaculityData2(initial_values2);
    // setFaculityData2(data.faculityData2);
    setIndData({});
    setEdit(false);
    setNewReg(false);
};

return (
    <div className="generalinfo">
        {newReg || edit ? (
            <ManangeGeneralaInformation
                faculityData2={faculityData2}
                setFaculityData2={setFaculityData2}
                edit={edit}
                newEdit={newEdit}
                setNewEdit={newEdit}
                onSubmit={onSubmit}
                fetchListing = {getOrdersListing()}
            />
        ) : (
            <GeneralInformation
                edit={edit}
                newEdit={newEdit}
                setNewEdit={setNewEdit}
                setEdit={manageEdit}
                list={listData}
                setIndData={setIndData}
            />
        )}
    </div>
);
}
export default DataOperatorStaffPatternMain;
