



// import { ServInstance } from "../config";
import axios from 'axios';
import Cookies from 'js-cookie';

const servInstance = process.env.REACT_APP_BASE_URL;

// Setting default headers for axios
axios.defaults.headers.common = {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    Authorization: Cookies.get('token') ? `Bearer ${Cookies.get('token')}` : null,
};

// Function to refresh the authorization token in headers
export const refreshheaderToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        Cookies.set('token', token);
    } else {
        axios.defaults.headers.common['Authorization'] = null;
        Cookies.remove('token');
    }
};

// API methods
const api = {
    get: (URL, queryParams, type = {}) => axios.get(servInstance + URL, queryParams, type).then((res) => res.data),
    post: (URL, reqData = {}) => axios.post(servInstance + URL, reqData).then((res) => res.data),
    put: (URL, reqData = {}) => axios.put(servInstance + URL, reqData).then((res) => res.data),
    delete: (URL, reqData = {}) => axios.delete(servInstance + URL, reqData).then((res) => res.data),
};

export default api;


















// // import { ServInstance } from "../config";
// import axios from 'axios';

// const servInstance = process.env.REACT_APP_BASE_URL;

// axios.defaults.headers.common = {
//     'Access-Control-Allow-Origin': '*',
//     Accept: 'application/json',
//     Authorization: `${localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : null}`,
// };

// export const refreshheaderToken = (token) => {
//     if (token) {
//         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//     } else {
//         axios.defaults.headers.common['Authorization'] = null;
//     }
// };

// const api = {
//     get: (URL, queryParams, type = {}) => axios.get(servInstance + URL, queryParams, type).then((res) => res.data),
//     post: (URL, reqData = {}) => axios.post(servInstance + URL, reqData).then((res) => res.data),

//     put: (URL, reqData = {}) => axios.put(servInstance + URL, reqData).then((res) => res.data),

//     delete: (URL, reqData = {}) => axios.delete(servInstance + URL, reqData).then((res) => res.data),
// };
// export default api;
